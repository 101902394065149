import axios from 'axios'

export default {
  getCombinedProducts () {
    // products are combined by artikelnumber. sizes as array in every product object
    return axios.get('/static/api/cms/allproducts.php?sort=Produktbezeichnung', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  // every size as own object
  getFlatProducts () {
    return axios.get('/static/api/cms/allproducts.php?sort=Produktbezeichnung&flat=true', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  //store product
  setProductData (product) {
    return axios.put('/static/api/cms/allproducts.php', JSON.stringify(product),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
      })
  },
  getInstitutionData (id) {
    return axios.get('/static/api/cms/institution.php?id=' + id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getUserData (id) {
    return axios.get('/static/api/cms/institution.php?userid=' + id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  setUserData (user) {
    return axios.put('/static/api/cms/user.php', JSON.stringify(user),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
      })
  },
  deleteUser (id, dsvgo) {
    return axios.delete('/static/api/cms/user.php?id=' + id+ '&dsvgo=' + dsvgo, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  deactivateUser (id) {
    var data = new FormData()
    data.append('userid', id)
    return axios.post('/static/api/auth/deactivateUser.php', data, {headers: {'Authorization': 'Bearer ' + localStorage.getItem('user-token'), 
      'Content-Type': 'application/x-www-form-urlencoded'}
    })
  },
  resetUserPW (id) {
    var data = new FormData()
    data.append('userid', id)
    return axios.post('/static/api/auth/setpw.php', data, {headers: {'Authorization': 'Bearer ' + localStorage.getItem('user-token'), 
      'Content-Type': 'application/x-www-form-urlencoded'}
    })
  },
  getAllInstitutionsData () {
    return axios.get('/static/api/cms/institution.php', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  setInstitutionData (institution) {
    return axios.put('/static/api/cms/institution.php', JSON.stringify(institution),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
      })
  },
  // before deleting check if there is no other knr registered
  countInstitutionNumber (kundennummer, id) {
    return axios.get('/static/api/cms/check_insti_nr.php?kundennummer='+kundennummer+'&id='+id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  deleteInstitution (id, dsvgo) {
    return axios.delete('/static/api/cms/institution.php?id=' + id + '&dsvgo=' + dsvgo, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getKrankenkassen () {
    return axios.get('/static/api/cms/krankenkassen.php?sort=kasse', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  setKVData (kv) {
    return axios.put('/static/api/cms/krankenkassen.php', JSON.stringify(kv),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
      })
  },
  deleteKVData (id) {
    return axios.delete('/static/api/cms/krankenkassen.php?id=' + id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  deleteProduct (id) {
    return axios.delete('/static/api/cms/allproducts.php?id=' + id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getWettbewerber () {
    return axios.get('/static/api/cms/wettbewerber.php', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  deleteWettbewerberData (id) {
    return axios.delete('/static/api/cms/wettbewerber.php?id=' + id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  setWettbewerberData (wettbewerber) {
    return axios.put('/static/api/cms/wettbewerber.php', JSON.stringify(wettbewerber),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
      })
  },
  getRecommendations () {
    return axios.get('/static/jsons/recommendations.json')
  },
  getTextRecommendations () {
    return axios.get('/static/jsons/textrecommendations.json')
  },
  getWriteTempToProd () {
    return axios.get('/static/api/cms/uploadcsv.php?writeTempTableToProducts=true', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getPaketsCount (paketId) {
    return axios.get('/static/api/cms/pakets.php?countPaketId='+paketId, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getRolesCount (roleId) {
    return axios.get('/static/api/cms/roles.php?countRoleId='+roleId, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getAllRoles () {
    return axios.get('/static/api/cms/roles.php', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  setRole (role) {
    return axios.put('/static/api/cms/roles.php', JSON.stringify(role),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
    })
  },
  deleteRole(id) {
    return axios.delete('/static/api/cms/roles.php?id=' + id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getAllRights () {
    return axios.get('/static/api/cms/rights.php', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getPakets (demo) {
    if (demo == true) {
      return axios.get('/static/api/cms/pakets.php?demo=true', { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }})
    } else {
      return axios.get('/static/api/cms/pakets.php', { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }})
    }
  
  },
  setPaket (paket) {
    return axios.put('/static/api/cms/pakets.php', JSON.stringify(paket),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
    })
  },
  deletePaket(id) {
    return axios.delete('/static/api/cms/pakets.php?id=' + id, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  getOwnPrices (instiId) {
    return axios.get('/static/api/cms/insti_prices.php?institutionsid='+instiId, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  setOwnPrices (prices, instiId) {
    return axios.put('/static/api/cms/insti_prices.php?institutionsid='+instiId, JSON.stringify(prices),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
    })
  },
  deleteOwnPrice(id,instiId) {
    return axios.delete('/static/api/cms/insti_prices.php?id=' + id + '&institutionsid='+instiId, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },

  getOwnKVs(instiId) {
    return axios.get('/static/api/cms/insti_kv.php?institutionsid='+instiId, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  setOwnKVs (kvs, instiId) {
    return axios.put('/static/api/cms/insti_kv.php?institutionsid='+instiId, JSON.stringify(kvs),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }
    })
  },
  deleteOwnKV(id, instiId) {
    return axios.delete('/static/api/cms/insti_kv.php?id=' + id + '&institutionsid='+instiId, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('user-token')
    }})
  },
  checkKnr(knr) {
    return axios.get('/static/api/register/checkknr.php?knr='+knr)
  },
  checkRecaptcha(token) {
    return axios.get('/static/api/register/checkrecaptcha.php?token='+token)
  },
  register (registerModel) {
    return axios.put('/static/api/register/register.php', JSON.stringify(registerModel),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    })
  },
  sendMail (contactModel) {
    return axios.put('/static/api/register/sendMail.php', JSON.stringify(contactModel),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    })
  },
  getWriteTempToProdKunden (from) {
    if (from == 'match') {
      return axios.put('/static/api/register/uploadmatchingcsv.php?writeTempTableToKunden=true','', { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }}) 
    } else {
      return axios.put('/static/api/register/uploadadmcsv.php?writeTempTableToADM=true','', { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }}) 
    }
  },
  verifyEmail(id, token, del) {
    return axios.get('/static/api/register/checkEmailToken.php?id='+id+'&token='+token+'&del='+del)
  },
  resendEmail(mail, username, forPassword) {
    return axios.get('/static/api/register/resendLink.php?mail='+mail+'&username='+username+'&forPassword='+forPassword)
  },
  resetPassword(id, token, pwd) {
    var data = {'id':id, 'token':token, 'pwd':pwd} 
    return axios.put('/static/api/register/resetPassword.php', JSON.stringify(data),
      { headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    })
    //return axios.get('/static/api/register/resetPassword.php?id='+id+'&token='+token+'&pwd='+pwd)
  },
  getLastUploadDate(from) {
    if (from == 'match') {
      return axios.get('/static/api/register/uploadmatchingcsv.php?', { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }})
    } else {
      return axios.get('/static/api/register/uploadadmcsv.php?', { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token')
      }})
    }
   
  },
  applyForFullversionDB(id, token) {
    
    return axios.get('/static/api/register/fullversion.php?userid='+id, { headers: {
      'Authorization': 'Bearer ' + token
    }})
  
  }
  /*
  checkKnr (knr, name) {
    var bodyFormData = new FormData();
    bodyFormData.set('knr', knr);
    bodyFormData.set('name', name);
    return axios({
      method: 'post',
      url: '/static/api/register/checkknr.php',
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    })
  },*/
}
