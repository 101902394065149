Seite nach der Zusammenfassung. Hier wird das PDF zum Pflegehilfsmittelantrag generiert. -> /utils/pflegeantrag.js
<template>
  <div id="selling">
    <img style="width: 100%" :src="require('../assets/img/crossSelling_header.jpg')">
    <div id="sellingHeaderText">
      <h1>MoliCare® Skin Hautpflege</h1>
      <p>Aktiver Hautschutz mit MoliCare® Skin - Die MoliCare® Skin Hautpflegeprodukte sorgen mit dem Nutriskin Protection Complex für den Erhalt des Säureschutzmantels mit pH-Wert 5,5 und tragen somit zur Gesunderhaltung der Haut bei Inkontinenz bei.</p>
    </div>
    <div style="position: relative; top: -50px" class='breadcrumbArrow'>
      <div style="width: 68px" class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="four"></div>
    </div>
    <div id="sellingHautpflege">
      <img :src="require('../assets/img/crossSelling_hautpflege.jpg')">
      <div>
        <h2>Hautpflege</h2>
        <h4>MoliCare® Skin Reinigungsschaum</h4>
        <p>Sanft reinigender Schaum zur schnellen und gezielten Reinigung verschmutzter Hautpartien bei Inkontinenz. Ohne Wasser anzwenden, wirkt geruchsneutralisierend, unterstützt den natürlichen Hautschutzmechanismus.</p>
      </div>
    </div>
    <div id="sellingPflegemittel">
      <h2>Pflegehilfsmittel</h2>
      <div>
        <div>
          <img :src="require('../assets/img/crossSelling_product1.jpg')">
          <h4>Peha-soft® powderfree</h4>
          <p>Praktische Einmal-Handschuhe aus weichem Latex zum hygienischen Wechseln von Inkontinenzprodukten</p>
        </div>
        <div>
          <img :src="require('../assets/img/crossSelling_product2.jpg')">
          <h4>MoliCare® Premium Bed Mat</h4>
          <p>Mit ihrer undurchlässigen Außenfolie eignen sich die saugstarken Krankenunterlagen von MoliCare® Premium Bed Mat besonders gut als direkte Inkontinenzunterlage oder zusätzlicher Bettschutz</p>
        </div>
      </div>
    </div>
    <b-modal ref="pflegemittelAntrag" hide-footer title="Hinweis">
      <div class="d-block text-center">
        <div>
          <p v-if="answers.antrag === 'PA1'">
           Der Betroffene hat Pflegegrad {{getPfelgegrad()}} und einen Pflegehilfsmittelantrag gestellt. Bieten Sie ihm Pflegehilfsmittel an.
          </p>
          <p v-else>
            <span v-if="answers.antrag === 'PA2'">
              Der Betroffene hat Pflegegrad {{getPfelgegrad()}} und noch <b>keinen Pflegehilfsmittelantrag</b> gestellt.
            </span>
            <span v-else>Der Betroffene hat Pflegegrad {{getPfelgegrad()}} und nicht angegeben, ob er einen Pflegehilfsmittelantrag gestellt hat.</span>
            Bieten Sie ihm an, mit ihm gemeinsam den Pflegehilfsmittelantrag auszufüllen. <br />
            Weisen Sie ihn darauf hin, dass er Pflegehilfsmittel im Wert<br/> von bis zu 40€ erhalten kann.
          </p>
          <b-button class="modalButton" @click="$refs.pflegemittelAntrag.hide()">Weiter</b-button>
          <b-button v-if="answers.antrag === 'PA2'" class="rightModalButton" @click="generateAntrag()">Jetzt Antrag erstellen</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {answerStorage} from '@/utils/localstorage.js'
import {generatePflegeAntrag} from '../utils/pflegeantrag.js'
import {EventBus} from '@/services/EventBus.js'

export default {
  name: 'Selling',
  data: function () {
    return {
      eventBusObject: EventBus,
      answers: answerStorage.fetch()
    }
  },
  created: function () {
    this.$nextTick().then(() => {
      if (this.answers.grad === 'G2' || this.answers.grad === 'G3' || this.answers.grad === 'G4' || this.answers.grad === 'G5' || this.answers.grad === 'G6') {
        this.$refs.pflegemittelAntrag.show()
      }
    })
    this.eventBusObject.$on('generatePHA', ($event) => {
      this.generateAntrag()
    })
  },
  methods: {
    getPfelgegrad: function () {
      if (this.answers.grad === 'G2') {
        return '1'
      }
      if (this.answers.grad === 'G3') {
        return '2'
      }
      if (this.answers.grad === 'G4') {
        return '3'
      }
      if (this.answers.grad === 'G5') {
        return '4'
      }
      if (this.answers.grad === 'G6') {
        return '5'
      }
    },
    generateAntrag: function () {
      if (!this.isIeOrEdge()){
        // var windowReference = window.open()
      }
      var doc = generatePflegeAntrag(this.answers)
      if (this.isIeOrEdge()){
            doc.save("Pflegehilfsmittelantrag.pdf")
          } else {
             doc.save("Pflegehilfsmittelantrag.pdf")
            // window.open(doc.output('bloburl'))
           /* if (windowReference != null) {
              windowReference.location = doc.output('bloburl')
            } else {
              window.open(doc.output('bloburl'))
              // doc.output('dataurlnewwindow')
            }
            */
            // doc.output('dataurlnewwindow')
            // doc.output('datauri')
          }
    },
    isIeOrEdge: function () {
      var returnValue = navigator.appName == 'Microsoft Internet Explorer' || (!!window.MSInputMethodContext && !!document.documentMode) || (window.navigator && window.navigator.msSaveOrOpenBlob)
      return returnValue
    },
    
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('generatePHA')
  }
}
</script>
