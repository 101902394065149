Listenelement für Recommendations. Die Parameter werden von aussen über "props" übergeben.
<template>
  <div style="position: relative;">
    <div class="productInfoContainer">
      <ProductImage :path="products[product.parentProductId].imagepfad+'.jpg'" />
      <div>
        <h4>{{products[product.parentProductId]["Anwendungstyp"]}}<span :class="products[product.parentProductId]['PremiumBasis']">{{products[product.parentProductId]["PremiumBasis"]}}</span></h4>
        <h2>{{products[product.parentProductId]["Produktbezeichnung"]}}</h2>
        <div v-if="hasFixPant(product)">
          In Kombination mit Fixierhose
        </div>
        <h3>{{products[product.parentProductId].Schweregrad}} <span style="display: inlineblock; margin-left: 20px"><span v-for="(size, key) in products[product.parentProductId].sizes"  :key="key"> {{key}}</span></span></h3>
        <h5 @click="showExtraSection(products[product.parentProductId].Artikelnummer)">Produktinformationen</h5>
      </div>
      <button :class="isSelected().class" @click="$parent.toggleSelectProduct(id)">{{isSelected().text}}</button>
    </div>

    <div :class="displayed(products[product.parentProductId].Artikelnummer) + ' ' + 'productInformationContainer'">
      <b-tabs>
        <b-tab title="Im Überblick">
          <div class="innerProductInformationConatiner">
            <div style="padding-right: 72px; width: 80%">
              <ul>
                <li v-for="(vorteil,key) in products[product.parentProductId].Vorteilskommunikation" :key="key">
                  {{vorteil}}
                </li>
              </ul>
            </div>
            <div style="width: 20%">
              <div v-if="products[product.parentProductId].Tropfenanzahl > 0">
                Saugumfang:<br />
                <div class="tropfenContainer">
                  <div :class="drawTrophen(tropfen, products[product.parentProductId].Tropfenanzahl)" v-for="tropfen in 10" :key="tropfen">
                  </div>
                </div>
              </div>
              <div v-if='products[product.parentProductId].ICONS[0] !== ""'>
                Produktvorteile:<br />
                <div v-for="(icon,key) in products[product.parentProductId].ICONS" :key="key" class="featureContainer">
                  <img v-bind:src="require('../../assets/img/' + icon + '.svg')">
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab class="anlegeHinweisContainer" v-if="products[product.parentProductId]['Anwendungstyp'] !== 'Pull-ups' && products[product.parentProductId]['Anwendungstyp'] !== 'Fixierhosen'" title="Anlegehinweise im Stehen">
        <b-carousel v-if="(products[product.parentProductId]['Anwendungstyp'] === 'Slip' || products[product.parentProductId]['Anwendungstyp'] === 'Slip Elastic')" :interval="0" img-width="668" img-height="192" controls indicators>
          <b-carousel-slide v-bind:img-src="require('../../assets/img/slip_stehend1.png')"></b-carousel-slide>
          <b-carousel-slide v-bind:img-src="require('../../assets/img/slip_stehend2.png')"></b-carousel-slide>
          <b-carousel-slide v-bind:img-src="require('../../assets/img/slip_stehend3.png')"></b-carousel-slide>
        </b-carousel>
          <b-carousel v-if="(products[product.parentProductId]['Anwendungstyp'] === 'Einlagen' || products[product.parentProductId]['Anwendungstyp'] === 'Vorlagen')" :interval="0" img-width="668" img-height="192" controls indicators>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/form_stehend1.png')"></b-carousel-slide>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/form_stehend2.png')"></b-carousel-slide>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/form_stehend3.png')"></b-carousel-slide>
          </b-carousel>
        </b-tab>
        <b-tab class="anlegeHinweisContainer" v-if="products[product.parentProductId]['Anwendungstyp'] !== 'Pull-ups' && products[product.parentProductId]['Anwendungstyp'] !== 'Fixierhosen'" title="Anlegehinweise im Liegen">
          <b-carousel v-if="products[product.parentProductId]['Anwendungstyp'] === 'Slip' || products[product.parentProductId]['Anwendungstyp'] === 'Slip Elastic'" :interval="0" img-width="668" img-height="192" controls indicators>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/slip_liegend1.png')"></b-carousel-slide>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/slip_liegend2.png')"></b-carousel-slide>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/slip_liegend3.png')"></b-carousel-slide>
          </b-carousel>
          <b-carousel v-if="products[product.parentProductId]['Anwendungstyp'] === 'Einlagen' || products[product.parentProductId]['Anwendungstyp'] === 'Vorlagen'" :interval="0" img-width="668" img-height="192" controls indicators>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/form_liegend1.png')"></b-carousel-slide>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/form_liegend2.png')"></b-carousel-slide>
            <b-carousel-slide v-bind:img-src="require('../../assets/img/form_liegend3.png')"></b-carousel-slide>
          </b-carousel>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import ProductImage from '@/components/ProductImage.vue'
export default {
  name: 'CartProduct',
  components: {
    ProductImage
  },
  props: {
    product: Object,
    products: Object,
    selected: Boolean,
    id: Number
  },
  data: function () {
    return {
      extraSections: []
    }
  },
  methods: {
    hasFixPant: function (product) {
      return (product.fixPant != null && Object.keys(product.fixPant).length > 0)
    },
    hasImage: function (id) {
      var imagepfad = this.products[id].imagepfad
      return !imagepfad.includes('fehlt')
    },
    isSelected: function () {
      if (this.selected) {
        return {'class': 'active', 'text': 'ausgewählt'}
      } else {
        return {'class': '', 'text': 'auswählen'}
      }
    },
    showExtraSection: function (id) {
      if (this.extraSections.indexOf(id) > -1) {
        this.extraSections.splice(this.extraSections.indexOf(id), 1)
      } else {
        this.extraSections.push(id)
      }
    },
    displayed: function (id) {
      if (this.extraSections.indexOf(id) > -1) {
        return ''
      } else {
        return 'notDisplayed'
      }
    },
    drawTrophen: function (index, tropfenanzahl) {
      if (index < tropfenanzahl) {
        return 'filled'
      } else if (index === tropfenanzahl) {
        return 'filled filled__last'
      } else {
        if (index > tropfenanzahl && index < tropfenanzahl + 1) {
          return 'half'
        } else {
          return 'empty'
        }
      }
    }
  }
}

</script>
