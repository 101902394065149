Zeigt die Produkt-Vorschläge nach der Anamnese an. Die Liste besteht aus /recommendation/SingleRecObject.vue Objekten.
Der Speichern-Knopf liegt im Footer. Deshalb wird hier auf ein Event vom Eventbus gehört. Ins cartArray Objekt werden die Einträge
aus denen im WizardContainer geschriebenen Elemente aus dem Localstorage geladen  (cartArrayStorage.fetch())
Die saveAndGoToCart() Funktion erstellt ein neues Objekt, das den Versorgungsrechner abbildet, und speichert es im Localstorage.
<template>
  <div id="recommendations">
    <div class='breadcrumb'>
      <!-- ><h2 v-if="currentWizardStep === 0">{{questionsComplete[currentWizardStep].segmentName}}</h2> -->
      <h2>Empfehlungen</h2>
      <div class='breadcrumbArrow'>
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>
    <div v-if="hintArray.length>0" id="hint-container">
      <h2>Optimale Versorgung mit Premium-Produkten</h2>
      <p>Dem Anwender ist besonders wichtig: {{isImportant()}}. Premium-Produkte entsprechen dem besonders gut, da sie...</p>
      <ul>
        <li v-for="(hint,key) in hintArray" :key="key" >{{hint}}</li>
      </ul>
    </div>
    <div>
      <h3><strong>{{ numberOfProducts() }} empfohlene Produkte basierend auf der Anamnese</strong></h3>
    </div>
    <div class="productsContainer">
      <div :class="'productContainer'+isFirstFixPant(product.parentProductId)" v-for="product in productCart" :key="product.parentProductId" >
        <RecObject :product="product" :products="products" :selected="isSelected(product.parentProductId)" :id="product.parentProductId" />
      </div>
    </div>
  </div>
</template>

<script>

import DataService from '@/services/DataService'
import RecObject from './recommendation/SingleRecObject'
import {cartProductStorage, answerStorage, cartArrayStorage, cartStatusStorage} from '../utils/localstorage.js'
import {EventBus} from '@/services/EventBus.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Recommendations',
  components: {
    RecObject
  },
  computed: {
    ...mapGetters(['getRights']),
    hintArray: function () {
      var hintArrayToReturn = []
      // var answers = answerStorage.fetch()
      for (var key in this.answers) {
        var singleHintObject = this.hintsObject['important']
        if (singleHintObject != null) {
          if (singleHintObject[this.answers[key]] != null) {
            hintArrayToReturn.push(singleHintObject[this.answers[key]])
          } else if (singleHintObject['*'] != null) {
            hintArrayToReturn.push(singleHintObject['*'])
          }
        }
      }
      return hintArrayToReturn
    },
    showVersorgung: function () {
      return this.getRights.indexOf('versorg_aufr') !== -1
    }
  },
  created: function () {
    DataService.fetchCombinedProducts().then((response) => {
      this.products = response.data
      this.productCart = cartProductStorage.fetch()
      this.cartArray = cartArrayStorage.fetch()
    }).catch((err) => {
      // handle error
      console.log(err)
    })
    DataService.fetchTextRecommendations().then((response) => {
      this.hintsObject = response.data
    }).catch((err) => {
      // handle error
      console.log(err)
    })
    this.eventBusObject.$on('saveRecommdenationAndGoToCart', ($event) => {
      this.saveAndGoToCart()
    })
  },
  data: function () {
    var answer = answerStorage.fetch()
    return {
      productCart: {},
      cartArray: [],
      price: 0,
      answers: answer['important'],
      products: {},
      wizard: {},
      hintsObject: {},
      selectedProducts: [],
      eventBusObject: EventBus
    }
  },
  props: {
  },
  methods: {
    hasImage: function (id) {
      var imagepfad = this.products[id].imagepfad
      return !imagepfad.includes('fehlt')
    },
    toggleSelectProduct: function (index) {
      if (this.selectedProducts.indexOf(index) > -1) {
        this.selectedProducts.splice(this.selectedProducts.indexOf(index), 1)
      } else {
        this.selectedProducts.push(index)
      }
    },
    isSelected: function (index) {
      if (this.selectedProducts.indexOf(index) > -1) {
        return true
      }
      return false
    },
    saveAndGoToCart: function () {
      var index = 0
      var productsTabBase = {}
      if (this.cartArray[0][index] != null) {
        productsTabBase = this.cartArray[0][index]
      }
      if (productsTabBase.products == null) {
        productsTabBase.products = []
      }
      if (productsTabBase.careType == null) {
        productsTabBase.careType = 0
      }

      var productsTabPremium = {}
      if (this.cartArray[1][index] != null) {
        productsTabPremium = this.cartArray[1][index]
      }
      if (productsTabPremium.products == null) {
        productsTabPremium.products = []
      }
      if (productsTabPremium.careType == null) {
        productsTabPremium.careType = 1
      }
      var addedABaseProduct = false
      var addedAPremiumProduct = false
      for (var i in this.selectedProducts) {
        var id = this.selectedProducts[i]
        for (var j in this.productCart) {
          if (this.productCart[j].parentProductId === id) {
            if (this.productCart[j].careType === 0) {
              productsTabBase.products.push(this.productCart[j])
              addedABaseProduct = true
            } else {
              productsTabPremium.products.push(this.productCart[j])
              addedAPremiumProduct = true
            }
          }
        }
      }

      var productsTabFrei= {}
      if (this.cartArray[2][index] != null) {
        productsTabFrei = this.cartArray[2][index]
      }
      if (productsTabFrei.products == null) {
        productsTabFrei.products = []
      }
      if (productsTabFrei.careType == null) {
        productsTabFrei.careType = 2
      }


      var allProducts = cartArrayStorage.fetch()
      allProducts[0][index] = productsTabBase
      allProducts[1][index] = productsTabPremium
      allProducts[2][index] = productsTabFrei
      cartArrayStorage.save(allProducts)
      // if no baseproduct was added, go to premium tab in cart
      if (addedAPremiumProduct && !addedABaseProduct) {
        var statusObject = {'tabIndex': index, 'baseOrPremiumTab': 1}
        cartStatusStorage.save(statusObject)
      }
      if (this.showVersorgung) {
        this.$router.push('cart')
      } else {
        this.$router.push('offer')
      }

      
    },
    isImportant: function () {
      var text = []
      if (this.answers.includes('I1')) {
        text.push('Tragekomfort')
      }
      if (this.answers.includes('I2')) {
        text.push('Diskretion')
      }
      if (this.answers.includes('I3')) {
        text.push('Saugleistung')
      }
      if (this.answers.includes('I4')) {
        text.push('Hautverträglichkeit')
      }
      return text.join(', ')
    },
    numberOfProducts: function () {
      var number = 0
      for (var i in this.productCart) {
        var product = this.productCart[i]
        if (product.isFixPant === false) {
          number = number + 1
        }
      }
      return number
    },
    isFirstFixPant: function (key) {
      var firstFixPantKey = this.getFirstFixierHosenKey()
      if (key === firstFixPantKey) {
        return ' isFirstFixPant'
      } else {
        return ''
      }
    },
    getFirstFixierHosenKey: function () {
      for (var i in this.productCart) {
        var product = this.productCart[i]
        var key = product.parentProductId
        if (this.products[key]['Anwendungstyp'] === 'Fixierhosen') {
          return key
        }
      }
    },
    selectionIsDone: function () {
      return 'Hallo'
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$emit('closeSummary')
    this.eventBusObject.$off('saveRecommdenationAndGoToCart')
  }
}
</script>
