Zeigt alle Produkte an. Produkte können hier über addToCart() dem Versorgungsrechner hinzugefügt werden.
Das (Computed-)"products"-Objekt liefert die, je nach gesetzten Filter, anzuzeigenden Daten. 
Im "allProducts"-Object sind alle Produkte aus der Datenbank.
<template>
  <div id="productCatalog">
      <b-modal ref="alreadyInCartModal" hide-footer title="Das Produkt befindet sich schon im Versorgungrechner">
          <div class="d-block text-center">
              Wollen Sie "{{productForModal.Produktbezeichnung}}" trotzdem hinzufügen?
            <div style="margin-top: 20px;">
              <b-button class="modalButton" @click="addToCart(productForModal.Artikelnummer, false)">Ja</b-button>
              <b-button class="rightModalButton" @click="$refs.alreadyInCartModal.hide()">Nein</b-button>
            </div>
          </div>
        </b-modal>
    <div class='breadcrumb'>
      <h2>Produktkatalog</h2>
      <div id="statusBar">
        <button @click="tabIndex = 0; currentFilters={}">Inkontinenzprodukt / Zubehör</button>
        <button @click="tabIndex = 1; currentFilters={}">Pflegehilfsmittel</button>
        <button @click="tabIndex = 2; currentFilters={}">Hautpflege</button>
      </div>
      <div class='breadcrumbArrow'>
        <div :style="positionOfStepIndicator()" class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>
      <div>
        <template>
          <div id="filterbar">
            <div class="filtergroup" v-for="(filterGroup,key) in superFilter[tabIndex]" :key="key">
              <h3 v-if="key !== 'PremiumBasis'">{{key}}:</h3>
              <h3 v-if="key === 'PremiumBasis'">Premium / Basis:</h3>
              <div>
                <button v-bind:class="buttonIsActive(key, 'noFilter')" @click="filterSelected(key)">Alle</button><button v-bind:class="buttonIsActive(key, filter.filterValue)" v-for="filter in filterGroup" :key="filter.name" @click="filterSelected(key,filter.filterValue)">{{filter.name}}</button>
              </div>
            </div>
          </div>
        </template>
        <div class="productsContainer" v-if="productsLoading" >
          <h4>Die Produkte werden geladen...</h4>
        </div>
        <div class="productsContainer" v-if="noProductAvailable && !productsLoading" >
          <h4>Ein Produkt mit den gefilterten Eigenschaften ist nicht vorhanden</h4>
          <p>Es kann z.B. sein, dass das Produkt nicht in der Konfektionsgröße vorliegt oder nicht für den Schweregrad geeignet ist.</p>
          <p>Bitte wählen Sie die Filter neu.</p>
        </div>
        <div class="section" v-for="(section,sectionKey) in products" :key="sectionKey" >
          <div class="systemContainer">
            <h2>{{sectionKey}}</h2>
            <p v-if="sectionKey === 'Einlagen'">
            <img v-bind:src="require('../assets/img/Einlage.svg')">
            MoliCare® Inkontinenzeinlagen eignen sich für die Versorgung von leichter bis mittlerer Harninkontinenz. Mit Hilfe eines Klebestreifens können die Einlagen sicher in der Unterwäsche fixiert werden.
            </p>
            <p v-if="sectionKey === 'Vorlagen'">
            <img v-bind:src="require('../assets/img/Vorlage.svg')">
            MoliCare® Inkontinenzvorlagen bieten einen zuverlässigen Schutz bei Harn- und Stuhlinkontinenz. Die Produkte sind je nach Saugstärkte für leichte bis schwere Formen der Inkontinenz bei Männern und Frauen geeignet. Für einen sicheren Sitz verwenden Sie zusätzlich die Fixierhosen von MoliCare®.</p>
            <p v-if="sectionKey === 'Fixierhosen'">
            <img v-bind:src="require('../assets/img/Fixierhose.svg')">
            MoliCare® Fixierhosen bieten zusätzliche Sicherheit beim Gebrauch von Inkontinenzeinlagen und sorgen für einen sicheren Sitz bei der Verwendung von Inkontinenzvorlagen. Alle Modelle sind waschbar und somit für eine hygienische Mehrfachverwendung geeignet.</p>
            <p v-if="sectionKey === 'Slip'">
            <img v-bind:src="require('../assets/img/Slip.svg')">
            Die Inkontinenzslips von MoliCare® eignen sich für eine sichere Versorgung bei leichter bis schwersten Harn- und Stuhlinkontinenz. Mit Hilfe von seitlichen Klebestreifen lassen sie sich sicher am Körper befestigen und sind daher gerade für bettlägerige Personen bestens geeignet.</p>
            <p v-if="sectionKey === 'Slip Elastic'">
            <img v-bind:src="require('../assets/img/Slip.svg')">
            Bei den elastischen MoliCare® Slips handelt es sich um eine besonders anwenderfreundliche Variante der Inkontinenzslips. Durch das Elastic-Fix-System, der Kombination aus dehnbaren Seitenteilen und handlichen, wiederverschließbaren Klett-Klebestreifen sind die Produkte bei mittlerer bis schwerster Inkontinenz sehr sicher und einfach anzulegen.</p>
            <p v-if="sectionKey === 'Pull-ups'">
            <img v-bind:src="require('../assets/img/Pullup.svg')">
            MoliCare® Pull-ups leisten insbesondere für aktive Personen eine praktische und zuverlässige Inkontinenzversorgung. Die Produkte werden wie Unterwäsche getragen und eignen sich je nach Saugstärke für leichte bis schwerste Formen von Harninkontinenz.</p>
            <p v-if="sectionKey === 'Reinigung'">
            MoliCare® Skin Reinigungsprodukte vermindern Hautreizungen des Intimbereichs und sind hautschonend mit einem hautneutralen pH-Wert von 5,5.</p>
            <p v-if="sectionKey === 'Pflege'">
            Besonders hochwertige Inhaltsstoffe der MoliCare® Skin Pflegeprodukte fördern die schnelle Regeneration der Haut und sorgen für eine reichhaltige Pflege.</p>
            <p v-if="sectionKey === 'Schutz'">
            Mit MoliCare® Skin Schutzprodukten wird die Haut optimal geschützt, denn der wichtige Säureschutzmantel bleibt erhalten.</p>
          </div>
          <div class='breadcrumbArrow'>
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
            <div class="four"></div>
          </div>
          <div class="productsContainer">
            <div class="productContainer" v-for="(product,key) in section" :key="key">
              <button v-if="tabIndex === 0" @click="addToCart(product.Artikelnummer, true)">Auswählen</button>
              <div class="productInfoContainer">
                <ProductImage :path="product.imagepfad+'.jpg'" />
                <div>
                  <h4>{{product["Anwendungstyp"]}} <span v-if="tabIndex === 0" :class="product['PremiumBasis']">{{product["PremiumBasis"]}}</span></h4>
                  <h2>{{product["Produktbezeichnung"]}}</h2>
                  <p v-if="product['Anwendungstyp'] === 'Vorlagen'">In Kombination mit Fixierhose</p>
                  <h3 v-if="tabIndex === 0">{{product.Schweregrad}}</h3>
                  <h5 @click="showExtraSection(product.Artikelnummer)">Produktinformationen</h5>
                </div>
              </div>
              <div :class="displayed(product.Artikelnummer) + ' ' + 'productInformationContainer'">
                <b-tabs v-model="hinweisTabsIndex">
                  <b-tab title="Im Überblick">
                    <div class="innerProductInformationConatiner">
                      <div style="width: 80%; padding-right: 72px">
                        <ul>
                          <li v-for="(vorteil,key) in product.Vorteilskommunikation" :key="key">
                            {{vorteil}}
                          </li>
                        </ul>
                      </div>
                      <div style="width: 20%;">
                        <div v-if="product.Tropfenanzahl > 0">
                          Saugvermögen:<br />
                          <div class="tropfenContainer">
                            <div :class="drawTrophen(tropfen, product.Tropfenanzahl)" v-for="tropfen in 10" :key="tropfen">
                              <!-- <img v-if="tropfen <= product.Tropfenanzahl" v-bind:src="require('../assets/img/drop_filled_Grey50.svg')"> -->
                              <!-- <img v-if="tropfen > product.Tropfenanzahl" v-bind:src="require('../assets/img/drop_outline_Grey50.svg')"> -->
                            </div>
                          </div>
                        </div>
                        <div v-if='product.ICONS[0] !== ""'>
                          Features:<br />
                          <div v-for="(icon,key) in product.ICONS" :key="key" class="featureContainer">
                            <img v-bind:src="require('../assets/img/' + icon + '.svg')">
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab class="anlegeHinweisContainer">
                    <template v-if="tabIndex === 0 && product['Anwendungstyp'] !== 'Pull-ups' && product['Anwendungstyp'] !== 'Fixierhosen'" v-slot:title>
                      Anlegehinweise im Stehen
                    </template>
                    <b-carousel v-if="product['Anwendungstyp'] === 'Slip' || product['Anwendungstyp'] === 'Slip Elastic'" :interval="0" img-width="668" img-height="192" v-model="slideStehend" controls indicators>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/slip_stehend1.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/slip_stehend2.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/slip_stehend3.png')"></b-carousel-slide>
                    </b-carousel>
                    <b-carousel v-if="product['Anwendungstyp'] === 'Einlagen' || product['Anwendungstyp'] === 'Vorlagen'" v-model="slideStehend" :interval="0" img-width="668" img-height="192" controls indicators>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/form_stehend1.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/form_stehend2.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/form_stehend3.png')"></b-carousel-slide>
                    </b-carousel>
                  </b-tab>
                  <b-tab class="anlegeHinweisContainer">
                    <template v-if="tabIndex === 0 && product['Anwendungstyp'] !== 'Pull-ups' && product['Anwendungstyp'] !== 'Fixierhosen'" v-slot:title>
                      Anlegehinweise im Liegen
                    </template>
                    <b-carousel v-if="product['Anwendungstyp'] === 'Slip' || product['Anwendungstyp'] === 'Slip Elastic'" :interval="0" img-width="668" img-height="192" v-model="slideLiegend" controls indicators>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/slip_liegend1.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/slip_liegend2.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/slip_liegend3.png')"></b-carousel-slide>
                    </b-carousel>
                    <b-carousel v-if="product['Anwendungstyp'] === 'Einlagen' || product['Anwendungstyp'] === 'Vorlagen'" v-model="slideLiegend" :interval="0" img-width="668" img-height="192" controls indicators>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/form_liegend1.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/form_liegend2.png')"></b-carousel-slide>
                      <b-carousel-slide v-bind:img-src="require('../assets/img/form_liegend3.png')"></b-carousel-slide>
                    </b-carousel>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="productArticleContainer">
                <table>
                  <tr >
                    <th>Artikelnummer</th>
                    <th>PZN</th>
                    <th v-if="tabIndex !== 2">Hilfsmittelnummer</th>

                    <th v-if="tabIndex === 0">Konfektionsgröße & <br />Hüftumfang in cm</th>
                    <th v-if="tabIndex === 1 && product['liquid'] === false">Grösse</th>

                    <th v-if="product['liquid'] === false">Stück pro <br />Packung</th>
                    <th v-if="product['liquid'] === true">Menge</th>
                    <th>UVP</th>
                    <th>Preis</th>
                  </tr>
                  <template v-for="(size,sizeKey) in product.sizes">
                    <tr :key="sizeKey">
                      <td>{{size.id}}</td>
                      <td>{{size.pzn}}</td>
                      <td v-if="tabIndex !== 2">{{size.aidnumber}}</td>
                      <td v-if="tabIndex !== 2 && product['liquid'] === false">{{sizeKey}} <span v-if="(size.hu !== 'onesize' && size.hu !== '-' && size.hu !== '')">({{size.hu}})</span></td>

                      <td v-if="product['liquid'] === false">{{size.pieces}}</td>
                      <td v-if="product['liquid'] === true">{{size.pieces}}</td>

                      <td>{{formatFloatToString(size.price)}} €</td>
                      <td v-if="priceSettingObject[size.id] != null">{{formatFloatToString(priceSettingObject[size.id])}} €</td>
                      <td v-else>{{formatFloatToString(size.price)}} €</td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>

import {cartStatusStorage, cartArrayStorage, answerStorage} from '@/utils/localstorage.js' // cartArrayStorage, answerStorage
import DataService from '@/services/DataService'
import {generateCartObject, formatFloatToStringUtil, sortProductAtSystemPosition, sortByName, showBaseTabUtil, generateKVPauschale} from '@/utils/util.js'
import {EventBus} from '@/services/EventBus.js'
import { mapGetters } from 'vuex'
import { REMOVE_FILTER } from '@/store/modules/data'
import ProductImage from '@/components/ProductImage.vue'
export default {
  name: 'Products',
  components: {
    ProductImage
  },
  computed: {
    // list of all possible 'Inkontinenzprodukt' values to filter
    typeFilter: function () { return ['Inkontinenzprodukt / Zubehör', 'Pflegehilfsmittel', 'Hautpflege'] },
    noProductAvailable: function () {
      var atleastOneObject = false
      if (this.products != null) {
        for (var sectionKey in this.products) {
          var section = this.products[sectionKey]
          if (section != null && Object.keys(section).length > 0) {
            atleastOneObject = true
            break
          }
        }
      }
      return !atleastOneObject
    },
    superFilter: function () {
      return {
        0: {
          'Anwendungstyp': [
            {'name': 'Einlage', 'filterValue': 'Einlagen'},
            {'name': 'Vorlage', 'filterValue': 'Vorlagen'},
            {'name': 'Fixierhose', 'filterValue': 'Fixierhosen'},
            {'name': 'Slip Elastic', 'filterValue': 'Slip Elastic'},
            {'name': 'Slip', 'filterValue': 'Slip'},
            {'name': 'Pull up', 'filterValue': 'Pull-ups'}
          ],
          'Schweregrad': [
            {'name': 'Leicht', 'filterValue': 'Leichte Inkontinenz'},
            {'name': 'Mittel', 'filterValue': 'Mittlere Inkontinenz'},
            {'name': 'Schwer', 'filterValue': 'Schwere Inkontinenz'},
            {'name': 'Schwerst', 'filterValue': 'Schwerste Inkontinenz'}
          ],
          'PremiumBasis': [
            {'name': 'Premium', 'filterValue': 'Premium'},
            {'name': 'Basis', 'filterValue': 'Basis'}
          ],
          'Konfektionsgrösse': [
            {'name': 'XS', 'filterValue': 'XS'},
            {'name': 'S', 'filterValue': 'S'},
            {'name': 'M', 'filterValue': 'M'},
            {'name': 'L', 'filterValue': 'L'},
            {'name': 'XL', 'filterValue': 'XL'},
            {'name': 'XXL', 'filterValue': 'XXL'},
            {'name': 'XXXL', 'filterValue': 'XXXL'}
          ]
        },
        1: {
          'Anwendungstyp': [
            {'name': 'Untersuchungshandschuhe', 'filterValue': 'Untersuchungshandschuhe'},
            {'name': 'Händedesinfektion', 'filterValue': 'Händedesinfektion'},
            {'name': 'Flächendesinfektion', 'filterValue': 'Flächendesinfektion'},
            {'name': 'Schutzschürze', 'filterValue': 'Schutzschürzen'},
            {'name': 'Mundschutz', 'filterValue': 'Mundschutz'},
            {'name': 'Krankenunterlagen', 'filterValue': 'Krankenunterlagen'}
          ]
        },
        2: {
          'Anwendungstyp': [
            {'name': 'Schutz', 'filterValue': 'Schutz'},
            {'name': 'Pflege', 'filterValue': 'Pflege'},
            {'name': 'Reinigung', 'filterValue': 'Reinigung'}
          ]
        }
      }
    },
    // all products or only some parts if a filter is set / updated
    products: function () {
      var returnProducts = {
        'Einlagen': [],
        'Vorlagen': [],
        'Fixierhosen': [],
        'Slip': [],
        'Slip Elastic': [],
        'Pull-ups': [],
        'Schutz': [],
        'Pflege': [],
        'Reinigung': [],
        'Untersuchungshandschuhe': [],
        'Händedesinfektion': [],
        'Flächendesinfektion': [],
        'Schutzschürzen': [],
        'Mundschutz': [],
        'Krankenunterlagen': []
      }
      for (var i in this.allProducts) {
        var singleProduct = this.allProducts[i]
        // don't filter when not on first tab or not one filter is selected
        if (!this.areAnyFilterSet()) {
          if (this.typeFilter[this.tabIndex].includes(singleProduct.Inkontinenzprodukt)) {
            sortProductAtSystemPosition(singleProduct, returnProducts)
          }
        } else {
          // inkontinenzprodukte:
          // standard is true. Only set to false when there are filters but not matching one
          var addProduct = true

          for (var filterKey in this.currentFilters) {
            var filterValueArray = this.currentFilters[filterKey]

            // always add product when filterValueArray is empty
            if (filterValueArray != null && filterValueArray.length > 0) {
              // different filter rules for sizes because an element can have more than one size
              if (filterKey !== 'Konfektionsgrösse') {
                var productHasNoFilterMatch = true
                // check if product has at least one match with the values of this filterArray
                for (var x in filterValueArray) {
                  var filterValue = filterValueArray[x]

                  if (singleProduct[filterKey] === filterValue) {
                    productHasNoFilterMatch = false
                    break
                  }
                }
                // if it has no match -> don't add product
                if (productHasNoFilterMatch) {
                  addProduct = false
                }
              } else {
                // size Filter:
                var productHasThisSize = false
                for (var sizeKey in singleProduct.sizes) {
                  // if product size matches one size of the filterValueArray -> productHasThisSize=true
                  if (filterValueArray.indexOf(sizeKey) >= 0 || sizeKey === 'onesize') {
                    productHasThisSize = true
                    break
                  }
                }
                // don't add product if it doesn't have this size
                if (!productHasThisSize) {
                  addProduct = false
                }
              }
            }
          }
          if (addProduct) {
            sortProductAtSystemPosition(singleProduct, returnProducts)
          }
        }
      }
      var objectKeysWithProducts = Object.keys(returnProducts).filter(function (key) {
        return Object.keys(returnProducts[key]).length > 0
      })


      var filterEmptyKeys = Object.keys(returnProducts)
        .filter(key => objectKeysWithProducts.includes(key))
        .reduce((obj, key) => {
          obj[key] = returnProducts[key];
          return obj;
        }, {});
      
  

      returnProducts = filterEmptyKeys
      if (returnProducts.Einlagen != null) {
        returnProducts.Einlagen.sort(function (a, b) {
          return sortByName(a, b)
        })
      }
      if (returnProducts.Vorlagen != null) {
        returnProducts.Vorlagen.sort(function (a, b) {
          return sortByName(a, b)
        })
      }
      return returnProducts
    },
    ...mapGetters(['getFilter','getInstitution'])
  },
  data: function () {
    var answer = answerStorage.fetch()
    return {
      // current 'InktontinenzProdukt' Filter
      currentFilters: {},
      tabIndex: 0,
      checkboxValue: [],
      allProducts: {},
      priceSettingObject: {},
      cartStatusObject: cartStatusStorage.fetch(),
      cartArray: cartArrayStorage.fetch(),
      extraSections: [],
      productForModal: {},
      eventBusObject: EventBus,
      slideStehend: 0,
      slideLiegend: 0,
      hinweisTabsIndex: 0,
      productsLoading: true,
      answers: answer
    }
  },
  created: function () {
    const self = this
    DataService.fetchPrices(this.getInstitution).then((response) => {
      this.priceSettingObject = response.data
      DataService.fetchCombinedProducts().then((response) => {
        self.allProducts = response.data
        // if Fixierhosen Filter is set in state management system vuex -> set it as currentFilter and remove it
        if (self.getFilter != null && self.getFilter['Anwendungstyp'] != null && self.getFilter['Anwendungstyp'][0] === 'Fixierhosen') {
          self.currentFilters = this.getFilter
          self.$store.dispatch(REMOVE_FILTER)
        }
        self.productsLoading = false
      }).catch((err) => {
        // handle error
        if (err.response.status === 401) {
          this.eventBusObject.$emit('tokenInvalid')
        } 
        console.log(err)
      })
    }).catch(() => {
      console.log(err)
    })


   
  },
  methods: {
    hasImage: function (id) {
      var product = this.allProducts[id]
      var imagepfad = 'fehlt'
      if (product === undefined) {
        return false
      } else {
        imagepfad = product.imagepfad
      }
      return !imagepfad.includes('fehlt') && !imagepfad.includes('per Mail')
    },
    addToCart: function (id, lookForAlreadyStoredProduct) {
      var index = 0
     
      if (this.cartStatusObject.tabIndex != null) {
        index = this.cartStatusObject.tabIndex
      }
      var baseOrPremiumTab = 0
      if (this.cartStatusObject.baseOrPremiumTab != null) {
        baseOrPremiumTab = this.cartStatusObject.baseOrPremiumTab
      } else if (!this.showBaseTab()) { // if there is no baseTab put the product in premiumTab
        
        baseOrPremiumTab = 1
      } 

      var productsTab = {}
      if (this.cartArray[baseOrPremiumTab][index] != null) {
        productsTab = this.cartArray[baseOrPremiumTab][index]
      }
      if (productsTab.products == null) {
        productsTab.products = []
      }
      var alreadyInCart = false
      if (lookForAlreadyStoredProduct) {
        for (var x in productsTab.products) {
          if (productsTab.products[x].parentProductId === id) {
            alreadyInCart = true
          }
        }
      }
      var product = this.allProducts[id]
      if (!alreadyInCart) {
        var answers = answerStorage.fetch()
        var cartObject = generateCartObject(product, answers, this.allProducts)
        productsTab.products.push(cartObject)

        var allProducts = cartArrayStorage.fetch()
        allProducts[baseOrPremiumTab][index] = productsTab
        cartArrayStorage.save(allProducts)
        this.$router.push('cart')
      } else {
        this.productForModal = product
        this.$refs.alreadyInCartModal.show()
      }
    },
    filterType: function (event) {
      this.currentTypeFilter = event.target.value
    },
    areAnyFilterSet: function () {
      for (var filterKey in this.currentFilters) {
        if (this.currentFilters[filterKey] != null && this.currentFilters[filterKey].length > 0) {
          return true
        }
      }
      return false
    },
    filterSelected: function (filterKey, filterValue) {
      if (this.currentFilters[filterKey] != null && filterValue != null) {
        // there are already filter set with this filterKey -> check if filterValue is in array
        var index = this.currentFilters[filterKey].indexOf(filterValue)
        var newArray = this.currentFilters[filterKey]
        if (index >= 0) {
          // remove element from current array
          newArray.splice(index, 1)
        } else {
          // add element to current array
          newArray.push(filterValue)
        }
        this.$set(this.currentFilters, filterKey, newArray)
      } else {
        // no filter was set with this key -> add new array with one element
        this.$set(this.currentFilters, filterKey, [filterValue])
      }
      if (filterValue == null) {
        // Alle was pressed
        // remove remove elements from array
        this.$set(this.currentFilters, filterKey, [])
      }
      this.$forceUpdate()
    },
    buttonIsActive: function (key, id) {
      if (id === 'noFilter') {
        // present 'alle' as active if no filter with this key OR this filter array is empty
        if (this.currentFilters[key] == null || (this.currentFilters[key] != null && this.currentFilters[key].length === 0)) {
          return 'active'
        }
      } else {
        if (this.currentFilters[key] != null && this.currentFilters[key].indexOf(id) >= 0) {
          return 'active'
        }
      }
    },
    showExtraSection: function (id) {
      this.slideLiegend = 0
      this.slideStehend = 0
      this.hinweisTabsIndex = 0
      if (this.extraSections.indexOf(id) > -1) {
        this.extraSections.splice(this.extraSections.indexOf(id), 1)
      } else {
        this.extraSections.push(id)
      }
    },
    displayed: function (id) {
      if (this.extraSections.indexOf(id) > -1) {
        return ''
      } else {
        return 'notDisplayed'
      }
    },
    showBaseTab: function () {
      return showBaseTabUtil(this.getKVPauschale(), this.answers)
    },
    getKVPauschale: function () {
      return generateKVPauschale(this.answers)
    },
    positionOfStepIndicator: function () {
      if (this.tabIndex === 0) {
        return 'width: 76px'
      }
      if (this.tabIndex === 1) {
        return 'width: 256px'
      }
      if (this.tabIndex === 2) {
        return 'width: 366px'
      }
    },
    drawTrophen: function (index, tropfenanzahl) {
      if (index < tropfenanzahl) {
        return 'filled'
      } else if (index === tropfenanzahl) {
        return 'filled filled__last'
      } else {
        if (index > tropfenanzahl && index < tropfenanzahl + 1) {
          return 'half'
        } else {
          return 'empty'
        }
      }
    },
    formatFloatToString (x) {
      return formatFloatToStringUtil(x)
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$emit('closeSummary')
  }
}
</script>
