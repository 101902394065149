Verwaltet die Wettbewerberliste.
Editieren und Neuanlegen öffent ein Modal mit dem Inhalt des "newCompetitor"-Objekts
<template>
  <div id="cms-competitors">
    <div class="headerLine">
      Wettbewerber hinzufügen<br />
      <input type="text" v-model="newCompetitor"><b-btn @click="addNew()">hinzufügen</b-btn>
    </div>
    <div class="filterBar">
      <h5>Wettbewerber ({{competitorArray.length}})</h5>
      <input placeholder="Wettbewerbersuche" type="text" v-model="search">
    </div>
    <table>
      <template v-for="(competitor, key) in matches">
        <tr :key="key" >
          <td v-if="editId == competitor.id"><input type="text" v-model="editCompetitor"></td>
          <td v-else>{{competitor.Name}}</td>
          <td v-if="editId === competitor.id" @click="save()"><img :src="require('../../assets/img/cms_check.png')"></td>
          <td v-if="editId === competitor.id" @click="cancel()"><img :src="require('../../assets/img/cms_cancel.png')"></td>
          <td v-if="editId !== competitor.id" @click="edit(competitor.id, competitor.Name)"><img :src="require('../../assets/img/cms_edit.png')"></td>
          <td v-if="editId !== competitor.id" @click="del(competitor.id, false)"><img :src="require('../../assets/img/cms_delete.png')"></td>
        </tr>
      </template>
    </table>
    <b-modal class="cms_notification_modal" ref="newCompModal" hide-footer hide-header title="">
      <h5>Ein neues Wettbewerberprodukt wurde hinzugefügt.</h5>
      <b-button @click="$refs.newCompModal.hide()" class="modalButton">ok</b-button>
    </b-modal>
    <b-modal class="cms_notification_modal" ref="editCompModal" hide-footer hide-header title="">
      <h5>Das Wettbewerberprodukt wurde erfolgreich bearbeitet.</h5>
      <b-button @click="$refs.editCompModal.hide()" class="modalButton">ok</b-button>
    </b-modal>
    <b-modal class="cms_notification_modal" ref="deleteCompModal" hide-footer hide-header title="">
      <h5>Wollen Sie dieses Wettbewerberprodukt wirklich entfernen?</h5>
      <b-button @click="del(0, true)" class="modalButton">ja</b-button>
      <b-button @click="$refs.deleteCompModal.hide()" class="rightModalButton">nein</b-button>
    </b-modal>
  </div>
</template>
<script>
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
export default {
  name: 'Competitor',
  data: function () {
    return {
      competitorArray: [],
      newCompetitor: '',
      editCompetitor: '',
      search: '',
      editId: 0,
      deleteID: 0
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      DataService.fetchWettbewerber().then((response) => {
        this.competitorArray = response.data
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    addNew: function () {
      if (this.newCompetitor.length > 0) {
        DataService.setWettbewerberData({id: 0, Name: this.newCompetitor}).then((response) => {
          this.newCompetitor = ''
          this.loadData()
          this.$refs.newCompModal.show()
        }).catch((err) => {
          // handle error
          console.log(err)
        })
      }
    },
    del: function (id, confirmed) {
      if (confirmed === false) {
        this.deleteID = id
        this.$refs.deleteCompModal.show()
      } else {
        DataService.deleteWettbewerberData(this.deleteID).then((response) => {
          this.loadData()
          this.deleteID = 0
          this.$refs.deleteCompModal.hide()
        }).catch((err) => {
          // handle error
          console.log(err)
        })
      }
    },
    edit: function (id, name) {
      this.editId = id
      this.editCompetitor = name
    },
    save: function () {
      if (this.editCompetitor.length > 0) {
        DataService.setWettbewerberData({id: this.editId, Name: this.editCompetitor}).then((response) => {
          this.loadData()
          this.editId = 0
          this.editCompetitor = ''
          this.$refs.editCompModal.show()
        }).catch((err) => {
          // handle error
          console.log(err)
        })
      }
    },
    cancel: function () {
      this.editId = 0
      this.editCompetitor = ''
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'getInstitution']),
    matches () {
      return this.competitorArray.filter((element) => {
        var elementText = element.Name
        return (elementText.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || this.search.length === 0)
      })
    }
  }
}
</script>
