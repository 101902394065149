Container für die Anamnese.
Lädt die einzelnen Frageseiten mit der "Question"-Komponente. Die Wizard-Fragen werden über den DataService aus einem JSON File geladen.
Im letzten Schritt werden die Vorschläge generiert. Dazu wird über die generateRecommKeyUtil() Funktion ein Key generiert, 
der aus einem JSON-Objekt die Produkt-Vorschläge erhält. Dieses JSON-Object wird auch aus einer Datei geladen.
Im QuestionComplete Array befinden sich die Fragen aufgeteilt auf die Unterseiten. Die erste Seite wird über die Question Komponente direkt angezeigt
und dann beim Laden der weiteren Tabs weggelassen. (questionsComplete.slice(1))
In der Url steht der aktuelle Schritt als Zahl z.b. /wizard/1
<template>
  <div id="wizardContainer">
    <div class='breadcrumb'>
      <!-- ><h2 v-if="currentWizardStep === 0">{{questionsComplete[currentWizardStep].segmentName}}</h2> -->
      <h2 v-if="currentWizardStep === 0">Beratung starten</h2>
      <h2 v-if="currentWizardStep !== 0">Anamnese</h2>
      <div v-if="currentWizardStep != 0" id="statusBar">
        <button v-bind:class="buttonIsActive(key)" v-for="(segment, key) in questionsComplete.slice(1)"  @click="jumpToStep(key)" :key="key" v-bind:disabled="buttonIsClickable(key) === 'disabled' ? true : false">{{ segment.segmentName }} </button>
      </div>

      <div class='breadcrumbArrow'>
        <div :style="positionOfStepIndicator()" class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>

    <div v-if="currentWizardStep === 3 || currentWizardStep === 4" class="helpSwitch"><span class="switcherText">Hilfe:</span> <switches type-bold="true" v-model="help"></switches></div>

    <Questions class="questionsContainer" v-if="questionsComplete[currentWizardStep] != null" :segmentObject="questionsComplete[currentWizardStep]" :help="help" :missingKeys="buttonStatus[currentWizardStep]" :showError="showError" />
    <div id="footer">
      <div id="segmentNavi">
        <b-button @click="prevStep">Zurück</b-button>
        <b-button class="right next" v-bind:class="buttonIsClickable(currentWizardStep)"  @click="nextStep">{{rightButtonText}}</b-button>
        <router-link to="/cart"><b-button v-if="(givenAnswers.anamneseType === 'AT3' || givenAnswers.anamneseType === 'AT2') && currentWizardStep === 0 && givenAnswers.krankenkasse !== undefined && givenAnswers.birthdate !== undefined && givenAnswers.inkontinenz !== undefined" class="right">Weiter ohne Anamnese</b-button></router-link>
      </div>
    </div>
  </div>
</template>

<script>

import Questions from '@/components/wizard/Questions'
import {answerStorage, cartProductStorage} from '@/utils/localstorage.js'
import {generateRecommKeyUtil, generateCartObject} from '@/utils/util.js'
import DataService from '@/services/DataService'
import Switches from 'vue-switches'
import { mapGetters, mapState } from 'vuex'
import {EventBus} from '@/services/EventBus.js'

export default {
  name: 'WizardContainer',
  created: function () {
    this.currentWizardStep = parseInt(this.$route.params.index)
    if (this.currentWizardStep === 0) {
      this.setBirthDayFieldsFromDate()
    }
    this.$on('updateButtons', () => {
      this.lookForMissingKeys()
      this.$parent.$emit('updateHeader', this.givenAnswers)
    })

    // first load products, then wizard, then recommendations and then "reload" header and button status
    DataService.fetchCombinedProducts().then((response) => {
      this.products = response.data
      DataService.fetchWizard().then((response) => {
        this.questionsComplete = response.data
        DataService.fetchRecommendations().then((response) => {
          this.recommendations = response.data
          this.lookForMissingKeys()
          this.$parent.$emit('updateHeader', this.givenAnswers)
        }).catch((err) => {
          console.log(err)
        })
      }).catch((err) => {
        console.log(err)
      })
    }).catch((err) => {
      if (err.response.status === 401) {
        this.eventBusObject.$emit('tokenInvalid')
      } 
      console.log(err)
    })
  },
  components: {
    Questions, Switches
  },
  computed: {
    rightButtonText: function () {
      if (this.currentWizardStep < 4) {
        if (this.currentWizardStep === 0 && this.showAnamnese == true) {
          return 'Anamnese starten'
        } else {
          return 'weiter'
        }
      } else {
        return 'Empfehlung anzeigen'
      }
    },
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'isAdmin', 'getRights']),
    showAnamnese: function () {
      return this.getRights.indexOf('bera_durch') !== -1
    },
    showVersorgung: function () {
      return this.getRights.indexOf('versorg_aufr') !== -1
    }
  },
  watch: {
    // for route/tab changes from sideMenu:
    '$route.params.index': function (index) {
      this.currentWizardStep = parseInt(this.$route.params.index)
    }
  },
  data: function () {
    return {
      currentWizardStep: 0,
      recommendations: {},
      givenAnswers: answerStorage.fetch(),
      buttonStatus: {},
      questionsComplete: [],
      products: {},
      help: false,
      showError: false,
      eventBusObject: EventBus
    }
  },
  methods: {
    nextStep: function () {
      if (this.buttonStatus[this.currentWizardStep] != null && this.buttonStatus[this.currentWizardStep].length === 0) {
        this.showError = false
        if (this.currentWizardStep < 4 && this.showAnamnese) {
          this.currentWizardStep += 1
          this.$router.replace('/wizard/' + this.currentWizardStep).catch(err => err)
        } else if (this.showAnamnese){
          var cart = []
          var recomKey = generateRecommKeyUtil(this.givenAnswers)
  
          var recomProducts = this.recommendations[recomKey]
         
          var fixPantNeeded = false
          for (var i in recomProducts) {
            var product = this.products[recomProducts[i]['ids'][0]]
            var cartObject = generateCartObject(product, this.givenAnswers, this.products)

            // only add Product if not Selbstzahler/privat and Basisprodukt
            if (!(cartObject.careType === 1 && product['PremiumBasis'] === 'Basis')) {
              if (product['Anwendungstyp'] === 'Vorlagen') {
                fixPantNeeded = true
              }
              if (product['PremiumBasis'] === 'Basis') {
                cart.push(cartObject)
              } else {
                cart.unshift(cartObject)
              }
            }
          }
          if (fixPantNeeded) {
            var productFix2 = this.products['9477957']
            var cartObjectFix2 = generateCartObject(productFix2, this.givenAnswers, this.products)
            cart.push(cartObjectFix2)
            var productFix = this.products['9477487']
            if (this.givenAnswers.groesse === 'M') {
              productFix = this.products['9477457']
            }
           
            var cartObjectFix = generateCartObject(productFix, this.givenAnswers, this.products)
            // only add base fixpant if base caretype
            if (cartObjectFix.careType === 0) {
              cart.push(cartObjectFix)
            }
          }

          cartProductStorage.save(cart)
          this.$router.push({path: '/recommendations'})
        } else if (this.showVersorgung) {
          this.$router.push({path: '/cart'})
        } else {
          this.$router.push({path: '/offer'})
        }
        // this.$parent.$emit('currentWizardStep', this.currentWizardStep + 1)
      } else {
        this.showError = true
      }
    },
    prevStep: function () {
      if (this.currentWizardStep > 0) {
        this.currentWizardStep -= 1
        this.$router.replace('/wizard/' + this.currentWizardStep).catch(err => err)
        if (this.currentWizardStep === 0) {
        }
      } else {
        this.$router.push('/')
      }
      // this.$parent.$emit('currentWizardStep', this.currentWizardStep - 1)
    },
    jumpToStep: function (step) {
      this.currentWizardStep = step + 1
      this.$router.replace('/wizard/' + this.currentWizardStep).catch(err => err)
    },
    buttonIsActive: function (step) {
      if (this.currentWizardStep === step+1) {
        return 'active'
      }
    },
    buttonIsClickable: function (step) {
      if (this.buttonStatus[step] != null && this.buttonStatus[step].length === 0) {
        return 'enabled'
      } else {
        return 'disabled'
      }
    },
    lookForMissingKeys: function () {
      this.givenAnswers = answerStorage.fetch()
      var missingKeys = {}
      for (var x in this.questionsComplete) {
        var missingKeysForSegment = []

        var segment = this.questionsComplete[x]
        for (var y in segment.questions) {
          var question = segment.questions[y]
          if (question.required === true) {
            var isAlreadySet = false
            for (var key in this.givenAnswers) {
              if (key === 'krankenkasse' && this.givenAnswers[key].Kasse !== 'Keine Krankenkasse') {
                this.setBirthdayRequired(true)
              } else if (key === 'krankenkasse' && this.givenAnswers[key].Kasse === 'Keine Krankenkasse') {
                this.setBirthdayRequired(false)
              }
              if (this.givenAnswers.hasOwnProperty(key) && key === question.id) {
                if (this.givenAnswers[key].toString().length > 0) {
                  isAlreadySet = true
                }
                break
              }
            }
            if (!isAlreadySet) {
              missingKeysForSegment.push(question.id)
            }
          }
        }
        missingKeys[x] = missingKeysForSegment
      }
      this.buttonStatus = missingKeys
      // return missingKeys
    },
    setBirthdayRequired: function (required) {
      for (var x in this.questionsComplete) {
        var segment = this.questionsComplete[x]
        for (var y in segment.questions) {
          var question = segment.questions[y]
          if (question.id === 'birthdate') {
            question.required = required
            break
          }
        }
      }
    },
    positionOfStepIndicator: function () {
      if (this.currentWizardStep === 1 || this.currentWizardStep === 0) {
        return 'width: 30px'
      }
      if (this.currentWizardStep === 2) {
        return 'width: 150px'
      }
      if (this.currentWizardStep === 3) {
        return 'width: 260px'
      }
      if (this.currentWizardStep === 4) {
        return 'width: 350px'
      }
    },
    setBirthDayFieldsFromDate: function () {
      // check for already set or changed birthdate and set day/month/year accordingly
      this.givenAnswers = answerStorage.fetch()
      var didChangeSomething = false
      if (this.givenAnswers['birthdate'] !== undefined) {
        var date = new Date(this.givenAnswers['birthdate'])
        if (date.getDate() !== this.givenAnswers['birthdate-day']) {
          didChangeSomething = true
          this.givenAnswers['birthdate-day'] = date.getDate()
        }
        if (date.getMonth() + 1 !== this.givenAnswers['birthdate-month']) {
          didChangeSomething = true
          this.givenAnswers['birthdate-month'] = date.getMonth() + 1
        }
        if (date.getFullYear() !== this.givenAnswers['birthdate-year']) {
          didChangeSomething = true
          this.givenAnswers['birthdate-year'] = date.getFullYear()
        }
        if (didChangeSomething) {
          answerStorage.save(this.givenAnswers)
          this.$forceUpdate()
        }
      }
    }
  }
}

</script>
