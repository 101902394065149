import axios from 'axios'

export default {
  getCombinedProducts () {
    return axios.get('/static/jsons/mock_combined_products.json')
  },
  getFlatProducts () {
    return axios.get('/static/jsons/mock_flat_products_array.json')
  },
  getWizard () {
    return axios.get('/static/jsons/wizard_141220.json')
  },
  getRecommendations () {
    return axios.get('/static/jsons/recommendations.json')
  },
  getTextRecommendations () {
    return axios.get('/static/jsons/textrecommendations_290119.json')
  },
  getKrankenkassen () {
    return axios.get('/static/jsons/mock_kvs.json')
  },
  getWettbewerber () {
    return axios.get('/static/jsons/mock_wettbewerber.json')
  },
  getMockUserId () {
    return axios.get('/static/jsons/mock_user.json')
  },
  getMockInstitutionData () {
    return axios.get('/static/jsons/mock_Institution.json')
  },
  getMockInstitutionsData () {
    return axios.get('/static/jsons/mock_Institutions.json') 
  },
  getMockRolesData () {
    return axios.get('/static/jsons/mock_roles.json')
  },
  getMockRolesCountData () {
    return axios.get('/static/jsons/mock_rolescount.json')
  },
  getMockRightsData () {
    return axios.get('/static/jsons/mock_rights.json')
  },
  getMockPaketsData () {
    return axios.get('/static/jsons/mock_pakets.json')
  },
  getMockPriceData () {
    return axios.get('/static/jsons/mock_prices.json')
  },
  getMockOwnKVData () {
    return axios.get('/static/jsons/mock_own_kvs.json')
  },
  getMockOwnKNRCheck () {
    return axios.get('/static/jsons/mock_knr_check.json')
  },
  getMockLastUploadDate () {
    return axios.get('/static/jsons/mock_last_kunden_csv_upload.json')
  }
}
