Verwaltet die Liste der Produkte. 
Über den CSV Upload kann eine komplette Liste neu hochgeladen werden.
Editieren oder Neuanlegen öffnet ein Modal mit den Daten aus dem "clickedProduct"-Objekt
<template>
  <div id="cms-products">

    <div class="headerLine">
      <div style="float:left">
        <b-button style="float: left" @click="create()">Produkt hinzufügen</b-button>
        <input class="searchField" style="margin-left: 10px; float: left; width: 250px;" type="text" v-model="search" placeholder="Produktsuche">
      </div>
      <div style="float:right">
          <b-btn style="float: left;" @click="downloadCSV()">Download CSV</b-btn>
          <b-btn v-if="upload==false" style="float: left;margin-left: 10px;" @click="uploadCSV">Upload CSV</b-btn>
      </div>
    </div>
    <div style="clear: both; margin-top: 10px" >
      <h5>alle Produkte ({{Object.keys(matches).length}})</h5>
    </div>
    <b-modal modal-class="uploadCSVModal cms_notification_modal" ref="uploadCSVModal" size="m" hide-footer hide-header title="">
      <vue-dropzone style="float: left;" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropSuccess" v-on:vdropzone-error="dropError"  v-on:vdropzone-canceled="dropCanceled"></vue-dropzone>
      <div v-if="CSVUploadCallback != null && CSVUploadCallback.edited != undefined"  style="float:left; margin-left:10px">
          {{CSVUploadCallback.edited}} Zeile(n) bearbeitet <br />
          {{CSVUploadCallback['not edited']}} Zeile(n) identisch <br />
          {{CSVUploadCallback.inserted}} Zeile(n) neu eingefügt <br />
          {{CSVUploadCallback.errors}} Fehler <br />
          {{CSVUploadCallback.errorMessages}} <br />
          <b-btn @click="writeCSVTempToDB">speichern</b-btn>
      </div>
      <div v-if="CSVUploadError != null && CSVUploadError != ''" style="float:left; margin-left:10px">
        Es gab einen Fehler beim Upload: {{CSVUploadError}}
      </div>
      <div class="closeButton" @click="hideCSVModal()" ></div>
    </b-modal>
    <table>
      <tr>
        <th><input style="width: 90px" type="text" v-model="searchNr" placeholder="Artikel Nr."></th>
        <th>Produkttyp:
          <select v-model="searchProdtyp" style="width: 90px">
            <option selected  value="alle">
              alle
            </option>
            <option value="Pflegehilfsmittel">
              Pflegehilfsmittel
            </option>
            <option value="Inkontinenzprodukt">
              Inkontinenzprodukt
            </option>
            <option value="Zubehör">
              Zubehör
            </option>
            <option value="Hautpflege">
              Hautpflege
            </option>
          </select>
        </th>
        <th>Anwendungstyp:
          <select v-model="searchAnwend" style="width: 90px">
            <option selected value="alle">
              alle
            </option>
            <option value="Einlage">
              Einlage
            </option>
            <option value="Vorlage">
              Vorlage
            </option>
            <option value="Fixierhose">
              Fixierhose
            </option>
            <option value="Slip">
              Slip
            </option>
            <option value="Pull-ups">
              Pull-ups
            </option>
            <option value="Untersuchungshandschuhe">
              Untersuchungshandschuhe
            </option>
            <option value="Händedesinfektion">
              Händedesinfektion
            </option>
            <option value="Flächendesinfektion">
              Flächendesinfektion
            </option>
            <option value="Schutzschürze">
              Schutzschürze
            </option>
            <option value="Mundschutz">
              Mundschutz
            </option>
            <option value="Krankenunterlagen">
              Krankenunterlagen
            </option>
            <option value="Schutz">
              Schutz
            </option>
            <option value="Pflege">
              Pflege
            </option>
            <option value="Reinigung">
              Reinigung
            </option>
          </select>
        </th>
        <th><input class="searchField" style="margin-left: 10px; float: left; width: 250px;" type="text" v-model="searchName" placeholder="Produktbezeichnung"></th>
        <th>Größe</th>
        <th>Anzahl pro Packung</th>
        <th>UVP in €</th>
      </tr>
      <template v-for="(product, key) in matches">
        <tr class="dataRow" :key="key" @click="show(key)">
          <td>{{product.Artikelnummer}}</td>
          <td>{{product.Inkontinenzprodukt}}</td>
          <td>{{product.Anwendungstyp}}</td>
          <td>{{product.Produktbezeichnung}}</td>
          <td>{{product.Konfektionsgroesse}}</td>
          <td>{{product.Anzahl}}</td>
          <td>{{product.UVP}}</td>
        </tr>
      </template>
    </table>
    <b-modal modal-class="editProductModal editModal" ref="editProductModal" size="lg" hide-footer hide-header title="">
      <div v-if="editMode==false">
        <h4>{{clickedProduct.Produktbezeichnung}} <span @click="edit()" >bearbeiten</span></h4>
        <div class="dataContainer">
          <div class="leftContainer">
            <div class="oneHalf">
              <span>Artikelnummer:</span>
              {{clickedProduct.Artikelnummer}}
            </div>
            <div class="oneHalf">
              <span>Produktart:</span>
              {{clickedProduct.Inkontinenzprodukt}}
            </div>
            <div class="oneHalf">
              <span>Anwendungstyp / System:</span>
              {{clickedProduct.Anwendungstyp}}
            </div>
            <div :class="'oneHalf ' + isDisabled('producttype')">
              <span>Premium / Basis:</span>
              {{clickedProduct.PremiumBasis}}
            </div>
            <div class="full">
              <span>Produktbezeichnung:</span>
              {{clickedProduct.Produktbezeichnung}}
            </div>
            <div class="oneThird">
              <span>PZN:</span>
              {{clickedProduct.PZN}}
            </div>
            <div class="oneThird">
              <span>EAN:</span>
              {{clickedProduct.EAN}}
            </div>
            <div class="oneThird">
              <span>Hilfsmittelnummer:</span>
              {{clickedProduct.Hilfsmittelnummer}}
            </div>
            <div class="full">
              <span>Vorteilskommunikation:</span>
              <ul>
                <li v-for="(vorteil, key2) in clickedProduct.Vorteilskommunikation" :key="key2">
                  {{clickedProduct.Vorteilskommunikation[key2]}}
                </li>
              </ul>
            </div>
          </div>
          <div class="rightContainer">
            <div>
              <div class="oneHalf">
                <span>Imagepfad:</span>
                {{clickedProduct.imagepfad}}
              </div>
              <div class="imageContainer">
                <img :src="'/static/productimgs/' + clickedProduct.imagepfad + '.jpg'">
              </div>
            </div>
            <div class="clearfix"></div>
            <div :class="'oneThird ' + isDisabled('fixpants')">
              <span>Schweregrad:</span>
              {{clickedProduct.Schweregrad}}
            </div>
             <div :class="'oneThird ' + isDisabled('fixpants')">
              <span>Tropfenanzahl:</span>
              {{clickedProduct.Tropfenanzahl}}
            </div>
            <div class="clearfix"></div>
            <div v-if="clickedProduct.Konfektionsgroesse !== 'onesize'" :class="'oneThird ' + isDisabled('conFegSize')">
              <span>Konfektionsgröße:</span>
               <p v-if="(clickedProduct.Inkontinenzprodukt === 'Inkontinenzprodukt' || clickedProduct.Inkontinenzprodukt === 'Zubehör') && clickedProduct.Konfektionsgroesse !== 'onesize'">{{clickedProduct.Konfektionsgroesse}}</p>
            </div>
            <div v-if="clickedProduct.Konfektionsgroesse !== 'onesize'" :class="'oneThird ' + isDisabled('conFegSize')">
              <span>Hüftumfang (cm):</span>
              <p v-if="clickedProduct.Konfektionsgroesse !== 'onesize'">{{clickedProduct.Hueftumfang}}</p>
            </div>
            <div v-if="clickedProduct.Konfektionsgroesse !== 'onesize'" :class="'oneThird ' + isDisabled('size')">
              <span>Grösse (cm):</span>
              <p v-if="clickedProduct.Inkontinenzprodukt === 'Pflegehilfsmittel' && clickedProduct.Konfektionsgroesse !== 'onesize'">{{clickedProduct.Konfektionsgroesse}}</p>
            </div>
            <div class="clearfix"></div>
            <div v-if="clickedProduct.Konfektionsgroesse === 'onesize'" class="oneThird">
              <span>Größe:</span>
              onesize
            </div>
            <div class="clearfix"></div>
            <div :class="'oneThird ' + isDisabled('amount')">
              <span>Menge:</span>
              <p v-if="clickedProduct.liquid === true">{{clickedProduct.Anzahl}}</p>
            </div>
            <div :class="'oneThird ' + isDisabled('quantity')">
              <span>Anzahl pro Packung:</span>
              <p v-if="clickedProduct.liquid === false">{{clickedProduct.Anzahl}}</p>
            </div>
            <div class="oneThird">
              <span>UVP (in €):</span>
              {{clickedProduct.UVP}}
            </div>
          </div>
          <div class="clearfix"></div>
          <div v-if="supportsFeatureIcons()" :class="'full iconContainer ' + isDisabled('producttype')">
            <span>Icons:</span>
            <ul>
              <li v-for="(icon, key3) in clickedProduct.ICONS" :key="key3">
                <img v-if="clickedProduct.ICONS[key3] != '' && clickedProduct.ICONS[key3] != undefined" :src="require('../../assets/img/' + clickedProduct.ICONS[key3] + '.svg')">
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 v-if="clickedProduct.Artikelnummer != null">Produkt bearbeiten</h4> <h4 v-else>Produkt hinzufügen</h4>
        <div class="dataContainer">
          <div class="leftContainer">
            <div class="oneHalf">
              <span>Artikelnummer:*</span>
              <input type="text" v-model='clickedProduct.Artikelnummer'>
            </div>
            <div class="oneHalf">
              <span>Produktart:</span>
              <select @change="changeProductType()" v-model='clickedProduct.Inkontinenzprodukt'>
                <option>bitte auswählen</option>
                <option>Inkontinenzprodukt</option>
                <option>Zubehör</option>
                <option>Pflegehilfsmittel</option>
                <option>Hautpflege</option>
              </select>
            </div>
            <div class="oneHalf">
              <span>Anwendungstyp / System:*</span>
              <select @change="changeSystem()" v-model='clickedProduct.Anwendungstyp'>
                <option>bitte auswählen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Zubehör'">Fixierhosen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Einlagen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Vorlagen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Slip Elastic</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Slip</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Pull-ups</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Pflegehilfsmittel'">Untersuchungshandschuhe</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Pflegehilfsmittel'">Händedesinfektion</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Pflegehilfsmittel'">Flächendesinfektion</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Pflegehilfsmittel'">Schutzschürzen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Pflegehilfsmittel'">Mundschutz</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Pflegehilfsmittel'">Krankenunterlagen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Hautpflege'">Schutz</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Hautpflege'">Pflege</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Hautpflege'">Reinigung</option>
              </select>
            </div>
            <div :class="'oneHalf ' + isDisabled('producttype')">
              <span>Premium / Basis:*</span>
              <select v-model='clickedProduct.PremiumBasis'>
                <option>bitte auswählen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt' || clickedProduct.Inkontinenzprodukt == 'Zubehör'">Premium</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt' || clickedProduct.Inkontinenzprodukt == 'Zubehör'">Basis</option>
                <option v-if="clickedProduct.Inkontinenzprodukt != 'Inkontinenzprodukt' && clickedProduct.Inkontinenzprodukt != 'Zubehör'">-</option>
              </select>
            </div>
            <div class="full">
              <span>Produktbezeichnung:*</span>
              <input type="text" v-model='clickedProduct.Produktbezeichnung'>
            </div>
            <div class="oneThird">
              <span>PZN:*</span>
              <input type="text" v-model='clickedProduct.PZN'>
            </div>
            <div class="oneThird">
              <span>EAN:*</span>
              <input type="text" v-model='clickedProduct.EAN'>
            </div>
            <div class="oneThird">
              <span>Hilfsmittelnummer:*</span>
              <input type="text" v-model='clickedProduct.Hilfsmittelnummer'>
            </div>
            <div class="full">
              <span>Vorteilskommunikation:</span>
              <textarea rows="10" style="width: 100%" v-model='vorteilsString'>
              </textarea>
            </div>
          </div>
          <div class="rightContainer">
            <div>
              <div class="oneHalf">
                <span>Imagepfad:</span>
                <input type="text" v-model='clickedProduct.imagepfad'>
              </div>
              <div v-if="clickedProduct.imagepfad != null && editImage === false" class="imageEditContainer">
                <img :src="'/static/productimgs/' + clickedProduct.imagepfad + '.jpg'">
                <b-btn size="sm" @click="editImage = true">Bild bearbeiten</b-btn>
              </div>
              <div v-else class="imageEditContainer">
                  <vue-dropzone ref="myVueDropzoneImage" id="dropzoneImage" :options="dropzoneImageOptions" v-on:vdropzone-complete="dropImageComplete" v-on:vdropzone-canceled="dropCanceled" :useCustomSlot=true>
                    <div class="dropzone-custom-content">
                      <div class="subtitle">Bitte in der Auflösung 510 x 510 Pixel <br> und im jpg Format hochladen</div>
                    </div>
                  </vue-dropzone>
                  <b-btn v-if="editImage === true" size="sm" @click="editImage = false">zurück</b-btn>
              </div>
            </div>
            <div class="clearfix"></div>
            <div :class="'oneHalf ' + isDisabled('fixpants')">
              <span>Schweregrad:*</span>
              <select v-model='clickedProduct.Schweregrad'>
                <option>bitte auswählen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Leichte Inkontinenz</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Mittlere Inkontinenz</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Schwere Inkontinenz</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">Schwerste Inkontinenz</option>
                <option v-if="clickedProduct.Inkontinenzprodukt != 'Inkontinenzprodukt'">Kein Schweregrad</option>
              </select>
            </div>
             <div :class="'oneHalf ' + isDisabled('fixpants')">
              <span>Tropfenanzahl:*</span>
              <select v-model='clickedProduct.Tropfenanzahl'>
                <option>bitte auswählen</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">0.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">1</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">1.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">2</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">2.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">3</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">3.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">4</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">4.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">5.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">6</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">6.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">7</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">7.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">8</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">8.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">9</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">9.5</option>
                <option v-if="clickedProduct.Inkontinenzprodukt == 'Inkontinenzprodukt'">10</option>
                <option v-if="clickedProduct.Inkontinenzprodukt != 'Inkontinenzprodukt'">0</option>
              </select>
            </div>
            <div class="clearfix"></div>
            <div class="oneHalf">
              <span>Ist es ein "onesize" Produkt?</span>
              <select @change="changeOnesize()" v-model="isOnesize">
                <option value="false">Nein</option>
                <option value="true">Ja</option>
              </select>
            </div>
            <div class="clearfix"></div>
            <div class="oneHalf">
              <span v-if="clickedProduct.Anwendungstyp !== 'Krankenunterlagen' && clickedProduct.Anwendungstyp !== 'Schutzschürzen' && clickedProduct.Anwendungstyp !== 'bitte auswählen' && isOnesize !== 'true'">Konfektionsgröße:*<br />
                <i>(z.B: XS | S | M | L | XL | XXL)</i>
              </span>
              <span v-if="(clickedProduct.Anwendungstyp === 'Krankenunterlagen' || clickedProduct.Anwendungstyp === 'Schutzschürzen') && isOnesize!== 'true'">Grösse (cm):*<br />
                <i>(z.B: 75 x 85 cm | 140 cm)</i>
              </span>
              <input v-if="isOnesize !== 'true' && clickedProduct.Anwendungstyp !== 'bitte auswählen'" type="text" v-model='clickedProduct.Konfektionsgroesse'>
            </div>

            <div class="oneHalf">
              <span v-if="(clickedProduct.Inkontinenzprodukt === 'Inkontinenzprodukt' || clickedProduct.Inkontinenzprodukt === 'Zubehör') && isOnesize !== 'true'">Hüftumfang (cm): <br />
              <i>(z.B: 40-60)</i>
              </span>
              <input v-if="(clickedProduct.Inkontinenzprodukt === 'Inkontinenzprodukt' || clickedProduct.Inkontinenzprodukt === 'Zubehör') && isOnesize !== 'true'" type="text" v-model='clickedProduct.Hueftumfang'>
            </div>

            <div class="clearfix"></div>

            <div class="oneHalf">
              <span>Handelt es sich um eine Flüssigkeit?</span>
              <select @change="changeLiquid()" v-model="isLiquid">
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select>
            </div>

            <div class="clearfix"></div>

            <div class="oneHalf">
              <span v-if="isLiquid === 'true'">Menge:*<br />
              <i>(z.B: 500ml)</i>
              </span>
              <span v-if="isLiquid === 'false'">Anzahl pro Packung:*<br />
              <i>(z.B: 25 Stück)</i>
              </span>
              <input type="text" v-model='clickedProduct.Anzahl'>
           </div>
           <div class="oneHalf">
              <span>UVP (in €):<br />
                <i>(Schreibweise mit '.')</i>
              </span>
              <input type="text" v-model='clickedProduct.UVP'>
            </div>
          </div>
          <div class="clearfix"></div>
          <div v-if="supportsFeatureIcons()" :class="'full iconContainer ' + isDisabled('producttype')">
            <span>Icons:</span>
            <ul>
              <li v-for="(name, index) in featureIconNames"
                  :key="index"
                  :class="iconIsChoosen(name)" 
                  @click="chooseIcon(name)"
              >
                <img :src="require('../../assets/img/' + name + '.svg')">
              </li>
            </ul>
          </div>
          <div class="clearfix"></div>
        </div>
        <b-button @click="deleteMode = true" v-if="createMode === false && deleteMode === false" style="float: left" class="noHighlight" >Produkt löschen</b-button>
        <div style="float: left; padding: 14px 0px;" v-if="createMode === false && deleteMode === true">
          <h5 style="font-size: 16px; display: inline-block">Jetzt löschen?</h5>
          <span style="display: inline-block" @click="deleteProduct()" ><img :src="require('../../assets/img/cms_check.png')"></span>
          <span style="display: inline-block" @click="deleteMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
        </div>

        <b-button :class="buttonIsClickable().text" v-bind:disabled="buttonIsClickable().boolean" @click="save()" >Speichern</b-button>
        <b-button :class="'noHighlight ' + buttonIsClickable().text" v-bind:disabled="buttonIsClickable().boolean" v-if="createMode === true" style="margin-right: 20px" @click="save('next')" >speichern und weiteres hinzufügen</b-button>
      </div>
      <div v-if="cancelMode === false" class="closeButton" @click="back()" ></div>
      <div v-if="cancelMode === true" style="position: absolute; top: 20px; right: 20px;">
        <h5 style="font-size: 16px; display: inline-block; padding: 0px; margin: 0px;">Abbrechen ohne speichern?</h5>
        <span style="display: inline-block" @click="editMode = false; cancelMode = false; back()" ><img :src="require('../../assets/img/cms_check.png')"></span>
        <span style="display: inline-block" @click="cancelMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
      </div>
    </b-modal>
    <b-modal modal-class="cms_notification_modal" ref="newProductModal" hide-footer hide-header title="">
      <div class="d-block text-center">
        <h5>Ein neues Produkt wurde hinzugefügt.</h5>
        <b-button @click="$refs.newProductModal.hide()" class="modalButton">Ok</b-button>
      </div>
    </b-modal>
    
    <b-modal modal-class="cms_notification_modal" ref="editedProductModal" hide-footer hide-header title="">
      <div class="d-block text-center">
        <h5>Produkt wurde erfolgreich bearbeitet.</h5>
        <b-button @click="$refs.editedProductModal.hide()" class="modalButton">Ok</b-button>
      </div>
    </b-modal>

    <b-modal modal-class="cms_notification_modal" ref="deletedProductModal" hide-footer hide-header title="">
      <div class="d-block text-center">
        <h5>Produkt wurde erfolgreich glöscht.</h5>
        <b-button @click="$refs.deletedProductModal.hide()" class="modalButton">Ok</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
import vue2Dropzone from '@/libs/vue-dropzone/index.js'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
const Json2csvParser = require('json2csv').Parser
export default {
  name: 'Products',
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function () {
    return {
      upload: false,
      productsObject: [],
      editMode: false,
      createMode: false,
      deleteMode: false,
      cancelMode: false,
      clickedProduct: {},
      choosenIcons: [],
      isLiquid: 'false',
      isOnesize: 'false',
      vorteilsString: '',
      search: '',
      searchName: '',
      searchNr: '',
      searchProdtyp: 'alle',
      searchAnwend: 'alle',
      editImage: false,
      dropzoneOptions: {
        createImageThumbnails: false,
        dictDefaultMessage: 'CSV hochladen',
        url: '/static/api/cms/uploadcsv.php',
        thumbnailWidth: 0,
        maxFilesize: 10,
        acceptedFiles: '.csv',
        timeout: 60000,
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user-token') }
      },
      dropzoneImageOptions: {
        createImageThumbnails: true,
        dictDefaultMessage: '',
        url: '/static/api/cms/uploadImage.php',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 10,
        acceptedFiles: '.jpg, .jpeg',
        timeout: 60000,
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user-token') }
      },
      CSVUploadCallback: {},
      CSVUploadError: ''
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      DataService.fetchAllFlatProducts().then((response) => {
        this.productsObject = response.data
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    create: function () {
      this.editMode = true
      this.createMode = true
      this.choosenIcons = []
      this.isLiquid = 'false'
      this.isOnesize = 'false'
      this.vorteilsString = ''
      this.clickedProduct = {
        'Inkontinenzprodukt': 'bitte auswählen',
        'Anwendungstyp': 'bitte auswählen',
        'PremiumBasis': 'bitte auswählen'
      }
      this.$refs.editProductModal.show()
      if (this.$refs.myVueDropzoneImage != null)
      {
        this.$refs.myVueDropzoneImage.removeAllFiles();
      }
    },
    show: function (key) {
      this.editMode = false
      this.cancelMode = false
      this.editImage = false
      this.clickedProduct = this.matches[key]
      this.$refs.editProductModal.show()
      if (this.$refs.myVueDropzoneImage != null)
      {
        this.$refs.myVueDropzoneImage.removeAllFiles();
      }
      
    },
    edit: function () {
      this.editMode = true
      this.createMode = false
      this.cancelMode = false
      this.editImage = false
      var tempVorteilsstring = this.clickedProduct.Vorteilskommunikation.join()
      tempVorteilsstring = tempVorteilsstring.replace(/, /g, 'XXXX')
      tempVorteilsstring = tempVorteilsstring.replace(/,/g, '\n')
      tempVorteilsstring = tempVorteilsstring.replace(/XXXX/g, ', ')
      this.vorteilsString = tempVorteilsstring
      this.choosenIcons = this.clickedProduct.ICONS
      if (this.clickedProduct.Konfektionsgroesse === 'onesize') {
        this.isOnesize = 'true'
      }
      if (this.clickedProduct.liquid === true) {
        this.isLiquid = 'true'
      }
    },
    back: function () {
      if (this.editMode === true) {
        this.cancelMode = true
      } else {
        this.editMode = false
        this.loadData()
        this.$refs.editProductModal.hide()
      }
    },
    save: function (special) {
      var dataObject = this.clickedProduct

      dataObject.ICONS = this.choosenIcons
      dataObject.liquid = this.isLiquid
      dataObject.Vorteilskommunikation = []
      if (this.vorteilsString != '') {
        dataObject.Vorteilskommunikation = this.vorteilsString.split("\n")
      }

      DataService.setProductData(dataObject).then((response) => {
        this.loadData()
        if (special !== 'next') {
          this.$refs.editProductModal.hide()
          this.editMode = false
          if (this.createMode === true) {
            this.$refs.newProductModal.show()
          } else {
            this.$refs.editedProductModal.show()
          }
        } else {
          this.choosenIcons = []
          this.isLiquid = 'false'
          this.isOnesize = 'false'
          this.vorteilsString = ''
          this.clickedProduct = {
            'Inkontinenzprodukt': 'bitte auswählen',
            'Anwendungstyp': 'bitte auswählen',
            'PremiumBasis': 'bitte auswählen'
          }
        }
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    deleteProduct: function () {
      DataService.deleteProduct(this.clickedProduct.id).then((response) => {
        this.loadData()
        this.$refs.editProductModal.hide()
        this.editMode = false
        this.deleteMode = false
        this.$refs.deletedProductModal.show()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    supportsFeatureIcons: function(){
        var productType = this.clickedProduct.Inkontinenzprodukt;
        var supportedTypes = [
          'Inkontinenzprodukt',
          'Zubehör',
          'Pflegehilfsmittel',
          'Hautpflege'
        ];
        return (supportedTypes.indexOf(productType) !== -1);
    },
    isDisabled: function (parameter) {
      if (parameter === 'producttype') {
        return this.supportsFeatureIcons() ? 'enabled' : 'disabled';
      }
      if (parameter === 'fixpants') {
        if (this.clickedProduct.Inkontinenzprodukt === 'Inkontinenzprodukt') {
          return 'enabled'
        } else {
          return 'disabled'
        }
      }
      if (parameter === 'size') {
        if (this.clickedProduct.Anwendungstyp === 'Krankenunterlagen' || this.clickedProduct.Anwendungstyp === 'Schutzschürzen') {
          return 'enabled'
        } else {
          return 'disabled'
        }
      }
      if (parameter === 'conFegSize') {
        if (this.clickedProduct.Anwendungstyp === 'Krankenunterlagen' || this.clickedProduct.Anwendungstyp === 'Schutzschürzen') {
          return 'disabled'
        } else {
          return 'enabled'
        }
      }
      if (parameter === 'amount') {
        if (this.clickedProduct.liquid === true) {
          return 'enabled'
        } else {
          return 'disabled'
        }
      }
      if (parameter === 'quantity') {
        if (this.clickedProduct.liquid === true) {
          return 'disabled'
        } else {
          return 'enabled'
        }
      }
    },
    iconIsChoosen: function (icon) {
      if (this.choosenIcons.indexOf(icon) > -1) {
        return 'choosen'
      } else {
        return 'notchoosen'
      }
    },
    chooseIcon: function (icon) {
      if (this.choosenIcons.indexOf(icon) > -1) {
        for (var i = this.choosenIcons.length - 1; i >= 0; i--) {
          if (this.choosenIcons[i] === icon) {
            this.choosenIcons.splice(i, 1)
          }
        }
      } else {
        this.choosenIcons.push(icon)
      }
    },
    changeProductType: function () {
      this.clickedProduct.Konfektionsgroesse = ''
      this.clickedProduct.Hueftumfang = ''

      if (this.clickedProduct.Inkontinenzprodukt === 'Pflegehilfsmittel' || this.clickedProduct.Inkontinenzprodukt === 'Hautpflege') {
        this.choosenIcons = []
        this.clickedProduct.PremiumBasis = '-'
        this.clickedProduct.Schweregrad = 'Kein Schweregrad'
        this.clickedProduct.Tropfenanzahl = '0'
      }
      if (this.clickedProduct.Inkontinenzprodukt === 'Zubehör' || this.clickedProduct.Inkontinenzprodukt === 'Inkontinenzprodukt') {
        this.clickedProduct.PremiumBasis = 'bitte auswählen'
      }
      if (this.clickedProduct.Inkontinenzprodukt === 'Zubehör') {
        this.clickedProduct.Anwendungstyp = 'Fixierhosen'
        this.clickedProduct.Schweregrad = 'Kein Schweregrad'
        this.clickedProduct.Tropfenanzahl = '0'
      } else {
        this.clickedProduct.Anwendungstyp = 'bitte auswählen'
      }
      if (this.clickedProduct.Inkontinenzprodukt === 'Inkontinenzprodukt') {
        this.clickedProduct.Schweregrad = 'bitte auswählen'
        this.clickedProduct.Tropfenanzahl = 'bitte auswählen'
      }
    },
    changeOnesize: function () {
      if (this.isOnesize === 'true') {
        this.clickedProduct.Konfektionsgroesse = 'onesize'
        this.clickedProduct.Hueftumfang = 'onesize'
      } else {
        this.clickedProduct.Konfektionsgroesse = ''
        this.clickedProduct.Hueftumfang = ''
      }
    },
    changeLiquid: function () {
      this.clickedProduct.Anzahl = ''
    },
    buttonIsClickable: function () {
      if (this.requiredAnswers() === true) {
        return {
          text: 'enabled',
          boolean: false
        }
      } else {
        return {
          text: 'disabled',
          boolean: true
        }
      }
    },
    requiredAnswers: function () {
      if (
        this.clickedProduct.Artikelnummer !== '' && this.clickedProduct.Artikelnummer !== undefined &&
        this.clickedProduct.Inkontinenzprodukt !== 'bitte auswählen' && this.clickedProduct.Inkontinenzprodukt !== undefined &&
        this.clickedProduct.Anwendungstyp !== 'bitte auswählen' && this.clickedProduct.Anwendungstyp !== undefined &&
        this.clickedProduct.PremiumBasis !== 'bitte auswählen' && this.clickedProduct.PremiumBasis !== undefined &&
        this.clickedProduct.Produktbezeichnung !== '' && this.clickedProduct.Produktbezeichnung !== undefined &&
        this.clickedProduct.PZN !== '' && this.clickedProduct.PZN !== undefined &&
        this.clickedProduct.EAN !== '' && this.clickedProduct.EAN !== undefined &&
        this.clickedProduct.Hilfsmittelnummer !== '' && this.clickedProduct.Hilfsmittelnummer !== undefined &&
        this.clickedProduct.Schweregrad !== 'bitte auswählen' && this.clickedProduct.Schweregrad !== undefined &&
        this.clickedProduct.Tropfenanzahl !== 'bitte auswählen' && this.clickedProduct.Tropfenanzahl !== undefined &&
        this.clickedProduct.Konfektionsgroesse !== 'bitte auswählen' && this.clickedProduct.Konfektionsgroesse !== undefined &&
        this.clickedProduct.Anzahl !== 'bitte auswählen' && this.clickedProduct.Anzahl !== undefined &&
        this.clickedProduct.UVP !== 'bitte auswählen' && this.clickedProduct.UVP !== undefined
      ) {
        return true
      } else {
        return false
      }
    },
    downloadCSV: function () {
      const fields = ['id', 'Artikelnummer', 'Produktbezeichnung', 'Schweregrad', 'PremiumBasis', 'Inkontinenzprodukt',
        'PZN', 'EAN', 'Hilfsmittelnummer', 'Konfektionsgroesse', 'Hueftumfang', 'Anzahl', 'Anwendungstyp', 'UVP', 'Vorteilskommunikation', 'imagepfad', 'Tropfenanzahl', 'ICONS', 'liquid', 'created', 'last_modified']
      const json2csvParser = new Json2csvParser({ fields: fields, delimiter: ';' }) //, delimiter: ';', eol: ';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;\n'
      var productsArray = []
      // use copy for csv download
      var productsObjectCopy = JSON.parse(JSON.stringify(this.productsObject))
      for (var key in productsObjectCopy) {
        var innerProduct = productsObjectCopy[key]
        innerProduct.Vorteilskommunikation = innerProduct.Vorteilskommunikation.join('\n')
        innerProduct.ICONS = innerProduct.ICONS.join(',')
        productsArray.push(productsObjectCopy[key])
      }
      const csv = json2csvParser.parse(productsArray)
      // var endCSV = 'sep=,' + '\r\n\n' + csv;
      var downloadLink = document.createElement('a')

      var blob = new Blob(['\ufeff', csv])
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, 'produkte.csv')
      } else {
        var url = URL.createObjectURL(blob)
        downloadLink.href = url
        downloadLink.download = 'produkte.csv'

        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
    },
    dropSuccess: function (file, response) {
      this.CSVUploadCallback = response
      if (file != null && file.status !== 'error') {
        console.log('success')
      } else {
        console.log('error')
      }
    },
    dropError: function (error, errorMessage) {
      console.log(error)
      this.CSVUploadError = errorMessage
    },
    dropImageComplete: function (file) {
      ('Image Upload complete')
      if (file != null && file.status !== 'error') {
        var filename = file.upload.filename.substring(0, file.upload.filename.lastIndexOf('.'))
        this.$set(this.clickedProduct, 'imagepfad', filename)
        console.log('success')
      } else {
        console.log('error')
      }
    },
    dropCanceled: function (file) {
      console.log('canceled')
    },
    writeCSVTempToDB: function () {
      DataService.writeProductChangesToDB().then((response) => {
        var returnObject = response.data
        if (returnObject.done === true) {
          this.CSVUploadCallback = {}
          this.loadData()
          this.$refs.uploadCSVModal.hide()
        } else {
          this.CSVUploadError = 'Fehler beim Übertragen der Tabelle'
        }
      }).catch((err) => {
        this.CSVUploadError = 'Server-Fehler beim Speichern'
        console.log(err)
      })
    },
    uploadCSV: function () {
      this.CSVUploadError = ''
      this.CSVUploadCallback = {}
      this.$refs.uploadCSVModal.show()
      this.$nextTick().then(() => {
        if (this.$refs.myVueDropzone != null) {
          this.$refs.myVueDropzone.removeAllFiles();
        }
      })
    },
    hideCSVModal: function () {
      this.$refs.uploadCSVModal.hide()
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'getInstitution']),
    matches () {
      return this.productsObject.filter((element) => {
        var elementNr = element.Artikelnummer + ''
        var elementText = element.Produktbezeichnung
        var elementTyp = element.Inkontinenzprodukt
        var elementAnwendungstyp = element.Anwendungstyp
        var elementAnzahl = element.Anzahl + ''
        var elementGroesse = element.Konfektionsgroesse
        var elementEAN = element.EAN
        var elementPZN = element.PZN + ''
        var elementHilf = element.Hilfsmittelnummer
        var elementBasisPremium = element.PremiumBasis
        var elementSchwere = element.Schweregrad
        var elementPrice = element.UVP + ''
        var returnBool = ((elementText.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementTyp.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          elementAnwendungstyp.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementAnzahl.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          elementNr.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementGroesse.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          elementPrice.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementEAN.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          elementPZN.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementHilf.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          elementBasisPremium.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementSchwere.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          this.search.length === 0) &&
          (elementText.toLowerCase().indexOf(this.searchName.toLowerCase()) >= 0 || this.searchName.length === 0) &&
          (elementAnwendungstyp.toLowerCase().indexOf(this.searchAnwend.toLowerCase()) >= 0 || this.searchAnwend === 'alle') &&
          (elementTyp.toLowerCase().indexOf(this.searchProdtyp.toLowerCase()) >= 0 || this.searchProdtyp === 'alle') &&
          (elementNr.toLowerCase().indexOf(this.searchNr.toLowerCase()) >= 0 || this.searchNr.length === 0))
        return returnBool
      })
    },
    featureIconNames () {
      return [
        "250xwashable",
        "50xwashable",
        "airactiveskinprotection",
        "aloevera",
        "anatomischangepasstepassform",
        "anatomischepassform",
        "auslaufschutz",
        "auslaufschutzsystem",
        "comfortablefit",
        "dermatologicallytested",
        "dermatologischgetestet",
        "discreetunderwearfeeling",
        "elastischeeinsaetze",
        "extrageruchsneutralisierend",
        "gefuehlwieunterwaescheladyuni",
        "gefuehlwieunterwaeschemenuni",
        "geruchsneutral",
        "geruchsneutralisierend",
        "hautfreundlichhautneutral",
        "hautneutralph5.5",
        "klettklebeverschluss",
        "naesseindikator",
        "nutriskinprotectioncomplex",
        "passgenauersitz",
        "rundumschutz",
        "schnelltrocknend",
        "securefixation",
        "sicherefixierung",
        "trockenesangenehmesgefuehl",
        "tumbledryatlowheat",
        "umweltfreundlich",
        "vorgefaltet",
        "vorgefaltetkomprimiert",
        "zusaetzlicherschutz"
      ]
    }
  }
}
</script>
