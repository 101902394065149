Hilfstemplate, um nicht vorhandende Bilder zu erkennen und ein Ersatzbild anzuzeigen.
<template>
  <div>
      <img ref="fullImg">
  </div>
  
</template>

<script>
export default {
  name: 'ProductImage',
  props: {
    path: String
  },
  watch : {
    path : function (value) {
      this.loadImage(value)
    }
  },
  created () {
    this.loadImage(this.path)
  },
  methods: {
    loadImage(path) {
      this.$nextTick(() => {
      var image = this.$refs['fullImg']
      if (image !== undefined) {
        var downloadingImage = new Image()
        downloadingImage.onload = function () {
          image.src = this.src
        }
        downloadingImage.onerror = function (err) {
          console.log(err)
          image.src = '/static/productimgs/loadingdiamond.gif'
        }
        downloadingImage.src = '/static/productimgs/' + path
      }
    })
    }
  }
}
</script>
