// login mock API. 

import axios from 'axios'
// var Promise = require('es6-promise').Promise

const mocks = {
  'auth': { 'POST': { token: 'This-is-a-mocked-token', role: 'admin', userid: 1, institution: 1, status: 'aktiv' ,
  rechte: ['insti_nutzer', 'bera_durch', 'versorg_aufr', 'prod_kat', 'kont_einst','kassen_einst', 'preis_einst', 'berechn_einst', 'pdf_vers', 'pdf_anzeig']}},
  'user/me': { 'GET': { name: 'doggo', title: 'sir' } }
} 

const apiCall = ({url, method, ...args}) => new Promise((resolve, reject) => {
  if (window.location.href === 'http://localhost:8080/login' || window.location.href.indexOf(':8080') > 0) {
    setTimeout(() => {
      try {
        resolve(mocks[url][method || 'GET'])
      } catch (err) {
        reject(new Error(err))
      }
    }, 1000)
  } else {
    var data = new FormData()
    data.append('username', args['data'].username)
    data.append('password', args['data'].password)
    axios.post('/static/api/auth/login.php', data)
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error.response)
      })
  }
})

export default apiCall
