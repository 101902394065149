Untercontainer für die Anzeige einzelner Produkte im Versorgungsrechner. 
Bekommt das Produktobjekt und eine Liste von allen Produkten vom SingleCartTab übergeben.
Über den Eventbus kommuniziert es zb das Löschen eines Produktes an die Vater-Komponente.
<template>
  <div>
    <div class="imageContainer">
      <ProductImage :path="products[product.parentProductId].imagepfad + '.jpg'" />
    </div>
    <div class="contentContainer">
      <div class="product-info">
        <h4>
          <span class="productType">{{products[product.parentProductId]["Anwendungstyp"]}}</span>
          -
          <span class="productType">{{products[product.parentProductId]["Schweregrad"]}}</span>
          <span :class="products[product.parentProductId]['PremiumBasis']">{{products[product.parentProductId]["PremiumBasis"]}}</span>
        </h4>
        <h2>{{products[product.parentProductId].Produktbezeichnung}}</h2>
        <span style="color: red" v-if="product.needsToSelectSize === true">Achtung: Bitte überprüfen Sie die vorausgewählte Grösse.</span>
      </div>
      <div v-if="hasFixPant(product)">
          <h5 @click="$parent.addProductFromCatalog(true)">In Kombination mit Fixierhose</h5>
      </div>
      <div>
        <table>
          <tr>
            <td>
              Zeitraum<br />
              <button style="position: static" v-bind:class="timeButtonIsActive(product, timeKey)" v-for="(time,timeKey) in product.times"  @click="$parent.selectProductTime(product.parentProductId, timeKey, tabIndex, arrayIndex)" :key="timeKey">
                  {{product.times[timeKey]}}
              </button>
              <br />
              Bitte auswählen
            </td>
            <td>
              Konfektionsgröße<br />
              <button style="position: static" v-bind:class="buttonIsActive(product, sizeKey)" v-for="(size,sizeKey) in products[product.parentProductId].sizes"  @click="$parent.selectProductSize(product, sizeKey, tabIndex, arrayIndex)" :key="sizeKey">
                  {{sizeKey}}
              </button>
              <br />
              <span v-if="products[product.parentProductId].sizes != null && Object.keys(products[product.parentProductId].sizes)[0] != 'onesize'">Bitte auswählen</span>
              <span v-else> <br /> </span>
            </td>
            <td v-if="!product.isFixPant">
              Stück<br />
              <select v-model="product.dailyNeed" @change="$parent.saveCartProducts(product, 'need', tabIndex)">
                <option v-bind:value="i" v-for="i in 10" :key="i">
                  {{i}}
                </option>
              </select> x 30 Tage<br />
              <span>{{product.dailyNeed * 30}}</span> Stück/ Monat
            </td>
            <td>
              Packung<br />
              <select v-model="product.monthlyPackages" @change="$parent.saveCartProducts(product, 'packages', tabIndex)">
                <option v-bind:value="i" v-for="i in 30" :key="i">
                  {{i}}
                </option>
              </select> x {{$parent.formatProductPieces(product.pieces)}} Stück<br />
              <span>{{$parent.formatProductPieces(product.pieces) * product.monthlyPackages}}</span> Stück / Monat
            </td>
          </tr>
        </table>
        <button @click="deleteFromCart(product)" class="deleteProduct">Löschen</button>
        <span class="productTotalPrice">{{formatFloatToString(product.totalPrice)}} €</span>
      </div>
    </div>
  </div>
</template>

<script>
import {extraPriceForCartObject, formatFloatToStringUtil} from '@/utils/util.js'
import {EventBus} from '@/services/EventBus.js'
import ProductImage from '@/components/ProductImage.vue'

export default {
  name: 'CartProduct',
  props: {
    product: Object,
    products: Object,
    priceSettingObject: Object,
    tabIndex: Number,
    arrayIndex: Number,
    baseOrPremiumTab: Number
  },
  components: {
    ProductImage
  },
  data: function () {
    return {
      eventBusObject: EventBus
    }
  },
  created: function () {
    this.product.monthlyPackages = this.product.monthlyPackages != null && this.product.monthlyPackages !== 0 ? this.product.monthlyPackages :  Math.ceil(30 * this.product.dailyNeed / this.formatProductPieces(this.product.pieces))
    this.product.totalPrice = this.product.monthlyPackages * this.product.price
    this.$parent.saveCartProducts(this.product, 'packages', this.tabIndex)
  },
  computed: {
    // return extraPrice from Sanitaetshaus if a price exists for this product and is a float, otherwise return normalprice
    extraPrice: function () {
      return extraPriceForCartObject(this.product, this.products, this.priceSettingObject)
    }
  },
  methods: {
    deleteFromCart: function (product) {
      this.eventBusObject.$emit('deleteProduct' + this.baseOrPremiumTab + '-' + this.tabIndex, {'product': product, 'tabIndex': this.tabIndex, 'arrayIndex': this.arrayIndex, 'baseOrPremiumTab': this.baseOrPremiumTab})
    },
    buttonIsActive: function (product, size) {
      if (product.selectedSize === size) {
        return 'active'
      }
    },
    timeButtonIsActive: function (product, time) {
      if (product.selectedTime === time) {
        return 'active'
      }
    },
    fixPantQualityButtonIsActive: function (fixPant, quality) {
      if (fixPant.selectedQuality === quality) {
        return 'active'
      }
    },
    fixPantSizeButtonIsActive: function (fixPant, size) {
      if (fixPant.selectedSize === size) {
        return 'active'
      }
    },
    hasFixPant: function (product) {
      return (product.fixPant != null && Object.keys(product.fixPant).length > 0)
    },
    hasImage: function (id) {
      var imagepfad = this.products[id].imagepfad
      return !imagepfad.includes('fehlt')
    },
    formatFloatToString (x) {
      return formatFloatToStringUtil(x)
    },
    formatProductPieces (pieces) {
      if (typeof pieces === 'string') {
        var newPieces = 0
        var endOfNumber = pieces.indexOf('Stück') - 1
        newPieces = parseFloat(pieces.slice(0, endOfNumber))

        return newPieces
      } else {
        return pieces
      }
    }
  }
}

</script>
