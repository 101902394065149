Zeigt die kurze Zusammenfassung der Anamnese im Header an. 
<template>
  <div id="summary">
    <div>
      <table>
        <tr>
          <th>Inkontinenzart</th>
          <th>Einflussfaktoren</th>
          <th>Mobilität</th>
          <th>Bedürfnisse</th>
        </tr>
        <tr>
          <td><strong>Typ*:</strong><br />{{summaryObject['inkontinenz']}}</td>
          <td><strong>Medikamente:</strong><br />{{renderMultiSelectArray('medikamente')}}</td>
          <td><strong>Beweglichkeit:</strong><br />{{summaryObject['mobil']}}</td>
          <td><strong>Flüssigkeit*:</strong><br />{{summaryObject['trink']}}</td>
        </tr>
        <tr>
          <td><strong>Verlust*:</strong><br />{{summaryObject['verlust']}}</td>
          <td><strong>Hilfsmittel:</strong><br />{{renderMultiSelectArray('hilfsmittel')}}</td>
          <td><strong>Toilettengang*:</strong><br />{{summaryObject['toilette']}}</td>
          <td><strong>Wechsel:</strong><br />{{summaryObject['wechseln']}}</td>
        </tr>
        <tr>
          <td><strong>Frequenz:</strong><br />{{summaryObject['wann']}}</td>
          <td><strong>weitere Einflussfaktoren:</strong><br />{{renderMultiSelectArray('weitereEinflussfaktoren')}}</td>
          <td><strong>Pflegegrad:</strong><br />{{summaryObject['grad']}}</td>
          <td><strong>Hautprobleme:</strong><br />{{summaryObject['haut']}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td><strong>Hilfe*:</strong><br />{{summaryObject['versorgung']}}</td>
          <td><strong>Konfektionsgröße:</strong><br />{{summaryObject['groesse']}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td><strong>Wichtige Kritierien:</strong><br />{{renderMultiSelectArray('important')}}</td>
        </tr>
        </table>
      </div>
      * Dies sind die Angaben, die zur Produktempfehlung geführt haben zzgl. der Angabe des Geschlechtes.
  </div>
</template>

<script>

export default {
  name: 'Summary',
  props: {
    summaryObject: {Object}
  },
  methods: {
    // Hilfsmethode zur besseren Darstellung eines Arrays:
    renderMultiSelectArray: function (questionId) {
      if( this.summaryObject[questionId] !== undefined ) {
        var importantString = ''
        if (typeof this.summaryObject[questionId] === 'string') {
          importantString = this.summaryObject[questionId]
        } else {
          if(this.summaryObject[questionId].length === 0) {
            importantString = "keine Angaben"
          } else {
            for (var i = 0; i < this.summaryObject[questionId].length; i++) {
              if (i < this.summaryObject[questionId].length - 1) {
                importantString = importantString + this.summaryObject[questionId][i] + ', '
              } else {
                importantString = importantString + this.summaryObject[questionId][i]
              }
            }
          }
        }
        return importantString
      } else {
        return ""
      }
    }
  }
}
</script>
