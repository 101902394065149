Container für das Userlist Template. 
<template>
  <div id="usermanagement" class="settings">
    <Userslist v-if="!isAdmin" :hartmann="false" :isInstitution="true"/>
    <div v-else>
      Bitte verwalten Sie die Hartmann Benutzer über das DMS.
    </div>
  </div>
</template>

<script>
import Userslist from '../cms/Userslist.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Usermanagement',
  components: {
    Userslist
  },
  props: {
    currentIndex: Number
  },
  data: function () {
    return {
    }
  },
  created: function () {
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'isAdmin'])
  },
  methods: {
  },
  beforeDestroy: function () {
  }
}

</script>
