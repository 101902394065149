Container für die Ansicht der Nutzer. Diese werden über die "Userslist" Komponente angezeigt.
Hier wird grundsätzlich zwischen internen und externen Nutzern unterschieden 
<template>
  <div id="cms">
      <b-tabs nav-wrapper-class="usersNavigation" v-model="tabIndex">
        <b-tab @click="tabChange" title="Benutzer HARTMANN">
          <Userslist :hartmann="true" :isInstitution="false" />
        </b-tab>
        <b-tab @click="tabChange" title="Benutzer Institutionen">
          <Userslist :hartmann="false" :isInstitution="false" />
        </b-tab>
      </b-tabs>
  </div>
</template>

<script>
import Userslist from './Userslist.vue'

export default {
  name: 'Users',
  components: {
    Userslist
  },
  created () {
  },
  data: function () {
    return {
      tabIndex: 0
    }
  },
  methods: {
    tabChange: function () {
    },
    openHelp () {
      window.open("/static/INCOAID_DMS_Handbuch.pdf", "_blank");
    }
  }
}
</script>
