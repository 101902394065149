<template>
  <div id="cookiepolicy">
    <div class='breadcrumb'>
      <h2>Cookie-Policy</h2>
      <div class='breadcrumbArrow'>
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>
    <div class="content">
      <article>
        <section class="content-running-text">
          <script type="application/javascript" 
                  id="CookieDeclaration" 
                  src="https://consent.cookiebot.com/0eafaea8-3508-4974-bb4b-79d8d8d9ec2e/cd.js" 
                  async 
                  data-culture="de">
          </script>
        </section>
      </article>
    </div>
  </div>
</template>

<style>
.content-running-text {
  padding: 20px 20px 0 20px;
}

</style>

<script>
export default {
  name: 'CookiePolicy'
}
</script>
