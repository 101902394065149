Versorgungsrechner
Dient als Container für die einzelnen Tabs (cart/SingeCartTab.vue). 
Lädt alle Preise und Produkte aus der Datenbank. Der Inhalt vom Versorgungsrechner stammt aus dem Localstorage und wird mit dem
cartArrayStorage Object geladen. Je nach Krankenkasse oder Einstellung werden die verschiedenen Tabs angezeigt oder ausgeblendet.
<template>
  <div id="cart" :class="isCalulationOn()">
    <div class='breadcrumb'>
      <div v-if="recommProducts.length === 0" style="margin: 20px;">
        <p>Führen Sie eine Anamnese durch, um die ideale Versorgung des Patienten sicherzustellen</p>
        <router-link to="/wizard/0"><b-button style="color: white; border: 1px solid #fff;
          border-radius: 0px;">Anamnese starten</b-button></router-link>
      </div>
      <!-- ><h2 v-if="currentWizardStep === 0">{{questionsComplete[currentWizardStep].segmentName}}</h2> -->
      <h2>Versorgung berechnen (nach Methode {{calcSetting}})</h2>
      <b-button  @click="showHintCollapse = !showHintCollapse" class="infoButton">{{hintButtonText}}</b-button>
      <b-collapse v-model="showHintCollapse" id="hintCollapse">
        <div v-if="hintArray.length>0" id="hint-container" class="cartHints">
        <div class="hints">
          <p>Bitte beachten Sie bei der Produktempfehlung, dass </p>
          <ul>
            <li v-for="(hint,key) in hintArray" :key="key" >{{hint}}</li>
          </ul>
        </div>
        <div class="wechselInterval">
          <p>Hinweise Wechselintervall</p>
          <p>
            <span>leichte & mittlere Inkontinenz</span>
            <strong>Wechsel 2 mal täglich</strong>
          </p>
          <p>
            <span>schwere & schwerste Inkontinenz</span>
            <strong>Wechsel 3 mal täglich</strong>
          </p>
        </div>
      </div>
      </b-collapse>
    </div>

    <div id="versorgungsSwitch">
      <span :class="isactiveArea(0)" @click="versorgungsSwitchClicked(0)">Medizinisch notw. Versorgung ({{baseTabCount}})</span>
      <span :class="isactiveArea(1)" @click="versorgungsSwitchClicked(1)">Wunschversorgung ({{premiumTabCount}})</span>
      <span v-if="showFreiverkauf" :class="isactiveArea(2)" @click="versorgungsSwitchClicked(2)">Wunschversorgung / Freiverkauf ({{freiverkaufCount}})</span>
    </div>

    <div class='breadcrumbArrow'>
      <div :style="choosenVersorgunsSwitch()" class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="four"></div>
    </div>

    <div class="cartProductsContainer">
      <b-modal ref="alertModal" title="Eine Berechnung kann nicht sinnvoll duchgeführt werden." hide-footer >
        <div class="d-block text-center">
          <div>
           Es fehlen noch entscheidende Angaben zur Person und Krankenkasse des Betroffenen.
          </div>
        </div>
        <router-link to="/wizard/0"><b-btn class="mt-3" block @click="hideModal">Zur Beratung</b-btn></router-link>
      </b-modal>
      <div style="overflow: auto; display: flex; position: relative">
        <div v-if="baseOrPremiumTab!=2"  style="position: absolute; top: 10px; right: 30px;">
          <span class="switcherText">Berechnung anzeigen:</span><switches type-bold="true" v-model="calculation"></switches>
        </div>
      <div v-if="showBaseTab() === true" :class="'bigTabContainer ' + isactiveArea(0)">
        <b-tabs v-model="tabIndexBasis" @changed="tabsChanged(0)">
          <b-tab v-for="(productsTab, key) in cartArray[0]" :title="key.toString()" :key="key">
            <template class="cartTabTitle" v-slot:title>
              <div @click="tabClicked(key, 0)">Vorschlag {{key + 1}}<br />
                <span :class="matchingColor(key, 0)">{{generateTabTitle(key, 0)}}</span>
              </div>
              <b-button v-if="key > 0" class="close" @click="closeTab(key, 0)"></b-button>
             
            </template>
            <SingleCartTab :index="key" :products="products" :kVPauschale="getKVPauschale()" :baseOrPremiumTab="0" :extraAmount="extraAmount" :calcSetting="calcSetting" :priceSettingObject="priceSettingObject" />
          </b-tab>
          <template v-slot:tabs-end>
            <b-nav-item class="newTab">
              <b-button @click="addNewBaseTab()" class="newTabButton"></b-button>
            </b-nav-item>
          </template>
        </b-tabs>
      </div>
      <div :class="'bigTabContainer ' + isactiveArea(1)">
        <b-tabs v-model="tabIndexPremium" @changed="tabsChanged(1)">
          <b-tab v-for="(productsTab, key) in cartArray[1]" :title="key.toString()" :key="key">
            <template class="cartTabTitle" v-slot:title>
              <div @click="tabClicked(key, 1)">Vorschlag {{key + 1}}<br />
                <span :class="matchingColor(key, 1)"><strong>{{generateTabTitle(key, 1)}}</strong></span>
              </div>
              <b-button v-if="key > 0" class="close" @click="closeTab(key, 1)"></b-button>
            </template>
            <SingleCartTab :index="key" :products="products" :kVPauschale="getKVPauschale()" :baseOrPremiumTab="1" :extraAmount="extraAmount" :calcSetting="calcSetting" :priceSettingObject="priceSettingObject" />
          </b-tab>
          <template v-slot:tabs-end>
            <b-nav-item class="newTab" href="#">
              <b-button @click="addNewPremiumTab()" class="newTabButton"></b-button>
            </b-nav-item>
          </template>
        </b-tabs>
      </div>
      <div v-if="showFreiverkauf" :class="'bigTabContainer ' + isactiveArea(2)">
        <b-tabs v-model="tabIndexFrei" @changed="tabsChanged(2)">
          <b-tab v-for="(productsTab, key) in cartArray[2]" :title="key.toString()" :key="key">
            <template class="cartTabTitle" v-slot:title>
              <div @click="tabClicked(key, 2)">Vorschlag {{key + 1}}<br />
                <span :class="matchingColor(key, 2)"><strong>{{generateTabTitle(key, 2)}}</strong></span>
              </div>
              <b-button v-if="key > 0" class="close" @click="closeTab(key, 2)"></b-button>
            </template>
            <SingleCartTab :index="key" :products="products" :kVPauschale="getKVPauschale()" :baseOrPremiumTab="2" :extraAmount="extraAmount" :calcSetting="calcSetting" :priceSettingObject="priceSettingObject" />
          </b-tab>
          <template v-slot:tabs-end>
            <b-nav-item class="newTab" href="#">
              <b-button @click="addNewFreiTab()" class="newTabButton"></b-button>
            </b-nav-item>
          </template>
        </b-tabs>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
import SingleCartTab from '@/components/cart/SingleCartTab.vue'
import {generateKVPauschale, generateExtraAmount, formatFloatToStringUtil, showBaseTabUtil} from '../utils/util.js'
import {cartArrayStorage, answerStorage, cartStatusStorage, cartProductStorage} from '../utils/localstorage.js'
import {EventBus} from '@/services/EventBus.js'
import Switches from 'vue-switches'

export default {
  name: 'Cart',
  components: {
    SingleCartTab, Switches
  },
  computed: {
    ...mapGetters(['getProfile', 'getUserId', 'isAuthenticated', 'isProfileLoaded', 'getInstitution']),
    showFreiverkauf: function () {
      return this.calcSetting == 'A' || this.calcSetting =='C'
    },
    hintArray: function () {
      var hintArrayToReturn = []
      // var answers = answerStorage.fetch()
      
      for (var key in this.answers) {
        var singleHintObject = this.hintsObject[key]
        
        if (typeof this.answers[key] === 'object' || typeof this.answers[key] === 'array') {
          
          for (var i = 0; i < this.answers[key].length; i++) { 
            if (singleHintObject != null) {
              if (singleHintObject[this.answers[key][i]] != null && key !== 'important') {
                hintArrayToReturn.push(singleHintObject[this.answers[key][i]])
                
              }
            }
            if (this.answers[key][i] === 'M2' || this.answers[key][i] === 'M3' || this.answers[key][i] === 'M4') { break }
          }
        } else {
          if (singleHintObject != null) {
            if (singleHintObject[this.answers[key]] != null && key !== 'important') {
              hintArrayToReturn.push(singleHintObject[this.answers[key]])
              // extra medikamente in inputfield, check if hint is already set
            } else if (singleHintObject['Input'] != null && this.answers['weitereMedikamente'].length > 0 && (this.answers['medikamente'] === null || this.answers['medikamente'] === 'M1')) {
              hintArrayToReturn.push(singleHintObject['Input'])
            }
          }
        } 
      }
      return hintArrayToReturn
    },
    hintButtonText: function () {
      if (this.showHintCollapse) {
        return 'Hinweise ausblenden'
      } else {
        return 'Hinweise einblenden'
      }
    }
  },
  created: function () {
    this.loadKvSetting()
    const self = this
    DataService.fetchPrices(this.getInstitution).then((response) => {
      self.priceSettingObject = response.data
      DataService.fetchCombinedProducts().then((response) => {
        self.products = response.data
        // cartArrayStorage.save([[{}], [{}]])
        self.cartArray = cartArrayStorage.fetch()
       
        self.productsLoaded = true
        // go to tab if stored index > 0
        
        //console.log(JSON.stringify(self.cartStatusObject))
        if (self.cartStatusObject.tabIndex > 0 || self.cartStatusObject.baseOrPremiumTab > 0) {
          self.$nextTick().then(() => {
           
            self.baseOrPremiumTab = self.cartStatusObject.baseOrPremiumTab
            if (self.baseOrPremiumTab === 0) {
              self.$nextTick().then(() => {
                self.tabIndexBasis = self.cartStatusObject.tabIndex
              })
            } else if (self.baseOrPremiumTab === 1) { 
              self.$nextTick().then(() => {
                self.tabIndexPremium = self.cartStatusObject.tabIndex
              })
            } else {
              self.$nextTick().then(() => {
                self.tabIndexFrei = self.cartStatusObject.tabIndex
              })
            }
            // remove stored index
            self.cartStatusObject = {}
            cartStatusStorage.save(self.cartStatusObject)
          })
        }
      // this.productCart = cartProductStorage.fetch()
      // this.$parent.$emit('changeCartCount', this.catalogCart.length + this.productCart.length)
      }).catch((err) => {
        // handle error
        if (err.response.status === 401) {
          this.eventBusObject.$emit('tokenInvalid')
        } 
        console.log(err)
      })
    }).catch((err) => {
      console.log(err)
    })
 
    
    DataService.fetchTextRecommendations().then((response) => {
      this.hintsObject = response.data
    }).catch((err) => {
      // handle error
      console.log(err)
    })
    DataService.fetchKrankenkassen().then((response) => {
      this.kvs = response.data
    }).catch((err) => {
      // handle error
      console.log(err)
    })
    DataService.fetchWizard().then((response) => {
      this.wizard = response.data
    }).catch((err) => {
      // handle error
      console.log(err)
    })
    // TODO
    this.eventBusObject.$on('updateTabTitle', ($event) => {
      this.updateTitles($event.baseOrPremium, $event.index, $event.price, $event.carePrice, $event.color)
    })
    this.eventBusObject.$on('updateTabCount', ($event) => {
      this.setTabCount()
    })
    this.eventBusObject.$on('gotToMnvTab', ($event) => {
      this.baseOrPremiumTab = 0
    })
    if (this.answers['krankenkasse'] === undefined) {
      this.$nextTick().then(() => {
        this.showModal()
      })
    }
    this.extraAmount = this.getExtraAmount();
    
    // switch to premium tab if no KV
    if (this.getKVPauschale() === 0) {
      this.baseOrPremiumTab = 1
    }
    this.setTabCount()
  },
  data: function () {
    var answer = answerStorage.fetch()
    return {
      cartArray: [[], [], []],
      productCart: {},
      price: 0,
      tabIndexBasis: 0,
      tabIndexPremium: 0,
      tabIndexFrei: 0,
      baseOrPremiumTab: 0,
      answers: answer,
      products: {},
      wizard: {},
      hintsObject: {},
      kvs: {},
      priceSettingObject: {},
      eventBusObject: EventBus,
      cartStatusObject: cartStatusStorage.fetch(),
      tabPrices: [[''], [''], ['']],
      tabColors: [[''], [''], ['']],
      colorTabHeader: '',
      recommProducts: cartProductStorage.fetch(),
      productsLoaded: false,
      extraAmount: 0,
      calcSetting: 'A',
      showHintCollapse: true,
      calculation: false,
      baseTabCount: 0,
      premiumTabCount: 0,
      freiverkaufCount: 0,
      reloadVariable: 0,
      priceSettingObject: {},
      addedNewTab: false
    }
  },
  props: {
  },
  methods: {
    loadKvSetting: function () {
        var self = this
        DataService.getUserData(this.getUserId).then((response) => {
          self.calcSetting = response.data['Berechnungsmethode']
        }).catch((err) => {
          // handle error
          console.log(err)
        })
      
    },
  

    addNewBaseTab: function () {
      this.addedNewTab = true
      var careType = 0
  
      this.baseOrPremiumTab = 0
      var cartArrayBase = this.cartArray[0]
      if (cartArrayBase == null) {
        cartArrayBase = []
      }
      cartArrayBase.push({products: [], careType: careType, totalPrice: 0})
      this.cartArray[0] = cartArrayBase
      cartArrayStorage.save(this.cartArray)
     
    },
    // called when tabs are added or removed
    tabsChanged: function (basePremium) {
      if (this.addedNewTab == true) {
        if (basePremium === 0) {
          requestAnimationFrame(() => {
            this.tabIndexBasis =  this.cartArray[basePremium].length - 1
          
          })
        } else if (basePremium === 1) {
          requestAnimationFrame(() => {
            this.tabIndexPremium =  this.cartArray[basePremium].length - 1
            
          })
        } else {
          requestAnimationFrame(() => {
            this.tabIndexFrei =  this.cartArray[basePremium].length - 1
          })
        }
        this.addedNewTab = false
      }
    },
    addNewPremiumTab: function () {
      this.addedNewTab = true
      this.baseOrPremiumTab = 1
      var careType = 1
  
      var cartArrayPremium = this.cartArray[1]
      if (cartArrayPremium == null) {
        cartArrayPremium = []
      }
      cartArrayPremium.push({products: [], careType: careType, totalPrice: 0})
      this.cartArray[1] = cartArrayPremium
      cartArrayStorage.save(this.cartArray)
     
    },
    addNewFreiTab: function () {
      this.addedNewTab = true
      this.baseOrPremiumTab = 2
      var careType = 2

      var cartArrayFrei= this.cartArray[2]
      if (cartArrayFrei == null) {
        cartArrayFrei = []
      }
      cartArrayFrei.push({products: [], careType: careType, totalPrice: 0})
      this.cartArray[2] = cartArrayFrei
      cartArrayStorage.save(this.cartArray)
    },
    hasImage: function (id) {
      var imagepfad = this.products[id].imagepfad
      return !imagepfad.includes('fehlt')
    },
    generateTabTitle: function (key, basePremium) {
      return formatFloatToStringUtil(this.tabPrices[basePremium][key]) + ' € '
    },
    showBaseTab: function () {
      return showBaseTabUtil(this.getKVPauschale(), this.answers)
    },
    updateTitles: function (basePremium, index, price, carePrice, color) {
      this.$set(this.tabPrices[basePremium], index, carePrice)
      this.$set(this.tabColors[basePremium], index, color)
      this.$nextTick().then(() => {
        this.$forceUpdate()
      })
    },
    getKVPauschale: function () {
      return generateKVPauschale(this.answers)
    },
    getExtraAmount: function () {
      return generateExtraAmount(this.answers, this.getKVPauschale())
    },
    closeTab: function (key, basePremium) {
      this.baseOrPremiumTab = basePremium
      if (this.cartArray[basePremium].length > key) {
        this.cartArray = cartArrayStorage.fetch()
        var innerArrayBase = this.cartArray[0]
        var innerArrayPremium = this.cartArray[1]
        var innerArrayFrei= this.cartArray[2]
        if (basePremium === 0) {
          innerArrayBase.splice(key, 1)
        } else if (basePremium == 1){
          innerArrayPremium.splice(key, 1)
        } else {
          innerArrayFrei.splice(key, 1)
        }
        this.cartArray.pop()
        this.cartArray.pop()
        this.cartArray.pop()
        this.$nextTick().then(() => {
          this.cartArray.push(innerArrayBase)
          this.cartArray.push(innerArrayPremium)
          this.cartArray.push(innerArrayFrei)
          this.$forceUpdate()
          cartArrayStorage.save(this.cartArray)
          this.setTabCount()
        })
      }
    },
    tabClicked: function (key, basePremium) {
      if (basePremium === 0) {
        this.tabIndexBasis = key
      } else if (basePremium === 1) {
        this.tabIndexPremium = key
      } else {
        this.tabIndexFrei = key
      }
    },
    versorgungsSwitchClicked: function (basePremium) {
      this.baseOrPremiumTab = basePremium
    },
    matchingColor: function (key, basePremium) {
      return this.tabColors[basePremium][key]
    },
    showModal () {
      this.$refs.alertModal.show()
    },
    hideModal () {
      this.$refs.alertModal.hide()
    },
    formatFloatToString (x) {
      return formatFloatToStringUtil(x)
    },
    isactiveArea (area) {
      var classString = ''
      if (area === this.baseOrPremiumTab) {
        classString = 'active'
      } else {
        if (!this.showBaseTab()) {
          classString = classString + 'hidden'
        }
      }
      return classString
    },
    choosenVersorgunsSwitch () {
      if (this.baseOrPremiumTab === 0) {
        return 'width: 100px'
      } else if (this.baseOrPremiumTab === 1){
        if (this.getKVPauschale() === 0) {
          return 'width: 60px'
        } else {
          return 'width: 340px'
        }
      } else {
        return 'width: 600px'
      }
    },
    isCalulationOn () {
      if (this.calculation === true) {
        return 'calculationIsOn'
      }
    },
    setTabCount: function () {
      var countInnerTabsWithProducts = 0
      var ownCartObject = cartArrayStorage.fetch()
      for (var i in ownCartObject[0]) {
        if (ownCartObject[0][i].products != null && ownCartObject[0][i].products.length > 0) {
          countInnerTabsWithProducts = countInnerTabsWithProducts + 1
        }
      }
      this.baseTabCount = countInnerTabsWithProducts

      countInnerTabsWithProducts = 0
      for (var j in ownCartObject[1]) {
        if (ownCartObject[1][j].products != null && ownCartObject[1][j].products.length > 0) {
          countInnerTabsWithProducts = countInnerTabsWithProducts + 1
        }
      }
      this.premiumTabCount = countInnerTabsWithProducts

      countInnerTabsWithProducts = 0
      for (var j in ownCartObject[2]) {
        if (ownCartObject[2][j].products != null && ownCartObject[2][j].products.length > 0) {
          countInnerTabsWithProducts = countInnerTabsWithProducts + 1
        }
      }
      this.freiverkaufCount = countInnerTabsWithProducts
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$emit('closeSummary')
    this.eventBusObject.$off('updateTitle')
    this.eventBusObject.$off('updateTabCount')
    this.eventBusObject.$off('gotToMnvTab')
  }
}
</script>
