import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import RegisterStepInstitution from './components/auth/RegisterStepInstitution.vue'
import RegisterStepUser from './components/auth/RegisterStepUser.vue'
import RegisterStepPassword from './components/auth/RegisterStepPassword.vue'
import Cart from './components/Cart.vue'
import Settings from './components/settings/SettingsContainer.vue'
import Home from './components/Home.vue'
import Wizard from './components/wizard/WizardContainer.vue'
import Recommendations from './components/Recommendations.vue'
import Products from './components/Products.vue'
import Offer from './components/Offer.vue'
import Impressum from './components/Impressum.vue'
import CookiePolicy from './components/CookiePolicy.vue'
import Selling from './components/Selling.vue'
import Final from './components/Final.vue'
import Feedback from './components/Feedback.vue'
import Login from './components/auth/Login.vue'
import Welcome from './components/auth/Welcome.vue'
import PasswordForgot from './components/auth/PasswordForgot.vue'
import CreatePassword from './components/auth/CreatePassword.vue'
import CMS from './components/cms/CMS.vue'
import myAccount from './components/MyAccount.vue'
import EmailVerify from './components/auth/EmailVerify.vue'
import ResetPassword from './components/auth/ResetPassword.vue'
import RegisterStepSuccess from './components/auth/RegisterStepSuccess.vue'

import store from './store'

import Vuex from 'vuex'
import { AUTH_LOGOUT } from '@/store/actions/auth'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueAnalytics from 'vue-analytics'
import sanitizeHTML from 'sanitize-html'

import { EventBus } from '@/services/EventBus.js'

Vue.prototype.$sanitize = sanitizeHTML

require('./assets/css/main.css')

Vue.use(Router)
Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(BootstrapVue)
Vue.use(Vuex)


window.addEventListener('CookiebotOnAccept', function (e) {
  if (Cookiebot.consent.statistics)
      {
        Vue.$ga.enable() 
        Vue.$ga.page('Appstart')
        Vue.$ga.page('/' + router.currentRoute.path)
        router.afterEach(( to, from ) => {
          Vue.$ga.page('/' + to.path)
        });
      }
      else {
        Vue.$ga.disable()
      }
  }, 
false);


// incoaid: 6LcWgsAZAAAAAKvpWTQyNALstf9TcaEldBJaSeXl
// recaptcha test : 6Lfly-EZAAAAABu5GR60egYw_zbF9bDyw6HsQMMq
// Google reCaptcha V3 Key wird hier global gesetzt:
Vue.prototype.$recaptcha_key = '6LcWgsAZAAAAAKvpWTQyNALstf9TcaEldBJaSeXl'

Vue.use(VueAnalytics, {
  id: 'UA-83914170-57',
  set: [
    { field: 'anonymizeIp', value: true }
  ]
})


// global auto logout function
var timeout
var minutes = 45

// jeder Klick sorgt für eine Logout-Überprüfung
//localStorage.setItem('lastclick', Date.now())

function refresh (firstLoad) {

 
    if (firstLoad == true && Date.now() - localStorage.getItem('lastclick', Date.now()) > minutes * 60 * 1000) {
      localStorage.setItem('lastclick', Date.now())
      if (store.getters.isAuthenticated) {
        store.dispatch(AUTH_LOGOUT).then(() => router.push('/login'))
        EventBus.$emit('autologout')
        EventBus.$emit('loggedOut')
      }
    }
    localStorage.setItem('lastclick', Date.now())
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (store.getters.isAuthenticated) {
        console.log('auto logout')
        store.dispatch(AUTH_LOGOUT).then(() => router.push('/login'))
        EventBus.$emit('autologout')
        EventBus.$emit('loggedOut')
      }
    }, minutes * 60 * 1000)
  }
 



// welcher Pfad mit welchem Rollen-Recht besucht werden darf 
function mapPathToRight (path) {
  if (path == '/products') {
    return 'prod_kat'
  }
  if (path == '/wizard/1' || path == '/wizard/2' || path == '/wizard/3' || path == '/wizard/4' || path == '/recommendations' ) {
    return 'bera_durch'
  }
  if (path == '/settings/0') {
    return 'kont_einst'
  }
  if (path == '/settings/1') {
    return 'insti_nutzer'
  }
  if (path == '/settings/2') {
    return 'kassen_einst'
  }
  if (path == '/settings/3') {
    return 'preis_einst'
  }
  if (path == '/settings/4') {
    return 'berechn_einst'
  }
  
  if (path == '/cart') {
    return 'versorg_aufr'
  }
  return 'go'
}

refresh(true)
document.addEventListener('click', refresh)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

// wir testen die Rollen-Rechte eines Nutzers (getRights), ob darin das Recht für den gewählten Pfad existiert
const ifAuthenticated = (to, from, next) => {
  
  if (store.getters.isAuthenticated) {
    if (!store.getters.isWartend) {
      if (mapPathToRight(to.path) == 'go' || store.getters.getRights.indexOf(mapPathToRight(to.path)) !== -1) {
        next()
      } else {
        next('/')
      }
      return
    } else {
      next('/createPassword')
    }
  }
  next('/welcome')
}

// Administratoren im Zustand "wartend" werden auf "createPassword" umgeleitet
const ifIsAdmin = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.isAdmin) {
      if (!store.getters.isWartend) {
        next()
        return
      } else {
        next('/createPassword')
      }
    }
  }
  next('/welcome')
}

const routes = [
  { path: '/', name: 'home', component: Home, beforeEnter: ifAuthenticated },
  { path: '/register1', name: 'register', component: RegisterStepInstitution, beforeEnter: ifNotAuthenticated },
  { path: '/register2', name: 'registeruser', component: RegisterStepUser, beforeEnter: ifNotAuthenticated },
  { path: '/register3', name: 'registerpass', component: RegisterStepPassword, beforeEnter: ifNotAuthenticated },
  { path: '/cart', name: 'cart', component: Cart, beforeEnter: ifAuthenticated },
  { path: '/settings/:index', name: 'settings', component: Settings, beforeEnter: ifAuthenticated },
  { path: '/myAccount', name: 'myAccount', component: myAccount, beforeEnter: ifAuthenticated },
  { path: '/wizard/:index', name: 'wizard', component: Wizard, beforeEnter: ifAuthenticated },
  { path: '/recommendations', name: 'recommendations', component: Recommendations, beforeEnter: ifAuthenticated },
  { path: '/products', name: 'products', component: Products, beforeEnter: ifAuthenticated },
  { path: '/offer', name: 'offer', component: Offer, beforeEnter: ifAuthenticated },
  { path: '/impressum', name: 'impressum', component: Impressum },
  { path: '/cookiepolicy', name: 'cookiepolicy', component: CookiePolicy },
  { path: '/selling', name: 'selling', component: Selling, beforeEnter: ifAuthenticated },
  { path: '/final', name: 'final', component: Final, beforeEnter: ifAuthenticated },
  { path: '/feedback', name: 'feedback', component: Feedback },
  { path: '/login', name: 'login', component: Login, beforeEnter: ifNotAuthenticated },
  { path: '/welcome', name: 'welcome', component: Welcome, beforeEnter: ifNotAuthenticated },
  { path: '/createPassword', name: 'createPassword', component: CreatePassword },
  { path: '/passwd', name: 'passwd', component: PasswordForgot },
  { path: '/verify', name: 'verify', component: EmailVerify },
  { path: '/resetpwd', name: 'resetpwd', component: ResetPassword },
  { path: '/success', name: 'success', component: RegisterStepSuccess },
  { path: '/cms', name: 'cms-base', component: CMS, beforeEnter: ifIsAdmin },
  { path: '/cms/:index', name: 'cms', component: CMS, beforeEnter: ifIsAdmin },

  { path: '*', name: 'Default', component: Home, beforeEnter: ifAuthenticated }
]

/*
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
*/

const router = new Router({
  mode: 'history',
  routes,
  //duplicateNavigationPolicy: 'ignore'
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
