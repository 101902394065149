Verwaltet die Liste der Produkte. 
Editieren oder Neuanlegen öffnet ein Modal mit den Daten aus dem "clickedRole"-Objekt
<template>
  <div id="cms-roles">
    <div class="headerLine">
      <b-button @click="create()" style="float:left">Rolle hinzufügen</b-button>
    </div>
    <div>
      <div style="clear:both; margin-top: 10px">
        <h5>alle Rollen</h5>
      </div>
      <table id="roleTable" >
        <tr>
          <th><input class="searchField" type="text" v-model="search" placeholder="Rollenname"></th>
          <th>Beschreibung</th>
          <th>Default*</th>
        </tr>
        <template v-for="(role, key) in matches">
          <tr :key="key" class="dataRow" @click="edit(key)">
            <td>{{role.Name}}</td>
            <td>{{role.Beschreibung}}</td>
            <td><input type="radio" :name="role.Name" :id="role.Name" :value="role.Name" disabled v-model="checkedRole"></td>
          </tr>
        </template>
      </table>
      
      <div style="margin-top: 20px; font-size: 12px;">
        * Die ausgewählte Rolle wird standartmäßig beim Anlegen neuer Benutzer (auf Institutionsebene) verwendet.
      </div>
      
      <b-modal modal-class="editRoleModal editModal" ref="editRoleModal" size="lg" hide-footer hide-header title="">
        <h4>Rollename:</h4>
        <input class="mb-4" type="text" v-model="clickedRole.Name">
        <h4>Rollenbeschreibung:</h4>
        <b-form-textarea class="mb-4"  v-model="clickedRole.Beschreibung" :rows="3" :max-rows="6">
        </b-form-textarea> 
        <h4>Rechte:</h4>
        <table class="mb-4" id="rightTable">
          <tr>
            <th>Name</th>
            <th>Beschreibung</th>
            <th></th>
          </tr>
          <template v-for="(right, key) in rightsArray">
            <tr :key="key" >
              <td>{{right.Name}}</td>
              <td>{{right.Beschreibung}}</td>
              <td><input type="checkbox" :id="right.id" :value="right.id" v-model="clickedRole.Rechte"></td>
            </tr>
          </template>
        </table>
        <b-button style="float: left" v-if="clickedRole.istStandard === 0" @click="clickedRole.istStandard=1">Als Standard setzen</b-button>
        <b-button class="highlight" style="float: left" v-if="clickedRole.istStandard === 1">Ist Standard</b-button>
        <b-button v-if="clickedRole.id !== 2" style="float: left" class="ml-2" @click="showRemoveModal()">Rolle löschen</b-button>
        <b-button @click="save()">Speichern</b-button>
        <div class="closeButton" @click="back()" ></div>
      </b-modal> 
      <b-modal modal-class="cms_notification_modal" ref="roleEditedModal" hide-footer hide-header title="">
        <h5>Die Rolle wurde erfolgreich angepasst.</h5>
        <b-button @click="$refs.roleEditedModal.hide()" class="modalButton">Ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="roleToDeleteModal" hide-footer hide-header title="">
        <h5>Soll diese Rolle wirklich gelöscht werden?</h5>
        <b-button class="ml-2 modalButton" @click="remove()">Ja</b-button>
        <b-button @click="$refs.roleToDeleteModal.hide()" class="rightModalButton">Nein</b-button>
        
      </b-modal>   
      <b-modal modal-class="cms_notification_modal" ref="roleToNotDeleteModal" hide-footer hide-header title="">
        <h5>Diese Rolle kann nicht gelöscht werden, weil es noch {{userCount}} Benutzer mit dieser Rolle gibt.</h5>
        <p>Suchen Sie in der Benutzerverwaltung nach der Rolle.</p>
        <b-button @click="$refs.roleToNotDeleteModal.hide()" class="modalButton">Schließen</b-button>
      </b-modal>   
    </div>
  </div>
</template>
<script>
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
export default {
  name: 'Roles',
  data: function () {
    return {
      rolesArray: [],
      rightsArray: [],
      checkedRole: "",
      search: '',
      clickedRole: {},
      userCount: 0
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      DataService.fetchRoles().then((response) => {
        this.rolesArray = response.data
        for (var i in this.rolesArray) {
          if (this.rolesArray[i].istStandard === 1) {
            this.checkedRole = this.rolesArray[i].Name
          }
        }
      }).catch((err) => {
        // handle error
        console.log(err)
      })
      DataService.fetchRights().then((response) => {
        this.rightsArray = response.data
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    create: function () {
      this.clickedRole = {Rechte: [], istStandard: 0}
      this.$refs.editRoleModal.show()
    },
    edit: function (key) {
      this.clickedRole = this.rolesArray[key]
      this.$refs.editRoleModal.show()
    },
    back: function () {
      this.$refs.editRoleModal.hide()
    },
    save: function () {
      DataService.setRole(this.clickedRole).then((response) => {
        this.clickedRole = {}
        this.$refs.editRoleModal.hide()
        this.$refs.roleEditedModal.show()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    showRemoveModal: function() {
      DataService.getRolesCount(this.clickedRole.id).then((response) => {
        if (response.data.Anzahl > 0) {
          this.userCount = response.data.Anzahl
          this.$refs.roleToNotDeleteModal.show()
        } else {
          this.$refs.roleToDeleteModal.show()
        }
      }).catch((err) => {
        // handle error
        console.log(err)
      })
      
    },
    remove: function() {
      DataService.deleteRole(this.clickedRole.id).then((response) => {
        this.clickedRole = {}
        this.$refs.roleToDeleteModal.hide()
        this.$refs.editRoleModal.hide()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'getInstitution']),
    matches () {
      return this.rolesArray.filter((element) => {
        var elementText = element.Name
        return (elementText.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || this.search.length === 0)
      })
    }
  },
  mounted() {
     this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
       this.loadData()
     })
   }
}
</script>
