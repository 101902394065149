export function generateRecommKeyUtil (answers) {
  var key = ''
  var missingKeys = []
  var genderKey = ''
  if (answers['gender'] !== undefined) {
    if (answers['gender'] === 'K') {
      genderKey = 'F'
    } else {
      genderKey = answers['gender']
    }
  } else {
    missingKeys.push('gender')
  }
  var inkontiKey = ''
  if (answers['inkontinenz'] !== undefined) {
    inkontiKey = answers['inkontinenz']
  } else {
    missingKeys.push('inkontinenz')
  }
  var toiKey = ''
  if (answers['toilette'] !== undefined) {
    toiKey = answers['toilette']
  } else {
    missingKeys.push('toilette')
  }
  var hilfKey = ''
  if (answers['versorgung'] !== undefined) {
    hilfKey = answers['versorgung']
  } else {
    missingKeys.push('versorgung')
  }
  var verlustKey = ''
  if (answers['verlust'] !== undefined) {
    verlustKey = answers['verlust']
  } else {
    missingKeys.push('verlust')
  }
  var trinkKey = ''
  if (answers['trink'] !== undefined) {
    trinkKey = answers['trink']
  } else {
    missingKeys.push('trink')
  }
  if (missingKeys.length === 0) {
    key = genderKey + '_' + inkontiKey + '_' + toiKey + '_' + hilfKey + '_' + verlustKey + '_' + trinkKey
    return key
  } else {
    return 'das fehlt: ' + missingKeys.join()
  }
}

export function  birthdateInRightFormatUtil (birthdayString) {
  var date = new Date(birthdayString)
  if (date != null) {
    var newDateString = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    return newDateString
    // return date.toLocaleDateString('de-DE')
  } else {
    return ''
  }
}

export function generateSummary (wizardJson, givenAnswers) {
  var returnObj = {}

  for (var questionIdFromAnswers in givenAnswers) {
    var answerKey = givenAnswers[questionIdFromAnswers]
    for (var x in wizardJson) {
      var segment = wizardJson[x]
      //  var returnQuestion = {}
      for (var y in segment.questions) {
        var question = segment.questions[y]
        if (question.id === questionIdFromAnswers) {
          if ((question.answers != null && question.answers[answerKey] != null)) {
            var answer = question.answers[answerKey]
            returnObj[questionIdFromAnswers] = answer
          } else if (question.type === 'multiselect') {
            var answerArray = []
            for (var i in answerKey) {
              answerArray.push(question.answers[answerKey[i]])
            }
            returnObj[questionIdFromAnswers] = answerArray
          }
        }
      }
    }
  }
  return returnObj
}
export function extraPriceForCartObject (product, products, priceSettingObject) {
  if (product.selectedSize !== '') {
    var productId = products[product.parentProductId].sizes[product.selectedSize].id
    if (productId !== undefined) {
      if (priceSettingObject !== null) {
        if (priceSettingObject[productId] !== undefined) {
          if (parseFloat(priceSettingObject[productId].toString().replace(',', '.')) !== null) {
            return parseFloat(priceSettingObject[productId])
          }
        }
      }
    }
  }
  return product.price
}

export function generateCartObject (product, answers, combinedProductJson) {
  var price = 0
  var size = ''

  if (answers['groesse'] !== undefined) {
    size = answers['groesse']
  }
  var sizeForFixPant = size
  var pieces = 0
  var selectedSize = ''
  var kvPausch = generateKVPauschale(answers)
  var needsToSelectSize = false

  var pzn = product.pzn
  var artikelnummer = product.Artikelnummer
  //  preselect onesize pieces and price for object from product.sizes object
  if (Object.keys(product.sizes).length === 1) {
    for (var sizeKey in product.sizes) {
      size = sizeKey
      var sizeObject = product.sizes[sizeKey]
      price = sizeObject.price
      pieces = sizeObject.pieces
      artikelnummer = sizeObject.id
      pzn = sizeObject.pzn
    }
    selectedSize = size
  } else if (size !== '' && product.sizes[size] !== undefined) {
    //  preselect pieces and price for selected size from product.sizes object
    var sizeObjectForSelectedSize = product.sizes[size]
    price = sizeObjectForSelectedSize.price
    pieces = sizeObjectForSelectedSize.pieces
    artikelnummer = sizeObjectForSelectedSize.id
    pzn = sizeObjectForSelectedSize.pzn
    selectedSize = size
  } else if (Object.keys(product.sizes).length > 0) {
    size = Object.keys(product.sizes)[0]
    needsToSelectSize = true
    var sizeObjectForSelectedSize2 = product.sizes[size]
    price = sizeObjectForSelectedSize2.price
    pieces = sizeObjectForSelectedSize2.pieces
    artikelnummer = sizeObjectForSelectedSize2.id
    pzn = sizeObjectForSelectedSize2.pzn
    selectedSize = size
  }
  var dailyNeed = 2
  if (product['Schweregrad'] === 'Schwere Inkontinenz' || product['Schweregrad'] === 'Schwerste Inkontinenz') {
    dailyNeed = 3
  }
  var monthlyPackages = 0
  if (pieces > 0) {
    monthlyPackages = Math.ceil(dailyNeed * 30 / pieces)
  }
  var isFixPant = false
  if (product['Anwendungstyp'] === 'Fixierhosen') {
    dailyNeed = 1
    monthlyPackages = 1
    isFixPant = true
  }
  //  kvPausch = 0 -> Selbstzahler
  var careType = 0
  if (product['PremiumBasis'].includes('Premium') || (kvPausch === 0 && answers['krankenkasse'] !== undefined && (answers['krankenkasse'].Kasse === 'Selbstzahler' || answers['krankenkasse'].Kasse === 'Private Krankenkasse' || answers['krankenkasse'].Kasse === 'Keine Krankenkasse'))) {
    careType = 1
  }
  var fixPant = {}
  if (product['Anwendungstyp'] === 'Vorlagen') {
    if (careType === 0) {
      fixPant['selectedQuality'] = 'Basis'
      fixPant['Artikelnummer'] = '9477457'
    } else {
      fixPant['selectedQuality'] = 'Premium'
      fixPant['Artikelnummer'] = '9477957'
    }
    fixPant['price'] = 0
    fixPant['pieces'] = 0
    fixPant['selectedSize'] = ''
    if (sizeForFixPant !== '') {
      var fixPantProduct = combinedProductJson[fixPant['Artikelnummer']]
      var sizeObjectForFixPant = fixPantProduct.sizes[sizeForFixPant]
      if (sizeObjectForFixPant != null) {
        fixPant['price'] = sizeObjectForFixPant.price
        fixPant['pieces'] = sizeObjectForFixPant.pieces
        fixPant['selectedSize'] = sizeForFixPant
      }
    }
  }
  var cartObject = {'parentProductId': product.Artikelnummer,
    'selectedSize': selectedSize,
    'price': price,
    'totalPrice': 0,
    'totalCarePrice': 0,
    'pieces': pieces,
    'dailyNeed': dailyNeed,
    'monthlyPackages': monthlyPackages,
    'careType': careType,
    'fixPant': fixPant,
    'isFixPant': isFixPant,
    'needsToSelectSize': needsToSelectSize,
    'selectedTime': 2,
    'PZN': pzn,
    'Artikelnummer': artikelnummer,
    'times': ['Tag', 'Nacht', '24h']
  }

  return cartObject
}

function _calculateAge (birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime()
  var ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getFullYear() - 1970)
}

export function generateKVPauschale (answerObject) {
  if (answerObject['krankenkasse'] !== undefined) {
    var birthdate = new Date(answerObject['birthdate'])
    var kindalter = 18
    var returnNumber = 0
    if (answerObject['krankenkasse'].Kindalter !== undefined) {
      kindalter = answerObject['krankenkasse'].Kindalter
    }

    if (birthdate != null && _calculateAge(birthdate) < kindalter && answerObject['krankenkasse'].Kinderpauschale !== undefined) {
      returnNumber = answerObject['krankenkasse'].Kinderpauschale
    } else if (answerObject['inkontinenz'] !== undefined && answerObject['inkontinenz'] === 'I1' && answerObject['krankenkasse'].Urinpauschale !== undefined) {
      returnNumber = answerObject['krankenkasse'].Urinpauschale
    } else if (answerObject['krankenkasse'].Stuhlpauschale !== undefined) {
      returnNumber = answerObject['krankenkasse'].Stuhlpauschale
    }
    return parseFloat(returnNumber)
  } else {
    return 0
  }
}

export function getMwst() {
  return 19 / 100
}

export function generateExtraAmount (answerObject, kvPausch) {
  var amount = 0
  if (answerObject['gebuehr'] === undefined || answerObject['gebuehr'] === 'GE2') {
    var kvBrutto = kvPausch + kvPausch * getMwst()
    if (kvBrutto > 0) {
      amount = kvBrutto * 0.1 > 10 ? 10 : kvBrutto * 0.1
    }
  }
  return amount
}

export function formatFloatToStringUtil (x) {
  if (x != null) {
    return parseFloat(x.toString().replace(',', '.')).toFixed(2).toString().replace('.', ',')
  }
}

export function OneLevelObjectIsEquivalent (a, b) {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a)
  var bProps = Object.getOwnPropertyNames(b)
  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false
    }
  }
  // If we made it this far, objects
  // are considered equivalent
  return true
}

// allProducts = {'Vorlagen':{
//                  {'341234':
//                         {'Name':'XXX',...}
//                 },..},
//               ...
export function sortProductAtSystemPosition (singleProduct, allProducts) {
  var systemOfProduct = singleProduct['Anwendungstyp']
  var sectionObject = allProducts[systemOfProduct]
  if (sectionObject == null) {
    sectionObject = []
  }
  sectionObject.push(singleProduct)
  allProducts[systemOfProduct] = sectionObject
}

export function sortByName (a, b) {
  if (a.Produktbezeichnung === b.Produktbezeichnung) {
    return 0
  }
  if (a.Produktbezeichnung < b.Produktbezeichnung) {
    return -1
  }
  if (a.Produktbezeichnung > b.Produktbezeichnung) {
    return 1
  }
}

export function showBaseTabUtil (kvPausch, answers) {
  if (kvPausch === 0 && answers['krankenkasse'] !== undefined && (answers['krankenkasse'].Kasse === 'Selbstzahler' || answers['krankenkasse'].Kasse === 'Private Krankenkasse' || answers['krankenkasse'].Kasse === 'Keine Krankenkasse')) {
    return false
  } else {
    return true
  }
}
