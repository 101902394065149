<template>
  <div id="feedback">
    <div class='breadcrumb'>
      <h2>Passwort vergessen</h2>
      <div class='breadcrumbArrow'>
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
       </div>
    </div>
    <div class="content">
        <h3>Kontaktieren Sie uns</h3>
        <p>
          Sie können uns unter unserer Support-Hotline telefonisch erreichen:<br />
          <a style="color: #00BD71; font-size: 24px">0800 7235585</a>
        </p>
        <p>
          <strong>Unsere Servicezeiten:</strong><br />
          Montag bis Freitag: 8.00 Uhr - 16:30 Uhr
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordForgot',
  data: function () {
    return {
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
