Verwaltet die Liste der Pakete.
Editieren oder Neuanlegen von Paketen öffnet ein Modal mit dem "clickedPaket"-Objekt
<template>
  <div id="cms-pakets">
    <div class="headerLine">
      <b-button @click="create()" style="float:left">Paket hinzufügen</b-button>
    </div>
    <div>
      <div style="clear:both; margin-top: 10px">
        <h5>alle Pakete</h5>
      </div>
      <table id="paketTable" >
        <tr>
          <th><input class="searchField" type="text" v-model="search" placeholder="Name"></th>
          <th>Benutzerlimit</th>
          <th>Testphase</th>
          <th>Standard für Registrierung</th>
          <th>Standard für Vertragsversion</th>
        </tr>
        <template v-for="(paket, key) in matches">
          <tr :key="key" class="dataRow" @click="edit(key)">
            
            <td>{{paket.Name}}</td>
            <td>{{paket.Nutzerlimit}}</td>
            <td v-if="paket.Testphasendauer==0">Keine</td>
            <td v-else>{{paket.Testphasendauer}} Tage</td>
       
            <td><input disabled="true" type="radio" :checked="paket.RegistrierungStandard == 1" ></td>
            <td><input disabled="true" type="radio" :checked="paket.VollversionStandard == 1"></td>
       
            
          </tr>
        </template>
      </table>
    
      
      <b-modal modal-class="editPaketModal editModal" ref="editPaketModal" size="lg" hide-footer hide-header title="">
        <h4>Paketname:</h4>
        <input class="mb-4" type="text" v-model="clickedPaket.Name">
        <h4>Benutzerlimit:</h4>
        <b-form-input class="mb-4"  v-model="clickedPaket.Nutzerlimit"/>
        <h4>Testphase:</h4>
        <b-input-group append="Tage">
          <b-form-input style="width: auto" v-model="clickedPaket.Testphasendauer"></b-form-input>
        </b-input-group>
        <b-button style="float: left; margin-right: 4px;" v-if="clickedPaket.RegistrierungStandard == 0" @click="clickedPaket.RegistrierungStandard=1">Als Standard für Registrierung setzen</b-button>
        <b-button class="highlight" style="float: left; margin-right: 4px" v-if="clickedPaket.RegistrierungStandard == 1">Ist Registrierung Standard</b-button>
        <b-button style="float: left; margin-right: 4px" v-if="clickedPaket.VollversionStandard == 0" @click="clickedPaket.VollversionStandard=1">Als Standard für Vertragsversion setzen</b-button>
        <b-button class="highlight" style="float: left; margin-right: 4px" v-if="clickedPaket.VollversionStandard == 1">Ist Vertragsversion Standard</b-button>
        <b-button style="float: left; margin-right: 4px" class="ml-2" @click="showRemoveModal()">Paket löschen</b-button>
        <b-button @click="save()">Speichern</b-button>
        <div class="closeButton" @click="back()" ></div>
      </b-modal> 
      <b-modal modal-class="cms_notification_modal" ref="paketEditedModal" hide-footer hide-header title="">
        <h5>Das Paket wurde erfolgreich angepasst.</h5>
        <b-button @click="$refs.paketEditedModal.hide()" class="modalButton">Ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="paketToDeleteModal" hide-footer hide-header title="">
        <h5>Soll dieses Paket wirklich gelöscht werden?</h5>
        <b-button @click="$refs.paketToDeleteModal.hide()" class="modalButton">Nein</b-button>
        <b-button class="rightModalButton" @click="remove()">Ja</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="paketToNotDeleteModal" hide-footer hide-header title="">
        <h5>Dieses Paket kann nicht gelöscht werden, weil es noch {{instiCount}} Institution(en) mit diesem Paket gibt.</h5>
        <b-button @click="$refs.paketToNotDeleteModal.hide()" class="modalButton">Schließen</b-button>
      </b-modal> 
    </div>
  </div>
</template>
<script>
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
import {EventBus} from '@/services/EventBus.js'

export default {
  name: 'Pakets',
  data: function () {
    return {
      paketsArray: [],
      search: '',
      clickedPaket: {},
      eventBusObject: EventBus,
      instiCount: 0
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      DataService.fetchPakets(false).then((response) => {
        this.paketsArray = response.data
       
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    create: function () {
      this.clickedPaket = {Name: '', Nutzerlimit: 10, Testphasendauer: 0, RegistrierungStandard:0, VollversionStandard: 0}
      this.$refs.editPaketModal.show()
    },
    edit: function (key) {
      this.clickedPaket = this.paketsArray[key]
      this.$refs.editPaketModal.show()
    },
    back: function () {
      this.$refs.editPaketModal.hide()
    },
    save: function () {
      DataService.setPaket(this.clickedPaket).then((response) => {
        this.clickedPaket = {}
        this.$refs.editPaketModal.hide()
        this.$refs.paketEditedModal.show()
        this.eventBusObject.$emit('updateInstiCMSTab')
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    showRemoveModal: function() {
      DataService.getPaketsCount(this.clickedPaket.id).then((response) => {
        if (response.data.Anzahl > 0) {
          this.instiCount = response.data.Anzahl
          this.$refs.paketToNotDeleteModal.show()
        } else {
          this.$refs.paketToDeleteModal.show()
        }
      }).catch((err) => {
        // handle error
        console.log(err)
      })
      
    },
    remove: function() {
      DataService.deletePaket(this.clickedPaket.id).then((response) => {
        this.clickedPaket = {}
        this.$refs.paketToDeleteModal.hide()
        this.$refs.editPaketModal.hide()
        this.eventBusObject.$emit('updateInstiCMSTab')
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'getInstitution']),
    matches () {
      return this.paketsArray.filter((element) => {
        var elementText = element.Name
        return (elementText.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || this.search.length === 0)
      })
    }
  },
  mounted() {
     this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
       this.loadData()
     })
   }
}
</script>
