import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from '../actions/auth'
import { USER_SUCCESS } from '../actions/user'
import apiCall from '@/utils/api' // <- here is the switch for the localhost login.
import axios from 'axios'
import {cartArrayStorage, answerStorage, cartProductStorage} from '@/utils/localstorage.js'

// var Promise = require('es6-promise').Promise
const state = { token: localStorage.getItem('user-token') || '', 
                status: '', hasLoadedOnce: false, 
                admin: localStorage.getItem('admin') || 'false', 
                wartend: localStorage.getItem('wartend') || 'false', 
                institution: localStorage.getItem('institution') || '', 
                userid: localStorage.getItem('userid') || '',
                rechte: localStorage.getItem('rechte') || '[]'}

const getters = {
  getToken : state => state.token,
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  isAdmin: state => JSON.parse(state.admin) === true,
  getInstitution: state => parseInt(state.institution),
  getUserId: state => parseInt(state.userid),
  isWartend: state => JSON.parse(state.wartend) === true,
  getRights: state => JSON.parse(state.rechte)
}

const actions = {
  [AUTH_REQUEST]: ({commit, dispatch}, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      apiCall({url: 'auth', data: user, method: 'POST'})
        .then(resp => {
          localStorage.setItem('user-token', resp.token)
          localStorage.setItem('admin', JSON.stringify(resp.role === 'admin'))
          localStorage.setItem('wartend', JSON.stringify(resp.status === 'wartend'))
          localStorage.setItem('institution', resp.institution)
          localStorage.setItem('userid', resp.userid)
          localStorage.setItem('rechte', JSON.stringify(resp.rechte))
          // Here set the header of the ajax library to the token value.
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.token
         
          // dispatch(USER_REQUEST)
          
          resolve(resp)
          commit(AUTH_SUCCESS, resp)
          commit(USER_SUCCESS, user.username)
          
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          localStorage.removeItem('user-token')
          localStorage.removeItem('username')
          localStorage.removeItem('institution')
          localStorage.removeItem('userid')
          localStorage.removeItem('admin')
          localStorage.removeItem('wartend')
          localStorage.removeItem('rechte')
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT)
      localStorage.removeItem('institution')
      localStorage.removeItem('userid')
      localStorage.removeItem('admin')
      localStorage.removeItem('wartend')
      localStorage.removeItem('rechte')
      cartArrayStorage.remove()
      answerStorage.save({})
      cartProductStorage.save([])
      var data = new FormData()
      data.append('username', localStorage.getItem('username'))
      axios.post('/static/api/auth/removeUserToken.php', data, {'headers': {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }})
        .then(function (response) {
          // console.log('token erfolgreich gelöscht')
          localStorage.removeItem('username')
          localStorage.removeItem('user-token')
          resolve()
        })
        .catch(function (error) {
          console.log(error)
          localStorage.removeItem('username')
          localStorage.removeItem('user-token')
          resolve()
        })
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.userid = resp.userid
    state.token = resp.token
    state.institution = resp.institution
    state.admin = JSON.stringify(resp.role === 'admin')
    state.wartend = JSON.stringify(resp.status === 'wartend')
    state.hasLoadedOnce = true
    state.rechte = JSON.stringify(resp.rechte)
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    state.admin = 'false'
    state.wartend = 'false'
    state.userid = 0
    state.rechte = '[]'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
