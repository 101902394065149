Dient als Container für die Produkte im Versorgungsrechner. Bekommt die Einstellungen direkt als "props" von aussen.
Die einzelnen Produkte werden durch die Verwendung einer weiteren Komponente angezeigt (CartProduct.vue)
<template>
  <div class="productsContainer" >
    <div v-if="productsTab.products.length > 0 && baseOrPremiumTab != 2" class="priceCalculation">
      <div>
        <span>Gesamtpreis Produktbedarf</span>
        <h2>{{calculateSumOfProducts()}} €</h2>
      </div>
      <div :class="isAdditionalArea() + ' operators'">
        <h2>-</h2>
      </div>

      <div :class="isAdditionalArea()">
        <span v-if="calcSetting !== 'C'">Pauschale (Brutto)</span>
        <span v-if="(calcSetting === 'C' && baseOrPremiumTab === '1') || (calcSetting === 'C' && findCheapestTabAndCompareToKV().price === (kVPauschale + kVPauschale * getMwstComp))" >Pauschale (Brutto)</span>
        <span v-if="findCheapestTabAndCompareToKV().price < kVPauschale && calcSetting === 'C'">med. notw. Versorgung</span>
        <h2>{{formatFloatToString(findCheapestTabAndCompareToKV().price)}} €</h2>
        <h5>{{findCheapestTabAndCompareToKV().hint.text}} <a @click="goToMnv()" :style="'display:' + findCheapestTabAndCompareToKV().hint.link"> erstellen.</a></h5>
      </div>

      <div :class="isAdditionalArea() + ' operators'">
        <h2 v-if="((calcSetting !=='C' || baseOrPremiumTab !==1) && (calcSetting !=='B' || baseOrPremiumTab !==1)) || (calcSetting ==='C' && baseOrPremiumTab === 1)">=</h2>
      </div>
      <div :style="isWunschversorung()" class="aditionalCalculation bilanzField">
        <span v-if="(calcSetting !=='C' && baseOrPremiumTab !==1) || (calcSetting ==='C' && baseOrPremiumTab ===0)">Bilanz</span>
        <h2 v-if="calcSetting !=='C' && baseOrPremiumTab !==1 || (calcSetting ==='C' && baseOrPremiumTab === 0)" :class="colorForPrice()"><strong>{{formatFloatToString(Math.abs(totalPrice))}} €</strong></h2>

      </div>
      <div>
        <span>Aufzahlung</span>
        <h2 :class="colorForPrice()">{{formatFloatToString(totalCarePrice)}} €</h2>
      </div>
      <div>
        <span style="background-color: #f7f7f8">Gesetzliche Zuzahlung</span>
        <h2 style="background-color: #f7f7f8">{{formatFloatToString(extraAmount)}} €</h2>
      </div>
    </div>
    <div>

      <div class="productContainer" v-for="(product, key) in productsTab.products" :key="key">
        <CartProduct :product="product" :products="products" :recomm="false" :priceSettingObject="priceSettingObject" :tabIndex="index" :arrayIndex="key" :baseOrPremiumTab="baseOrPremiumTab" />
      </div>
    </div>
    <div v-if="productsTab.products.length > 0" style="margin-top: 30px; padding: 30px; background-color: #f5f5f6; text-align: right; border-bottom: 1px solid #CACBCD; border-top: 1px solid #CACBCD">
      Gesamtpreis Produktbedarf: <strong>{{calculateSumOfProducts()}} €</strong>
    </div>
    <div class="newProductContainer">
      <p>Hinzufügen eines Produktes aus:</p>
      <b-button v-if="recommProducts.length > 0" @click="showModal">Empfehlung</b-button>
      <b-button v-if="showProductKat" @click="addProductFromCatalog()" style="margin-left: 8px;">Produktkatalog</b-button>
    </div>

    <b-modal id="cartRecommendations" ref="myModalRef" hide-header hide-footer title="">
      <div id="cartRecommendationsHeader">
        <h5>Empfohlene Produkte</h5><router-link to="/products/">zum Katalog</router-link>
      </div>
      <div id="cartRecommendationsBody">
        <div class="cartRecommendationsProduct" v-for="(product, key) in recommProducts" :key="key">
          <div>
            <ProductImage :path="products[product.parentProductId].imagepfad + '.jpg'" />
            <!--<img v-bind:src="require('../../assets/img/'+products[product.parentProductId].imagepfad+'.jpg')">-->
          </div>
          <div>
            <span class="productType">{{products[product.parentProductId]["Anwendungstyp"]}}</span><span :class="products[product.parentProductId]['PremiumBasis']">{{products[product.parentProductId]["PremiumBasis"]}}</span>
            <h2>{{products[product.parentProductId].Produktbezeichnung}}</h2>
            <h5>
              <span class="productType">{{products[product.parentProductId]["Schweregrad"]}}</span>
            </h5>
          </div>
          <b-button @click="addProduct(product)">Auswählen</b-button>
        </div>
      </div>
      <div id="cartRecommendationsFooter">
        <b-btn class="mt-3" block @click="hideModal">Beenden</b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {EventBus} from '@/services/EventBus.js'
import {cartProductStorage, cartArrayStorage, cartStatusStorage} from '@/utils/localstorage.js'
import CartProduct from '@/components/cart/CartProduct.vue'
import ProductImage from '@/components/ProductImage.vue'
import {extraPriceForCartObject, formatFloatToStringUtil, getMwst} from '@/utils/util.js'
import { CHANGE_FILTER } from '@/store/modules/data'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SingleCartTab',
  props: {
    index: Number,
    products: Object,
    kVPauschale: Number,
    baseOrPremiumTab: Number,
    extraAmount: Number,
    calcSetting: String,
    priceSettingObject: Object
  },
  components: {
    CartProduct,
    ProductImage
  },
  data: function () {
    return {
      productsTab: {},
      recommProducts: [],
      eventBusObject: EventBus,
      totalPrice: 0, // always the real amount
      totalCarePrice: 0, // 0 if med. notwenig
      hintText: '',
      showCalculation: false
    }
  },
  computed: {
    ...mapGetters(['getRights']),
    showProductKat: function () {
      return this.getRights.indexOf('prod_kat') !== -1
    },
    getMwstComp: function () {
      return getMwst()
    },
  },
  created: function () {
    this.productsTab = cartArrayStorage.fetch()[this.baseOrPremiumTab][this.index]
    
    if (this.productsTab == null) {
      this.productsTab = {}
    }
    if (this.productsTab.products == null) {

      this.productsTab.products = []
      this.productsTab.careType = this.baseOrPremiumTab
      var allProducts = cartArrayStorage.fetch()
      allProducts[this.baseOrPremiumTab][this.index] = this.productsTab
      cartArrayStorage.save(allProducts)
    }
    for (var i in this.productsTab.products) {
      this.productsTab.products[i] = this.calculatePrice(this.productsTab.products[i])
    }
    
    this.updateTotalPrice(false)
    this.recommProducts = cartProductStorage.fetch()
    this.eventBusObject.$on('deleteProduct' + this.baseOrPremiumTab + '-' + this.index, ($event) => {
      this.deleteProduct($event.product, $event.tabIndex, $event.arrayIndex, $event.baseOrPremiumTab)
    })
    // listen for updates from other tabs to update own totalprice
    this.eventBusObject.$on('updateOtherTabTotalPrices' + this.baseOrPremiumTab + '-' + this.index, ($event) => {
      if (!($event.baseOrPremium === this.baseOrPremiumTab && $event.index === this.index)) {
        // only update if event was emitted from other than this tab
        this.updateTotalPrice(true)
      }
    })
  },
  methods: {
    addProduct: function (product) {
      if (this.productsTab == null) {
        this.productsTab = {}
      }
      if (this.productsTab.products == null) {
        this.productsTab.products = []
      }
      product = this.calculatePrice(product)
      this.productsTab.products.push(product)
      var allProducts = cartArrayStorage.fetch()
      allProducts[this.baseOrPremiumTab][this.index] = this.productsTab
      cartArrayStorage.save(allProducts)
      this.updateTotalPrice(false)
      this.hideModal()

      this.$nextTick().then(() => {
        // this.$forceUpdate()
        this.eventBusObject.$emit('updateTabCount')
      })
    },
    deleteProduct: function (product, index, arrayIndex, baseOrPremium) {
      if (index === this.index && baseOrPremium === this.baseOrPremiumTab) {
        for (var i in this.productsTab.products) {
          if (this.productsTab.products[i] != null && this.productsTab.products[i].parentProductId === product.parentProductId) {
            if (parseInt(arrayIndex) === parseInt(i)) {
              this.productsTab.products.splice(i, 1)
            }
          }
        }
        var allProducts = cartArrayStorage.fetch()
        allProducts[this.baseOrPremiumTab][this.index] = this.productsTab
        cartArrayStorage.save(allProducts)
        if (this.productsTab.products.length === 0) {
          this.showCalculation = false
        }
        this.updateTotalPrice(false)
        this.$nextTick().then(() => {
          this.eventBusObject.$emit('updateTabCount')
        })
      }
    },
    selectProductSize: function (product, size, index, arrayIndex) {
      if (index === this.index) {
        for (var i in this.productsTab.products) {
          
          if (this.productsTab.products[i] != null && this.productsTab.products[i].parentProductId === product.parentProductId && i == arrayIndex) {
            this.productsTab.products[i].selectedSize = size
            this.productsTab.products[i].needsToSelectSize = false
            var productFromCart = this.products[this.productsTab.products[i].parentProductId]
            var sizeObject = productFromCart.sizes[this.productsTab.products[i].selectedSize]
            this.productsTab.products[i].price = sizeObject.price.toFixed(2)
            this.productsTab.products[i].pieces = sizeObject.pieces
            this.productsTab.products[i].PZN = sizeObject.pzn
            this.productsTab.products[i].Artikelnummer = sizeObject.id
            if (product.isFixPant === true) {
              this.productsTab.products[i].monthlyPackages = 1
            } else {
              this.productsTab.products[i].monthlyPackages = Math.ceil(this.productsTab.products[i].dailyNeed * 30 / this.formatProductPieces(this.productsTab.products[i].pieces))
            }
            this.productsTab.products[i] = this.calculatePrice(this.productsTab.products[i])
          }
        }
        this.updateTotalPrice(false)
        var allProducts = cartArrayStorage.fetch()
        allProducts[this.baseOrPremiumTab][this.index] = this.productsTab
        cartArrayStorage.save(allProducts)
      }
    },
    selectProductTime: function (id, time, index, arrayIndex) {
      if (index === this.index) {
        for (var i in this.productsTab.products) {
          if (this.productsTab.products[i] != null && this.productsTab.products[i].parentProductId === id && i == arrayIndex) {
            this.productsTab.products[i].selectedTime = time
          }
        }
        var allProducts = cartArrayStorage.fetch()
        allProducts[this.baseOrPremiumTab][this.index] = this.productsTab
        cartArrayStorage.save(allProducts)
        this.updateTotalPrice(false)
      }
    },
  
    saveCartProducts: function (product, type, index) {
      if (index === this.index) {
        if (type === 'need' && product.isFixPant === false) {
          for (var i in this.productsTab.products) {
            if (this.productsTab.products[i] !== null && this.productsTab.products[i].parentProductId === product.parentProductId) {
              this.productsTab.products[i].monthlyPackages = Math.ceil(this.productsTab.products[i].dailyNeed * 30 / this.formatProductPieces(this.productsTab.products[i].pieces))
            }
          }
        }
        for (var j in this.productsTab.products) {
          this.productsTab.products[j] = this.calculatePrice(this.productsTab.products[j])
        }
        var allProducts = cartArrayStorage.fetch()
        allProducts[this.baseOrPremiumTab][this.index] = this.productsTab
        cartArrayStorage.save(allProducts)
        this.updateTotalPrice(false)
      }
    },
    calculatePrice: function (product) {
      var extraPrice = extraPriceForCartObject(product, this.products, this.priceSettingObject)
      var totalPrice = extraPrice * product.monthlyPackages
      // totalPrice = totalPrice - this.kVPauschale
      product.totalPrice = totalPrice.toFixed(2)
      return product
    },
    addProductFromCatalog: function (fixpant) {
      // store current tabIndex and section for product catalog
      var statusObject = {'tabIndex': this.index, 'baseOrPremiumTab': this.baseOrPremiumTab}
      if (fixpant === true) {
        this.$store.dispatch(CHANGE_FILTER, {'Anwendungstyp': ['Fixierhosen']})
      }
      cartStatusStorage.save(statusObject)
      this.$router.push('products')
    },
    showModal () {
      this.$refs.myModalRef.show()
    },
    hideModal () {
      this.$refs.myModalRef.hide()
    },
    calculateSumOfProducts () {
      var price = 0
      for (var i in this.productsTab.products) {
        price = price + parseFloat(this.productsTab.products[i].totalPrice)
      }
      return this.formatFloatToString(price)
    },
    updateTotalPrice (fromEventBus) {
      var bruttoKv = this.findCheapestTabAndCompareToKV().price
      var price = 0
      for (var i in this.productsTab.products) {
        price = price + parseFloat(this.productsTab.products[i].totalPrice)
      }
      if (this.useKVForCalculation() && this.baseOrPremiumTab != 2) {
        this.totalPrice = parseFloat(price - bruttoKv).toFixed(2)
      } else {
        this.totalPrice = parseFloat(price).toFixed(2)
      }
      this.totalCarePrice = this.totalPrice
      // med notwendig
      if (this.productsTab.careType === 0) {
        this.totalCarePrice = 0
        // wunschversorgung:
      } else if (this.totalPrice < 0) {
        this.totalCarePrice = 0
      }

      var allProducts = cartArrayStorage.fetch()
      this.productsTab.totalPrice = this.totalPrice
      this.productsTab.totalCarePrice = this.totalCarePrice
      allProducts[this.baseOrPremiumTab][this.index] = this.productsTab
      cartArrayStorage.save(allProducts)
      // only update other tabs if changes come from this tab
      if (!fromEventBus) {
        for (var baseOrPremium in allProducts) {
          for (var tab in allProducts[baseOrPremium]) {
            this.eventBusObject.$emit('updateOtherTabTotalPrices' + baseOrPremium + '-' + tab, {baseOrPremium: this.baseOrPremiumTab, index: this.index})
          }
        }
      }
      this.eventBusObject.$emit('updateTabTitle', {baseOrPremium: this.baseOrPremiumTab, index: this.index, price: this.totalPrice, carePrice: this.totalCarePrice, color: this.colorForPrice()})
    },
    colorForPrice () {
      var hintText = ''
      var colorClass = 'tab-title-red'

      if (this.totalPrice > 0 && this.productsTab.careType === 0) {
        hintText = 'Die KV-Pauschale deckt nicht die Gesamtkosten dieses Versorgungsvorschlags, es entstehen Mehrkosten die übernommen werden müssen.'
        colorClass = 'tab-title-red'
      } else {
        if (this.totalPrice <= 0 && this.productsTab.careType === 0) {
          hintText = 'Die KV-Pauschale deckt die Gesamtkosten dieses Versorgungsvorschlags.'
        } else if (this.totalPrice <= 0 && this.productsTab.careType === 1 && this.useKVForCalculation()) {
          hintText = 'Die KV-Pauschale deckt die Gesamtkosten dieses Versorgungsvorschlags, es entstehen trotz Wunschversorgung keine Mehrkosten für den Patienten'
        } else if (this.totalPrice > 0 && this.productsTab.careType === 1 && this.useKVForCalculation()) {
          hintText = 'Die KV-Pauschale deckt nicht die Gesamtkosten dieses Versorgungsvorschlags, es entstehen Mehrkosten die vom Patienten übernommen werden müssen.'
        }
        colorClass = 'tab-title-green'
      }

      if (this.kVPauschale === 0) {
        hintText = 'Die Gesamtkosten werden vom Patienten übernommen.'
      }
      this.hintText = hintText
      return colorClass
    },
    formatFloatToString (x) {
      return formatFloatToStringUtil(x)
    },
    isVisible () {
      if (this.productsTab.products.length === 0) {
        return 'invisible'
      }
    },
    isActive () {
      if (this.showCalculation === true) {
        return 'active'
      }
    },
    calcText () {
      if (this.showCalculation === true) {
        return 'Kalkulation ausblenden'
      } else {
        return 'Kalkulation einblenden'
      }
    },
    useKVForCalculation () {
      if (this.kVPauschale <= 0) {
        return false
      }
      if (this.calcSetting === 'B' && this.baseOrPremiumTab === 1) {
        return false
      }
      return true
    },
    isAdditionalArea () {
      if (this.baseOrPremiumTab === 0) {
        return 'aditionalCalculation'
      } else {
        if (this.calcSetting === 'A' && this.baseOrPremiumTab != 2) {
          return 'aditionalCalculation'
        }
        if (this.calcSetting === 'B' || this.baseOrPremiumTab == 2) {
          return 'hidden'
        }
        if (this.calcSetting === 'C' && this.baseOrPremiumTab != 2) {
          return 'aditionalCalculation'
        }
        return ''
      }
    },
    isWunschversorung () {
      if (this.baseOrPremiumTab === 1) {
        return 'border-right: 1px solid #fff'
      }
    },
    findCheapestTabAndCompareToKV () {
      var link = 'none'
      var text = ''
      var bruttoKv = this.kVPauschale + this.kVPauschale * getMwst()
      var kvText = 'Krankenkassen Pauschale (brutto)'
      if (this.calcSetting === 'C' && this.baseOrPremiumTab === 1) {
        var allProducts = cartArrayStorage.fetch()
        var cheapestPrice = 10000000
        for (var j in allProducts[0]) {
          var baseProductsTab = allProducts[0][j]
          var price = 0
          for (var i in baseProductsTab.products) {
            price = price + parseFloat(baseProductsTab.products[i].totalPrice)
          }
          if (price < cheapestPrice && price !== 0) {
            cheapestPrice = price
          }
        }
        // only show this text if not Selbstzahler
        if (cheapestPrice === 10000000 && bruttoKv !== 0) {
          text = 'Berechnungsgrundlage ist hier die Krankenkassenpauschale, bitte medizinisch notwendige Versorgung'
          link = 'inline-block'
          return {text: kvText, price: bruttoKv, hint: {'text': text, 'link': link}}
        } else {
          if (cheapestPrice < bruttoKv) {
            text = 'Berechnungsgrundlage ist hier die medizinisch notwendige Versorgung mit dem geringsten Betrag'
            return {text: 'Geringste errechnete med. notw. Verorgung', price: cheapestPrice, hint: {'text': text, 'link': link}}
          } else {
            text = 'Berechnungsgrundlage ist hier die Krankenkassenpauschale'
            return {text: kvText, price: bruttoKv, hint: {'text': text, 'link': link}}
          }
        }
      } else {
        return {text: kvText, price: bruttoKv, hint: {'text': text, 'link': link}}
      }
    },
    goToMnv () {
      this.eventBusObject.$emit('gotToMnvTab')
    },
    formatProductPieces (pieces) {
      if (typeof pieces === 'string') {
        var newPieces = 0
        var endOfNumber = pieces.indexOf('Stück') - 1
        newPieces = parseFloat(pieces.slice(0, endOfNumber))
        return newPieces
      } else {
        return pieces
      }
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('updateOtherTabTotalPrices' + this.baseOrPremiumTab + '-' + this.index)
    this.eventBusObject.$off('deleteProduct' + this.baseOrPremiumTab + '-' + this.index)
  }
}

</script>
