Einstellungsseite für die Berechnungsmethode.
Hört über den Eventbus auf den Speichernknopf vom Footer. Lädt und sepeichert in die Datenbank.
<template>
  <div class="settings">
    <p>Bitte entscheiden Sie sich für eine Berechnungsmethode, um Ihre Versorgungsvorschläge zu erstellen.</p>
    <b-button-group>
      <b-button v-bind:class='buttonIsActive("A")' @click='select("A")'>Methode A</b-button>
      <b-button v-bind:class='buttonIsActive("B")' @click='select("B")'>Methode B</b-button>
      <b-button v-bind:class='buttonIsActive("C")' @click='select("C")'>Methode C</b-button>
    </b-button-group>
    <div class="methodDescription">
      <div v-bind:class='buttonIsActive("A")'>
        <h2>Methode A</h2>
        <p>
          <strong>Medizinische notwendige Versorgung</strong><br />
          Bei der medizinisch notwendigen Versorgung zahlt der Betroffene nichts auf, der Aufzahlbetrag im Versorgungsrechner wird mit 0,00€ ausgewiesen. Das System berechnet im Hintergrund die Differenz zwischen dem Preis der medizinisch notwendigen Versorgung und der Krankenkassenpauschale (brutto). Die gesetzliche Zuzahlung wird gesondert ausgewiesen sofern der Betroffene nicht zuzahlungsbefreit ist.
        </p>
        <p>
          <strong>Wunschversorgung</strong><br />
          Bei der Wunschversorgung trägt der Betroffene die Aufzahlung. Das System berechnet die Differenz zwischen dem Preis der gewünschten Versorgung und der Krankenkassenpauschale (brutto). Die gesetzliche Zuzahlung wird gesondert ausgewiesen sofern der Betroffene nicht zuzahlungsbefreit ist. <br /><br />
          <u>Beispiel</u>: Versichert bei Krankenkasse XY mit monatlicher Pauschale 25€ <br />
          Medizinisch notwendiger Bedarf für Betroffenen: 2x MoliCare Pad 3 Tropfen (13,90€) <br />
          Wunschbedarf des Betroffenen: 4x MoliCare Premium lady pants 5 Tropfen (35,96€) <br /><br />
          Ermittlung der Aufzahlung bei Wunschversorgung: 35,96€ - 25€ = 10,96€
        </p>
      </div>
      <div v-bind:class='buttonIsActive("B")'>
        <h2>Methode B</h2>
        <p>
          <strong>Medizinische notwendige Versorgung</strong><br />
          Bei der medizinisch notwendigen Versorgung zahlt der Betroffene nichts auf, der Aufzahlbetrag im Versorgungsrechner wird mit 0,00€ ausgewiesen. Das System berechnet im Hintergrund die Differenz zwischen dem Preis der medizinisch notwendigen Versorgung und der Krankenkassenpauschale (brutto). Die gesetzliche Zuzahlung wird gesondert ausgewiesen sofern der Betroffene nicht zuzahlungsbefreit ist.
        </p>
        <p>
          <strong>Wunschversorgung</strong><br />
          Bei der Wunschversorgung trägt der Betroffene die Aufzahlung. Der Preis der Wunschversorgung entspricht der Aufzahlung. Die gesetzliche Zuzahlung wird gesondert ausgewiesen sofern der Betroffene nicht zuzahlungsbefreit ist.<br /><br />
          <u>Beispiel</u>: Versichert bei Krankenkasse XY mit monatlicher Pauschale 25€<br />
          Medizinisch notwendiger Bedarf für Betroffenen: 2x MoliCare Pad 3 Tropfen (13,90€)<br />
          Wunschbedarf des Betroffenen: 4x MoliCare Premium lady pants 5 Tropfen (35,96€)<br /><br />
          Ermittlung der Aufzahlung bei Wunschversorgung: 35,96€
        </p>
      </div>
      <div v-bind:class='buttonIsActive("C")'>
        <h2>Methode C</h2>
        <p>
          <strong>Medizinische notwendige Versorgung</strong><br />
          Bei der medizinisch notwendigen Versorgung zahlt der Betroffene nichts auf, der Aufzahlbetrag im Versorgungsrechner wird mit 0,00€ ausgewiesen. Das System berechnet im Hintergrund die Differenz zwischen dem Preis der medizinisch notwendigen Versorgung und der Krankenkassenpauschale (brutto). Die gesetzliche Zuzahlung wird gesondert ausgewiesen sofern der Betroffene nicht zuzahlungsbefreit ist.
        </p>
        <p>
          <strong>Wunschversorgung</strong><br />
          Bei der Wunschversorgung trägt der Betroffene die Aufzahlung. Das System berechnet die Differenz zwischen dem Preis der gewünschten Versorgung und der vorher zu definierenden medizinisch notwendigen Versorgung, welche als Basis der Berechnung gilt. Sollte der Betrag höher sein als die Krankenkassenpauschale wird die Pauschale zur Berechnung herangezogen. Die gesetzliche Zuzahlung wird gesondert ausgewiesen sofern der Betroffene nicht zuzahlungsbefreit ist.<br /><br />
          <u>Beispiel 1</u>: Versichert bei Krankenkasse XY mit monatlicher Pauschale 25€<br />
          Medizinisch notwendiger Bedarf für Betroffenen: 3x MoliCare Pad 3 Tropfen (20,85€)<br />
          Wunschbedarf des Betroffenen: 4x MoliCare Premium lady pants 5 Tropfen (35,96€)<br /><br />
          Ermittlung der Aufzahlung bei Wunschversorgung: 35,96€ -20,85€ = 15,11€<br />
          <br /><br /><br />
          <u>Beispiel 2</u>: Versichert bei Krankenkasse XY mit monatlicher Pauschale 25€<br />
          Medizinisch notwendiger Bedarf für Betroffenen: 4x MoliCare Pad 3 Tropfen (27,80€)<br />
          Wunschbedarf des Betroffenen: 4x MoliCare Premium lady pants 5 Tropfen (35,96€)<br /><br />

          Ermittlung der Aufzahlung bei Wunschversorgung: 35,96€ -25€ = 10,96€
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {EventBus} from '@/services/EventBus.js'
import DataService from '@/services/DataService'
import {formatFloatToStringUtil} from '@/utils/util.js'

export default {
  name: 'CalcSettings',
  props: {
    currentIndex: Number
  },
  data: function () {
    return {
      eventBusObject: EventBus,
      selectedMethod: '',
      thisUserID: '',
      thisInstituion: ''
    }
  },
  created: function () {
    this.loadData()
    this.eventBusObject.$on('saveSettings', ($event) => {
      if (this.currentIndex === 4) {
        this.sendCalcSettingToDB()
      }
    })
  },
  methods: {
    loadData: function () {

        var self = this
        DataService.getUserData(this.getUserId).then((response) => {
          self.thisInstituion = response.data
          self.selectedMethod = response.data['Berechnungsmethode']
        }).catch((err) => {
          // handle error
          console.log(err)
        })
     
    },
    sendCalcSettingToDB: function () {
      var institution = {}
      institution['Kundennummer'] = this.thisInstituion.Kundennummer
      institution['Name'] = this.thisInstituion.Name
      institution['id'] = this.thisInstituion.id
      institution['Vertriebsweg'] = this.thisInstituion.Vertriebsweg
      institution['Berechnungsmethode'] = this.selectedMethod
      institution['Strasse'] = this.thisInstituion.Strasse
      institution['Hausnummer'] = this.thisInstituion.Hausnummer
      institution['PLZ'] = this.thisInstituion.PLZ
      institution['Ort'] = this.thisInstituion.Ort
      institution['Telefon'] = this.thisInstituion.Telefon
      DataService.setInstitutionData(institution).then((response) => {
        this.eventBusObject.$emit('saveSettingsDone')
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    isVisible: function (name) {
      if (name.toLowerCase().indexOf(this.filterString.toLowerCase()) !== 0) {
        return 'invisible'
      }
    },
    formatFloatToString (x) {
      return formatFloatToStringUtil(x)
    },
    select (x) {
      this.selectedMethod = x
    },
    buttonIsActive: function (key) {
      if (this.selectedMethod === key) {
        return 'active'
      }
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('saveSettings')
    this.eventBusObject.$off('deleteThisSettings')
    this.eventBusObject.$off('reloadSettings')
  },
  computed: {
    ...mapGetters(['getProfile', 'getUserId', 'isAuthenticated', 'isProfileLoaded', 'getInstitution'])
  }
}

</script>
