Die Institution kann hier eigene Preise für jedes Produkt einstellen. Diese werden in der Datenbank gespeichert.
Der Speicherknopf befindet sich im Footer und kommuniziert über den Eventbus.
<template>
  <div class="settings">
    <template>
      <div id="filterbar">
        <b-tabs v-model="filterTabIndex">
          <b-tab v-for="(tab, index) in superFilter" :key="index" :title="typeFilter[index]" @click="currentFilter='Alle'">
            <button v-bind:class="buttonIsActive(filter.filterValue)" v-for="filter in tab" :key="filter.name" @click="filterSelected(filter.filterValue)">{{filter.name}}</button>
          </b-tab>
        </b-tabs>
      </div>
    </template>
    <input v-model="filterString" id="prodFilter" placeholder="Name">
    <div style="margin-top: 10px;" v-for="(section, sectionKey) in filteredProducts" :key="sectionKey">
      <div v-if="Object.keys(filteredProducts[sectionKey]).length > 0 && (currentFilter==='Alle' || currentFilter===sectionKey)">

      
      <h3>{{sectionKey}}</h3>
      <table style="margin-top:0px;">
        <tr>
          <th>Name</th>

          <th>Grösse</th>

          <th v-if="Object.keys(section).length > 0 && section[Object.keys(section)[0]]['liquid'] === true">Menge</th>
          <th v-else> Stück pro Packung</th>

          <th>UVP</th>
          <th>Preis (brutto)</th>
        </tr>
          <template v-for="(product) in section">
            <tr :class="isVisible(product.Produktbezeichnung)" v-for="(size,sizeKey) in product.sizes" :key="product.Artikelnummer+'-'+sizeKey">
              <td>{{product.Produktbezeichnung}}</td>
              <td>{{sizeKey}}</td>
              <td v-if="product['liquid'] === false">{{size.pieces}}</td>
              <td v-if="product['liquid'] === true">{{size.pieces}}</td>
              <td>{{formatFloatToString(size.price)}}€</td>
              <td>
                <!--<input type='number' max="9999" maxlength = "8" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)" v-model='prices[size.id]'> €-->
                <input class="priceInput" type='text' maxlength = "8" oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/(\,,*)\,/g, '$1');" v-model="pricesComma[size.id]"> €
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import DataService from '@/services/DataService'
import {EventBus} from '@/services/EventBus.js'
import {formatFloatToStringUtil, sortProductAtSystemPosition, sortByName} from '@/utils/util.js'

export default {
  name: 'PriceSettings',
  props: {
    currentIndex: Number
  },
  data: function () {
    return {
      prices: [],
      pricesComma: {},
      filterString: '',
      filterTabIndex: 0,
      eventBusObject: EventBus,
      currentFilter: 'Alle',
      allProducts: {}
    }
  },
  created: function () {
    this.loadData()
   
    this.eventBusObject.$on('saveSettings', () => {
      if (this.currentIndex === 3) {
        this.savePricesToStorage()
      }
    })
    this.eventBusObject.$on('reloadSettings', () => {
      this.loadData()
    })
    this.eventBusObject.$on('deleteThisSettings', () => {
      if (this.currentIndex === 3) {
        this.deleteStorage()
      }
    })
  },
  methods: {
    loadData: function () {
      DataService.fetchPrices(this.getInstitution).then((response) => {
      this.prices = response.data
      for (var id in this.prices) {
        var priceToSave = this.prices[id].toFixed(2).toString().replace('.',',')
        this.pricesComma[id] = priceToSave
        
      }
      DataService.fetchCombinedProducts().then((response) => {
        this.allProducts = response.data
      
      }).catch(() => {
        console.log(err)
      })
    }).catch(() => {
      console.log(err)
    })
    },

    deleteStorage: function () {
      this.prices = {}
      this.pricesComma = {}

      this.savePricesToStorage()
    },

    savePricesToStorage: function () {
    
      // this.$ga.event( 'Preise gespeichert', this.getProfile , Object.keys(this.prices).length)

      var trackOwnPricesSet = false
      var deleted = false
      for (var id in this.pricesComma) {
        
        
        var priceToSave = this.pricesComma[id].toString()
        //console.log(priceToSave)
        //console.log(typeof priceToSave)
       
        priceToSave = priceToSave.replace(",", ".")
        // console.log(typeof priceToSave)
        // console.log(priceToSave)
        priceToSave = parseFloat(priceToSave)
        // console.log(typeof priceToSave)
        this.pricesComma[id] = priceToSave.toFixed(2).toString().replace('.',',')
        this.prices[id] = priceToSave
        
       // console.log(priceToSave)
        if (priceToSave == null || priceToSave == undefined || priceToSave === '' || isNaN(priceToSave))
        {
          deleted = true
          delete this.prices[id]
          delete this.pricesComma[id]
        } 
      }

      this.$ga.set('dimension1', Object.keys(this.prices).length)
      this.$ga.set('metric1', Object.keys(this.prices).length)
      this.$ga.event('Einstellungen Kategorie', 'Preise gespeichert (Aktion)', 'Preise gespeichert (Ereignis)', Object.keys(this.prices).length)
      this.$ga.page('Preisgespeichert')
      
      this.eventBusObject.$emit('saveSettingsDone') 
      DataService.setPrices(this.prices, this.getInstitution).then((response) => {
        this.$nextTick().then(() => {
          this.$forceUpdate()
        })
      }).catch(() => {
        console.log(err)
      })
      
    },
    isVisible: function (name) {
      if (this.filterString.length > 0) {
        if (name.toLowerCase().indexOf(this.filterString.toLowerCase()) >= 0) {
          return 'visible'
        } else {
          return 'invisible'
        }
      } else {
        return 'visible'
      }
    },
    formatFloatToString (x) {
      return formatFloatToStringUtil(x)
    },
    buttonIsActive: function (filterValue) {
      if (this.currentFilter != null && this.currentFilter === filterValue) {
        return 'active'
      }
    },
    filterSelected: function (filterValue) {
      this.currentFilter = filterValue
    },
    convertToFloat: function (value) {
      if (value != null) {
        return value.replace('.',',')
      }
      return ""
    }
  },    
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'isAdmin', 'getInstitution']),
    // list of all possible 'Inkontinenzprodukt' values to filter
    typeFilter: function () { return ['Inkontinenzprodukt / Zubehör', 'Pflegehilfsmittel', 'Hautpflege'] },
    superFilter: function () {
      return [
        [
          {'name': 'Alle', 'filterValue': 'Alle'},
          {'name': 'Einlage', 'filterValue': 'Einlagen'},
          {'name': 'Vorlage', 'filterValue': 'Vorlagen'},
          {'name': 'Fixierhose', 'filterValue': 'Fixierhosen'},
          {'name': 'Slip', 'filterValue': 'Slip'},
          {'name': 'Slip Elastic', 'filterValue': 'Slip Elastic'},
          {'name': 'Pull up', 'filterValue': 'Pull-ups'}
        ],
        [
          {'name': 'Alle', 'filterValue': 'Alle'},
          {'name': 'Untersuchungshandschuhe', 'filterValue': 'Untersuchungshandschuhe'},
          {'name': 'Händedesinfektion', 'filterValue': 'Händedesinfektion'},
          {'name': 'Flächendesinfektion', 'filterValue': 'Flächendesinfektion'},
          {'name': 'Schutzschürze', 'filterValue': 'Schutzschürzen'},
          {'name': 'Mundschutz', 'filterValue': 'Mundschutz'},
          {'name': 'Krankenunterlagen', 'filterValue': 'Krankenunterlagen'}
        ],
        [
          {'name': 'Alle', 'filterValue': 'Alle'},
          {'name': 'Schutz', 'filterValue': 'Schutz'},
          {'name': 'Pflege', 'filterValue': 'Pflege'},
          {'name': 'Reinigung', 'filterValue': 'Reinigung'}
        ]
      ]
    },
    filteredProducts: function () {
      var returnProducts = {
        'Einlagen': [],
        'Vorlagen': [],
        'Fixierhosen': [],
        'Slip': [],
        'Slip Elastic': [],
        'Pull-ups': [],
        'Schutz': [],
        'Pflege': [],
        'Reinigung': [],
        'Untersuchungshandschuhe': [],
        'Händedesinfektion': [],
        'Flächendesinfektion': [],
        'Schutzschürzen': [],
        'Mundschutz': [],
        'Krankenunterlagen': []
      }
      for (var i in this.allProducts) {
        var singleProduct = this.allProducts[i]
        if (this.typeFilter[this.filterTabIndex].includes(singleProduct.Inkontinenzprodukt)) {
          sortProductAtSystemPosition(singleProduct, returnProducts)
        }
      }
      returnProducts.Einlagen.sort(function (a, b) {
        return sortByName(a, b)
      })
      returnProducts.Vorlagen.sort(function (a, b) {
        return sortByName(a, b)
      })
      return returnProducts
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('saveSettings')
    this.eventBusObject.$off('deleteThisSettings')
    this.eventBusObject.$off('reloadSettings')
  }
}

</script>
