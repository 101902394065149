Hilfskomponente um die Frageseiten in der Anamnese anzuzeigen.
Im SegmentObject sind alle Fragen enthalten.
<template>
  <div>
      <div :class='isShown(question) + " " + getErrorClass(question.id, question.type)' v-for='question in segmentObject.questions' :key='question.id'>
          <label>
            {{question.questiontext}} <span v-if='question.required'>*</span>
            <div v-html="$sanitize(question.hint)" v-if='help === true' class='hintContainer'>
              {{question.hint}}
            </div>
          </label>
          <!-- type 'devider'> -->
          <div v-if="question.type==='devider'" class='devider'></div>

          <!-- type 'subline'> -->
          <h3 v-if="question.type==='subline'" class='subline'>
            {{question.sublinetext}}
          </h3>

          <!-- type 'select'> -->

          <b-button-group v-if="question.type==='select'">
            <b-button v-bind:class='buttonIsActive(key, question.id)' v-for='(value, key) in question.answers' :key='key' v-bind:value='key' @click='saveButtonAnswer(key, question.id)' >{{ value }}</b-button>
          </b-button-group>

          <div class="sizesConverter" v-if="question.type==='select' && question.id==='groesse'">
            {{sizesConverter()}}
          </div>

          <!-- type 'multiselect'> -->

          <div v-if="question.type==='multiselect'">
            <b-button v-bind:class='multiselectIsActive(key, question.id)' v-for='(value, key) in question.answers' :key='key' v-bind:value='key' @click='saveMultiAnswer(key, question.id)' >{{ value }}</b-button>
          </div>

          <!-- type 'date' -->
          <div class="dateContainer" v-if="question.type==='date'">
            <input min="1" max="31" maxlength="2" oninput="javascript: if (this.value > 31) this.value = this.value.slice(0, this.value.length - 1)" placeholder="TT" type='number' v-bind:value='givenAnswers[question.id+"-day"]' @change='saveDateAnswer($event, question.id, "day")'>
            <input min="1" max="12" maxlength="2" oninput="javascript: if (this.value > 12) this.value = this.value.slice(0, this.value.length - 1)" placeholder="MM" type='number' v-bind:value='givenAnswers[question.id+"-month"]' @change='saveDateAnswer($event, question.id, "month")'>
            <input min="1900" max="2018" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)" placeholder="JJJJ" type='number' v-bind:value="givenAnswers[question.id+'-year']" @change='saveDateAnswer($event, question.id, "year")'>
          </div>
          <!--<input type='date' v-if="question.type==='date'" v-bind:value='givenAnswers[question.id]' @change='saveInpuOrSelectAnswer($event, question.id)'> -->

          <!-- type 'input'> -->
          <input type='text' v-if="question.type==='input'" v-bind:value='givenAnswers[question.id]' @change='saveInpuOrSelectAnswer($event, question.id)'>

          <!-- type 'autocomplete'> -->
          <input type='text' id="autocomplete" v-if="question.type==='autocomplete'" v-model='givenAnswers[question.id]'  @input="searchChanged(question.id)"  :class="getErrorClass(question.id, question.type)">
          <ul v-if="question.type==='autocomplete' && showSuggestions" class="suggestion-list">
            <li v-for="(suggestion, index) in matches" @click="setAutoCompleteText(suggestion.Name)" :key="index">
              {{ suggestion.Name }}
            </li>
          </ul>
          <!-- type 'phone'> -->
          <input type='phone' v-if="question.type==='phone'" v-bind:value='givenAnswers[question.id]' @change='saveInpuOrSelectAnswer($event, question.id)'>

          <!-- type 'email'> -->
          <input type='email' v-if="question.type==='email'" v-bind:value='givenAnswers[question.id]' @change='saveInpuOrSelectAnswer($event, question.id)'>

          <!-- type 'dropdown'> -->
          <b-dropdown v-if="question.type==='dropdown'" v-bind:text='updateDropdown(givenAnswers[question.id])'>
            <b-dropdown-item v-for="answer in question.answers" :value='answer' @click="saveButtonAnswer(answer, question.id)" :key="answer">{{answer}}</b-dropdown-item>
          </b-dropdown>

          <!-- type 'krankenkasse'> -->
          <b-dropdown v-if="question.type==='kvs'" class="kvsSearch scrollable" v-bind:text='updateDropdown(givenAnswers[question.id])'>
            <b-dropdown-item value='Keine Krankenkasse' @click="saveButtonAnswer({'Kasse': 'Keine Krankenkasse'}, question.id)">Keine Krankenkasse</b-dropdown-item>
            <b-dropdown-item value='Private Krankenkasse' @click="saveButtonAnswer({'Kasse': 'Private Krankenkasse'}, question.id)">Private Krankenkasse</b-dropdown-item>
            <b-dropdown-item value='Selbstzahler' @click="saveButtonAnswer({'Kasse': 'Selbstzahler'}, question.id)">Selbstzahler</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-for="kv in kvs" value='kv.Kasse' @click="saveButtonAnswer(kv, question.id)" :key="kv.Kasse">{{kv.Kasse}}</b-dropdown-item>
          </b-dropdown>

           <!-- type 'textarea'> -->
           <b-form-textarea v-if="question.type==='textarea'" v-model='givenAnswers[question.id]' @input='saveButtonAnswer(givenAnswers[question.id], question.id)' :rows="3" :max-rows="6">
           </b-form-textarea>

          <span class="requiredMissingText">Bitte auswählen</span>
          <span class="requiredMissingDateText">Bitte ausfüllen im Format Tag & Monat zweistellig, Jahr vierstellig</span>

        </div>
  </div>
</template>

<script>

import {answerStorage} from '@/utils/localstorage.js'
import {EventBus} from '@/services/EventBus.js'
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
export default {
  name: 'Questions',
  props: {
    segmentObject: Object,
    help: Boolean,
    missingKeys: Array,
    showError: Boolean
  },
  data: function () {
    return {
      givenAnswers: answerStorage.fetch(),
      kvs: [],
      eventBusObject: EventBus,
      wettbewerber: [],
      autoCompleteSearchText: '',
      showSuggestions: false
    }
  },
  mounted() {
    this.deselectKSVChoiceOnDropdownShow()
  },
  computed: {
    ...mapGetters(['getInstitution']),
    matches () {
      return this.wettbewerber.filter((element) => {
        var elementText = element.Name.toUpperCase()
        return (elementText.toLowerCase().indexOf(this.givenAnswers['equivalence'].toLowerCase()) === 0 && this.givenAnswers['equivalence'].length > 0)
      })
    }
  },
  created: function () {

    DataService.fetchOwnKVs(this.getInstitution).then((response) => {
        this.kvs = response.data
       
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    DataService.fetchWettbewerber().then((response) => {
      this.wettbewerber = response.data
    }).catch((err) => {
      // handle error
      console.log(err)
    })
  },
  methods: {
    saveButtonAnswer: function (key, id) {
      this.givenAnswers[id] = key
      answerStorage.save(this.givenAnswers)
      this.$parent.$emit('updateButtons')
      this.$forceUpdate()
      this.eventBusObject.$emit('updateSummary')
    },
    deselectKSVChoiceOnDropdownShow: function(){
      this.$root.$on('bv::dropdown::show', bvEvent => {
        if(bvEvent.vueTarget.$el.className.split(" ").indexOf('kvsSearch') !== -1){
          delete this.givenAnswers.krankenkasse;
          answerStorage.save(this.givenAnswers)
          this.$parent.$emit('updateButtons')
          this.$forceUpdate()
          this.eventBusObject.$emit('updateSummary')
        }
      })
    },
    saveMultiAnswer: function (key, id) {
      if (this.givenAnswers[id] != null && key != null) {
        // there are already filter set with this filterKey -> check if filterValue is in array
        var index = this.givenAnswers[id].indexOf(key)
        var newArray = this.givenAnswers[id]
        if (index >= 0) {
          // remove element from current array
          newArray.splice(index, 1)
        } else {
          // add element to current array
          if (key === 'M1' || key === "Hi1" || key === 'Ef1') {
            newArray = []
          } else {
            var searchTerm = ''
            
            if (id === 'medikamente') {
              searchTerm = 'M1'
            }
            if (id === 'hilfsmittel') {
              searchTerm = 'Hi1'
            }
            if (id === 'weitereEinflussfaktoren') {
              searchTerm = 'Ef1'
            }
            if (newArray.indexOf(searchTerm) >= 0) {
              newArray.splice(newArray.indexOf(searchTerm), 1)
            }
          }
          newArray.push(key)
        }
        this.$set(this.givenAnswers, id, newArray)
      } else {
        // no filter was set with this key -> add new array with one element
        this.$set(this.givenAnswers, id, [key])
      }
      answerStorage.save(this.givenAnswers)
      
      this.$forceUpdate()
      this.eventBusObject.$emit('updateSummary')
    },
    saveInpuOrSelectAnswer: function (event, id) {
      this.givenAnswers[id] = event.target.value
      answerStorage.save(this.givenAnswers)
      this.$parent.$emit('updateButtons')
      this.$forceUpdate()
      this.eventBusObject.$emit('updateSummary')
    },
    saveDateAnswer: function (event, id, date) {
      var newKey = id + '-' + date
      this.givenAnswers[newKey] = event.target.value
      // check for valid date numbers otherwise remove birthday from savedanswers
      if (id === 'birthdate') {
        if (this.givenAnswers['birthdate-day'] !== undefined && parseInt(this.givenAnswers['birthdate-day']) < 32 && parseInt(this.givenAnswers['birthdate-day']) > 0 && this.givenAnswers['birthdate-month'] !== undefined && parseInt(this.givenAnswers['birthdate-month']) < 13 && parseInt(this.givenAnswers['birthdate-month']) > 0 &&
          this.givenAnswers['birthdate-year'] !== undefined && this.givenAnswers['birthdate-year'] && parseInt(this.givenAnswers['birthdate-year']) > 999) {
          var dateFromAnswer = new Date(this.givenAnswers['birthdate-year'] + '/' + this.givenAnswers['birthdate-month'] + '/' + this.givenAnswers['birthdate-day'])
          var twoDigitDay = ('0' + dateFromAnswer.getDate()).slice(-2)
          var twoDigitMonth = ('0' + (dateFromAnswer.getMonth() + 1)).slice(-2)
          var birthDayStringForDate = this.givenAnswers['birthdate-year'] + '-' + twoDigitMonth + '-' + twoDigitDay
          this.givenAnswers['birthdate'] = birthDayStringForDate
          // console.log("DATUM:")
          // console.log(birthDayStringForDate)
        } else {
          // remove birthdate from answers
          delete this.givenAnswers['birthdate']
        }
      }
      answerStorage.save(this.givenAnswers)
      this.$parent.$emit('updateButtons')
      this.$forceUpdate()
      this.eventBusObject.$emit('updateSummary')
    },
    buttonIsActive: function (key, id) {
      if (this.givenAnswers[id] === key) {
        return 'active'
      }
    },
    multiselectIsActive: function (key, id) {
      if (this.givenAnswers[id] != null && this.givenAnswers[id].indexOf(key) >= 0) {
        return 'active'
      }
    },
    optionIsSelected: function (value, id) {
      if (this.givenAnswers[id] === value) {
        return true
      } else {
        return false
      }
    },
    updateDropdown: function (answer) {
      if (answer !== undefined) {
        if (answer.Kasse !== undefined) {
          return answer.Kasse
        } else {
          return answer
        }
      } else {
        return 'bitte auswählen'
      }
    },
    isShown: function (question) {
      if (typeof question.dependence !== 'undefined') {
        if (question.dependence === 'representative' && this.givenAnswers.representative !== 'R1') {
          return 'question notDisplayed'
        } else if ((question.dependence === 'representative_type' && this.givenAnswers.representative_type !== 'Sonstige') || (question.dependence === 'representative' && this.givenAnswers.representative !== 'R1')) {
          return 'question notDisplayed'
        } else if (question.dependence === 'grad' && this.givenAnswers.grad !== 'G2' && this.givenAnswers.grad !== 'G3' && this.givenAnswers.grad !== 'G4' && this.givenAnswers.grad !== 'G5' && this.givenAnswers.grad !== 'G6') {
          return 'question notDisplayed'
        } else if (question.dependence === 'experience' && this.givenAnswers.experience !== 'E1') {
          return 'question notDisplayed'
        } else {
          return 'question'
        }
      } else {
        return 'question'
      }
    },
    getErrorClass: function (questionId, type) {
      if (this.missingKeys != null && this.showError === true) {
        if (this.missingKeys.indexOf(questionId) >= 0 && type !== 'date') {
          return 'requiredMissing' // + type
        } else if (this.missingKeys.indexOf(questionId) >= 0 && type === 'date') {
          return 'requiredMissingDate'
        }
      }
    },
    setAutoCompleteText: function (suggestion) {
      this.givenAnswers['equivalence'] = suggestion
      this.showSuggestions = false
      answerStorage.save(this.givenAnswers)
      this.$parent.$emit('updateButtons')
      this.$forceUpdate()
    },
    searchChanged: function (questionId) {
      this.showSuggestions = true
      answerStorage.save(this.givenAnswers)
      this.$parent.$emit('updateButtons')
      this.$forceUpdate()
      this.eventBusObject.$emit('updateSummary')
    },
    sizesConverter: function () {
      var text = ''
      if (this.givenAnswers.gender === 'M') {
        if (this.givenAnswers.groesse === 'S') {
          text = 'Kleidergröße bei Herren: 48 | U-Hosen-Größe: 4'
        }
        if (this.givenAnswers.groesse === 'M') {
          text = 'Kleidergröße bei Herren: 50, 52 | U-Hosen-Größe: 5, 6'
        }
        if (this.givenAnswers.groesse === 'L') {
          text = 'Kleidergröße bei Herren: 54, 56 | U-Hosen-Größe: 7'
        }
        if (this.givenAnswers.groesse === 'XL') {
          text = 'Kleidergröße bei Herren: 58 | U-Hosen-Größe: 8'
        }
      }
      if (this.givenAnswers.gender === 'F') {
        if (this.givenAnswers.groesse === 'XS') {
          text = 'Kleidergröße bei Damen: 34'
        }
        if (this.givenAnswers.groesse === 'S') {
          text = 'Kleidergröße bei Damen: 36, 38, 40'
        }
        if (this.givenAnswers.groesse === 'M') {
          text = 'Kleidergröße bei Damen: 40, 42, 44, 46'
        }
        if (this.givenAnswers.groesse === 'L') {
          text = 'Kleidergröße bei Damen: 48, 50'
        }
        if (this.givenAnswers.groesse === 'XL') {
          text = 'Kleidergröße bei Damen: >50'
        }
      }
      return text
    }
  }
}

</script>

<style scoped>
  .scrollable /deep/ .dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
  }
</style>
