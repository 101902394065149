Haupttemplate
Hier befinden der Quellcode für das Seitenmenü und die meisten Modals (Pop-Ups). 
Header und Footer werden hier reingeladen und die weiteren Komponenten in die "router-view".
Um die verschiedenen Modals anzuzeigen, wird das Eventbus-Pattern verwendet. Eine beliebige Komponente kann so ein Event auslösen.

Zustände werden grundsätzlich über Vuex (/store/) im Localstorage gespeichert. Nur die Antworten des Nutzers in der Anamnese werden mit dem 
answerStorage Object in den Localstorage geschrieben und gelesen. Man könnte dies auch nach Vuex verlagern.
Früher wurden hier noch mehr Einstellungen gespeichert, aber mittlerweile wurden diese in die Datenbank überführt. (siehe saveDataToDB()-Funktion)
<template>
  <div id="app">
    <div id="main" @click="clickSomewhere">
      
      <Header :sidebarStatus="sidebar" :answerObject="answerObjectForHeader"/>
      <div :class="sidebar" id="sidebar">
        <div v-if="isAuthenticated" id="accountInfo">
          <router-link id="accountButton" to="/myAccount">Account: <span style="color: #323E48">{{getProfile}}</span></router-link> <a class="logout" @click="logout">Logout</a>
          <router-link v-if="isAdmin" id="adminButton" to="/cms">zum Datenverwaltungssystem</router-link>
        </div>
        <ul>
          <li class="navArea" v-if="isAuthenticated">
            <h3>Beratung</h3>
            <ul>
              <!-- ><li><router-link id="anamneseButton" to="/wizard">Beratung beginnen</router-link></li> -->
              <li v-if="showProductKat"><router-link id="catalogButton" to="/products">Produktkatalog</router-link></li>
              <li><router-link id="anamneseButton" to="/wizard/0">Beratung starten</router-link></li>
            </ul>
          </li>
          <li class="navArea" v-if="isAuthenticated">
            <h3 style="position: relative">
              Einstellungen
            </h3>
            <div style="clear:both; height: 1px; width:100%;border-bottom: 1px solid #EFF0F1;"></div>
            <ul>
              <li v-if="showContactSettings"><router-link id="settingsButton" to="/settings/0" >Kontaktdaten</router-link></li>
              <li v-if="showUsermanagement"><router-link id="settingsButton" to="/settings/1" >Benutzerverwaltung</router-link></li>
              <li v-if="showKVSettings"><router-link id="settingsButton" to="/settings/2" >Vertragskassen</router-link></li>
              <li v-if="showPriceSettings"><router-link id="settingsButton" to="/settings/3" >Preise</router-link></li>
              <li v-if="showCalcSettings"><router-link id="settingsButton" to="/settings/4">Berechnungsmethode</router-link></li>
            </ul>
          </li>
          <li class="navArea">
            <h3>Hilfe & Support</h3>
            <ul>
              <li><router-link to="/feedback">Kontaktieren Sie uns</router-link></li>
              <li><a href="/static/INCOAID_Handbuch.pdf" target="_blank">Benutzerhandbuch</a></li>
            </ul>
            <h3 style="position: relative">
              Rechtliches
            </h3>
            <ul>
              <li><a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutz</a></li>
              <li><a href="https://hartmann.info/de-DE/Impressum" target="_blank">Impressum</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <router-view class="content"></router-view>
    
      <b-modal size="sm" ref="tokenInvalidModal" hide-footer hide-header title="" style="text-align: center">
        <div class="d-block text-center">
          <p>Ihre Session ist abgelaufen. Bitte loggen Sie sich aus und erneut ein.</p>
          <b-button @click="closeAndLogout" class="modalButton">Ausloggen</b-button>
        </div>
      </b-modal>

      <b-modal size="sm" ref="autologoutModal" hide-footer hide-header title="" style="text-align: center">
        <div class="d-block text-center">
          <p>Sie wurden ausgeloggt, weil sie länger als 45 Minuten inaktiv waren.</p>
          <b-button  @click="$refs.autologoutModal.hide()" class="modalButton">Ok</b-button>
        </div>      
      </b-modal>

      <b-modal size="m" ref="dataTransferModal" hide-footer title="Produkt Update" style="text-align: center">
        <div class="d-block text-center">
          <p>Die Einstellungen Ihrer Institution brauchen <br>zukünftig
            nicht mehr im- bzw. exportiert zu werden.<br>Ihre Einstellungen sind jetzt zentral abgespeichert.
           </p>
          <b-form-checkbox v-model="gelesen" value="1" unchecked-value="0">
            nicht mehr anzeigen
          </b-form-checkbox>
          <br/>
          <b-button @click="saveDataToDB(true)" class="modalButton">Ok</b-button>
        </div>       
      </b-modal>

      <b-modal size="m" ref="applyModal" hide-footer title="Ja, ich möchte INCOAID vollumfänglich und weiterhin nutzen!" >
        <div id="applyoverlay" class="d-block">
          <span style="font-size: 13px">Hinweis:</span><p>Wenn Sie die Vertragsversion beantragen, werden von uns die notwendigen Vertragsunterlagen erstellt und Ihnen zugeschickt.<br />
            Sobald wir die von Ihnen unterzeichneten Unterlagen zurückerhalten haben, geht Ihre Demoversion in eine vollumfängliche Version über.</p>
          <ul>
            <li>Für die vollumfängliche Nutzung von INCOAID berechnen wir 19,95€ im Monat.</li> 
            <li>Die hinterlegten Einstellungen aus der Demoversion bleiben erhalten.</li> 
            <li>Der Vertrag kann mit einer Frist von 3 Monaten zum Monatsende gekündigt werden.</li> 
           
           
          </ul>
          <b-form-checkbox v-model="checked">
          Ja, ich stimme den <a href="/static/Nutzungsbedingungen_INCOAID_V1.pdf" target="_blank">Nutzungsbedingungen</a> und <a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutzhinweisen</a> zu
        </b-form-checkbox>
          <br/>
          <b-button @click="applyForFullVersion" class="callToAction" :disabled="!checked">Vertragsversion beantragen</b-button>
          <b-button @click="$refs.applyModal.hide()"  style="margin-left: 8px;">Abbrechen</b-button>
        </div>       
      </b-modal>
      <b-modal size="m" ref="applySuccessModal" hide-footer title="Vertragsversion erfolgreich beantragt" style="text-align: center">
        <div class="d-block">
          
            <span style="display: inline-block;margin-right: 20px; margin-bottom: 20px;" class="checkCircle"></span><span style="display: inline-block;width: 70%;padding-left: 10px;">Ihre Anfrage ist bei uns eingegangen und wird umgehend bearbeitet.<br />Wir haben Ihnen weitere Informationen an die folgende E-Mail-Adresse geschickt:<br/><strong>{{applySuccessMail}}</strong></span>
         
        </div>       
      </b-modal>
      <b-modal size="m" ref="resetSuccessModal" hide-footer title="Passwort erfolgreich zurückgesetzt." style="text-align: center">
        <div class="d-block">
          <p><span style="float: left; margin-right: 20px;" class="checkCircle"></span>Wir haben Ihnen eine E-Mail an <strong>{{resetpwdmail}}</strong> gesendet.
          </p>
        </div>       
      </b-modal>
      <b-modal size="m" ref="resetModal" hide-footer title="Passwort zurücksetzen" style="text-align: center">
        <div class="d-block morePadding">
          <div class="row">
            <label for="resetuserName">Benutzername</label><br/>
            <input type="text" id="resetuserName" required v-model="resetuserName" autocomplete="username" />
          </div>
          <div class="row">
            <label for="resetpwdmail">E-Mail-Adresse</label><br/>
            <input id="resetpwdmail" required v-model="resetpwdmail" type="email" />
          </div>
          <div v-if="showResetPwdError" style="margin-top: 10px;color:rgb(246, 168, 0);">Der Benutzername ist nicht vorhanden oder passt nicht zur angegebenen E-Mail-Adresse.<br/>
            Überprüfen Sie Ihre Unterlagen oder kontaktieren Sie den Kundensupport.</div>
          <br/>
          <div class="modalFooter">
            <b-button @click="resendPwdMailFn" class="modalButton">Passwort zurücksetzen</b-button>
            <b-button class="rightModalButton" @click="$refs.resetModal.hide()">abbrechen</b-button>
          </div>  
        </div>       
      </b-modal>
    </div>
    
    <Footer v-if="route!='cms' && route!='cms-base' && route!='wizard' && route!='home' && route!='login' && route !='createPassword'  && route !='register'" :count = "cartCount"/>
  </div>
</template>

<script>

import {answerStorage, dataSettingStorage, kvSettingStorage, priceSettingStorage} from '@/utils/localstorage.js'
import Home from '@/components/Home'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { mapGetters, mapState } from 'vuex'
import { AUTH_LOGOUT } from '@/store/actions/auth'
import {EventBus} from '@/services/EventBus.js'
import CryptoJS from 'crypto-js'
import DataService from '@/services/DataService'

export default {
  name: 'app',
  components: {
    Home, Header, Footer
  },
  data: function () {
    return {
      cartCount: 0, 
      answerObjectForHeader: answerStorage.fetch(),
      sidebar: 'closed',
      lastUserDataTime: '',
      userData: {},
      eventBusObject: EventBus,
      gelesen: 0,
      institutionObject: {},
      fullVersionToken: '',
      fullVersionUserId: 0,
      applySuccessMail: '',
      resetpwdmail: '',
      resetuserName: '',
      checked: false,
      showResetPwdError: false,
    }
  },
  created () {
    this.$ga.set('dimension2', CryptoJS.MD5(CryptoJS.SHA1(this.getProfile)))
    // this.$ga.set('dimension2', this.getProfile)
    // wartet auf einen Aufruf von einer Child-Komponente:
    this.$on('changeCartCount', (number) => {
      this.cartCount = number
    })
    this.eventBusObject.$on('showApplyModal', (userid, token) => {
      this.fullVersionToken = token
      this.fullVersionUserId = userid
      this.$refs.applyModal.show()
    })
    // wartet auf einen Aufruf von einer Child-Komponente:
    this.$on('updateHeader', (answerObject) => {
      this.answerObjectForHeader = answerObject
      
    })
    this.$on('toggleSidebar', (status) => {
     
      this.sidebar = status
      
    })
    this.eventBusObject.$on('loggedIn', ($event) => {
      this.getData()
    })
    this.eventBusObject.$on('tokenInvalid', ($event) => {
      this.$refs.tokenInvalidModal.show()
    })
    this.eventBusObject.$on('autologout', ($event) => {
      this.$refs.autologoutModal.show()
    })
    this.eventBusObject.$on('resetPwd', ($event) => {
      this.resetuserName = ''
      this.resetpwdmail = ''
      this.$refs.resetModal.show()
    })
    this.getData()
  },
  methods: {
    clickSomewhere () {
      if (this.sidebar !== 'closed') {
        this.sidebar = 'closed'
      }
    },
    logout () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login').catch(err => {}))
      this.lastUserData = {}
      this.lastUserDataTime = ''
      this.eventBusObject.$emit('loggedOut')
    },
    getData () {
      const self = this
      
      if (this.isAuthenticated) {
         
          DataService.getUserData(this.getUserId).then((response) => {
            self.userData = response.data.Benutzer[0]
            self.institutionObject = response.data
            if (self.institutionObject.DatenTransferiert == 0) {
              self.saveDataToDB(false)
            }
            if (self.userData.Gelesen == 0) {
              this.gelesen = 0
              self.$refs.dataTransferModal.show()
            }
          }).catch((err) => {
            // handle error
            console.log(err)
          })
      }
    },
    closeAndLogout () {
      this.$refs.tokenInvalidModal.hide()
      this.$nextTick().then(() => {
         this.logout()
      })
    },
    saveDataToDB(close) {
      if (this.institutionObject.DatenTransferiert == 0) {
        var kvData = kvSettingStorage.fetch()
        var kvDataNotNull = false
        for (var i = 0; i < kvData.length; i++) {
          kvData[i].Krankenkassenid = kvData[i].id
          kvData[i].id = null
          kvDataNotNull = true
        }
        var priceData = priceSettingStorage.fetch()
        if (kvDataNotNull) {
          DataService.setOwnKVs(kvData, this.getInstitution)
        }
        if (Object.keys(priceData).length > 0) {
          DataService.setPrices(priceData, this.getInstitution)
        }
      
        var dataStorageObject = dataSettingStorage.fetch()
        this.institutionObject.DatenTransferiert = 1
        this.institutionObject.PdfPasswort = dataStorageObject.PdfPassword
        this.institutionObject.Fax = dataStorageObject.Fax
        this.institutionObject.Webseite = dataStorageObject.Webseite
      } 
      DataService.setInstitutionData(this.institutionObject)

      this.userData.Gelesen = this.gelesen
      DataService.setUserData(this.userData)
      if (close) {
        this.$refs.dataTransferModal.hide()
      }
      
    },
    applyForFullVersion: function () {
      this.$refs.applyModal.hide()
     
      DataService.applyForFullversionDS(this.fullVersionUserId, this.fullVersionToken).then((response) => {
        this.$refs.applySuccessModal.show()
        this.applySuccessMail = response.data.Mail
        this.eventBusObject.$emit('applySuccessful')
        this.eventBusObject.$emit('loggedIn') // reload header
      }).catch((err) => {
        console.log(err)
        
      })
    },
    resendPwdMailFn: function () {
      const self = this

      DataService.resendEmail(this.resetpwdmail, this.resetuserName, true).then((response) => {
        self.$refs.resetSuccessModal.show()
        self.$refs.resetModal.hide()
      }).catch((err) => {
        console.log(err)
        self.showResetPwdError = true
       
      })
    },
  },
  computed: {
    ...mapGetters(['getUserId','getProfile','getInstitution', 'isAuthenticated', 'isProfileLoaded', 'isAdmin', 'getRights']),
    ...mapState({
      authLoading: state => state.auth.status === 'loading',
      name: state => `${state.user.profile}`
    }),
    route: function () {
      return this.$route.name
    },
    showProductKat: function () {
      return this.getRights.indexOf('prod_kat') !== -1
    },
    showUsermanagement: function () {
      return this.getRights.indexOf('insti_nutzer') !== -1
    },
    showContactSettings: function () {
      return this.getRights.indexOf('kont_einst') !== -1
    },
    showPriceSettings: function () {
      return this.getRights.indexOf('preis_einst') !== -1
    },
    showKVSettings: function () {
      return this.getRights.indexOf('kassen_einst') !== -1
    },
    showCalcSettings: function () {
      return this.getRights.indexOf('berechn_einst') !== -1
    },
    showExportOption: function () {
      return this.getRights.indexOf('einst_export') !== -1
    },
    showImportOption: function () {
      return this.getRights.indexOf('einst_import') !== -1
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('loggedIn')
    this.eventBusObject.$off('changeCartCount')
    this.eventBusObject.$off('updateHeader')
    this.eventBusObject.$off('tokenInvalid')
    this.eventBusObject.$off('autologout')
    this.eventBusObject.$off('showApplyModal')
    this.eventBusObject.$off('resetPwd')
  }
}

</script>
