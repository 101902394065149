var STORAGE_KEY = 'answerStorage'
var CART_CATALOG_STORAGE_KEY = 'cartCatalogStorage'
var CART_PRODUCT_STORAGE_KEY = 'cartProductStorage'
var KV_STORAGE_KEY = 'kvStorage'
var PRICE_SETTING_STORAGE_KEY = 'priceStorage'
var CALC_SETTING_STORAGE_KEY = 'calcSettingStorage'
var DATA_SETTING_STORAGE_KEY = 'dataSettingStorage'
var CART_STATUS_STORAGE_KEY = 'cartStatusStorage'

export var answerStorage = {
  fetch: function () {
    var answers = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
    return answers
  },
  save: function (answers) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(answers))
  }
}

export var cartArrayStorage = {
  fetch: function () {
    // default basis and premium tabs
    var cart = JSON.parse(localStorage.getItem(CART_CATALOG_STORAGE_KEY) || 
      '[[{"products":[],"careType":0}],[{"products":[],"careType":1}],[{"products":[],"careType":2}]]')
    return cart
  },
  save: function (cart) {
    localStorage.setItem(CART_CATALOG_STORAGE_KEY, JSON.stringify(cart))
  },
  remove: function () {
    localStorage.removeItem(CART_CATALOG_STORAGE_KEY);
  }
}

export var cartProductStorage = {
  fetch: function () {
    var cart = JSON.parse(localStorage.getItem(CART_PRODUCT_STORAGE_KEY) || '[]')
    return cart
  },
  save: function (cart) {
    localStorage.setItem(CART_PRODUCT_STORAGE_KEY, JSON.stringify(cart))
  }
}


export var kvSettingStorage = {
  fetch: function () {
    var settings = JSON.parse(localStorage.getItem(KV_STORAGE_KEY) || '[]')
    return settings
  },
  save: function (settings) {
    localStorage.setItem(KV_STORAGE_KEY, JSON.stringify(settings))
  }
}

export var priceSettingStorage = {
  fetch: function () {
    var settings = JSON.parse(localStorage.getItem(PRICE_SETTING_STORAGE_KEY) || '{}')
    return settings
  },
  save: function (settings) {
    localStorage.setItem(PRICE_SETTING_STORAGE_KEY, JSON.stringify(settings))
  }
}

export var dataSettingStorage = {
  fetch: function () {
    var settings = JSON.parse(localStorage.getItem(DATA_SETTING_STORAGE_KEY) || '{}')
    return settings
  },
  save: function (settings) {
    localStorage.setItem(DATA_SETTING_STORAGE_KEY, JSON.stringify(settings))
  }
}


export var calcSettingStorage = {
  fetch: function () {
    var settings = JSON.parse(localStorage.getItem(CALC_SETTING_STORAGE_KEY) || '0')
    return settings
  },
  save: function (settings) {
    localStorage.setItem(CALC_SETTING_STORAGE_KEY, JSON.stringify(settings))
  }
}

// object for current cart index and selected section
export var cartStatusStorage = {
  fetch: function () {
    var settings = JSON.parse(localStorage.getItem(CART_STATUS_STORAGE_KEY) || '{}')
    return settings
  },
  save: function (settings) {
    localStorage.setItem(CART_STATUS_STORAGE_KEY, JSON.stringify(settings))
  }
}
