Zussammenfassung
Die Daten aus dem Versorgungsrechner und der Anamnese werden nochmal dargestellt und sind teilweise bearbeitbar.
Die geänderten Daten werden direkt im Localstorage gespeichert.
Es kann aus den Daten ein PDF entweder direkt im nächsten Tab angezeigt oder ein verschlüsseltes PDF per Mail verschickt werden.
Zum verschlüsseln muss erst das PDF-Passwort aus der Datenbank entschlüsselt werden. Das geschieht über die "Mailversand/generatePDFPsw" Schnittstelle.
Das Passwort wird dann im Klartext zusammen mit dem PDF an die "Mailversand/upload" Schnittstelle gesendet und dort wird das PDF verschlüsselt und 
anschließend noch über die "Mailversand/sendPDFMail" Schnittstelle versendet und vom Server gelöscht.
<template>
  <div v-if="showOffer" id="offer" ref="offer">
    <b-modal ref="noLastName" hide-footer title="Sie haben noch keinen Namen eingetragen">
      <div class="d-block text-center">
        Wollen Sie trotzdem fortfahren?
        <div style="margin-top: 12px">
          <b-button v-if="selectedPdf=='Doku-Pdf'" class="modalButton" @click="generateDokPdf(true); $refs.noLastName.hide()">Ja</b-button>
          <b-button v-if="selectedPdf=='Doku-Email'" class="modalButton" @click="generateDokPdf(false); $refs.noLastName.hide()">Ja</b-button>
          <b-button v-if="selectedPdf=='Customer-Pdf'" class="modalButton" @click="generateCustomerPdf(true); $refs.noLastName.hide()">Ja</b-button>
          <b-button v-if="selectedPdf=='Customer-Email'" class="modalButton" @click="generateCustomerPdf(false); $refs.noLastName.hide()">Ja</b-button>
          <a href="#offerPersonData"><b-button class="rightModalButton" @click="$refs.noLastName.hide()">Nein</b-button></a>
        </div>
      </div>
    </b-modal>
    <div class='breadcrumb'>
      <!-- ><h2 v-if="currentWizardStep === 0">{{questionsComplete[currentWizardStep].segmentName}}</h2> -->
      <h2>Zusammenfassung</h2>
      <div class='breadcrumbArrow'>
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>
    <div class="offerSectionContainer" >
      <button :class="'inlineEditingButton ' + inputIsInEditingMode()" @click="setSectionInEditingMode()">Bearbeiten</button>
      <button :class="'inlineSaveButton ' + inputIsInEditingMode()" @click="saveAnswers()">Speichern</button>
      <h2>Beratung</h2>
      <div class="offerSection">
        <div>
          <span>Art</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.anamneseType">
            <option value="AT1">Erstberatung</option>
            <option value="AT2">Folgeberatung</option>
            <option value="AT3">Produktwechsel</option>
          </select>
          <span>Zuzahlungsbefreit</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.gebuehr">
            <option value="GE1">Ja</option>
            <option value="GE2">Nein</option>
          </select>

          <span>Beraten durch:</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.member">

          <span>Stellvertreter</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.representative">
            <option value="R1">Ja</option>
            <option value="R2">Nein</option>
          </select>

          <div style="width: 100%" v-if="answers.representative === 'R1'">

          <span>Art</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.representative_type">
            <option>Angehörige/Familie</option>
            <option>Nachbar/Bekannter</option>
            <option>Pflegedienst</option>
            <option>Sonstige</option>
          </select>

          <span v-if="answers.representative_type === 'Sonstige'">Beschreibung der Art</span>
          <input v-if="answers.representative_type === 'Sonstige'" v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.custom_representative_type">

          <span>Vorname</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.representative_givenName">

          <span>Nachname</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.representative_lastname">

          <span>Telefon</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.representative_phone">

          <span>Email</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.representative_email">

          </div>

          <span>Erfahrung mit Inkontinenzprodukten</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.experience">
            <option value="E1">Ja (Produkt bekannt)</option>
            <option value="E2">Ja (Produkt unbekannt)</option>
            <option value="E3">Nein</option>
          </select>
          <span v-if="answers.experience === 'E1'">Produkte</span>
          <input v-if="answers.experience === 'E1'" v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.equivalence">
          <span>Krankenkasse</span>
          <input type="text" v-model="answers.krankenkasse.Kasse" disabled>
        </div>
      </div>
    </div>
    <div class="offerSectionContainer" style="background: #F7F7F8">
      <button :class="'inlineEditingButton ' + inputIsInEditingMode()" @click="setSectionInEditingMode()">Bearbeiten</button>
      <button :class="'inlineSaveButton ' + inputIsInEditingMode()" @click="saveAnswers()">Speichern</button>
      <h2 id="offerPersonData">Persönliche Daten <span v-if="answers.lastname === '' || answers.lastname === undefined" style="color: red; display: block; font-size: 18px; font-weight: bold;">HINWEIS: Es wurde noch kein Name hinterlegt!</span></h2>
      <div class="offerSection">
        <div>
          <span>Anrede</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.gender">
            <option value="M">Herr</option>
            <option value="F">Frau</option>
            <option value="K">Keine Angabe</option>
          </select>
          <span>Vorname</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.givenname">
          <span>Nachname</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.lastname">
          <span>Geburtsdatum</span>
          <span style="margin-top: 0px; font-size: 16px; color: #222">{{birthdateInRightFormat}}</span>
          <span>E-Mail</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="email" v-model="answers.email">
        </div>
        <div class="offerRight">
          <span>Telefon</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.telephone">
          <span>Straße</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.street">
          <span>PLZ</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.plz">
          <span>Wohnort</span>
          <input v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" type="text" v-model="answers.city">
        </div>
      </div>
    </div>
    <div class="offerSectionContainer">
      <button :class="'inlineEditingButton ' + inputIsInEditingMode()" @click="setSectionInEditingMode()">Bearbeiten</button>
      <button :class="'inlineSaveButton ' + inputIsInEditingMode()" @click="saveAnswers()">Speichern</button>
      <h2>Anamnese</h2>
      <div class="offerSection">
        <div>
          <h3>Inkontinenzart</h3>
          <span>Inkontinenz</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.inkontinenz">
            <option value="I1">Urin</option>
            <option value="I2">Stuhl (flüssig)</option>
            <option value="I3">Stuhl (weich/fest)</option>
            <option value="I4">Urin/Stuhl</option>
          </select>
          <span>Verlust</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.verlust">
            <option value="V1">Eine kleine Menge</option>
            <option value="V2">Eine große Menge</option>
            <option value="V3">Jeweils komplette Entleerung</option>
          </select>

          <span>Tag/Nacht</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.wann">
            <option value="W1">Tag/Nacht gleich</option>
            <option value="W2">Tagsüber deutlich mehr</option>
            <option value="W3">Nachts deutlich mehr</option>
          </select>

          <h3>Einflussfaktoren</h3>
          <span>Medikamente</span>
          <span v-if="inputIsInEditingMode() === 'disabled' ? true : false" style="margin-top: 0px; font-size: 16px; color: #222">{{renderMultiSelectArray('medikamente')}}</span>
          <div style="width: 100%; padding: 6px 0px 0px 20px;" v-if="inputIsInEditingMode() === 'disabled' ? false : true">
            <b-button v-bind:class='multiselectIsActive("M1", "medikamente")' @click='saveMultiAnswer("M1", "medikamente")'>keine</b-button>
            <b-button v-bind:class='multiselectIsActive("M2", "medikamente")' @click='saveMultiAnswer("M2", "medikamente")'>Blutdrucksenker</b-button>
            <b-button v-bind:class='multiselectIsActive("M3", "medikamente")' @click='saveMultiAnswer("M3", "medikamente")'>Diabetes-Medikamente</b-button>
            <b-button v-bind:class='multiselectIsActive("M4", "medikamente")' @click='saveMultiAnswer("M4", "medikamente")'>Abführmittel</b-button>
          </div>
          
          <span>Hilfsmittel</span>
          <span v-if="inputIsInEditingMode() === 'disabled' ? true : false" style="margin-top: 0px; font-size: 16px; color: #222">{{renderMultiSelectArray('hilfsmittel')}}</span>
          <div style="width: 100%; padding: 6px 0px 0px 20px;" v-if="inputIsInEditingMode() === 'disabled' ? false : true">
            <b-button v-bind:class='multiselectIsActive("Hi1", "hilfsmittel")' @click='saveMultiAnswer("Hi1", "hilfsmittel")'>nichts</b-button>
            <b-button v-bind:class='multiselectIsActive("Hi2", "hilfsmittel")' @click='saveMultiAnswer("Hi2", "hilfsmittel")'>Blasenkatheter</b-button>
            <b-button v-bind:class='multiselectIsActive("Hi3", "hilfsmittel")' @click='saveMultiAnswer("Hi3", "hilfsmittel")'>Sondennahrung</b-button>
          </div>
          
          <span>weitere Einflussfaktoren</span>
          <span v-if="inputIsInEditingMode() === 'disabled' ? true : false" style="margin-top: 0px; font-size: 16px; color: #222">{{renderMultiSelectArray('weitereEinflussfaktoren')}}</span>
          <div style="width: 100%; padding: 6px 0px 0px 20px;" v-if="inputIsInEditingMode() === 'disabled' ? false : true">
            <b-button v-bind:class='multiselectIsActive("Ef1", "weitereEinflussfaktoren")' @click='saveMultiAnswer("Ef1", "weitereEinflussfaktoren")'>keine</b-button>
            <b-button v-bind:class='multiselectIsActive("Ef2", "weitereEinflussfaktoren")' @click='saveMultiAnswer("Ef2", "weitereEinflussfaktoren")'>Demenz</b-button>
            <b-button v-bind:class='multiselectIsActive("Ef3", "weitereEinflussfaktoren")' @click='saveMultiAnswer("Ef3", "weitereEinflussfaktoren")'>OP</b-button>
          </div>
        </div>
        <div class="offerRight">
          <h3>Mobilität</h3>
          <span>Mobilität</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.mobil">
            <option value="Mo1">Mobil</option>
            <option value="Mo2">Eingeschränkt mobil</option>
            <option value="Mo3">Rollstuhl</option>
            <option value="Mo4">Bettlägerig</option>
          </select>

          <span>Toilettengang</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.toilette">
            <option value="T1">ja</option>
            <option value="T2">nein</option>
          </select>

          <span>Hilfe</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.versorgung">
            <option value="H1">Ja</option>
            <option value="H2">Nein</option>
          </select>

          <span>Pflegegrad</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.grad">
            <option value="G1">Nein</option>
            <option value="G2">1</option>
            <option value="G3">2</option>
            <option value="G4">3</option>
            <option value="G5">4</option>
            <option value="G6">5</option>
          </select>

          <h3>Bedürfnisse</h3>
          <span>Flüssigkeit</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.trink">
            <option value="X1">Wenig (&lt;1l)</option>
            <option value="X2">Normal (1 - 1,5l)</option>
            <option value="X3">Viel (1,5 - 2l)</option>
            <option value="X4">Sehr Viel (&gt;2l)</option>
          </select>
          <span>Wechsel</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.wechseln">
            <option value="We1">1x täglich</option>
            <option value="We2">bis 3x täglich</option>
            <option value="We3">bis 6x täglich</option>
            <option value="We4">mehr als 6x täglich</option>
          </select>
          <span>Hautprobleme</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.haut">
            <option value="Ha1">Ja</option>
            <option value="Ha2">Nein</option>
          </select>
          <span>Konfektionsgröße</span>
          <select v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false" v-bind:class="inputIsInEditingMode()" v-model="answers.groesse">
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
          </select>
          <span>wichtige Kiriterien</span>
          <span v-if="inputIsInEditingMode() === 'disabled' ? true : false" style="margin-top: 0px; font-size: 16px; color: #222">{{renderMultiSelectArray('important')}}</span>
          <div style="width: 100%; padding: 6px 0px 0px 20px;" v-if="inputIsInEditingMode() === 'disabled' ? false : true">
            <b-button v-bind:class='multiselectIsActive("I1", "important")' @click='saveMultiAnswer("I1", "important")'>Tragekomfort</b-button>
            <b-button v-bind:class='multiselectIsActive("I2", "important")' @click='saveMultiAnswer("I2", "important")'>Diskretion</b-button>
            <b-button v-bind:class='multiselectIsActive("I3", "important")' @click='saveMultiAnswer("I3", "important")'>Saugleistung</b-button>
            <b-button v-bind:class='multiselectIsActive("I4", "important")' @click='saveMultiAnswer("I4", "important")'>Hautverträglichkeit</b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="offerSectionContainer"  style="background: #F5F5F6">
      <button :class="'inlineEditingButton ' + inputIsInEditingMode()" @click="setSectionInEditingMode()">Bearbeiten</button>
      <button :class="'inlineSaveButton ' + inputIsInEditingMode()" @click="saveAnswers()">Speichern</button>
      <h2>Zusätzliche Informationen</h2>
      <div class="offerSection">
        <div class="offerLeft">
          <h3>Inkontinenzart</h3>
          <span>
            <b-form-textarea v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false"  v-model="answers.extra2" :rows="3" :max-rows="3">
            </b-form-textarea>
          </span>
          <h3>Einflussfaktoren</h3>
          <span>
            <b-form-textarea v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false"  v-model="answers.extra3" :rows="3" :max-rows="3">
            </b-form-textarea>
          </span>
        </div>
        <div class="offerRight">
          <h3>Mobilität</h3>
          <span>
            <b-form-textarea v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false"  v-model="answers.extra4" :rows="3" :max-rows="3">
            </b-form-textarea>
          </span>
          <h3>Bedürfnisse</h3>
          <span>
            <b-form-textarea v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false"  v-model="answers.extra5" :rows="3" :max-rows="3">
            </b-form-textarea>
          </span>
        </div>
      </div>
      <div class="offerSection">
        <div style="width: 90%">
          <h3>Allgemein</h3>
          <span>
            <b-form-textarea v-bind:disabled="inputIsInEditingMode() === 'disabled' ? true : false"  v-model="answers.extra6" :rows="3" :max-rows="3">
            </b-form-textarea>
          </span>
        </div>
      </div>
    </div>

    <div>
      <div v-for="(baseOrPremiumTab, basekey) in cartArray" :key="basekey">
        <div class="offerTabContainer"  v-for="(productTab, key) in filterNullEntries(baseOrPremiumTab)" :key="key">
          <button v-if="showVersorgung" class="inlineEditingButton" @click="jumptoVersorgungsrechner(basekey, key)">Bearbeiten</button>
          <h2 v-if="basekey==0"> Medizinisch notwendige Versorgung {{key + 1}}:</h2>
          <h2 v-if="basekey==1"> Wunschversorgung {{key + 1}}:</h2>
          <h2 v-if="basekey==2"> Wunschversorgung / Freiverkauf {{key + 1}}:</h2>
          <table >
            <tr>
              <th>
                System
              </th>
              <th>
                Produkt
              </th>
              <th>
              </th>
              <th>
                Zeitraum
              </th>
              <th>
                Konfektions-<br/>
                grösse
              </th>
              <th>
                Packungen
              </th>
              <th>
                Stück /<br/>
                Packung
              </th>
              <th style="text-align: right">
                Preis
              </th>
            </tr>
            <tr v-for="(product, key) in productTab['products']" :key="key" >
              <td>{{products[product.parentProductId]['Anwendungstyp']}}</td>
              <td><strong>{{products[product.parentProductId].Produktbezeichnung}}</strong></td>
              <td><span :class="products[product.parentProductId]['PremiumBasis']">{{products[product.parentProductId]['PremiumBasis']}}</span></td>
              <td>{{product.times[product.selectedTime]}}</td>
              <td>{{product.selectedSize}}</td>
              <td>{{product.monthlyPackages}}</td>
              <td>{{product.pieces}}</td>
              <td style="text-align: right">{{formatFloatToString(product.totalPrice)}} €</td>
            </tr>
          </table>
          <h3 v-if="productTab['careType'] === 0">Medizinisch notwendige Versorgung <span :class="colorForPrice(productTab['totalPrice'],productTab['careType'])">{{formatFloatToString(productTab['totalCarePrice'])}} €</span></h3>
          <h3 v-if="productTab['careType'] === 1">Wunschversorgung <span :class="colorForPrice(productTab['totalPrice'],productTab['careType'])">{{formatFloatToString(productTab['totalCarePrice'])}} €</span></h3>
          <h3 v-if="productTab['careType'] === 2">Freiverkauf <span :class="colorForPrice(productTab['totalPrice'],productTab['careType'])">{{formatFloatToString(productTab['totalCarePrice'])}} €</span></h3>
          <span v-if="showVersorgung" style="float: right; text-decoration: underline" :id="'price'+key" @click="jumptoVersorgungsrechner(basekey, key)">Kalkulation anzeigen</span>
        </div>
      </div>
    </div>

     <div class="offerButtonsContainer">
    <!-- <b-button v-if="showDeleteButton" @click="deleteAllUserData" >Anamnesedaten & Angebote löschen</b-button><br /><br/> -->
      <div>
        <h2>Dokumentation</h2>
        <b-button v-if="dataSettingObject.PdfPasswort !== '' && dataSettingObject.PdfPasswort !== undefined && showPDFSendbutton && institutionData.Testphase != 1" @click="showGenerateDialog('Doku', false)" >per Email versenden</b-button>
        <b-button v-if="showPDFSendbutton" class="pdfAnzeigen" @click="showGenerateDialog('Doku', true)" >als PDF anzeigen</b-button>
        <p v-if="institutionData.Testphase === 0">Die Dokumentation (Kundendaten, Anamnese und Versorgungsvorschlag) wird in einem PDF generiert und an Ihre Account Email-Adresse geschickt.</p>
        <p style="color: orangered" v-else>Da Sie sich in der Demophase befinden, wird bei der PDF Erstellung der Vorname und Nachname des Kunden in "Max Mustermann" abgeändert.</p>
        <p style="color: red" v-if="institutionData.Testphase === 0 && (dataSettingObject.PdfPasswort === '' || dataSettingObject.PdfPasswort === undefined)">Der Versand per E-Mail ist nicht möglich, da Sie noch kein Passwort zur Verschlüsselung in den Kontaktdaten angegeben haben.</p>

        <p v-if="pdfDokuResponseStatus === 'pdfGenerated'"><span style="color: green">&#10003;</span><i>Das PDF wurde erfolgreich generiert</i></p>
        <p v-if="pdfDokuResponseStatus === 'pdfNotGenerated'"><span style="color: red">&#10005;</span><i>Es gab einen Fehler beim Erstellen des PDFs</i></p>
        <p v-if="pdfDokuMailResponseStatus === 'pdfPosted'"><span style="color: green">&#10003;</span><i>Das PDF wurde erfolgreich versendet</i></p>
        <p v-if="pdfDokuMailResponseStatus === 'pdfNotPosted'"><span style="color:red">&#10005;</span><i>Es gab einen Fehler beim Versand des PDFs</i></p>
        <p v-if="pdfDokuDeleteResponseStatus === 'pdfDeleted'"><span style="color: green">&#10003;</span><i>Das PDF wurde erfolgreich gelöscht</i></p>
        <p v-if="pdfDokuDeleteResponseStatus === 'pdfNotDeleted'"><span style="color:red">&#10005;</span><i>Es gab einen Fehler beim Löschen des PDFs</i></p>
      </div>
      <div>
        <h2>Versorgungsvorschlag</h2>
        <b-button v-if="dataSettingObject.PdfPasswort !== '' && dataSettingObject.PdfPasswort !== undefined && showPDFSendbutton && institutionData.Testphase != 1" @click="showGenerateDialog('Customer', false)">per Email versenden</b-button>
        <b-button v-if="showPDFSendbutton" @click="showGenerateDialog('Customer', true)">als PDF anzeigen</b-button>
        <p v-if="institutionData.Testphase === 0">Der Versorgungsvorschlag für den Kunden inkl. Mehrkostenübernahmeerklärung wird in einem PDF generiert und an Ihre Account Email-Adresse geschickt.</p>
        <p style="color: orangered" v-else>Da Sie sich in der Demophase befinden, wird bei der PDF Erstellung der Vorname und Nachname des Kunden in "Max Mustermann" abgeändert.</p>
        <p style="color: red" v-if="institutionData.Testphase === 0 && (dataSettingObject.PdfPasswort === '' || dataSettingObject.PdfPasswort === undefined)">Der Versand per E-Mail ist nicht möglich, da Sie noch kein Passwort zur Verschlüsselung in den Kontaktdaten angegeben haben.</p>

        <p v-if="pdfResponseStatus === 'pdfGenerated'"><span style="color: green">&#10003;</span><i>Das PDF wurde erfolgreich generiert</i></p>
        <p v-if="pdfResponseStatus === 'pdfNotGenerated'"><span style="color:red">&#10005;</span><i>Es gab einen Fehler beim Erstellen des PDFs</i></p>
        <p v-if="pdfMailResponseStatus === 'pdfPosted'"><span style="color: green">&#10003;</span><i>Das PDF wurde erfolgreich versendet</i></p>
        <p v-if="pdfMailResponseStatus === 'pdfNotPosted'"><span style="color:red">&#10005;</span><i>Es gab einen Fehler beim Versand des PDFs</i></p>
        <p v-if="pdfDeleteResponseStatus === 'pdfDeleted'"><span style="color: green">&#10003;</span><i>Das PDF wurde erfolgreich gelöscht</i></p>
        <p v-if="pdfDeleteResponseStatus === 'pdfNotDeleted'"><span style="color:red">&#10005;</span><i>Es gab einen Fehler beim Löschen des PDFs</i></p>
      </div>
    </div>
    <!-- <router-link v-if="!showDeleteButton" to="/"><b-button>Zur Startseite</b-button></router-link> -->
  </div>
  <div class="offerSectionContainer"  v-else>Ohne ausgewählte Krankenkasse kann kein vernünftiges Angebot erstellt werden</div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/2.3.2/jspdf.plugin.autotable.js"></script>
<script>

import DataService from '@/services/DataService'
import {generateKVPauschale, generateSummary, formatFloatToStringUtil, generateRecommKeyUtil, getMwst, generateExtraAmount, birthdateInRightFormatUtil} from '../utils/util.js'
import {generateDokuEmail, generateCustomerPdfUtil} from '../utils/pdfgenerator.js'
import axios from 'axios'
import { mapGetters } from 'vuex'

// import html2canvas from 'html2canvas'

import {cartArrayStorage, answerStorage, cartStatusStorage } from '../utils/localstorage.js'
export default {
  name: 'offer',
  data: function () {
    var answer = answerStorage.fetch()
    return {
      // current 'InktontinenzProdukt' Filter
      cartArray: [[],[]], // load cartObject after productObject is loaded from DataService
      answers: answer,
      dataSettingObject: {},
      calcSettingObject: '',
      showDeleteButton: true,
      products: {},
      wizard: {},
      summaryObject: {},
      pdfResponseStatus: '',
      pdfDokuResponseStatus: '',
      pdfMailResponseStatus: '',
      pdfDokuMailResponseStatus: '',
      pdfDokuDeleteResponseStatus: '',
      pdfDeleteResponseStatus: '',
      inEditingMode: false,
      hintText: '',
      selectedPdf: '',
      accountEmail: '',
      institutionData: {}
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getUserId', 'isAuthenticated', 'isProfileLoaded', 'getRights']),
    showOffer() {
      return this.answers.krankenkasse != null
    },
    footerText() {
      var text = '<p>PAUL HARTMANN AG</p><p>Paul-Hartmann-Strasse 12</p><p>89522 Heidenheim</p><p>Postfach 1420</p><p>89504 Heidenheim</p><p>Deutschland</p>'
      text = text + '<br /><p>Telefon: +49 7321 36-0</p><p>Telefax: +49 7321 36-3636</p><p>www.hartmann.de</p<br/><p>Vorstand: Andreas Joehle (Vorsitzender), Dr. Raymund Heinen, Michel Kuehn, Stephan Schulz</p>'
      text = text + '<p>Vorsitzender des Aufsichtsrats: Fritz-Jürgen Heckmann.</p><p>Sitz Heidenheim</p><p>Amtsgericht Ulm HRB 661090</p><p>WEEE-Reg.Nr.: DE 26216897</p>'
      return text
    },
    birthdateInRightFormat: function () {
      return birthdateInRightFormatUtil(this.answers['birthdate'])
    },
    showPDFSendbutton: function () {
      return this.getRights.indexOf('pdf_vers') !== -1
    },
    showPDFShowbutton: function () {
      return this.getRights.indexOf('pdf_anzeig') !== -1
    },
    showVersorgung: function () {
      return this.getRights.indexOf('versorg_aufr') !== -1
    }
  },
  created: function () {
    this.loadData()
    DataService.fetchCombinedProducts().then((response) => {
      this.products = response.data
      this.cartArray = cartArrayStorage.fetch()

    
      DataService.fetchRecommendations().then((response) => {
          var recommendations = response.data
          /** Beratung durchgeführt tracking **/ 
          var medNotwendigeProdukte = ''
          var recomKey = generateRecommKeyUtil(this.answers)
          // console.log(JSON.stringify(this.cartArray))
          var recommendationsObject = recommendations[recomKey]

          var recomProducts = recommendations[recomKey]
          
          var productsTotal = 0
          var productsFromRec = 0
          var productsFromCatalog = 0

          for (var tabKey in this.cartArray[0]) {
            for (var productKey in this.cartArray[0][tabKey].products) { 
              var product = this.cartArray[0][tabKey].products[productKey]
              productsTotal = productsTotal + 1
              this.$ga.event('Produkt für ' + recomKey, 'Med. notwendiges Produkt', product.parentProductId, product.monthlyPackages)
              medNotwendigeProdukte = medNotwendigeProdukte + ' ' + product.monthlyPackages + ' x ' + product.parentProductId + ','
              var isInRec = false
              for (var i in recomProducts) {
                if (product.parentProductId == recomProducts[i]['ids'][0]){
                  isInRec = true
                  break
                }
              }
              if (isInRec){
                this.$ga.event('Empfehlung vs Katalog ' + recomKey, 'aus Empfehlung', product.parentProductId, 1)
                productsFromRec = productsFromRec + 1
              } else {
                this.$ga.event('Empfehlung vs Katalog ' + recomKey, 'aus Katalog', product.parentProductId, 1)
                productsFromCatalog = productsFromCatalog + 1
              }
              
            }
          }
          var wunschProdukte = ''
          for (var tabKey in this.cartArray[1]) {
            for (var productKey in this.cartArray[1][tabKey].products) { 
              var product = this.cartArray[1][tabKey].products[productKey]
              productsTotal = productsTotal + 1
              this.$ga.event('Produkt für ' + recomKey, 'Wunschprodukt', product.parentProductId, product.monthlyPackages)
              wunschProdukte = wunschProdukte + ' ' + product.monthlyPackages + ' x ' + product.parentProductId + ','
              for (var i in recomProducts) {
                if (product.parentProductId == recomProducts[i]['ids'][0]){
                  isInRec = true
                  break
                }
              }
              if (isInRec){
                this.$ga.event('Empfehlung vs Katalog ' + recomKey, 'aus Empfehlung', product.parentProductId, 1)
                productsFromRec = productsFromRec + 1
              } else {
                this.$ga.event('Empfehlung vs Katalog ' + recomKey, 'aus Katalog', product.parentProductId, 1)
                productsFromCatalog = productsFromCatalog + 1
              }
            }
          }


          wunschProdukte = wunschProdukte.substring(0, wunschProdukte.length-1)
          medNotwendigeProdukte = medNotwendigeProdukte.substring(0, medNotwendigeProdukte.length-1)
          var recomKey = generateRecommKeyUtil(this.answers)
          this.$ga.event('Vorschlag erstellt', recomKey , 'Medizinisch notwendig: ' + medNotwendigeProdukte + ' Wunschversorgung: ' + wunschProdukte)


  
          var ageDifMs = Date.now() - new Date(this.answers['birthdate']).getTime()
          var ageDate = new Date(ageDifMs) // miliseconds from epoch
          var age = Math.abs(ageDate.getFullYear() - 1970)
          var kassenValue = this.answers['krankenkasse'].Kasse || 'keine Kasse'
          this.$ga.event('Beratung Krankenkasse und Alter', recomKey , kassenValue, age)
          // this.$ga.event('Beratung Krankenkasse und Alter für '+recomKey,  this.answers['krankenkasse'].Kasse , age)
          var onlyRecText = 'nur Empfehlung'
          var onlyCatText = 'nur Katalog'
          var mixed = 'gemischt'

          var text = ''
          if (productsFromRec == productsTotal) {
            text = 'nur Produkte aus der Empfehlung'
          } else if (productsFromCatalog == productsTotal) {
            text = 'nur Produkte aus dem Katalog'
          } else {
            text = 'Produkte gemischt aus Katalog und Empfehlung'
          }
          this.$ga.event('Beratung durchgeführt:' + recomKey, 'Produkte aus Empfehlung: ' + productsFromRec + ', Produkte aus Katalog: ' + productsFromCatalog, text)
          var krankenkassenText = 'keine Krankenkasse'
          if (this.answers['krankenkasse'] !== undefined && (this.answers['krankenkasse'].Kasse === 'Selbstzahler' || this.answers['krankenkasse'].Kasse === 'Private Krankenkasse')) {
            krankenkassenText = 'Selbstzahler'
          } else if (this.answers['krankenkasse'] !== undefined && this.answers['krankenkasse'].Kasse != 'Keine Krankenkasse') {
            krankenkassenText = 'Kassenversorgung'
          }
          this.$ga.event('Beratung durchgeführt:' + recomKey, 'Krankenkasse', krankenkassenText)
        }
      )
     
      
      
      
      DataService.fetchWizard().then((response) => {
        this.wizard = response.data
        this.summaryObject = generateSummary(this.wizard, this.answers)
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    }).catch((err) => {
      // handle error
      console.log(err)
    })
    this.getAccountEmail()
  },
  methods: {
    loadData: function () {
    
        var self = this
        DataService.getUserData(this.getUserId).then((response) => {
          var days = 0  
          if (response.data.Testphase) {
            days = self.calcDaysTillEnd(response.data.Testbeginn, response.data.PaketObjekt.Testphasendauer)
          }

          self.institutionData = {
            "Testphase": response.data['Testphase'],
            "DaysLeft": days
          }

          self.dataSettingObject = response.data
          if (response.data != null) {
            if (self.dataSettingObject['Ansprechpartner'] === undefined || self.dataSettingObject['Ansprechpartner'] === '') {
              self.$set(self.dataSettingObject, 'Ansprechpartner', response.data['Benutzer'][0]['Vorname'] + ' ' + response.data['Benutzer'][0]['Nachname'])
            }
            if (self.dataSettingObject['Email'] === undefined || self.dataSettingObject['Email'] === '') {
              self.$set(self.dataSettingObject, 'Email', response.data['Benutzer'][0]['Mail'])
            }
          }
          
    
              
        }).catch((err) => {
          // handle error
          console.log(err)
        })
     
    },
    calcDaysTillEnd: function (date, testdays) {
      var testDate = new Date(date)
      testDate.setDate(testDate.getDate() + testdays)
      var diff = new Date(testDate - new Date())     
      diff = parseInt((testDate-new Date())/(24*3600*1000))
    },
    renderMultiSelectArray: function (questionId) {
      if( this.summaryObject[questionId] !== undefined ) {
        var importantString = ''
        if (typeof this.summaryObject[questionId] === 'string') {
          importantString = this.summaryObject[questionId]
        } else {
          if(this.summaryObject[questionId].length === 0) {
            importantString = "keine Angaben"
          } else {
            for (var i = 0; i < this.summaryObject[questionId].length; i++) {
              if (i < this.summaryObject[questionId].length - 1) {
                importantString = importantString + this.summaryObject[questionId][i] + ', '
              } else {
                importantString = importantString + this.summaryObject[questionId][i]
              }
            }
          }
        }
        return importantString
      } else {
        return ""
      }
    },
    multiselectIsActive: function (value, id) {
      if (this.answers[id] != null && this.answers[id].indexOf(value) >= 0) {
        return 'active'
      }
    },
    saveMultiAnswer: function (key, id) {
      if (this.answers[id] != null && key != null) {
        // there are already filter set with this filterKey -> check if filterValue is in array
        var index = this.answers[id].indexOf(key)
        var newArray = this.answers[id]
        if (index >= 0) {
          // remove element from current array
          newArray.splice(index, 1)
        } else {
          // add element to current array
          if (key === 'M1' || key === "Hi1" || key === 'Ef1') {
            newArray = []
          } else {
            var searchTerm = ''
            
            if (id === 'medikamente') {
              searchTerm = 'M1'
            }
            if (id === 'hilfsmittel') {
              searchTerm = 'Hi1'
            }
            if (id === 'weitereEinflussfaktoren') {
              searchTerm = 'Ef1'
            }
            if (newArray.indexOf(searchTerm) >= 0) {
              newArray.splice(newArray.indexOf(searchTerm), 1)
            }
          }
          newArray.push(key)
        }
        this.$set(this.answers, id, newArray)
      } else {
        // no filter was set with this key -> add new array with one element
        this.$set(this.answers, id, [key])
      }
      answerStorage.save(this.answers)
      
      // this.$forceUpdate()
      
    },
    getAccountEmail: function () {
     
        DataService.getUserData(this.getUserId).then((response) => {
          //console.log(JSON.stringify(response))
          this.accountEmail = response.data.Benutzer[0].Mail
          this.calcSettingObject = response.data.Berechnungsmethode
        }).catch((err) => {
          // handle error
          console.log(err)
        })
     
    },
    deleteAllUserData: function () {
      cartArrayStorage.remove()
      answerStorage.save({})
      this.$parent.$emit('changeCartCount', 0)
      this.showDeleteButton = false
    },
    showGenerateDialog: function (usecase, asPdf) {

      var type = asPdf ? 'Pdf' : 'Email'
      var typeTracking = asPdf ? 'angezeigt' : 'verschickt'
      var usecaseTracking = usecase === 'Doku' ? 'Dokumentation' : 'Versorgungsvorschlag'
      this.selectedPdf = usecase + '-' + type
      this.$ga.event( 'PDF verschickt oder angezeigt', usecaseTracking + ' ' + typeTracking , 'Zusammenfassung ' + usecaseTracking + ' ' + typeTracking)
      if ( usecase === 'Doku' ) {
        if ( this.answers.lastname === "" || this.answers.lastname === undefined) {
          this.$refs.noLastName.show()
        } else {
          this.generateDokPdf(asPdf)
        }
      }
      if ( usecase === 'Customer' ) {
        if ( this.answers.lastname === "" || this.answers.lastname === undefined) {
          this.$refs.noLastName.show()
        } else {
          this.generateCustomerPdf(asPdf)
        }
      }
    },
    generateDokPdf: function (asPdf) {
      // eslint-disable-next-line

      var tempAnswers = JSON.parse(JSON.stringify(this.answers));
      if (this.institutionData.Testphase === 1) {
        tempAnswers["givenname"] = "Max"
        tempAnswers["lastname"] = "Mustermann"
      }
      if (!this.isIeOrEdge() && asPdf) {
        var windowReference = window.open()
      }

      var doc = generateDokuEmail(this.dataSettingObject, this.summaryObject, this.cartArray, this.products, tempAnswers, this.wizard, this.calcSettingObject, this.getExtraAmount())
      var img = new Image()
      let self = this
      img.addEventListener('load', function () {
        doc.setPage(1)
        var date = new Date()
        var newDateString = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + '-' + date.getHours() + '-' + date.getMinutes()
        let name = 'Dokumentation-' + newDateString + '.pdf' // new Date().toLocaleDateString('de-DE') + '-' + new Date().toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'}) + '.pdf'
        if (asPdf) {
          
          if (self.isIeOrEdge()){
            doc.save(name)
          } 
          else {
            // doc.save(name)
            // window.open(doc.output('bloburl'))
            if (windowReference != null) {
              windowReference.location = doc.output('bloburl')
            } else {
              window.open(doc.output('bloburl'))
            }
        
            // windowReference.location =  doc.output('dataurlnewwindow')
            // doc.output('datauri')
          }
        } else {
          var data = new FormData()
          data.append('pdfCipher', self.dataSettingObject.PdfPasswort)
          axios.post('/static/api/userdata/generatePdfPsw.php', data, {'headers': {'Authorization': 'Bearer ' + localStorage.getItem('user-token'), 'Content-Type': 'application/x-www-form-urlencoded'}})
            .then(function (response) {

              var pdf = doc.output('blob') // returns raw body of resulting PDF returned as a string as per the plugin documentation.
              // var enc = window.btoa(pdf) // encode the string because of Umlaute
              var data = new FormData()
              // To Test
              data.append('data', pdf)
              data.append('name', name)
              data.append('username', self.getProfile)

              data.append('psw', response.data.plainPsw)
             
              axios.post('/static/api/mailversand/upload.php', data, {'headers': {
                  'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
                  'Content-Type': 'multipart/form-data'
              }})
                .then(function (response) {
                  
                  var dataToSend = new FormData()
                  dataToSend.append('name', 'protected-' + name)
                  dataToSend.append('email', self.accountEmail)
                  dataToSend.append('loginName', self.getProfile)
                  dataToSend.append('subject', 'Dokumentation')
                  self.pdfDokuResponseStatus = 'pdfGenerated'
                  var text = '<p>' + self.generateEmailPreNameText() + ', </p><p> anbei die Dokumentation Ihrer letzten Inkontinenz-Beratung. </p><p>Mit freundlichen Grüßen, </p><p>das Inkontinenz Management Team</p>'
                  // text = text + '<br/>' + self.footerText
                  dataToSend.append('text', text)
                  self.sendMail(dataToSend, true, name)
                  
                })
                .catch(function (error) {
                  console.log(error)
                  self.pdfDokuResponseStatus = 'pdfNotGenerated'
                })
              
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      })
      img.src = require('../assets/img/logo.jpg')
    },
    generateCustomerPdf: function (asPdf) {
      // console.log(jsPDF().getFontList() )
      // eslint-disable-next-line

      if (!this.isIeOrEdge() && asPdf){
        var windowReference = window.open()
      }
      var tempAnswers = JSON.parse(JSON.stringify(this.answers));
      if (this.institutionData.Testphase === 1) {
        tempAnswers["givenname"] = "Max"
        tempAnswers["lastname"] = "Mustermann"
      }

      var doc = generateCustomerPdfUtil(this.dataSettingObject, this.summaryObject, this.cartArray, this.products, tempAnswers, this.calcSettingObject, this.getExtraAmount())
      var img = new Image()
      let self = this
      img.addEventListener('load', function () {
        doc.setPage(1)
        // doc.addImage(img, 'jpg', 180, 10, 20, 10)
        var date = new Date()
        var newDateString = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + '-' + date.getHours() + '-' + date.getMinutes()
        let name = 'Versorgungsvorschlag-' + newDateString + '.pdf' //new Date().toLocaleDateString('de-DE') + '-' + new Date().toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'}) + '.pdf'
        if (asPdf) {
          if (self.isIeOrEdge()){
            doc.save(name)
          } else {
            // doc.save(name)
            // window.open(doc.output('bloburl'))
            if (windowReference != null) {
              windowReference.location = doc.output('bloburl')
            } else {
              window.open(doc.output('bloburl'))
            }
            // doc.output('dataurlnewwindow')
            // doc.output('datauri')
          }
        } else {
          //doc.save('sample-file.pdf')
          var data = new FormData()
          data.append('pdfCipher', self.dataSettingObject.PdfPasswort)
          axios.post('/static/api/userdata/generatePdfPsw.php', data, {'headers': {'Authorization': 'Bearer ' + localStorage.getItem('user-token'), 'Content-Type': 'application/x-www-form-urlencoded'}})
            .then(function (response) {
                var pdf = doc.output('blob') // returns raw body of resulting PDF returned as a string as per the plugin documentation.
               // var enc = window.btoa(pdf) // encode the string because of Umlaute
                var data = new FormData()
                data.append('data', pdf)
                data.append('name', name)
                data.append('username', self.getProfile)
                data.append('psw', response.data.plainPsw)
                axios.post('/static/api/mailversand/upload.php', data, {'headers': {
                    'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
                    'Content-Type': 'multipart/form-data'
                }})
                  .then(function (response) {
                    var dataToSend = new FormData()
                    dataToSend.append('name', 'protected-' +name)
                    /* TO DO */
                    dataToSend.append('email', self.accountEmail)
                    dataToSend.append('loginName', self.getProfile)
                    dataToSend.append('subject', 'Versorgungsvorschlag')
                    var text = '<p>' + self.generateEmailPreNameText() + ', </p><p> anbei der Versorgungsvorschlag für den Kunden Ihrer letzten Inkontinenz-Beratung. </p><p>Mit freundlichen Grüßen, </p><p>das Inkontinenz Management Team</p>'
                    // text = text + '<br/>' + self.footerText
                    dataToSend.append('text', text)
                    self.pdfResponseStatus = 'pdfGenerated'
                    self.sendMail(dataToSend, false, name)
                  })
                  .catch(function (error) {
                    console.log(error)
                    self.pdfResponseStatus = 'pdfNotGenerated'
                  })
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      })
      img.src = require('../assets/img/logo.jpg')
    },
    sendMail: function (data, doku, name) {
      let self = this
      axios.post('/static/api/mailversand/sendPdfMail.php', data, {'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
            'Content-Type': 'application/x-www-form-urlencoded'
      }})
        .then(function (response) {
          if (doku)
          {
            self.pdfDokuMailResponseStatus = 'pdfPosted'
          } else {
            self.pdfMailResponseStatus = 'pdfPosted'
          }
          var dataToDelete = new FormData()
          dataToDelete.append('name', name)
          dataToDelete.append('username', self.getProfile)
          self.removePdfFromServer(dataToDelete,doku)
        })
        .catch(function (error) {
          if (doku)
          {
            self.pdfDokuMailResponseStatus = 'pdfNotPosted'
          } else {
            self.pdfMailResponseStatus = 'pdfNotPosted'
          }
          var dataToDelete = new FormData()
          dataToDelete.append('name', name)
          dataToDelete.append('username', self.getProfile)
          self.removePdfFromServer(dataToDelete,doku)
        })
    },
    getKVPauschale: function () {
      return generateKVPauschale(this.answers) + generateKVPauschale(this.answers) * getMwst()
    },
    getExtraAmount: function () {
      return generateExtraAmount(this.answers, this.getKVPauschale() / (1+getMwst()))
    },
    setSectionInEditingMode: function () {
      this.inEditingMode = true
    },
    inputIsInEditingMode: function (section) {
      if (this.inEditingMode === true) {
        return 'enabled'
      } else {
        return 'disabled'
      }
    },
    saveAnswers: function () {
      this.inEditingMode = false
      this.summaryObject = generateSummary(this.wizard, this.answers)
      answerStorage.save(this.answers)
    },
    jumptoVersorgungsrechner: function (baseOrPremiumTab, key) {
      // save statusObject for Cart to be in the right tab:
      var statusObject = {'tabIndex': key, 'baseOrPremiumTab': baseOrPremiumTab}
      cartStatusStorage.save(statusObject)
      this.$router.push('cart')
    },
    formatFloatToString (x) {
      return formatFloatToStringUtil(x)
    },
    generateHintText (totalPrice, caretype) {
      var hintText = ''
      if (totalPrice > 0 && caretype === 0) {
        hintText = 'Die KV-Pauschale deckt nicht die Gesamtkosten dieses Versorgungsvorschlags, es entstehen Mehrkosten die übernommen werden müssen.'
      } else {
        if (totalPrice <= 0 && caretype === 0) {
          hintText = 'Die KV-Pauschale deckt die Gesamtkosten dieses Versorgungsvorschlags.'
        } else if (totalPrice <= 0 && caretype === 1) {
          hintText = 'Die KV-Pauschale deckt die Gesamtkosten dieses Versorgungsvorschlags, es entstehen trotz Wunschversorgung keine Mehrkosten für den Patienten'
        } else if (totalPrice > 0 && caretype === 1) {
          hintText = 'Die KV-Pauschale deckt nicht die Gesamtkosten dieses Versorgungsvorschlags, es entstehen Mehrkosten die vom Patienten übernommen werden müssen.'
        }
      }

      if (this.getKVPauschale() === 0) {
        hintText = 'Die Gesamtkosten werden vom Patienten übernommen.'
      }
      this.hintText = hintText
    },
    colorForPrice (totalPrice, caretype) {
      var colorClass = 'tab-title-red'

      if (totalPrice > 0 && caretype === 0) {
        colorClass = 'tab-title-red'
      } else {
        colorClass = 'tab-title-green'
      }
      return colorClass
    },
    generateEmailPreNameText () {
      /* if (this.answers['gender'] === 'M'){
        return 'Sehr geehrter Herr '
      } else if (this.answers['gender'] === 'F') {
        return 'Sehr geehrte Frau '
      } */
      var anrede = ''

      if (this.dataSettingObject['Firma'] !== undefined)
      {
        anrede = this.dataSettingObject['Firma']
      }

      return 'Guten Tag ' + anrede
    },
    removePdfFromServer(data, doku) {
      let self = this
      axios.post('/static/api/mailversand/delete.php', data, {'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
            'Content-Type': 'application/x-www-form-urlencoded'
      }})
        .then(function (response) {
          if (doku)
          {
            self.pdfDokuDeleteResponseStatus = 'pdfDeleted'
          } else {
            self.pdfDeleteResponseStatus = 'pdfDeleted'
          }
        })
        .catch(function (error) {
          if (doku)
          {
            self.pdfDokuDeleteResponseStatus = 'pdfNotDeleted'
          } else {
            self.pdfDeleteResponseStatus = 'pdfNotDeleted'
          }
        })
    },
    isIeOrEdge: function () {
      var returnValue = navigator.appName == 'Microsoft Internet Explorer' || (!!window.MSInputMethodContext && !!document.documentMode) || (window.navigator && window.navigator.msSaveOrOpenBlob)
      return returnValue
    },
    filterNullEntries: function (tabArray) {
      return tabArray.filter(function (el) {
        return el.products != null && el.products.length>0
      })
    }
  }
}
</script>
