import jsPDF from 'jspdf'
// eslint-disable-next-line

const leftMargin = 14
var newRowHeight = 7
var standardFontSize = 10
var rightColumnStart = 100
var rightColumnEntryStart = 130
var rightColumnEnd = 200

var lineEndLeft = 96

var fontRef = require('@/utils/fonts/FrutigerNextLT-normal.js')
var fontRefBold = require('@/utils/fonts/FrutigerNextLT-bold.js')

export function generatePflegeAntrag ( answers) {

  //fontRef
  //fontRefBold
  var doc = new jsPDF()
  var {
    RadioButton,
    Appearance,
    CheckBox,
  } = jsPDF.AcroForm;

  
  doc.getFontList(); 
  doc.setProperties({
    title: "Pflegehilfsmittelantrag"
  })
  doc.setFont('FrutigerNextLT')


  doc.rect(122, 10, 78, 35);
  doc.setFontSize(standardFontSize - 2)
  doc.text('Stempel', 124, 13) 

  doc.setTextColor(0, 56, 129)
  var rowLine = 10
  rowLine = rowLine + newRowHeight
  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize + 14)
  doc.text('Versorgung mit zum Verbrauch', leftMargin, rowLine) 
  rowLine = rowLine + 8
  
  doc.text('bestimmten Pflegehilfsmitteln', leftMargin, rowLine)
  rowLine = rowLine + 10
  doc.setTextColor(135, 136, 138)
  doc.text('Antrag auf Kostenübernahme*', leftMargin, rowLine)
  rowLine = rowLine + newRowHeight * 1.5

 


  doc.setFontSize(standardFontSize-1)
  doc.setFont('FrutigerNextLT', 'bold')
  doc.setTextColor(0,0,0)
  doc.text('Versicherte/r | Pflegebedürftige/r', leftMargin, rowLine)
  rowLine = rowLine + newRowHeight
  if (answers['givenname'] !== undefined) {
  
    doc.setFont('FrutigerNextLT', 'normal')
    doc.setFontSize(standardFontSize-1)
    
    // first TextField is broken:
    doc.addField(addTextField(doc, leftMargin, rowLine, 0.1, 0.1, ""))

    doc.text('Name:', leftMargin, rowLine)
    doc.setFont('FrutigerNextLT', 'bold')
    
    //doc.setFontSize(standardFontSize-1)
    //doc.text( answers['lastname'], leftMargin + 30, rowLine)
    doc.addField(addTextField(doc,leftMargin+30, rowLine-3, 50, 4, answers['lastname']))
    doc.setFont('FrutigerNextLT', 'normal')
    doc.setFontSize(standardFontSize-1)
    doc.text('Vorname:', rightColumnStart, rowLine)
    doc.setFont('FrutigerNextLT', 'bold')
    doc.setFontSize(standardFontSize-1)
    //doc.text( answers['givenname'], rightColumnEntryStart, rowLine)
    doc.addField(addTextField(doc,rightColumnEntryStart, rowLine-3, 60, 4, answers['givenname']))
    rowLine = rowLine + 1
    doc.setLineWidth(0.1)
    doc.line(leftMargin, rowLine, lineEndLeft, rowLine)
    doc.line(rightColumnStart, rowLine, rightColumnEnd, rowLine)
    rowLine = rowLine + newRowHeight
  }

  doc.setFont('FrutigerNextLT', 'normal')  
  doc.setFontSize(standardFontSize-1)
  doc.text('Straße, Nr.:', leftMargin, rowLine)
  doc.setFont('FrutigerNextLT', 'bold')
  if (answers['street'] !== undefined) {
    doc.setFontSize(standardFontSize)
    //doc.text( answers['street'], leftMargin + 30, rowLine)
    doc.addField(addTextField(doc,leftMargin + 30, rowLine-3,  50, 4, answers['street']))
  } else {
    doc.addField(addTextField(doc,leftMargin + 30, rowLine-3, 50, 4, ""))
  }
  doc.setFontSize(standardFontSize-1)
  doc.setFont('FrutigerNextLT', 'normal')
  doc.text('PLZ, Ort:', rightColumnStart, rowLine)
  doc.setFont('FrutigerNextLT', 'bold')
  if (answers['plz'] !== undefined && answers['city'] !== undefined) {
    doc.setFontSize(standardFontSize)
    //doc.text( answers['plz'], rightColumnEntryStart, rowLine)
    //doc.text( answers['city'], rightColumnEntryStart + 20, rowLine)
    doc.addField(addTextField(doc,rightColumnEntryStart, rowLine-3,  60, 4, answers['plz'] + "  " + answers["city"]))
  } else {
    doc.addField(addTextField(doc,rightColumnEntryStart, rowLine-3,  60, 4, ""))
  }
  
  rowLine = rowLine + 1
  doc.setLineWidth(0.1)
  doc.line(leftMargin, rowLine, lineEndLeft, rowLine)
  doc.line(rightColumnStart, rowLine, rightColumnEnd, rowLine)
  rowLine = rowLine + newRowHeight


  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('Geburtsdatum:', leftMargin, rowLine)
  doc.setFont('FrutigerNextLT', 'bold')
  if (answers['birthdate'] !== undefined) {
    doc.setFontSize(standardFontSize)
    var date = new Date(answers['birthdate'])
    var newDateString = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
    //doc.text( newDateString, leftMargin + 30, rowLine)
    doc.addField(addTextField(doc,leftMargin + 30, rowLine-3, 50, 4, newDateString))
  } else {
    doc.addField(addTextField(doc,leftMargin + 30, rowLine-3, 50, 4, ""))
  }
  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('Telefon:', rightColumnStart, rowLine)
  doc.setFont('FrutigerNextLT', 'bold')
  if (answers['telephone'] !== undefined) {
    doc.setFontSize(standardFontSize)
    //doc.text( answers['telephone'], rightColumnEntryStart, rowLine)
    doc.addField(addTextField(doc,rightColumnEntryStart, rowLine-3, 60, 4, answers['telephone']))
  } else {
    doc.addField(addTextField(doc,rightColumnEntryStart, rowLine-3, 60, 4, ""))
  }
  rowLine = rowLine + 1
  doc.setLineWidth(0.1)
  doc.line(leftMargin, rowLine, lineEndLeft, rowLine)
  doc.line(rightColumnStart, rowLine, rightColumnEnd, rowLine)
  rowLine = rowLine + newRowHeight


  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('Versichertennummer:', rightColumnStart, rowLine)
  doc.addField(addTextField(doc,rightColumnStart + 45, rowLine-3,  50, 4,""))
  rowLine = rowLine + newRowHeight / 1.5

  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('Pflegekasse:', leftMargin, rowLine)
  doc.setFont('FrutigerNextLT', 'bold')
  if (answers['krankenkasse'] !== undefined) {
    doc.setFontSize(standardFontSize)
    //doc.text( answers['krankenkasse'].Kasse, leftMargin + 30, rowLine)
    doc.addField(addTextField(doc,leftMargin + 30, rowLine-3, 50, 4, answers['krankenkasse'].Kasse))
  } else {
    doc.addField(addTextField(doc,leftMargin + 30, rowLine-3, 50, 4, ""))
  }
  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('IK-Nummer der Versichertenkarte:', rightColumnStart, rowLine)
  doc.addField(addTextField(doc,rightColumnStart + 45, rowLine-3, 50, 4, ""))
  rowLine = rowLine + 1
  doc.setLineWidth(0.1)
  doc.line(leftMargin, rowLine, lineEndLeft, rowLine)
  doc.line(rightColumnStart, rowLine, rightColumnEnd, rowLine)
  rowLine = rowLine + newRowHeight

  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('Eine Zuzahlungsbefreiung', rightColumnStart, rowLine)
  rowLine = rowLine + newRowHeight / 1.5

  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('Pflegegrad:', leftMargin, rowLine)
 

  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize-1)
  doc.text('liegt vor?', rightColumnStart, rowLine)

  

  


  var radioGroup = new RadioButton();
  doc.addField(radioGroup);

  var radioButton1 = radioGroup.createOption("G2");
  
  radioButton1.Rect = [40, rowLine-3, 3, 3];

  doc.rect(39.5, rowLine-3.5, 4, 4);
  doc.text('1', 44, rowLine)

  var radioButton2 = radioGroup.createOption("G3");
  radioButton2.Rect = [50, rowLine-3, 3, 3];
  doc.rect(49.5, rowLine-3.5, 4, 4);
  doc.text('2', 54, rowLine)
  
  var radioButton3 = radioGroup.createOption("G4");
  radioButton3.Rect = [60, rowLine-3, 3, 3];
  doc.rect(59.5, rowLine-3.5, 4, 4);
  doc.text('3', 64, rowLine)

  var radioButton4 = radioGroup.createOption("G5");
  radioButton4.Rect = [70, rowLine-3, 3, 3];
  doc.rect(69.5, rowLine-3.5, 4, 4);
  doc.text('4', 74, rowLine)

  var radioButton5 = radioGroup.createOption("G6");
  radioButton5.Rect = [80, rowLine-3, 3, 3];
  doc.rect(79.5, rowLine-3.5, 4, 4);
  doc.text('5', 84, rowLine)

  if (answers['grad']=="G2") {
    radioButton1.AS = "/G2";
  }
  if (answers['grad']=="G3") {
    radioButton2.AS = "/G3";
  }
  if (answers['grad']=="G4") {
    radioButton3.AS = "/G4";
  }
  if (answers['grad']=="G5") {
    radioButton4.AS = "/G5";
  }
  if (answers['grad']=="G6") {
    radioButton5.AS = "/G6";
  }
  radioGroup.setAppearance(Appearance.RadioButton.Cross);
  rowLine = rowLine + 1
  doc.setLineWidth(0.1)
  doc.line(leftMargin, rowLine, lineEndLeft, rowLine)
  doc.line(rightColumnStart, rowLine, rightColumnEnd, rowLine)

  
  var radioGroupZuzahlung = new RadioButton();
  doc.addField(radioGroupZuzahlung);
  rowLine = rowLine - 1 - newRowHeight / 1.5
  var radioButtonJa = radioGroupZuzahlung.createOption("Ja");
  radioButtonJa.Rect = [rightColumnStart+40, rowLine-3, 3, 3];
  doc.rect(rightColumnStart+39.5, rowLine-3.5, 4, 4);
  doc.text('Ja (bitte Kopie mit dem Antrag einreichen)', rightColumnStart+45, rowLine)
  rowLine = rowLine + newRowHeight / 1.5

  var radioButtonNein = radioGroupZuzahlung.createOption("Nein");
  radioButtonNein.Rect = [rightColumnStart+40, rowLine-3, 3, 3];
  doc.rect(rightColumnStart+39.5, rowLine-3.5, 4, 4);
  doc.text('Nein', rightColumnStart+45, rowLine)

  if (answers['gebuehr']=="GE1") {
    radioButtonJa.AS = "/Ja";
  } else {
    radioButtonNein.AS = "/Nein";
  }
  radioGroupZuzahlung.setAppearance(Appearance.RadioButton.Cross);
  rowLine = rowLine + newRowHeight * 1.5


  /*** Ende Header */

  doc.setTextColor(0, 56, 129)
  doc.setFontSize(standardFontSize + 3)
  doc.setFont('FrutigerNextLT', 'bold')
  doc.text('Ich beantrage die Kostenübernahme für:', leftMargin, rowLine)
  rowLine = rowLine + newRowHeight / 1.5
  /*
  var radioGroupKosten = new RadioButton();
  doc.addField(radioGroupKosten);
  var radioButtonJa = radioGroupKosten.createOption("Ja");
  radioButtonJa.Rect = [leftMargin, rowLine, 3, 3];
 
  radioGroupKosten.setAppearance(AcroForm.Appearance.RadioButton.Cross);*/

  var checkBoxAntrag = new CheckBox();
  checkBoxAntrag.fieldName = "CheckBox1";
  checkBoxAntrag.Rect = [leftMargin-0.5, rowLine-0.5, 5, 5];

  checkBoxAntrag.maxFontSize = 13
  checkBoxAntrag.value = 'No'
  checkBoxAntrag.appearanceState = 'Off'
  doc.addField(checkBoxAntrag)
  doc.rect(leftMargin-0.7, rowLine-0.7, 5.5, 5.5);
  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize+1)
  rowLine = drawMultilineText(doc, "zum Verbrauch bestimmte Pflegehilfsmittel – (Produktgruppe PG 54) – bis maximal des monatlichen Höchstbetrages nach § 40 Abs. 2 SGB XI / bei Beihilfeberechtigung bis maximal der Hälfte des monatlichen Höchstbetrages nach § 40 Abs. 2 SGB XI. Darüber hinaus gehende Kosten werden von mir selbst getragen.", rowLine-newRowHeight/2, newRowHeight*0.6, leftMargin + 6)
  rowLine = rowLine + newRowHeight 
  //** table: */

  var secondRowStart = 30
  var thirdRowStart = 50
  var forthRowStart = 150
  var fifthRowStart = 170
  doc.setFont('FrutigerNextLT', 'bold')
  doc.setFontSize(standardFontSize-1)
  doc.setTextColor(0,0,0)
  doc.text('Wird', leftMargin, rowLine)
  doc.text('Anzahl', secondRowStart, rowLine)
  doc.text('Artikel', thirdRowStart, rowLine)
  doc.text('Packungs-', forthRowStart, rowLine)
  doc.text('Pflegehilfsmittel-', fifthRowStart, rowLine)
  rowLine = rowLine + newRowHeight * 0.6
  doc.text('benötigt', leftMargin, rowLine)
  doc.text('inhalt', forthRowStart, rowLine)
  doc.text('positionsnummer', fifthRowStart, rowLine)
  rowLine = rowLine + 1
  doc.setFont('FrutigerNextLT', 'normal')
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)


  rowLine = rowLine + newRowHeight * 0.6
  doc.addField(addMiniCheckBox(doc,leftMargin+0.5, rowLine, "CheckMini1"))

  //doc.rect(leftMargin+0.3, rowLine-2.7, 3.3, 3.3);
  doc.circle(leftMargin+1.5, rowLine-1.5, 1.5);
  doc.addField(addTextField(doc, secondRowStart, rowLine - 4,  18, 4, ""))
  doc.line(secondRowStart, rowLine, thirdRowStart-2, rowLine)
  doc.text('Saugende Bettschutzeinlagen (Einmalgebrauch)', thirdRowStart, rowLine)
  doc.text('25 Stück', forthRowStart, rowLine)
  doc.text('54.45.01.0001', fifthRowStart, rowLine)
  rowLine = rowLine + 1
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)

  rowLine = rowLine + newRowHeight * 0.6

  doc.addField(addMiniCheckBox(doc,leftMargin+0.5, rowLine, "CheckMini2"))
  doc.circle(leftMargin+1.5, rowLine-1.5, 1.5);
  doc.addField(addTextField(doc,secondRowStart, rowLine -4,  18, 4, ""))
  doc.line(secondRowStart, rowLine, thirdRowStart-2, rowLine)
  doc.text('Einmalhandschuhe (puderfrei)', thirdRowStart, rowLine)
  doc.text('100 Stück', forthRowStart, rowLine)
  doc.text('54.99.01.1001', fifthRowStart, rowLine)
  doc.text('XS', thirdRowStart+48, rowLine)
  doc.addField(addMiniCheckBox(doc,thirdRowStart+54, rowLine+0.5, "CheckMiniXS"))
  doc.circle(thirdRowStart+55, rowLine-1, 1.5);
  doc.text('S', thirdRowStart+59, rowLine)
  doc.addField(addMiniCheckBox(doc,thirdRowStart+63, rowLine+0.5, "CheckMiniS"))
  doc.circle(thirdRowStart+64, rowLine-1, 1.5);
 
  doc.text('M', thirdRowStart+68, rowLine)
  doc.addField(addMiniCheckBox(doc,thirdRowStart+72, rowLine+0.5, "CheckMiniM"))
  doc.circle(thirdRowStart+73, rowLine-1, 1.5);
  doc.text('L', thirdRowStart+78, rowLine)
  doc.addField(addMiniCheckBox(doc,thirdRowStart+82, rowLine+0.5, "CheckMiniL"))
  doc.circle(thirdRowStart+83, rowLine-1, 1.5);
  doc.text('XL', thirdRowStart+88, rowLine)
  doc.addField(addMiniCheckBox(doc,thirdRowStart+94, rowLine+0.5, "CheckMiniXL"))
  doc.circle(thirdRowStart+95, rowLine-1, 1.5);
  rowLine = rowLine + 1
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)

  rowLine = rowLine + newRowHeight * 0.6

  doc.addField(addMiniCheckBox(doc,leftMargin+0.5, rowLine, "CheckMini22"))
  doc.circle(leftMargin+1.5, rowLine-1.5, 1.5);
  doc.addField(addTextField(doc,secondRowStart, rowLine -4,  18, 4, ""))
  doc.line(secondRowStart, rowLine, thirdRowStart-2, rowLine)
  doc.text('Mundschutz (Einmalgebrauch)', thirdRowStart, rowLine)
  doc.text('50 Stück', forthRowStart, rowLine)
  doc.text('54.99.01.2001', fifthRowStart, rowLine)
  rowLine = rowLine + 1
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)

  rowLine = rowLine + newRowHeight * 0.6
  doc.addField(addMiniCheckBox(doc,leftMargin+0.5, rowLine, "CheckMini3"))
  doc.circle(leftMargin+1.5, rowLine-1.5, 1.5);
  doc.addField(addTextField(doc,secondRowStart, rowLine -4,  18, 4, ""))
  doc.line(secondRowStart, rowLine, thirdRowStart-2, rowLine)
  doc.text('Schutzschürzen (Einmalgebrauch, aus wasserabweisender Folie)', thirdRowStart, rowLine)
  doc.text('100 Stück', forthRowStart, rowLine)
  doc.text('54.99.01.3001', fifthRowStart, rowLine)
  rowLine = rowLine + 1
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)

  rowLine = rowLine + newRowHeight * 0.6
  doc.addField(addMiniCheckBox(doc,leftMargin+0.5, rowLine, "CheckMini4"))
  doc.circle(leftMargin+1.5, rowLine-1.5, 1.5);
  doc.addField(addTextField(doc,secondRowStart, rowLine -4,  18, 4, ""))
  doc.line(secondRowStart, rowLine, thirdRowStart-2, rowLine)
  doc.text('Händedesinfektionsmittel', thirdRowStart, rowLine)
  doc.text('500 ml', forthRowStart, rowLine)
  doc.text('54.99.02.0001', fifthRowStart, rowLine)
  rowLine = rowLine + 1
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)

  rowLine = rowLine + newRowHeight * 0.6
  doc.addField(addMiniCheckBox(doc,leftMargin+0.5, rowLine, "CheckMini5"))
  doc.circle(leftMargin+1.5, rowLine-1.5, 1.5);
  doc.addField(addTextField(doc,secondRowStart, rowLine -4,  18, 4, ""))
  doc.line(secondRowStart, rowLine, thirdRowStart-2, rowLine)
  doc.text('Flächendesinfektionsmittel', thirdRowStart, rowLine)
  doc.text('500 ml', forthRowStart, rowLine)
  doc.text('54.99.02.0002', fifthRowStart, rowLine)
  rowLine = rowLine + 1
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)

  /** ende table */
  rowLine = rowLine + newRowHeight 

  var checkBoxPflegehilfsmittel = new CheckBox();
  checkBoxPflegehilfsmittel.fieldName = "CheckBox2";
  checkBoxPflegehilfsmittel.Rect = [leftMargin-0.5, rowLine-0.5, 5, 5];
  checkBoxPflegehilfsmittel.maxFontSize = 14
  checkBoxPflegehilfsmittel.appearanceState = 'Off'
  checkBoxPflegehilfsmittel.value = 'No'
  doc.addField(checkBoxPflegehilfsmittel)
  doc.rect(leftMargin-0.7, rowLine-0.7, 5.5, 5.5);
  doc.setFont('FrutigerNextLT', 'normal')
  doc.setFontSize(standardFontSize+1)
  doc.setTextColor(0, 56, 129)
  rowLine = drawMultilineText(doc, "Pflegehilfsmittel zur Körperpflege / Körperhygiene (PG 51) unter Abzug eines Eigenanteils von 10 v. H., soweit keine Befreiung nach § 40 Abs. 3 Satz 5 SGB XI vorliegt.", rowLine-newRowHeight/2, newRowHeight*0.6, leftMargin + 6)
  rowLine = rowLine + newRowHeight 

  doc.setTextColor(0, 0, 0)
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)
  rowLine = rowLine + newRowHeight * 0.7
  doc.addField(addMiniCheckBox(doc,leftMargin+0.5, rowLine, "CheckMini6"))
  doc.circle(leftMargin+1.5, rowLine-1.5, 1.5);
  doc.setFontSize(standardFontSize-1)
  doc.addField(addTextField(doc,secondRowStart, rowLine -4,  18, 4, ""))
  doc.line(secondRowStart, rowLine, thirdRowStart-2, rowLine)
  doc.text('Saugende Bettschutzeinlagen – wiederverwendbar', thirdRowStart, rowLine)
  //doc.text('500 ml', forthRowStart, rowLine)
  doc.text('51.40.01.4', fifthRowStart, rowLine)
  rowLine = rowLine + 1
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)
  rowLine = rowLine + 2
  doc.setDrawColor(0, 56, 129)
  doc.setLineWidth(0.5)
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)
  doc.setFillColor(0,  56, 129);
  doc.triangle(95, rowLine, 115, rowLine, 105, rowLine+3, 'FD')
  rowLine = rowLine + newRowHeight*0.8
  doc.setFont('FrutigerNextLT', 'bold')
  doc.setFontSize(standardFontSize-0.7)
  var text = "Mit meiner Unterschrift bestätige ich, dass ich darüber informiert wurde, dass die gewünschten Produkte ausnahmslos für die ambulante private Pflege (nicht durch Pflegedienste) verwendet werden dürfen. Die Mittel werden im angegebenen Umfang dauerhaft benötigt. "
  text = text + "Bitte streichen Sie vorhandene Leistungserbringer. Meine Daten dürfen für den Zweck und Dauer der Leistungserbringung genutzt werden."
  rowLine = drawMultilineText(doc, text, rowLine, newRowHeight * 0.5, leftMargin)
  doc.setFont('FrutigerNextLT', 'normal')
  rowLine= rowLine + newRowHeight
  doc.text('Abtretungserklärung/Nutzungsbedingungen (zur Abrechnung mit der Pflegekasse notwendig):',leftMargin, rowLine )
  rowLine= rowLine + 0.5*newRowHeight
  doc.text('Hiermit trete ich als Antragsteller/Leistungsnehmer meinen etwaigen Kostenerstattungsanspruch gemäß § 398 BGB gegenüber der Pflegekasse:',leftMargin, rowLine)
  rowLine= rowLine + newRowHeight
  doc.text('an',leftMargin, rowLine )
  doc.text('(siehe Stempelfeld) ab.',120, rowLine )
  rowLine= rowLine + 1
  doc.setDrawColor(0, 0, 0)
  doc.setLineWidth(0.1)
  doc.addField(addTextField(doc,leftMargin+5, rowLine-5, 70, 5,""))
  doc.line(leftMargin, rowLine, rightColumnEnd, rowLine)


  rowLine= rowLine + newRowHeight
  doc.addField(addTextField(doc,leftMargin, rowLine-5, 70, 5,""))
  doc.line(leftMargin, rowLine, rightColumnStart, rowLine)
  rowLine= rowLine + 0.5*newRowHeight
  doc.text('Datum und Unterschrift der / des Versicherten bzw. deren ges. Vertreter',leftMargin, rowLine )
  rowLine= rowLine + newRowHeight * 0.75
  for (var i = 0; i < 30; i++) {
    var x = i * 7
    doc.line(x, rowLine, x+6, rowLine)
  } 
  rowLine= rowLine + newRowHeight * 0.75
  doc.setFont('FrutigerNextLT', 'bold')
  doc.setFontSize(standardFontSize)
  doc.text('Genehmigungsvermerk der Pflegekasse',leftMargin, rowLine )
  doc.setFont('FrutigerNextLT', 'normal')
  rowLine = rowLine + 2

  var rowLineRight = rowLine

  rowLine = rowLine + newRowHeight * 0.5
  
  doc.addField(addCheckBox(doc,leftMargin, rowLine, "pg54"))
  doc.rect(leftMargin, rowLine-3.5, 4, 4);
  doc.text('PG 54', leftMargin+5, rowLine)
  rowLine= rowLine + newRowHeight * 0.5
  doc.text('bis maximal des monatlichen Höchstbetrages', leftMargin, rowLine)
  rowLine= rowLine + newRowHeight * 0.5
  doc.text('nach § 40 Abs. 2 SGB XI', leftMargin, rowLine)
  rowLine= rowLine + newRowHeight * 0.7
  doc.addField(addCheckBox(doc,leftMargin, rowLine, "pg542"))
  doc.rect(leftMargin, rowLine-3.5, 4, 4);
  doc.text('PG 54 Beihilfeberechtigung', leftMargin+5, rowLine)
  rowLine= rowLine + newRowHeight * 0.5
  doc.text('bis maximal der Hälfte des monatlichen Höchstbetrages', leftMargin, rowLine)
  rowLine= rowLine + newRowHeight * 0.5
  doc.text('nach § 40 Abs. 2 SGB XI', leftMargin, rowLine)

  doc.addField(addCheckBox(doc,rightColumnStart, rowLineRight, "pg51z"))
  doc.rect(rightColumnStart, rowLineRight-3.5, 4, 4);
  doc.text('PG 51 mit Zuzahlung', rightColumnStart+5, rowLineRight)
  rowLineRight= rowLineRight + newRowHeight * 0.7
  doc.addField(addCheckBox(doc,rightColumnStart, rowLineRight, "pg51oz"))
  doc.rect(rightColumnStart, rowLineRight-3.5, 4, 4);
  doc.text('PG 51 ohne Zuzahlung', rightColumnStart+5, rowLineRight)
  rowLineRight= rowLineRight + newRowHeight * 0.7
  doc.addField(addCheckBox(doc,rightColumnStart, rowLineRight, "pg51zb"))
  doc.rect(rightColumnStart, rowLineRight-3.5, 4, 4);
  doc.text('PG 51 mit Zuzahlung/Beihilfeberechtigter', rightColumnStart+5, rowLineRight)
  rowLineRight= rowLineRight + newRowHeight * 0.7
  doc.addField(addCheckBox(doc,rightColumnStart, rowLineRight, "pg51ozb"))
  doc.rect(rightColumnStart, rowLineRight-3.5, 4, 4);
  doc.text('PG 51 ohne Zuzahlung/Beihilfeberechtigter', rightColumnStart+5, rowLineRight)
  
  rowLine= rowLine + newRowHeight
  doc.setDrawColor(0, 0, 0)
  doc.setLineWidth(0.1)
  doc.line(leftMargin, rowLine, rightColumnStart-10, rowLine)
  doc.line(rightColumnStart, rowLine, rightColumnEnd, rowLine)
  doc.addField(addTextField(doc,rightColumnStart, rowLine-5, 70, 5,""))
  doc.addField(addTextField(doc,leftMargin, rowLine-5, 50, 5,""))
  rowLine= rowLine + 0.5*newRowHeight
  doc.text('(Datum)',leftMargin, rowLine )
  doc.text('(IK der Pflegekasse, Stempel und Unterschrift)',rightColumnStart, rowLine )
  rowLine= rowLine + newRowHeight * 0.8
  doc.setFont('FrutigerNextLT', 'bold')
  rowLine = drawMultilineText(doc, "* Vertrag über die Versorgung der Versicherten mit zum Verbrauch bestimmten Pflegehilfsmitteln gemäß §§ 78 Abs. 1 in Verbindung mit § 40 Abs. 2 SGB XI", rowLine, newRowHeight*0.6, leftMargin)
  
  return doc
}

function drawMultilineText (doc, text, rowLine, newRowHeight, leftMargin) {
  var splitTitle = doc.splitTextToSize(text, 182)

  // loop thru each line and output while increasing the vertical space
  for (var c = 0, stlength = splitTitle.length; c < stlength; c++) {
    rowLine = rowLine + newRowHeight
    doc.text(splitTitle[c], leftMargin, rowLine)
  }
  return rowLine
}

function addMiniCheckBox(doc, leftMargin, rowLine, name){
  var checkBoxMini3= new doc.AcroFormCheckBox();
  checkBoxMini3.fieldName = name;
  checkBoxMini3.Rect = [leftMargin, rowLine-2.5, 2, 2];
  checkBoxMini3.maxFontSize = 8
  checkBoxMini3.appearanceState = 'Off'
  checkBoxMini3.value = 'No'
  return checkBoxMini3
}

function addCheckBox(doc,leftMargin, rowLine, name){
  var checkBoxMini3= new doc.AcroFormCheckBox();
  checkBoxMini3.fieldName = name;
  checkBoxMini3.Rect = [leftMargin+0.25, rowLine-3.25, 3.5, 3.5];
  checkBoxMini3.maxFontSize = 9
  checkBoxMini3.appearanceState = 'Off'
  checkBoxMini3.value = 'No'
  return checkBoxMini3
}

function addTextField (doc, leftMargin, rowLine, length, height, text) {
  var textField = new doc.AcroFormTextField();
  textField.Rect = [leftMargin,rowLine, length, height]
  textField.multiline = false
  textField.V = text
  textField.maxFontSize = standardFontSize
  return textField
}
