<template>
  <div id="loginScreen">
    <div id="headerPic">
    </div>

    <div class="formContainer">
      <!-- <h3><img :src="require('@/assets/img/inco_logo.svg')"></h3> -->
      <form @submit.prevent="createPassword">
        <h1>Neues Passwort vergeben</h1>
        <div>
          <div>
            <label for="userName">Passwort</label><br/>
            <input id="password" required v-model="password" type="password"/>
          </div>
          <div>
            <label for="userPassword">Passwort wiederholen</label><br/>
            <input id="passwordRepeat" required v-model="passwordRepeat" type="password"/>
          </div>
        </div>
        <b-button type="submit" >speichern</b-button>
      </form>
      <div>
        <p style="padding: 20px 0px; color: #F6A800">{{createError}}</p>
      </div>
      <div>
        <p>Bitte vergeben Sie ein sicheres Passwort für die Benutzung von INCOAID und notieren Sie es sich anschliessend.</p>
        <p>Ein sicheres Passwort besteht aus 8 Zeichen und erfüllt folgende Kriterien:</p>
        <p>Verwendung von:</p>
        <ul>
          <li>mind. einem Groß- und einem Kleinbuchstaben</li>
          <li>einem Sonderzeichen (z.B. !#*@)</li>
          <li>und mind. einer Ziffer (123..)</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import {AUTH_REQUEST} from '@/store/actions/auth'
import {EventBus} from '@/services/EventBus.js'

export default {
  name: 'CreatePassword',
  data () {
    return {
      password: '',
      passwordRepeat: '',
      createError: '',
      username: this.getProfile
    }
  },
  created: function () {
    this.username = this.getProfile
  },
  methods: {
    createPassword: function () {
      const { username, password } = this
      const self = this
      if (this.password !== this.passwordRepeat) {
        this.createError = 'Die beiden Passwörter stimmen nicht überein.'
      } else {
        if (this.validatePassword(this.password) === true) {
          this.createError = ''
          var data = new FormData()
          data.append('password', this.password)
          data.append('username', this.username)
          axios.post('/static/api/auth/setpw.php', data, {'headers': {'Authorization': 'Bearer ' + localStorage.getItem('user-token'), 'Content-Type': 'application/x-www-form-urlencoded'}})
            .then(function (response) {
              self.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
                self.$router.push('/')
              }).catch(function (error) {
                console.log(error)
              })
            })
            .catch(function (error) {
              console.log(error)
            })
        } else {
          this.createError = 'Bitte überprüfen Sie Ihr Passwort und stellen Sie sicher, dass es die erforderlichen Kritieren erfüllt.'
        }
      }
    },
    validatePassword: function (pw) {
      return /[A-Z]/.test(pw) &&
              /[a-z]/.test(pw) &&
              (/[0-9]/.test(pw) &&
              /[^A-Za-z0-9]/.test(pw)) &&
              pw.length > 7
    }
  },
  computed: {
    ...mapGetters(['getProfile'])
  }
}
</script>
