Einstellungsseite für die allgemeinen Informationen der Institution.
Hört über den Eventbus auf den Speichernknopf vom Footer. Lädt und sepeichert in die Datenbank.
Das PDF-Passwort wird verschlüsselt in der Datenbank gespeichert aber wird zum verschlüsseln der Pdfs im Klartext benötigt.
Zum ver- und entschlüsseln wird direkt mit einer PHP Schnittstelle kommuniziert.
<template>
  <div class="settings">
    <p>Diese Angaben sind in dem Dokumentations PDF / Versorgungsvorschlag sichtbar.</p>
    <table class="dataContainer">
      <tr>
        <td>
          Firma:
        </td>
        <td>  
          <input v-model='data.Name'>
        </td>
      </tr>
      <tr>
        <td>
          Straße:
        </td>
        <td>
          <input v-model='data.Strasse'>
        </td>
      </tr>
      <tr>
        <td>
          Hausnummer:
        </td>
        <td>
          <input v-model='data.Hausnummer'>
        </td>
      </tr>
      <tr>
        <td>
          PLZ:
        </td>
        <td>
          <input v-model='data.PLZ'>
        </td>
      </tr>
      <tr>
        <td>
          Ort:
        </td>
        <td>
          <input v-model='data.Ort'>
        </td>
      </tr>
      <tr>
        <td>
          Telefon:
        </td>
        <td>
          <input v-model='data.Telefon'>
        </td>
      </tr>
      <tr>
        <td>
          Fax:
        </td>
        <td>
          <input v-model='data.Fax'>
        </td>
      </tr>
      <tr>
        <td>
          Ansprechpartner:
          <span>wird als Kontakt im PDF angzeigt</span>
        </td>
        <td>
          <input v-model='data.Ansprechpartner'>
        </td>
      </tr>
      <tr>
        <td>
          E-Mail:
          <span>wird als Kontaktemail im PDF angzeigt</span>
        </td>
        <td>
          <input v-model='data.Email'>
        </td>
      </tr>
      <tr>
        <td>
          Webseite:
        </td>
        <td>
          <input v-model='data.Webseite'>
        </td>
      </tr>
      <tr v-if="isTestphase === 0">
        <td>
          PDF-Passwort:
          <span>wird zur Verschlüsselung der versendeten PDFs verwendet. Bitte verwenden Sie:
            <ul style="margin-left: 20px; padding: 0px">
              <li>mind. einen Groß- und einen Kleinbuchstaben</li>
              <li>ein Sonderzeichen (z.B. !#*@)</li>
              <li>und mind. eine Ziffer (123..)</li>
            </ul>
          </span>
        </td>
        <td style="vertical-align: top">
          <form name=pdfPaswordForm onsubmit="return false">
            <input type="password" v-model='tempPassword' autocomplete="new-password">
          </form>
          <div>Passwort-Status: <span style="font-weight: bold" :class="passwordStatus().class">{{passwordStatus().text}}</span></div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>


import {OneLevelObjectIsEquivalent} from '@/utils/util.js'
import {EventBus} from '@/services/EventBus.js'
import DataService from '@/services/DataService'
import {mapGetters} from 'vuex'
import axios from 'axios'
export default {
  name: 'DataSettings',
  props: {
    currentIndex: Number
  },
  data: function () {
    return {
      data: {},
      savedData: {},
      eventBusObject: EventBus,
      tempPassword: '', 
      isTestphase: 0
    }
  },
  created: function () {
    this.loadData()
    const self = this
    var data = new FormData()
    
  
    this.eventBusObject.$on('saveSettings', ($event) => {
      if (this.currentIndex === 0) {
        this.saveDataToStorage()
      }
    })
    this.eventBusObject.$on('reloadSettings', ($event) => {
        this.loadData()
    })
    
  },
  computed: {
    ...mapGetters(['getProfile', 'getUserId', 'isAuthenticated', 'isProfileLoaded'])
  },
  methods: {
    loadData: function () {
   
        var self = this
        DataService.getUserData(this.getUserId).then((response) => {
       
          self.data = response.data
          if (self.data['Ansprechpartner'] === '' || self.data['Ansprechpartner'] === undefined) {
            self.$set(self.data, 'Ansprechpartner', response.data['Benutzer'][0]['Vorname'] + ' ' + response.data['Benutzer'][0]['Nachname'])
          }
          if (self.data['Email'] === '' || self.data['Email'] === undefined) {
            self.$set(self.data, 'Email', response.data['Benutzer'][0]['Mail'])
          }
          
          self.isTestphase = response.data['Testphase']
          self.savedData = self.data
     
          if (response.data['PdfPasswort'] !== '' && response.data['PdfPasswort'] !== undefined) {
 
            var postData = new FormData()
            postData.append('pdfCipher', response.data['PdfPasswort'])
            axios.post('/static/api/userdata/generatePdfPsw.php', postData, {'headers': {'Authorization': 'Bearer ' + localStorage.getItem('user-token'), 'Content-Type': 'application/x-www-form-urlencoded'}})
              .then(function (response) {
 
                self.tempPassword = response.data.plainPsw
              
              })
              .catch(function (error) {
                console.log(error)
              })
          }
        
          
        }).catch((err) => {
          // handle error
          console.log(err)
        })
    },

    saveDataToStorage: function () {
       const self = this
      if (this.tempPassword !== '') {
        var data = new FormData()
        data.append('pdfPasswort', this.tempPassword)
        axios.post('/static/api/userdata/generatePdfPsw.php', data, {'headers': {'Authorization': 'Bearer ' + localStorage.getItem('user-token'), 'Content-Type': 'application/x-www-form-urlencoded'}})
          .then(function (response) {
            self.data.PdfPasswort = response.data.encryptedPdfPsw
            DataService.setInstitutionData(self.data)
              .then((response) => {
                var changed = response.data.changed
                if (changed) {
                  self.eventBusObject.$emit('ChangedMail')
                } else {
                  self.eventBusObject.$emit('saveSettingsDone')
                }
                self.savedData = self.data
              })
              .catch((err) => { // set insti
                  console.log(err)
                })
           
          })
          .catch(function (error) {
            console.log(error)
          })
      } else {
          DataService.setInstitutionData(self.data)
          .then((response) => {
            var changed = response.data.changed
            if (changed) {
              self.eventBusObject.$emit('ChangedMail')
            } else {
              self.eventBusObject.$emit('saveSettingsDone')
            }
            self.savedData = self.data
          })
          .catch((err) => { // set insti
              console.log(err)
            })
       
      }
    },

    removeChanges: function () {
      this.loadData()
    },
    checkBeforeLeaving: function () {
     
      var dataWithoutExtraKey = this.data
      delete dataWithoutExtraKey['__ob__']
      if (OneLevelObjectIsEquivalent(this.savedData, dataWithoutExtraKey)) {
        return true
      } else {
        return false
      }
    },
    passwordStatus: function () {
      var password = this.tempPassword
      if (this.validatePassword(password) === true) {
        return {
          class: 'save',
          text: 'sicher'
        }
      } else {
        return {
          class: 'unsave',
          text: 'unsicher'
        }
      }
    },
    validatePassword: function (pw) {
      return /[A-Z]/.test(pw) &&
              /[a-z]/.test(pw) &&
              (/[0-9]/.test(pw) &&
              /[^A-Za-z0-9]/.test(pw)) &&
              pw.length > 7
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('saveSettings')
    this.eventBusObject.$off('deleteThisSettings')
    this.eventBusObject.$off('reloadSettings')
  }
}

</script>
