<template>
  <div id="registerScreen">
    <div id="headerPic">
    </div>
    <b-modal size="m" ref="recaptchaErrorModal" hide-footer title="Roboter erkannt" style="text-align: center">
      <div class="d-block text-center">
        Wenn Sie wirklich kein Roboter sind, versuchen Sie es bitte erneut.
        <br/>
        <b-button @click="$refs.recaptchaErrorModal.hide()" class="modalButton">Ok</b-button>
      </div>       
    </b-modal>
    <div class="innerContainer">
      <h1>INCOAID Registrierung<br />
      <span v-if="registerModel.selectedVersion == 0">Demoversion</span>
      <span v-else>Vertragsversion</span>
      </h1>
      <div class="formContainer">
        <h2>3. Passwort festlegen</h2>
        <form @submit.prevent="registerInstitution">
          <div class="data">
            <div class="column">
              <div id="registerInstituionsContainer">
                <div class="full">
                  <label for="password">Passwort*</label><br/>
                  <b-form-input id="password" required v-model="registerModel.Password" type="password"/>
                </div>
                <div class="full">
                  <label for="passwordRepeat">Passwort wiederholen*</label><br/>
                  <b-form-input id="passwordRepeat" required v-model="passwordRepeat" type="password"/>
                </div> 
              </div>
              <span style="font-size: 14px; display: block; margin-top: 8px;">* Pflichtfeld</span>
            </div>  
            <div class="column">
              <div class="registerFortschritt">
                Schritt 3/3
                <span class="threeThird"></span>
              </div>
              <img :src="require('@/assets/img/computer_ipad.png')">
              <p>Bitte beachten Sie folgende Kriterien für die Passwortvergabe:</p>
              <ul>
                <li>mind. 8 Zeichen</li>
                <li>mind. ein Groß- und ein Kleinbuchstabe</li>
                <li>ein Sonderzeichen (z.B. !#*@)</li>
                <li>und mind. eine Ziffer (123..)</li>
              </ul>
            </div>
            <div style="width: 100%;">
              <p style="width: 100%; padding: 10px 0px; color: #F6A800">{{createError}}</p>
            </div>
          </div>
          <div class="navigation">
           <b-button @click="$router.push({name: 'registeruser'})" class="backButton">Zurück</b-button>
           <b-button v-if="registerModel.selectedVersion == 0" class="submitButton" type="submit" :disabled="sendPressed">Für Demoversion registrieren</b-button>
           <b-button v-if="registerModel.selectedVersion == 1" class="submitButton" type="submit" :disabled="sendPressed">Für Vertragsversion registrieren</b-button>
          </div>
        </form>
      </div>
      <div class="registerFooter">
        <div>
          <span v-if="registerModel.selectedVersion == 0" @click="registerModel.selectedVersion = 1">Für Vertragsversion registrieren</span>
          <span v-else @click="registerModel.selectedVersion = 0">Für Demoversion registrieren</span>
        </div>
        <div>
          <a href="https://hartmann.info/de-DE/Impressum" target="_blank">Impressum</a> | <a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutzerklärung</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
import { ADD_REG_MAIL, ADD_REG_USERNAME } from '@/store/modules/data'

export default {
  name: 'Register',
  data () {
    return {
      registerModel: {},
      password: '',
      passwordRepeat: '',
      createError: '',
      correctKnr: '',
      sendPressed: false
    }
  },
  created: function () {

    this.registerModel = this.getRegisterModel
   
    console.log(this.$route.params)
    if (this.$route.params.version == 0) {
      this.registerModel.selectedVersion = 0
    } else if (this.$route.params.version == 1) {
      this.registerModel.selectedVersion = 1
    }
     console.log(this.getRegisterModel)
  },
  methods: {
   
    validatePassword: function (pw) {
      return /[A-Z]/.test(pw) &&
              /[a-z]/.test(pw) &&
              (/[0-9]/.test(pw) &&
              /[^A-Za-z0-9]/.test(pw)) &&
              pw.length > 7
    },
    buttonIsClickable: function() {
      console.log(JSON.stringify(this.registerModel))
      if ( 
        this.registerModel.Name !== "" && this.registerModel.Name !== undefined &&
        this.registerModel.PLZ !== "" && this.registerModel.PLZ !== undefined &&
        this.registerModel.Strasse !== "" && this.registerModel.Strasse !== undefined &&
        this.registerModel.Ort !== "" && this.registerModel.Ort !== undefined &&
        this.registerModel.Hausnummer !== "" && this.registerModel.Hausnummer !== undefined &&
        this.registerModel.Vorname !== "" && this.registerModel.Vorname !== undefined &&
        this.registerModel.Nachname !== "" && this.registerModel.Nachname !== undefined &&
        this.registerModel.Mail !== "" && this.registerModel.Mail !== undefined &&
        this.registerModel.Password !== "" && this.registerModel.Password !== undefined && this.passwordRepeat === this.registerModel.Password &&
        (this.registerModel.KnrVerifiziert === true || this.registerModel.Kundennummer === "")
      ) {
        return true
      } else {
        return false
      }
    },
    registerInstitution: function () {

      const self = this
    
      
      this.sendPressed = true
      
      if (self.registerModel.Password !== self.passwordRepeat) {
        self.createError = 'Die beiden Passwörter stimmen nicht überein.'
        self.sendPressed = false
      } else {
        
        if (!self.buttonIsClickable()) {
          self.createError = 'Bitte überprüfen Sie, dass auch alle Felder der vorherigen Schritte vollständig ausgefüllt sind.'
          self.sendPressed = false
          return
        } 
        if (self.validatePassword(self.registerModel.Password) === true) {
          grecaptcha.ready(function() {
            grecaptcha.execute(self.$recaptcha_key, {action: 'submit'}).then(function(token) {
                // Send Recaptcha Token to backend for validation
                // console.log(token)
                DataService.checkRecaptcha(token).then(function (response) {      
                  if (response != null && response.data.success == true) {
                    self.createError = ''
                    DataService.register(self.registerModel).then(function (response) {
                      self.sendPressed = false
                      self.$store.dispatch(ADD_REG_MAIL, self.registerModel.Mail)
                      if (response.data.username != null) {
                        self.$store.dispatch(ADD_REG_USERNAME,response.data.username)
                      }
                      self.$router.push('success')
                    })
                    .catch((error) => {
                      self.sendPressed = false
                      if (error.response) {
                        if (error.response.data.error === 1) {
                          self.createError = 'Diese E-Mail-Adresse wird bereits verwendet.'
                        }
                      }
                    })
                  } else {
                    self.$refs.recaptchaErrorModal.show()
                  }
                
                }).catch((err) => {
                  console.log(err)
                  self.$refs.recaptchaErrorModal.show()
                })   
              }).catch((err) => {
                  console.log(err)
              });
          });

          
          
        } else {
          self.sendPressed = false
          self.createError = 'Bitte überprüfen Sie Ihr Passwort und stellen Sie sicher, dass es die erforderlichen Kritieren erfüllt.'
        }
      }
    
    }
  },
  computed: {
    ...mapGetters(['getRegisterModel'])
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render='+this.$recaptcha_key)
    document.head.appendChild(recaptchaScript)
  }
}
</script>
