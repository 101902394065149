import jsPDF from 'jspdf'
// eslint-disable-next-line
import jspdfAutotable from 'jspdf-autotable'
import {formatFloatToStringUtil} from '@/utils/util.js'
const leftMargin = 20
var newRowHeight = 6
var standardFontSize = 10
var page = 1

export function generateCustomerPdfUtil (dataSettingObject, summaryObject, productCart, allProducts, answers, calcSettingObject, extraAmount) {
  // eslint-disable-next-line
  var doc = new jsPDF()
  page = 1
  // doc.setFont('arial')
  doc.setTextColor(0, 0, 0)
  addFooter(doc, answers)
  var rowLine = 10
  rowLine = addHeaderToDoc(doc, rowLine, dataSettingObject, calcSettingObject, answers, false, extraAmount)
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  addVorschlaegeToDoc(doc, rowLine, summaryObject, productCart, allProducts, answers, false)

  doc.addPage()
  page = page + 1
  addFooter(doc, answers)
  /*
  var priceText = ''
  var innerIndex = 0
  for (var premiumTabIndex in productCart[1]) {
    var premiumTab = productCart[1][premiumTabIndex]
    innerIndex = innerIndex + 1
    if (premiumTab.totalCarePrice > 0) {
      priceText = priceText + premiumTab.totalCarePrice + ' EUR für Wunschversorgungsvorschlag ' + innerIndex + ', '
    }
  }
  priceText = priceText.substr(0, priceText.length - 2)
  */
  rowLine = 20
  doc.setFontSize(standardFontSize + 4)
  doc.text('Mehrkostenübernahmeerklärung', leftMargin, rowLine)
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  doc.setFontSize(standardFontSize)
  var text = 'Ich wurde umfassend über das aufzahlungsfreie (gesetzliche Zuzahlung ausgenommen) Angebot der Versorgung mit Inkontinenzprodukten informiert. Mir wurde eine aufzahlungsfreie Versorgung mit für mich geeigneten Produkten angeboten.'
  rowLine = drawMultilineText(doc, text, rowLine, newRowHeight, leftMargin)
  rowLine = rowLine + newRowHeight
  var text1 = '- Ich wünsche ausdrücklich eine Versorgung mit Inkontinenzprodukten, die über die aufzahlungsfreie Versorgung und somit das Maß des Notwendigen hinausgeht.'
  rowLine = drawMultilineText(doc, text1, rowLine, newRowHeight, leftMargin)
  rowLine = rowLine + newRowHeight
  var text2 = '- Ich wurde über die monatlichen Mehrkosten in Höhe von ___________ informiert und entscheide mich diese selbst zu tragen. '
  rowLine = drawMultilineText(doc, text2, rowLine, newRowHeight, leftMargin)
  rowLine = rowLine + newRowHeight
  var text3 = '- Ich wurde darüber informiert, dass anfallende Mehrkosten mir weder von der Krankenkasse erstattet noch mir im Rahmen der Prüfung einer Zuzahlungsbefreiung angerechnet werden können.'
  rowLine = drawMultilineText(doc, text3, rowLine, newRowHeight, leftMargin)
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  doc.line(leftMargin, rowLine, 80, rowLine)
  doc.line(100, rowLine, 170, rowLine)
  rowLine = rowLine + newRowHeight
  doc.setFontSize(standardFontSize - 2)
  doc.text('Ort, Datum', leftMargin, rowLine)
  doc.text('Unterschrift des Versicherten/Bevollmächtigten', 100, rowLine)
  return doc
}

export function generateDokuEmail (dataSettingObject, summaryObject, productCart, allProducts, answers, wizard, calcSettingObject, extraAmount) {
  // eslint-disable-next-line
  var doc = new jsPDF()
  page = 1
  doc.setFont('helvetica')
  doc.setTextColor(0, 0, 0)
  addFooter(doc, answers)
  var leftMargin = 20
  var newRowHeight = 5
  var rowLine = 10
  var standardFontSize = 10
  rowLine = addHeaderToDoc(doc, rowLine, dataSettingObject, calcSettingObject, answers, true, extraAmount)
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  addVorschlaegeToDoc(doc, rowLine, summaryObject, productCart, allProducts, answers, true)
  doc.addPage()
  page = page + 1
  addFooter(doc, answers)

  rowLine = 20
  // rowLine = addHeaderToDoc(doc, rowLine, dataSettingObject)
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  doc.setFontSize(standardFontSize + 4)
  doc.text('Anamnese Dokumentation', leftMargin, rowLine)
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  doc.setFontSize(standardFontSize)
  if (answers['givenname'] !== undefined && answers['lastname'] !== undefined) {
    doc.text('Name: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    doc.text(answers['givenname'] + ' ' + answers['lastname'], leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }

  if (answers['birthdate'] !== undefined) {
    doc.setFont('helvetica', 'normal')
    doc.text('Geburtsdatum: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    var date = new Date(answers['birthdate'])
    var newDateString = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
    doc.text(newDateString, leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }

  if (answers['krankenkasse'] !== undefined) {
    doc.setFont('helvetica', 'normal')
    doc.text('Krankenkasse: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    doc.text(answers['krankenkasse'].Kasse, leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }

  if (answers['email'] !== undefined) {
    doc.setFont('helvetica', 'normal')
    doc.text('Email: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    doc.text(answers['email'], leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }

  if (answers['telephone'] !== undefined) {
    doc.setFont('helvetica', 'normal')
    doc.text('Tel: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    doc.text(answers['telephone'], leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }

  if (answers['street'] !== undefined) {
    doc.setFont('helvetica', 'normal')
    doc.text('Straße: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    doc.text(answers['street'], leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }
  if (answers['plz'] !== undefined) {
    doc.setFont('helvetica', 'normal')
    doc.text('Plz: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    doc.text(answers['plz'], leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }
  if (answers['city'] !== undefined) {
    doc.setFont('helvetica', 'normal')
    doc.text('Stadt: ', leftMargin, rowLine)
    doc.setFont('helvetica', 'bold')
    doc.text(answers['city'], leftMargin + 30, rowLine)
    rowLine = rowLine + newRowHeight
  }
  doc.setFont('helvetica', 'normal')
  rowLine = rowLine + newRowHeight
  var summaryKeys = Object.keys(summaryObject)

  if (answers['representative'] === 'R1') {
    if (answers['representative_type'] !== undefined) {
      doc.setFont('helvetica', 'normal')
      doc.text('Stellvertreter: ', leftMargin, rowLine)
      doc.setFont('helvetica', 'bold')
      doc.text(answers['representative_type'], leftMargin + 30, rowLine)
      rowLine = rowLine + newRowHeight
    }
    if (answers['custom_representative_type'] !== undefined) {
      doc.setFont('helvetica', 'normal')
      doc.text('Sonstige: ', leftMargin, rowLine)
      doc.setFont('helvetica', 'bold')
      doc.text(answers['custom_representative_type'], leftMargin + 30, rowLine)
      rowLine = rowLine + newRowHeight
    }
    if (answers['representative_givenName'] !== undefined && answers['representative_lastname'] !== undefined) {
      doc.setFont('helvetica', 'normal')
      doc.text('Name: ', leftMargin, rowLine)
      doc.setFont('helvetica', 'bold')
      doc.text(answers['representative_givenName'] + ' ' + answers['representative_lastname'], leftMargin + 30, rowLine)
      rowLine = rowLine + newRowHeight
    }
    if (answers['representative_phone'] !== undefined) {
      doc.setFont('helvetica', 'normal')
      doc.text('Tel: ', leftMargin, rowLine)
      doc.setFont('helvetica', 'bold')
      doc.text(answers['representative_phone'], leftMargin + 30, rowLine)
      rowLine = rowLine + newRowHeight
    }
    if (answers['representative_email'] !== undefined) {
      doc.setFont('helvetica', 'normal')
      doc.text('Email: ', leftMargin, rowLine)
      doc.setFont('helvetica', 'bold')
      doc.text(answers['representative_email'], leftMargin + 30, rowLine)
      rowLine = rowLine + newRowHeight
    }
  }

  for (var wizKey in wizard) {
    var segment = wizard[wizKey]
    var segName = segment.segmentName
    var segmentHeight = 20
    // calculate length of segment, needs to be done before segement headline is drawn:
    for (var j in segment.questions) {
      var q = segment.questions[j]
      if (summaryKeys.indexOf(q.id) >= 0 && summaryObject[q.id] !== undefined) {
        var answerLength = 0
        if (Object.keys(q.answers).length > 0) {
          answerLength = Object.keys(q.answers).length / 4 * newRowHeight
        }
        segmentHeight = segmentHeight + answerLength
      }
    }

    if (segmentHeight + rowLine > 255) {
      doc.addPage()
      page = page + 1
      addFooter(doc, answers)
      rowLine = 20
    }
    rowLine = rowLine + newRowHeight
    doc.setFontSize(standardFontSize)
    doc.setFont('helvetica', 'normal')
    if (wizKey > 0) {
      doc.setFontSize(standardFontSize + 2)
      doc.text(segName, leftMargin, rowLine)
      doc.setFontSize(standardFontSize)
      rowLine = rowLine + newRowHeight
      rowLine = rowLine + newRowHeight
    }
    // add each question and question answers to doc
    for (var i in segment.questions) {
      var question = segment.questions[i]
      if (summaryKeys.indexOf(question.id) >= 0 && summaryObject[question.id] !== undefined) {
        doc.setFont('helvetica', 'bold')
        doc.text(question.questiontext, leftMargin, rowLine)
        rowLine = rowLine + newRowHeight
        var ownLeftMargin = leftMargin
        for (var answerKey in question.answers) {
          var answerStringLength = 0
          if (summaryObject[question.id] === question.answers[answerKey] || (question.type === 'multiselect' && summaryObject[question.id].indexOf(question.answers[answerKey]) >= 0)) {
            doc.setFont('helvetica', 'bold')
            doc.setLineWidth(0.4)
            doc.rect(ownLeftMargin, rowLine - 2.9, 4, 4)
            var answerString = ' x   ' + question.answers[answerKey]
            doc.text(answerString, ownLeftMargin, rowLine)
            answerStringLength = answerString.length
          } else {
            doc.setFont('helvetica', 'normal')
            doc.setLineWidth(0.4)
            doc.rect(ownLeftMargin, rowLine - 2.9, 4, 4)
            var answerStringNot = '      ' + question.answers[answerKey]
            doc.text(answerStringNot, ownLeftMargin, rowLine)
            answerStringLength = answerStringNot.length
          }

          ownLeftMargin = ownLeftMargin + (answerStringLength * 2)
          if (ownLeftMargin > 200) {
            ownLeftMargin = leftMargin
            rowLine = rowLine + newRowHeight
          }
        }
        if (question.id === 'experience') {
          if (answers['experience'] === 'E1' && answers['equivalence'] !== undefined) {
            rowLine = rowLine + newRowHeight
            doc.setFont('helvetica', 'bold')
            doc.text('Produkt: ', leftMargin, rowLine)
            doc.setFont('helvetica', 'normal')
            doc.text(answers['equivalence'], leftMargin + 20, rowLine)
            rowLine = rowLine + newRowHeight
          }
        }
        rowLine = rowLine + newRowHeight
        rowLine = rowLine + newRowHeight
      }
    }
  }

  rowLine = rowLine + newRowHeight
  rowLine = addExtraTexts(doc, answers, rowLine)
  
  if (rowLine > 235) {
    doc.addPage()
    page = page + 1
    addFooter(doc, answers)
    rowLine = 20
  }
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  doc.line(leftMargin, rowLine, 80, rowLine)
  doc.line(100, rowLine, 170, rowLine)
  rowLine = rowLine + newRowHeight
  doc.setFontSize(standardFontSize - 2)
  doc.text('Ort, Datum', leftMargin, rowLine)
  doc.text('Unterschrift des Versicherten/Bevollmächtigten', 100, rowLine)
  return doc
}

function addHeaderToDoc (doc, rowLine, dataSettingObject, calcSetting, answers, onlyForDocumentation, extraAmount) {
  /* SH Daten: */
  // doc.setFontType('bold')
  rowLine = rowLine + newRowHeight
  rowLine = rowLine + newRowHeight
  doc.setFontSize(standardFontSize)

  if (dataSettingObject.Name !== undefined) {
    doc.text(dataSettingObject.Name, leftMargin, rowLine)
  }
  rowLine = rowLine + newRowHeight
  if (dataSettingObject.Ansprechpartner !== undefined) {
    doc.text(dataSettingObject.Ansprechpartner, leftMargin, rowLine)
  }
  doc.setFont('helvetica', 'normal')
  rowLine = rowLine + newRowHeight
  if (dataSettingObject.Strasse !== undefined && dataSettingObject.Hausnummer !== undefined) {
    doc.text(dataSettingObject.Strasse + ' ' + dataSettingObject.Hausnummer, leftMargin, rowLine)
    rowLine = rowLine + newRowHeight
  }
  if (dataSettingObject.Ort !== undefined && dataSettingObject.PLZ !== undefined) {
    doc.text(dataSettingObject.PLZ + ' ' + dataSettingObject.Ort, leftMargin, rowLine)
    rowLine = rowLine + newRowHeight
  }
  if (dataSettingObject.Telefon !== undefined) {
    doc.text('Tel: ' + dataSettingObject.Telefon, leftMargin, rowLine)
    rowLine = rowLine + newRowHeight
  }
  if (dataSettingObject.Email !== undefined) {
    doc.text('Email: ' + dataSettingObject.Email, leftMargin, rowLine)
    rowLine = rowLine + newRowHeight
  }
  if (dataSettingObject.Webseite !== undefined) {
    doc.text(dataSettingObject.Webseite, leftMargin, rowLine)
    rowLine = rowLine + newRowHeight
  }
  rowLine = rowLine + newRowHeight + newRowHeight

  doc.setFont('helvetica', 'bold')
  var date = new Date()
  var newDateString = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
  doc.text('Versorgung mit Inkontinenzprodukten - Vorgang vom ' + newDateString, leftMargin, rowLine)
  doc.setFont('helvetica', 'normal')
  rowLine = rowLine + newRowHeight

  var beraterAndMethodString = ''

  if (answers['member'] !== undefined && answers['member'] !== '') {
    beraterAndMethodString = 'Beraten durch: ' + answers['member']
  } else {
    beraterAndMethodString = 'Beraten durch: ' + '--'
  }

  if (onlyForDocumentation === true) {
    beraterAndMethodString = beraterAndMethodString + ' / ' + 'Berechnungsmethode: ' + calcSetting
  }
  if (beraterAndMethodString !== '') {
    doc.text(beraterAndMethodString, leftMargin, rowLine)
    rowLine = rowLine + newRowHeight
  }

  
  doc.text('Gesetzliche Zuzahlung: ' +formatFloatToStringUtil(extraAmount) + ' Euro', leftMargin, rowLine)
  rowLine = rowLine + newRowHeight

  return rowLine
}

function addVorschlaegeToDoc (doc, rowLine, summaryObject, productCart, allProducts, answers, onlyForDocumentation) {
  // Anrede :
  var customer = 'Kunde'
  if (!onlyForDocumentation) {
    if (answers['givenname'] !== undefined && answers['lastname'] !== undefined) {
      customer = summaryObject['gender'] + ' ' + answers['givenname'] + ' ' + answers['lastname']
    }
    doc.text('Sehr geehrte(r) ' + customer + ',', leftMargin, rowLine)
    rowLine = rowLine + newRowHeight
    var date = new Date()
    var newDateString = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
    var text = 'vielen Dank, dass Sie sich für uns als Lieferant Ihrer Inkontinenzprodukte entschieden haben. '
    text = text + 'Auf Basis unseres Gesprächs vom ' + newDateString + ' haben wir verschiedene '
    text = text + 'Versorgungsvorschläge für Sie zusammengestellt und Ihnen Produktmuster mitgegeben, die Sie ausprobieren können.'
    rowLine = drawMultilineText(doc, text, rowLine, newRowHeight, leftMargin)
    rowLine = rowLine + newRowHeight
    rowLine = rowLine + newRowHeight
  }
  // Vorschläge:
  var columns = [{title: 'Menge', dataKey: 'Menge'}, {title: 'Produkt', dataKey: 'Produktbezeichnung'}, {title: '', dataKey: 'Basis'}, {title: 'System', dataKey: 'System'}, {title: 'Größe', dataKey: 'selectedSize'}, {title: 'Zeitraum', dataKey: 'selectedTime'}]

  /*
  if (onlyForDocumentation) {
    columns.splice(0, 0, {title: 'Menge', dataKey: 'Menge'})
  }
  */
  var nummerOverall = 1
  var freiOverall = 1
  var showNumber = false
  for (var baseOrPremiumTab in productCart) {
    var productTab = productCart[baseOrPremiumTab]
    if (productTab.length > 1) {
      showNumber = true
    }
    for (var x in productTab) {
      var tab = productTab[x]
      var products = tab.products
      if (products != null && products.length > 0) {
        var i = 16 * products.length + rowLine
        if (i > 260 || (page === 1 && i > 240)) {
          doc.addPage()
          page = page + 1
          addFooter(doc, answers)
          rowLine = 20
        }
        doc.setFontSize(14)
        var nummer = parseInt(x) + 1;

        if (baseOrPremiumTab === '0') {
          doc.text('Medizinisch notwendige Versorgung ' + nummer + ':', leftMargin, rowLine)
        } else if (baseOrPremiumTab === '1') {
          doc.text('Wunschversorgung ' + nummer + ':', leftMargin, rowLine)
        } else if (baseOrPremiumTab === '2') {
          doc.text('Wunschversorgung / Freiverkauf ' + nummer + ':', leftMargin, rowLine)
        }
        
        rowLine = rowLine + newRowHeight
        
        var tableProducts = []
        var tableHeight = 0
        for (var y in products) {
          var product = products[y]
          var productRow = {}
          productRow['Produktbezeichnung'] = allProducts[product.parentProductId].Produktbezeichnung + ' \nInhalt: ' + product.pieces + ' / Packung'
          + '\nPZN: '+ product.PZN + "   Art.-Nr: "+product.Artikelnummer
          productRow['Basis'] = allProducts[product.parentProductId]['PremiumBasis'].toUpperCase()
          productRow['System'] = allProducts[product.parentProductId]['Anwendungstyp']
          productRow['selectedSize'] = product.selectedSize
          productRow['Menge'] = product.monthlyPackages + ' x'

          if (product.selectedTime > -1) {
            productRow['selectedTime'] = product.times[product.selectedTime] + ' \nStück pro Tag: ' + product.dailyNeed
          }

          tableHeight = tableHeight + 12
          tableProducts.push(productRow)
          var extraRowAsBlackLine = {}
          extraRowAsBlackLine['Produktbezeichnung'] = ''
          extraRowAsBlackLine['Basis'] = ''
          extraRowAsBlackLine['System'] = ''
          extraRowAsBlackLine['selectedSize'] = ''
          extraRowAsBlackLine['Menge'] = ''
          extraRowAsBlackLine['selectedTime'] = ''
          tableProducts.push(extraRowAsBlackLine)
        }

        doc.autoTable({
          theme: 'plain',
          columns: columns, 
          body: tableProducts, 
          startY: rowLine,
          startX: leftMargin, 
          styles: {overflow: 'linebreak'},
          margin: {top: 0, left: leftMargin, right: leftMargin},
          bodyStyles: {fontSize: 9, fontStyle: 'normal'},
          headStyles: {fontSize: 10, fillColor: [240, 240, 240], textColor: [50, 50, 50], fontStyle: 'normal'},
          columnStyles: {
            Produktbezeichnung: {
              fontStyle: 'bold',
              cellWidth: 70
            },
            selectedSize: {
              fontStyle: 'bold',
              halign: 'center',
              valign: 'middle'
  
            },
            System: {
              halign: 'center',
              valign: 'middle'
            },
            selectedTime: {
              fontStyle: 'bold'
            },
            Basis: {
              fontStyle: 'bold'
            },
            Menge: {
              halign: 'center',
              valign: 'middle'
            }
          },
          tableLineColor: 0,
          tableLineWidth: 0,
          willDrawCell: function (data) {
            // every second row is an empty line
            if (data.row.index % 2 === 1) {
              data.row.height = 0.05
              data.cell.height = 0.05
              data.cell.styles.fillColor = true
              return true
            } else {
              

              if (data.row.section === 'body' && data.column.dataKey === 'Produktbezeichnung') {
                if (data.row.cells['Produktbezeichnung'].text !== null && data.row.cells['Produktbezeichnung'].text[1] != null) {
                  doc.autoTableText(data.row.cells['Produktbezeichnung'].text[0], data.cell.x, data.cell.y + 1.5, {
                    halign: 'left',
                    valign: 'top'
                  })
                  doc.setFontSize(9)
                  doc.setFont('helvetica', 'normal')
                  doc.autoTableText(data.row.cells['Produktbezeichnung'].text[1], data.cell.x, data.cell.y + data.cell.height/3+2, {
                    halign: 'left',
                    valign: 'top'
                  })
                  doc.setFontSize(9)
                  doc.setFont('helvetica', 'normal')
                  doc.autoTableText(data.row.cells['Produktbezeichnung'].text[2], data.cell.x, data.cell.y + 2*data.cell.height/3 +1, {
                    halign: 'left',
                    valign: 'top'
                  })
                  /*
                  doc.setFontSize(9)
                  doc.setFontType('normal')
                  doc.autoTableText(data.row.cells['Produktbezeichnung'].text[2], cell.x, cell.y + cell.height/2, {
                    halign: 'left',
                    valign: 'bottom'
                  })*/
                }
                return false
                
              }

              if (data.row.section === 'body' && data.column.dataKey === 'selectedTime') {
                if (data.row.cells['selectedTime'].text !== null && data.row.cells['selectedTime'].text[1] != null) {
                  doc.autoTableText(data.row.cells['selectedTime'].text[0], data.cell.x, data.cell.y + data.cell.height / 2, {
                    halign: 'left',
                    valign: 'bottom'
                  })
                  doc.setFontSize(9)
                  doc.setFont('helvetica', 'normal')
                  doc.autoTableText(data.row.cells['selectedTime'].text[1], data.cell.x, data.cell.y + data.cell.height / 2, {
                    halign: 'left',
                    valign: 'top'
                  })
                }
                return false
              }

              if (data.row.section === 'body' && data.column.dataKey === 'Basis') {
                doc.setLineWidth(0.4)
                doc.setFont('helvetica', 'normal')
                doc.setFontSize(8)
                if (data.row.cells['Basis'].text[0] === 'BASIS') {
                  doc.setFillColor(170, 170, 170)
                  doc.rect(data.cell.x, data.cell.y + data.cell.height / 4, 13, 7, 'F')
                } else  if (data.row.cells['Basis'].text[0] === 'PREMIUM') {
                  doc.setFillColor(246, 168, 0)
                  doc.rect(data.cell.x, data.cell.y + data.cell.height / 4, 18, 7, 'F')
                }
                doc.setTextColor(255, 255, 255)
                doc.autoTableText(data.row.cells['Basis'].text[0], data.cell.x+2, data.cell.y + data.cell.height / 2, {
                  halign: 'left',
                  valign: 'middle'
                })
                
                return false
              }
            
              return true
            }
          }
        })

        rowLine = doc.autoTable.previous.finalY + 7
        var tabLeftMargin = 100
        doc.setFontSize(11)
        doc.text('Monatliche Aufzahlung *', tabLeftMargin, rowLine)
        doc.setFont('helvetica', 'normal')
        tabLeftMargin = 160
        doc.text(formatFloatToStringUtil(tab['totalCarePrice']) + ' Euro **', tabLeftMargin, rowLine)
        doc.setFont('helvetica', 'normal')
        rowLine = rowLine + newRowHeight
        rowLine = rowLine + newRowHeight
      }
    }
  }
  if (rowLine > 245) {
    doc.addPage()
    page = page + 1
    addFooter(doc, answers)
    rowLine = 20
  }
  if (!onlyForDocumentation) {
    rowLine = rowLine + newRowHeight
    doc.setFontSize(standardFontSize)
    var vorschlagtext = 'Haben Sie sich für einen Versorgungsvorschlag entschieden? Oder haben Sie noch Fragen? Bitte kommen Sie auf uns zu!'
    rowLine = drawMultilineText(doc, vorschlagtext, rowLine, newRowHeight, leftMargin)
    rowLine = rowLine + newRowHeight
    rowLine = rowLine + newRowHeight
  }
  doc.setFontSize(standardFontSize - 4)
  var extraLine = '* Produkte ohne Aufzahlung sind medizinisch ausreichend und zweckmäßig. Produkte, die aufgrund ihrer Ausstattung oder Menge über die medizinische Notwendigkeit hinausgehen, sind mit einer Aufzahlung verbunden.'
  rowLine = drawMultilineText(doc, extraLine, rowLine, newRowHeight - 2, leftMargin)
  rowLine = rowLine + newRowHeight
  doc.text('** Die angezeigten Preise gelten unter Vorbehalt', leftMargin, rowLine)
  doc.setFontSize(standardFontSize)
}
function addFooter (doc, answers) {
  doc.setFont('helvetica', 'normal')
  doc.setFontSize(standardFontSize - 4)
  var name = ''
  if (answers['givenname'] !== undefined && answers['lastname'] !== undefined) {
    name = ' - ' + answers['givenname'] + ' ' + answers['lastname']
  }
  var member = ''
  if (answers['member'] !== undefined) {
    member = ' - Beraten durch ' + answers['member']
  }
  var date = new Date()
  var newDateString = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
  doc.text(newDateString + name + member, leftMargin, 292)
  doc.text(page + '', 200, 292)
}

function addExtraTexts (doc, answers, rowLine) {
  if ((answers['extra2'] !== undefined && answers['extra2'].length > 0) ||
  (answers['extra3'] !== undefined && answers['extra3'].length > 0) ||
  (answers['extra4'] !== undefined && answers['extra4'].length > 0) ||
  (answers['extra5'] !== undefined && answers['extra5'].length > 0) ||
  (answers['extra6'] !== undefined && answers['extra6'].length > 0)) {
    var rows1 = 0
    var rows2 = 0
    var rows3 = 0
    var rows4 = 0
    var rows5 = 0
    var splitTxt = ''
    if (answers['extra2'] !== undefined && answers['extra2'].length > 0) {
      splitTxt = doc.splitTextToSize(answers['extra2'], 160)
      rows1 = 2 * newRowHeight + splitTxt.length * newRowHeight
    }
    if (answers['extra3'] !== undefined && answers['extra3'].length > 0) {
      splitTxt = doc.splitTextToSize(answers['extra3'], 160)
      rows2 = 2 * newRowHeight + splitTxt.length * newRowHeight
    }
    if (answers['extra4'] !== undefined && answers['extra4'].length > 0) {
      splitTxt = doc.splitTextToSize(answers['extra4'], 160)
      rows3 = 2 * newRowHeight + splitTxt.length * newRowHeight
    }
    if (answers['extra5'] !== undefined && answers['extra5'].length > 0) {
      splitTxt = doc.splitTextToSize(answers['extra5'], 160)
      rows4 = 2 * newRowHeight + splitTxt.length * newRowHeight
    }
    if (answers['extra6'] !== undefined && answers['extra6'].length > 0) {
      splitTxt = doc.splitTextToSize(answers['extra5'], 160)
      rows5 = 2 * newRowHeight + splitTxt.length * newRowHeight
    }
    var totalExtraHeight = rows1 + rows2 + rows3 + rows4 + rows5
    if (rowLine + totalExtraHeight > 270) {
      doc.addPage()
      page = page + 1
      addFooter(doc, answers)
      rowLine = 20
    }
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(standardFontSize + 2)
    doc.text('Zusätzliche Informationen', leftMargin, rowLine)
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(standardFontSize)
    rowLine = rowLine + newRowHeight
    rowLine = rowLine + newRowHeight
    if (answers['extra2'] !== undefined && answers['extra2'].length > 0) {
      doc.setFont('helvetica', 'bold')
      doc.text('Inkontinenzart', leftMargin, rowLine)
      doc.setFont('helvetica', 'normal')
      rowLine = drawMultilineText(doc, answers['extra2'], rowLine, newRowHeight, leftMargin)
      rowLine = rowLine + newRowHeight
      rowLine = rowLine + newRowHeight
    }
    if (answers['extra3'] !== undefined && answers['extra3'].length > 0) {
      doc.setFont('helvetica', 'bold')
      doc.text('Einflussfaktoren', leftMargin, rowLine)
      doc.setFont('helvetica', 'normal')
      rowLine = drawMultilineText(doc, answers['extra3'], rowLine, newRowHeight, leftMargin)
      rowLine = rowLine + newRowHeight
      rowLine = rowLine + newRowHeight
    }
    if (answers['extra4'] !== undefined && answers['extra4'].length > 0) {
      doc.setFont('helvetica', 'bold')
      doc.text('Mobilität', leftMargin, rowLine)
      doc.setFont('helvetica', 'normal')
      rowLine = drawMultilineText(doc, answers['extra4'], rowLine, newRowHeight, leftMargin)
      rowLine = rowLine + newRowHeight
      rowLine = rowLine + newRowHeight
    }
    if (answers['extra5'] !== undefined && answers['extra5'].length > 0) {
      doc.setFont('helvetica', 'bold')
      doc.text('Befürfnisse', leftMargin, rowLine)
      doc.setFont('helvetica', 'normal')
      rowLine = drawMultilineText(doc, answers['extra5'], rowLine, newRowHeight, leftMargin)
      rowLine = rowLine + newRowHeight
      rowLine = rowLine + newRowHeight
    }
    if (answers['extra6'] !== undefined && answers['extra6'].length > 0) {
      doc.setFont('helvetica', 'bold')
      doc.text('Allgemein', leftMargin, rowLine)
      doc.setFont('helvetica', 'normal')
      rowLine = drawMultilineText(doc, answers['extra6'], rowLine, newRowHeight, leftMargin)
      rowLine = rowLine + newRowHeight
      rowLine = rowLine + newRowHeight
    }
  }
  return rowLine
}

function drawMultilineText (doc, text, rowLine, newRowHeight, leftMargin) {
  var splitTitle = doc.splitTextToSize(text, 160)

  // loop thru each line and output while increasing the vertical space
  for (var c = 0, stlength = splitTitle.length; c < stlength; c++) {
    rowLine = rowLine + newRowHeight
    doc.text(splitTitle[c], leftMargin, rowLine)
  }
  return rowLine
}
