<template>
  <div id="impressum">
    <div class='breadcrumb'>
      <!-- ><h2 v-if="currentWizardStep === 0">{{questionsComplete[currentWizardStep].segmentName}}</h2> -->
      <h2>Impressum</h2>
      <div class='breadcrumbArrow'>
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>
    <div class="content">
      <article>
      <section>
      <p>
        PAUL HARTMANN AG<br>
        Paul-Hartmann-Straße 12<br>
        89522 Heidenheim<br>
        Deutschland<br>
        <br>
        Telefon: +49-7321-36-0 <br>
        Fax: +49-7321-36-3636 <br>
        <br>
        E-Mail: <a href="mailto:info@hartmann.info">info@hartmann.info</a><br>
        <br>
        Vertretungsberechtigte / Vorstand: <br>
        Andreas Joehle (Vorsitzender) <br>
        Dr. Raymund Heinen<br>
        Michel Kuehn <br>
        Stephan Schulz <br>
        <br>
        Handelsregister: <br>
        HRB 661090 Amtsgericht Ulm (Germany) <br>
        <br>
        Umsatzsteueridentifikationsnummer: <br>
        DE 145 566 683
      </p>
      <p>
        WEEE-Reg.Nr.: DE 26216897
      </p>
      </section>
      <section>
      <h2>Haftung für Inhalte</h2>
      <p>
      Die Inhalte der Seiten der PAUL HARTMANN AG wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter ist die PAUL HARTMANN AG gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
      <br>
      <br>
      Nach § 8 bis 10 TMG ist die PAUL HARTMANN AG als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekannt werden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      </section>
      <section>
      <h2>Urheberrecht</h2>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Alle Rechte an Texten, Abbildungen, Audio/Video-Dateien und sämtlichen weiteren veröffentlichten Informationen behält sich die PAUL HARTMANN AG vor. Eine Reproduktion, Verarbeitung, Vervielfältigung, Verbreitung sowie Speicherung in Datenverarbeitungsanlagen des Ganzen oder von Teilen, gleich welcher Art, bedarf einer vorherigen schriftlichen Zustimmung der PAUL HARTMANN AG.
      </p>
      <p>
        Wir sind damit einverstanden, dass&nbsp;Bilder und Graphiken herunterladen und im Rahmen des geltenden Presse- und Urheberrechts in eigenen Publikationen und Vorträgen einsetzen, wenn die Darstellung insgesamt positiver Natur ist und Sie deutlich sichtbar die Angabe "Abbildung: PAUL HARTMANN AG" erkennen lassen. Änderungen an den Motiven sind nicht zulässig. Eine Übertragung der Rechte an Dritte ist ausgeschlossen.
      <br>
      <br>
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekannt werden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>
      </section>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impressum'
}
</script>
