<template>
  <div id="loginScreen">
    <div id="headerPic">
    </div>
    <div class="formContainer">
      <!-- <h3><img :src="require('@/assets/img/inco_logo.svg')"></h3> -->
      <form @submit.prevent="login">
        <h1>Anmelden</h1>
        <div>
          <div>
            <label for="userName">Benutzername</label><br/>
            <input type="text" id="userName" required v-model="username" autocomplete="username" />
          </div>
          <div>
            <label for="userPassword">Passwort</label><br/>
            <input id="userPassword" required v-model="password" type="password" autocomplete="current-password"/>
          </div>
        </div>
        <div>
          <b-button v-if="!showFullversionBtn" type="submit" >Anmelden</b-button>
          <b-button v-if="showFullversionBtn" @click="applyForFullVersion" >Vertragsversion beantragen</b-button>
        </div>
      </form>
      <div>
        <span @click="resendPwdMail" style="text-decoration: underline; cursor: pointer; color:#00BD71; margin-top: 40px; display: block;" >Passwort zurücksetzen</span>
      </div>
      <div>
        <p style="padding: 20px 0px; color: #F6A800">{{loginError}}<br/>
          <span  v-if="showResendEmailButton" style="color: #fff; text-decoration: underline; cursor: pointer;" @click="resendMail">E-Mail erneut senden</span>
        </p> 
        <span  v-if="resendSuccess" style="color: #fff; ">E-Mail wurde erneut an {{mail}} gesendet</span> 
        <span  v-if="fullVersionSuccess" style="color: #fff; ">Eine E-Mail wurde an Sie versendet und ihr Testzeitraum verlängert. Sie können sich jetzt anmelden.</span> 
      </div>
     
      <div style="margin-top: 80px">
        <a style="color: #fff; text-decoration: underline" href="https://hartmann.info/de-DE/Impressum" target="_blank">Impressum</a> | <a style="color: #fff; text-decoration: underline" href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutzerklärung</a>
      </div>
    </div>
  </div>

</template>

<script>
  
import DataService from '@/services/DataService'
import {AUTH_REQUEST} from '@/store/actions/auth'
import { REMOVE_REG_MAIL } from '@/store/modules/data'
import {EventBus} from '@/services/EventBus.js'
export default {
  name: 'Login',
  created () {
    

    // this.$ga.set('dimension2', this.getProfile)
    this.eventBusObject.$on('applySuccessful', ($event) => {
      this.showFullversionBtn = false
    })
  },
  data () {
    return {
      username: '',
      password: '',
      eventBusObject: EventBus,
      loginError: '',
      mail:'',
      resendSuccess: false,
      showResendEmailButton : false,
      showResetPwdButton:true,
      showFullversionBtn: false,
      fullVersionToken: '',
      fullVersionUserId: 0,
      fullVersionSuccess: false
    }
  },
  methods: {
    resendPwdMail: function () {
      this.eventBusObject.$emit('resetPwd')
    },
    resendMail: function () {
      const self = this
    
      this.showResendEmailButton = false
      DataService.resendEmail(this.mail, this.username, false).then((response) => {
        self.resendSuccess = true
      }).catch((err) => {
        console.log(err)
        self.loginError = 'Der Benutzername ist nicht bekannt oder schon verifiziert.'
      })
    },
    login: function () {
      this.$store.dispatch(REMOVE_REG_MAIL)
      this.resendSuccess = false
      const self = this
      const { username, password } = this
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        this.$router.push('/')
        this.eventBusObject.$emit('loggedIn')
      }).catch(function (error) {
        var errorText = ''

        if (error.data.error === 1) {
          errorText = 'Die Anmeldung ist fehlgeschlagen. Es gibt keinen Benutzer mit diesem Benutzernamen in unserem System. Bitte schauen Sie nach Ihren Benutzernamen in der Email, mit der Sie Ihre E-Mail-Adresse bestätigt haben. Alternativ rufen Sie unseren Kundenservice an, um Ihren Benutzernamen zu erfragen.'
        }
        if (error.data.error === 2) {
          self.mail = error.data.mail
          self.showResetPwdButton = true
          errorText = 'Die Anmeldung ist fehlgeschlagen. Der Benutzername und / oder das Passwort ist falsch. Sie haben noch 2 Versuche.'
        }
        if (error.data.error === 3) {
          self.mail = error.data.mail
          self.showResetPwdButton = true
          errorText = 'Die Anmeldung ist fehlgeschlagen. Der Benutzername und / oder das Passwort ist falsch. Sie haben noch einen Versuch.'
        }
        if (error.data.error === 4) {
          errorText = 'Die Anmeldung ist fehlgeschlagen. Sie haben sich 3 mal mit einer falschen Kombination von Passwort und Benutzernamen versucht einzuloggen. Ihr Account wurde deshalb aus Sichherheitsgründen gesperrt. Bitte setzen Sie Ihr Passwort zurück.'
        }
        if (error.data.error === 5) {
          errorText = 'Die Anmeldung ist fehlgeschlagen. Ihr Account ist deaktiviert. Bitte setzen Sie Ihr Passwort zurück oder wenden Sie sich an unseren Kundenservice, um Ihren Account wieder freischalten zu lassen.'
        }
        if (error.data.error === 6) {
          errorText = 'Die Anmeldung ist fehlgeschlagen. Ihre Testphase ist abgelaufen. Bitte wenden Sie sich an unseren Kundenservice für einen Vertragsabschluss um INCOAID dauerhaft zu nutzen.'
          if (error.data.token != null) {
            errorText = 'Die Anmeldung ist fehlgeschlagen. Ihre Testphase ist abgelaufen. Sie können jetzt zur kostenpflichtigen Vertragsversion wechseln. Ihre gespeicherten Daten bleiben erhalten.';
            self.showFullversionBtn = true
            self.showResendEmailButton = false
            self.fullVersionToken = error.data.token
            self.fullVersionUserId = error.data.id
          }
        }
        if (error.data.error === 7) {
          errorText = 'Die Anmeldung ist fehlgeschlagen. Ihre E-Mail-Adresse muss noch bestätigt werden.'
          self.mail = error.data.mail
          self.showResendEmailButton = true
        }
        self.loginError = errorText
      })
    },
    
    applyForFullVersion: function () {
      this.eventBusObject.$emit('showApplyModal', this.fullVersionUserId, this.fullVersionToken)
      this.errorText = ""
    },
    loginTestAccount: function () {
      /*
      const self = this
      const { username, password } = {username: 'Andrea-Nicole.Ziegler@hartmann.info', password: '12incoaidpass!'}
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        this.$router.push('/')
        this.eventBusObject.$emit('loggedIn')
      }).catch(function (error) {
        self.showLoginError = true
        console.log('Login fehlgeschlagen' + error.status)
      })
      */
    }
  }
}
</script>
