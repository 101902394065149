Zeigt z.B. die im Anamnese Tool gemachten Angaben an.
Je nach Route werden verschiedene Informationen angezeigt.
Die Kommunikation mit anderen Templates findet über den Eventbus statt.
Zeigt eine kurze Anamnese Zusammenfassung (Summary) in einem Collapse Container an.
<template>
  <div id="header">
    <div style="overflow: auto; margin-bottom: 20px; position: relative;">
      <img id="logo_hartmann" v-if="showFullLogos" :src="require('../assets/img/logo_hartmann.svg')">
      <router-link id="logo" v-if="showFullLogos" to="/"><img :src="require('../assets/img/logo_incoaid.svg')"></router-link>
      <router-link id="logo-big" v-if="!showFullLogos" to="/"><img :src="require('../assets/img/logo_incoaid.svg')"></router-link>
      <router-link v-if="showLoginButton"  id="loginButton" to="/login">Sie haben bereits ein Konto?<button>Anmelden</button></router-link>  
      <router-link v-if="route=='login'" id="registerButton" to="/register">Sie haben noch kein Konto?<button>Registrieren</button></router-link>  
      <div class="contactInfo" v-if="showLoginButton || route=='login'"> 
        <p>Kundensupport: <span class="phoneNumber">0800 7235585</span></p>
        <span>(Montag - Freitag 8.00 - 16.30 Uhr)</span>
      </div>
      <div id="hinweisTestphase" v-if="institutionData.Testphase===1 && institutionData.VollversionStandard===0"><span>Demoversion – <strong>{{institutionData.DaysLeft}}</strong> Tage verbleiben</span></div>
      <div class="blue" id="hinweisTestphase" v-if="institutionData.Testphase===1 && institutionData.VollversionStandard===1"><span class="blue">Vertragserstellung – Demoversion noch <strong>{{institutionData.DaysLeft}}</strong> Tage</span></div>
      <b-button  class="applyButton" v-if="institutionData.Testphase===1 && institutionData.VollversionStandard===0" @click="applyForFullVersion" >Vertragsversion beantragen</b-button>

      <div v-if="route!='cms' && route!='cms-base' && isAuthenticated==true" @click.stop="toggleSidebar(sidebarStatus)" :class="sidebarStatus" id="menuButton">
        <span class="menuButtonText" v-if="sidebarStatus === 'closed'">Menu</span>
        <span v-if="sidebarStatus === 'open'">X</span>
      </div>
      <div id="cmsHeader" v-if="route=='cms' || route=='cms-base'">
        <div style="float:left;">
          Datenverwaltungssystem <router-link class="backToApp" to="/">zum Tool</router-link>
        </div>
        <div style="float:right;">
          <router-link id="accountButton" to="/myAccount">Account: <span style="color: #323E48">{{getProfile}}</span></router-link>
        </div>
      </div>
    </div>
    <div v-if="route=='wizard' || route=='cart' || route=='products' || route=='recommendations' || route=='selling'" id="patientInfo">
        <table>
          <tr>
            <td v-if="answerObject.lastname !== undefined && answerObject.givenname !== undefined">
              <strong>{{answerObject.lastname}}, {{answerObject.givenname}}</strong>
            </td>
            <td v-if="answerObject.birthdate !== undefined">
              {{birthdateInRightFormat}}
            </td>
            <td v-if="answerObject['krankenkasse'] !== undefined">
              {{answerObject['krankenkasse'].Kasse}}<span></span>
            </td>
            <td>
              <span v-if="answerObject['krankenkasse'] !== undefined && answerObject['krankenkasse'].Kasse !== 'Selbstzahler' && answerObject['krankenkasse'].Kasse !== 'Private Krankenkasse' && answerObject['krankenkasse'].Kasse !== 'Keine Krankenkasse'" style="color: #999">Pauschale {{calculatePauschale()}}€/Monat (netto) | {{calculateBruttoPauschale()}}€/Monat (brutto)</span>
            </td>
          </tr>
        </table>
        <b-btn v-if="route=='cart' || route=='products' || route=='recommendations'" :class="'anamneseButton ' + anamneseIsShown().class" @click="showAnamneseCollapse = !showAnamneseCollapse">{{anamneseIsShown().text}}</b-btn>
    </div>
    <b-collapse v-model="showAnamneseCollapse" id="anamneseContainer">
      <Summary :summaryObject="summaryObject" />
    </b-collapse>
  </div>

</template>

<script>

import Summary from './Summary.vue'
import {generateSummary, generateKVPauschale, formatFloatToStringUtil, getMwst,birthdateInRightFormatUtil} from '../utils/util.js'
import {answerStorage} from '../utils/localstorage.js'
import DataService from '@/services/DataService'
import {EventBus} from '@/services/EventBus.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  components: {
    Summary
  },
  props: {
    answerObject: Object,
    sidebarStatus: String
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'getUserId', 'getToken']),
    route: function () {
      return this.$route.name
    },
    birthdateInRightFormat: function () {
      return birthdateInRightFormatUtil(this.answerObject['birthdate'])
      
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    },
    showFullLogos : function () {
      return this.$route.name=='home' || this.$route.name=='login' || this.$route.name=='createPassword' || this.$route.name=='register' || this.$route.name=='welcome' || this.$route.name=='registeruser' || this.$route.name=='registerpass' || this.$route.name=='success' || this.$route.name=='verify' || this.$route.name=='passwd' || this.$route.name=='resetpwd'
    },
    showLoginButton : function () {
      return this.$route.name=='welcome' || this.$route.name=='register' || this.$route.name=='registeruser' || this.$route.name=='registerpass' || this.$route.name=='success' || this.$route.name=='verify' || this.$route.name=='passwd' || this.$route.name=='resetpwd'
    }
  },
  created: function () {
    this.loadData()
    this.eventBusObject.$on('updateSummary', ($event) => {
      var answer = answerStorage.fetch()
      this.summaryObject = generateSummary(this.wizard, answer)
    })
    this.eventBusObject.$on('closeSummary', ($event) => {
      this.showAnamneseCollapse = false
    })
    this.eventBusObject.$on('loggedIn', ($event) => {
      this.loadData()
    })
    this.eventBusObject.$on('loggedOut', ($event) => {
      this.institutionData = {}
    })
    DataService.fetchWizard().then((response) => {
      var answer = answerStorage.fetch()
      this.wizard = response.data
      this.summaryObject = generateSummary(this.wizard, answer)
    }).catch((err) => {
      // handle error
      console.log(err)
    })
  },
  data: function () {
    return {
      summaryObject: {},
      institutionData: {},
      wizard: {},
      eventBusObject: EventBus,
      showAnamneseCollapse: false
    }
  },
  methods: {
    applyForFullVersion: function () {
      this.eventBusObject.$emit('showApplyModal', this.getUserId, this.getToken)
    },
    loadData: function () {
      if (this.getProfile != null && this.getProfile!= "") {

        // ToDo own function
          var self = this
          DataService.getUserData(this.getUserId).then((response) => {
            var days = 0  
            if (response.data.Testphase) {
              days = self.calcDaysTillEnd(response.data.Testbeginn, response.data.PaketObjekt.Testphasendauer)
            }

            self.institutionData = {
              "VollversionStandard": response.data['PaketObjekt']['VollversionStandard'],
              "VollversionVorgemerkt": response.data['VollversionVorgemerkt'],
              "Testphase": response.data['Testphase'],
              "DaysLeft": days
            }
          
          }).catch((err) => {
            // handle error
            console.log(err)
          })
      }
    
    },
    toggleSidebar: function (sidebarStatus) {
      if (sidebarStatus === 'open') {
        this.$parent.$emit('toggleSidebar', 'closed')
      } else {
        this.$parent.$emit('toggleSidebar', 'open')
      }
    },
    calculatePauschale: function () {
      return formatFloatToStringUtil(generateKVPauschale(this.answerObject))
    },
    calculateBruttoPauschale: function () {
      return formatFloatToStringUtil(generateKVPauschale(this.answerObject) + generateKVPauschale(this.answerObject) * getMwst())
    },
    anamneseIsShown: function () {
      if (this.showAnamneseCollapse === true) {
        return {'class': 'active', 'text': 'Anamnese ausblenden'}
      } else {
        return {'class': '', 'text': 'Anamnese einblenden'}
      }
    },
    calcDaysTillEnd: function (date, testdays) {
      var testDate = new Date(date)
      testDate.setDate(testDate.getDate() + testdays)
      var diff = new Date(testDate - new Date())     
      diff = parseInt((testDate-new Date())/(24*3600*1000))
      return diff
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('updateSummary')
    this.eventBusObject.$off('closeSummary')
    this.eventBusObject.$off('loggedIn')
    this.eventBusObject.$off('loggedOut')
  }
}
</script>
