Container für die verschiedenen Einstellungsseiten, die in Tabs dargestellt werden.
Je nach Recht sind verschiedene Tabs ausgeblendet. Der Speichern-Knopf befindet sich im Footer und kommuniziert über den Eventbus mit den Untertemplates.
Hier werden die Modals angezeigt.
<template>
<div>
  <div class='breadcrumb'>
    <h2>Einstellungen</h2>
    <div id="statusBar">
      <button @click="tabIndex = 0" :disabled="disabledFn(0)">Kontaktdaten</button>
      <button @click="tabIndex = 1" :disabled="disabledFn(1)">Benutzer</button>
      <button @click="tabIndex = 2" :disabled="disabledFn(2)">Krankenkassen</button>
      <button @click="tabIndex = 3" :disabled="disabledFn(3)">Produktpreise</button>
      <button @click="tabIndex = 4" :disabled="disabledFn(4)">Berechnungsmethode</button>
    </div>
    <div class='breadcrumbArrow'>
      <div :style="positionOfStepIndicator()" class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="four"></div>
    </div>
  </div>
  <b-tabs v-model="tabIndex">
    <b-tab title="">
      <DataSettings id="datasettings" :currentIndex="tabIndex" ref="DataSettings"/>
    </b-tab>
    <b-tab  title="">
      <Usermanagement v-if="showUsermanagement" id="usermanagement" :currentIndex="tabIndex"/>
      <div class="m-2" v-else>
        Sie haben leider nicht die passenden Berechtigungen
      </div>
    </b-tab>
    <b-tab title="">
      <KvSettings id="kvsettings" :currentIndex="tabIndex" />
    </b-tab>
    <b-tab title="">
      <PriceSettings id="pricesettings" :currentIndex="tabIndex" />
    </b-tab>
    <b-tab title="">
      <CalcSettings id="calcsettings" :currentIndex="tabIndex" />
    </b-tab>
  </b-tabs>
  <b-modal ref="deleteModal" hide-footer title="Wollen Sie wirklich alles löschen?">
    <div class="d-block text-center">
      <div>
        <b-button @click="modalYes" style="margin-right: 10px">Ja</b-button>
        <b-button @click="modalNo">Nein</b-button>
      </div>
    </div>
  </b-modal>
  <b-modal ref="saveModal" hide-footer title="Erfolgreich gespeichert">
    <div class="d-block text-center">
      <div>
        <b-button @click="$refs.saveModal.hide()">Ok</b-button>
      </div>
    </div>
  </b-modal>
  <b-modal ref="errorModal" hide-footer title="Es gab einen Fehler beim Speichern. Bitte überprüfen Sie die Eingaben.">
    <div class="d-block text-center">
      <div>
        <b-button @click="$refs.errorModal.hide()">Ok</b-button>
      </div>
    </div>
  </b-modal>
  <b-modal ref="errorAddModal" hide-footer title="Es gab einen Fehler beim Hinzufügen. Bitte überprüfen Sie die Eingaben.">
    <div class="d-block text-center">
      <div>
        <b-button @click="$refs.errorAddModal.hide()">Ok</b-button>
      </div>
    </div>
  </b-modal>

  <b-modal ref="changedModal" hide-footer title="Erfolgreich gespeichert">
    <div class="d-block text-center">
      <div>
        <p>
          Bitte beachten Sie, dass die Änderungen nur für die Anzeige auf den generierten PDFs „Dokumentation“ und „Versorgungsvorschlag“ gelten. 
          Ihre für den Versand der PDFs hinterlegte E-Mail Adresse können Sie im Menü unter dem Reiter „Benutzer“ ändern.
        </p>
        <b-button @click="$refs.changedModal.hide()">Ok</b-button>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>

import KvSettings from './KvSettings.vue'
import PriceSettings from './PriceSettings.vue'
import DataSettings from './DataSettings.vue'
import CalcSettings from './CalcSettings.vue'
import Usermanagement from './Usermanagement.vue'
import {EventBus} from '@/services/EventBus.js'
import { mapGetters } from 'vuex'

export default {
  name: 'KvSettingsContainer',
  components: {
    KvSettings, PriceSettings, DataSettings, CalcSettings, Usermanagement
  },
  created: function () {
  
    this.$nextTick().then(() => {
      this.tabIndex = parseInt(this.$route.params.index)
    })
    this.eventBusObject.$on('deleteThisSettingsEventually', ($event) => {
      this.$refs.deleteModal.show()
    })
    this.eventBusObject.$on('saveSettingsDone', ($event) => {
      this.$refs.saveModal.show()
    })
    this.eventBusObject.$on('saveSettingsError', ($event) => {
      this.$refs.errorModal.show()
    })
    this.eventBusObject.$on('AddKvError', ($event) => {
      this.$refs.errorAddModal.show()
    })
    this.eventBusObject.$on('ChangedMail', ($event) => {
      this.$refs.changedModal.show()
    })
  },
  watch: {
    
    // for route/tab changes from sideMenu:
    '$route.params.index': function (index) {
      this.tabIndex = parseInt(this.$route.params.index)
    },
    
    tabIndex: function (newIndex, oldIndex) {
         // set url to fit current tab
         //catch error because of https://github.com/vuejs/vue-router/issues/2881
         if (newIndex != oldIndex) {
          this.$router.replace('/settings/' + this.tabIndex).catch(err => err)
         }

    }
    
  },
  data: function () {
    return {
      tabIndex: 0,
      nextTabIndex: 0,
      eventBusObject: EventBus
    }
  },
  methods: {
    positionOfStepIndicator: function () {
      if (this.tabIndex === 0) {
        return 'width: 25px'
      }
      if (this.tabIndex === 1) {
        return 'width: 120px'
      }
      if (this.tabIndex === 2) {
        return 'width: 220px'
      }
      if (this.tabIndex === 3) {
        return 'width: 330px'
      }
      if (this.tabIndex === 4) {
        return 'width: 470px'
      }
    },
    modalYes: function () {
      this.eventBusObject.$emit('deleteThisSettings')
      this.$refs.deleteModal.hide()
    },
    modalNo: function () {
      this.$refs.deleteModal.hide()
    },
    disabledFn: function (x) {
      if (x == 0) {
        return !this.showContactSettings
      }
      if (x == 1) {
        return !this.showUsermanagement
      }
      if (x == 2) {
        return !this.showKVSettings
      }
      if (x == 3) {
        return !this.showPriceSettings
      }
      if (x == 4) {
        return !this.showCalcSettings
      }
    },
  },
  computed: {
    rightButtonText: function () {
      if (this.currentWizardStep < 4) {
        if (this.currentWizardStep === 0 && this.showAnamnese == true) {
          return 'Anamnese starten'
        } else {
          return 'weiter'
        }
      } else {
        return 'Empfehlung anzeigen'
      }
    },
    ...mapGetters(['getRights']),
    showUsermanagement: function () {
      return this.getRights.indexOf('insti_nutzer') !== -1
    },
    showContactSettings: function () {
      return this.getRights.indexOf('kont_einst') !== -1
    },
    showPriceSettings: function () {
      return this.getRights.indexOf('preis_einst') !== -1
    },
    showKVSettings: function () {
      return this.getRights.indexOf('kassen_einst') !== -1
    },
    showCalcSettings: function () {
      return this.getRights.indexOf('berechn_einst') !== -1
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('saveSettingsDone')
    this.eventBusObject.$off('deleteThisSettingsEventually')
    this.eventBusObject.$off('saveSettingsError')
    this.eventBusObject.$off('AddKvError')
    this.eventBusObject.$off('ChangedMail')
  }
}

</script>
