 <template>
  <div id="verifyScreen">
    <div id="headerPic">
    </div>
    <div class="formContainer">
      <h1 style="color: white;">
        {{answer}}
      </h1>
      <p v-if="pwdsuccess"><b-button><router-link to="/login">Jetzt anmelden</router-link></b-button></p>  
      <form v-if="showForm" @submit.prevent="resetPwd">
        <div>
          <h2>Passwort festlegen</h2>
          <p>Kriterien:</p>
            <ul style="margin-left: 0px;">
              <li>mind. 8 Zeichen</li>
              <li>mind. ein Groß- und ein Kleinbuchstabe</li>
              <li>ein Sonderzeichen (z.B. !#*@)</li>
              <li>und mind. eine Ziffer (123..)</li>
            </ul>
        </div>
        <div class="flexBlock" style="justify-content: flex-start">
          <div style="margin-right: 5%;">
            <label for="password">Passwort*</label><br/>
            <b-form-input id="password" required v-model="password" type="password"/>
          </div>
          <div>
            <label for="passwordRepeat">Passwort wiederholen*</label><br/>
            <b-form-input id="passwordRepeat" required v-model="passwordRepeat" type="password"/>
          </div>
        </div>
        <div>
          <b-button class="registerButton" type="submit">Passwort speichern</b-button>
        </div>
      </form>
      <div>
        <p style="padding: 20px 0px; color: #F6A800">{{createError}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DataService from '@/services/DataService'
import {EventBus} from '@/services/EventBus.js'
import { AUTH_LOGOUT } from '@/store/actions/auth'

export default {
  name: 'EmailVerify',
  data () {
    return {
      eventBusObject: EventBus,
      answer: "Überprüfe Daten...",
      showForm: false,
      password: '',
      passwordRepeat: '',
      createError: '',
      pwdsuccess: false
    }
  },
  created: function () {
    this.checkToken()
    this.$store.dispatch(AUTH_LOGOUT).then(() => this.eventBusObject.$emit('loggedOut'))
  },
  methods: {
    validatePassword: function (pw) {
      return /[A-Z]/.test(pw) &&
              /[a-z]/.test(pw) &&
              (/[0-9]/.test(pw) &&
              /[^A-Za-z0-9]/.test(pw)) &&
              pw.length > 7
    },
    resetPwd: function () {
      const self = this
      if (this.password !== this.passwordRepeat) {
          this.createError = 'Die beiden Passwörter stimmen nicht überein.'
        } else {
          if (this.validatePassword(this.password) === true) {
            this.createError = ''
            var id = this.$route.query.id
            var token = this.$route.query.token
            DataService.resetPassword(id, token, this.password).then(function (response) {
              if (response.data.correct == true) {
                self.showForm = false
                self.pwdsuccess = true
                self.answer = "Password erfolgreich geändert!"
              } else {
                self.answer =  "Falsche Parameter übermittelt, Passwor wurde nicht geändert."
              }  
            }).catch(function (response) {
              
              console.log(response)
            })
          
          } else {
            this.createError = 'Bitte überprüfen Sie Ihr Passwort und stellen Sie sicher, dass es die erforderlichen Kritieren erfüllt.'
          }
        }
      
    },
    checkToken:  function () {
      var id = this.$route.query.id
      var token = this.$route.query.token
      const self = this
      if (id != null && token != null) {
        DataService.verifyEmail(id, token, false).then(function (response) {
          if (response.data.correct == true) {
            
            self.answer =  ""
            self.showForm = true
           
          } else {
            self.answer =  "Falsche Parameter"
          }  
        }).catch(function (response) {
          console.log(response)
        })
      } else {
        this.answer = "Keine Queryparameter gefunden"
      }
    }
    
  },
  computed: {
    
  }
}
</script>
