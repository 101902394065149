Einstellungsseite für die Krankenkassen, die man in der Anamnese auswählen kann. Diese werden in der Datenbank gespeichert.
Der Speicherknopf befindet sich im Footer und kommuniziert über den Eventbus.
<template>
  <div class="settings">
    <div>
      <h5>Dies ist Ihre Auswahl der Krankenkassen:</h5>
      <ul style="height: 100px; overflow: scroll">
          <li class="kv-tag" v-for="kv in kvSelected" :key="kv.Kasse">
            {{kv.Kasse}} <span @click="deleteSingeKv(kv)">x</span>
          </li>
      </ul>
    </div>
    <div style="max-width: 900px">
      <h5 style="float:left">Bitte wählen Sie hier die Krankenkassen aus, die Sie in der Berechnung haben möchten:</h5>
      <button @click="addAllKvs()" style="border: 1px solid #00BD71; color: #00BD71; background: transparent; margin-bottom: 20px; float: right">Alle Krankenkassen hinzufügen</button>
      <input v-model="filterString" id="kvFilter" placeholder="Name">
      <div class="kvContainer">
        <table>
          <tr :class="isVisible(kv.Kasse)" v-for="kv in kvComplete" :key="kv.Kasse">
            <td>{{kv.Kasse}}</td>
            <!-- <td>{{kv.Kasse}} ( KP = {{kv.Kinderpauschale}} | UP = {{kv.Urinpauschale}} | SP = {{kv.Stuhlpauschale}} | KA = {{kv.Kindalter}} )</td> -->
            <td>
              <button :class="isInKvArray(kv.Kasse) + ' ' + 'kvEditButton'" @click="fillKvArray(kv)">
                +
              </button>
            </td>
          </tr>
        </table>
      </div>

      <h5 style="clear: both; margin-top: 40px; padding-top: 40px; border-top: 1px solid rgb(0, 189, 113);">Weitere Krankenkasse (Netto-Preise) hinzufügen: {{extraKv.Kasse}}</h5>
       <table style="margin-bottom: 80px">
          <tr>
              <th>Name</th>
              <th>Kinderpauschale</th>
              <th>Urinpauschale</th>
              <th>Stuhlpauschale</th>
              <th>Kindalter</th>
              <th></th>
            </tr>
        <tr>
          <td> <input v-model='extraKv.Kasse'></td>
          <td><input style="width:85%" type="number" v-model='extraKv.Kinderpauschale'> €</td>
          <td><input style="width:85%" type="number" v-model='extraKv.Urinpauschale'> €</td>
          <td><input style="width:85%" type="number" v-model='extraKv.Stuhlpauschale'> €</td>
          <td><input type="number" v-model='extraKv.Kindalter'></td>
          <td>
            <button :class="isInKvArray(extraKv.Kasse) + ' ' + 'kvEditButton'" @click="fillKvArray(extraKv)">
              +
            </button>
          </td>
        </tr>
       </table>
     </div>
  </div>
</template>

<script>

import DataService from '@/services/DataService'
import {EventBus} from '@/services/EventBus.js'
import { mapGetters } from 'vuex'

export default {
  name: 'KvSettings',
  props: {
    currentIndex: Number
  },
  data: function () {
    return {

      // all selected KVs in one Array
      kvSelected: [],
      filterString: '',
      modalShow: false,
      kvComplete: {},
      extraKv: {'Kasse': '','Kinderpauschale': 0, 'Urinpauschale': 0, 'Stuhlpauschale': 0, 'Kindalter': 18},
      eventBusObject: EventBus
    }
  },
  created: function () {
    this.loadData()
   
    this.eventBusObject.$on('saveSettings', ($event) => {
      if (this.currentIndex === 2) {
        this.saveKvArrayToStorage()
      }
    })
    this.eventBusObject.$on('reloadSettings', ($event) => {
      this.loadData()
    })
    this.eventBusObject.$on('deleteThisSettings', ($event) => {
      if (this.currentIndex === 2) {
        this.deleteStorage()
      }
    })
  },

  methods: {
    loadData: function () {
      DataService.fetchOwnKVs(this.getInstitution).then((response) => {
        this.kvSelected = response.data
        DataService.fetchKrankenkassen().then((response) => {
          this.kvComplete = response.data
        }).catch((err) => {
          // handle error
          console.log(err)
        })
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    deleteStorage: function () {
      this.kvSelected = []
      this.saveKvArrayToStorage()
    },
    deleteSingeKv: function (kv) {
      var isInArray = false
      var where = 0
      for (var i = 0; i < this.kvSelected.length; i++) {
        if (this.kvSelected[i].Kasse === kv.Kasse) {
          isInArray = true
          where = i
        }
      }
      if (isInArray === true) {
        this.kvSelected.splice(where, 1)
      }
    },

    saveKvArrayToStorage: function () { 
      DataService.setOwnKVs(this.kvSelected, this.getInstitution).then((response) => {
        this.modalShow = false 
        this.eventBusObject.$emit('saveSettingsDone')
        this.$ga.set('dimension3', this.kvSelected.length)
        this.$ga.event('Einstellungen Kategorie', 'Krankenkassen gespeichert (Aktion)', 'Krankenkassen gespeichert (Ereignis)', this.kvSelected.length)
        this.$ga.page('KV - gespeichert')
      }).catch((err) => {
        // handle error
        console.log(err)
      })
     
    },

    fillKvArray: function (kv) {
      var kvCopy = Object.assign({}, kv)
      kvCopy['Krankenkassenid'] = kv.id
      kvCopy['id'] = null
      if (kv.Kasse != null && kv.Kasse !== '' && kv.Kinderpauschale != null && kv.Kinderpauschale !== '' && kv.Urinpauschale != null && kv.Urinpauschale !== '' && kv.Stuhlpauschale != null && kv.Stuhlpauschale !== ''&& kv.Kindalter != null && kv.Kindalter !== '') {
        var kindPausch = 0
        if (parseFloat(kv.Kinderpauschale.toString().replace(",", ".")) != null) {
          kindPausch = parseFloat(kv.Kinderpauschale.toString().replace(",", "."))
        }
        var urinPausch = 0 
        if (parseFloat(kv.Urinpauschale.toString().replace(",", ".")) != null) {
          urinPausch = parseFloat(kv.Urinpauschale.toString().replace(",", "."))
        }
        var stuhlPausch = 0
        if (parseFloat(kv.Stuhlpauschale.toString().replace(",", ".")) != null) {
          stuhlPausch = parseFloat(kv.Stuhlpauschale.toString().replace(",", "."))
        }
        kvCopy['Kinderpauschale'] = kindPausch
        kvCopy['Urinpauschale'] = urinPausch
        kvCopy['Stuhlpauschale'] = stuhlPausch


        var isInArray = false
        var where = 0
        for (var i = 0; i < this.kvSelected.length; i++) {
          if (this.kvSelected[i].Kasse === kv.Kasse) {
            isInArray = true
            where = i
          }
        }
        if (isInArray === true) {
          this.kvSelected.splice(where, 1)
        } else {
          this.kvSelected.push(kvCopy)
        }
        this.extraKv = {'Kasse': '','Kinderpauschale': 0, 'Urinpauschale': 0, 'Stuhlpauschale': 0, 'Kindalter': 18}
      } else {
        this.eventBusObject.$emit('AddKvError')
      }
    },

    addAllKvs: function () {
      var tempSelected = JSON.parse(JSON.stringify(this.kvSelected))
      for (var i = 0; i < this.kvComplete.length; i++) {
        // this.fillKvArray(this.kvComplete[i])

        var kvCopy = Object.assign({}, this.kvComplete[i])
        var isInArray = false

        for (var u = 0; u < tempSelected.length; u++) {
          if (tempSelected[u].Kasse === this.kvComplete[i].Kasse) {
            isInArray = true
          }
        }
        if (isInArray === true) {

        } else {
          this.kvSelected.push(kvCopy)
        }
        this.extraKv = {}
      }
    },

    isInKvArray: function (name) {
      for (var i = 0; i < this.kvSelected.length; i++) {
        if (this.kvSelected[i].Kasse === name) {
          return 'remove'
        }
      }
    },
    isVisible: function (name) {
      if (name.toLowerCase().indexOf(this.filterString.toLowerCase()) !== 0) {
        return 'invisible'
      }
    }
  },
  computed: {
    ...mapGetters(['getInstitution'])
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('saveSettings')
    this.eventBusObject.$off('deleteThisSettings')
    this.eventBusObject.$off('reloadSettings')
  }
}

</script>
