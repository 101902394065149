 <template>
  <div id="verifyScreen">
    <div id="headerPic">
    </div>
    <div class="innerContainer">
      <h1 style="color: white;" v-html="answerheadline"></h1>
      <p v-if="subtext!=''" style="color: white;" v-html="subtext"></p>
      <p style="color: white;" ><b-button><router-link to="/login">Jetzt anmelden</router-link></b-button></p>  
    </div>
  </div>
</template>

<script>
import DataService from '@/services/DataService'

export default {
  name: 'EmailVerify',
  data () {
    return {
      answerheadline: "Überprüfe Daten...",
      subtext: ''
    }
  },
  created: function () {
    this.checkToken()
  },
  methods: {
    checkToken:  function () {
      var id = this.$route.query.id
      var token = this.$route.query.token
      const self = this
      if (id != null && token != null) {
        DataService.verifyEmail(id, token, true).then(function (response) {
          if (response.data.correct == true) {
            if (response.data.userchanged > 0) {
              self.answerheadline =  "Ihre E-Mail-Adresse wurde erfolgreich bestätigt!"
            } else {
              self.answerheadline =  "Sie haben den Benutzer bereits bestätigt."
            }
          } else {
            self.answerheadline =  "Der Link ist bereits abgelaufen." 
            self.subtext = "Bitte klicken Sie auf den Bestätigungslink, den wir Ihnen als Letztes zugeschickt haben, oder wenden Sie sich an das Kundencenter."
          }  
        }).catch(function (response) {
          console.log(response)
        })
      } else {
        this.answerheadline = "<b>Keine Parameter gefunden</b>"
      }
    }
    
  },
  computed: {
    
  }
}
</script>
