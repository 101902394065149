<template>
  <div id="feedback">
    <div class='breadcrumb'>
      <h2>Hilfe & Support</h2>
      <div class='breadcrumbArrow'>
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>
    <div class="content">
      <div>
        <h3>Kontaktieren Sie uns</h3>
        <p>
          Sie können uns unter unserer Support-Hotline telefonisch erreichen:<br />
          <a style="color: #00BD71; font-size: 24px">0800 7235585</a>
        </p>
        <p>
          <strong>Unsere Servicezeiten:</strong><br />
          Montag bis Freitag: 8.00 Uhr - 16:30 Uhr
        </p>
        <p>
          Vielen Dank,<br />
          Ihr PAUL HARTMANN Kundencenter
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Feedback',
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded'])
  }
}
</script>
