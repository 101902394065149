Containerobject für das DMS
<template>
  <div id="cms">
      <b-tabs nav-wrapper-class="cmsNavigation" v-model="tabIndex">
        <b-tab @click="tabChange" title="Kundeninstitutionen">
          <Accounts />
        </b-tab>
        <b-tab @click="tabChange" title="Benutzerverwaltung">
          <Users/>
        </b-tab>
        <b-tab @click="tabChange" title="Rollen">
            <Roles />
        </b-tab>
        <b-tab @click="tabChange" title="Pakete">
          <Pakets />
        </b-tab>
        <b-tab @click="tabChange" title="Produkte" title-item-class="right">
          <Products />
        </b-tab>
        <b-tab @click="tabChange" title="Wettbewerberliste">
          <Competitor />
        </b-tab>
        <b-tab @click="tabChange" title="Krankenkassen">
          <KV />
        </b-tab>
        <b-tab @click="openHelp()" title="Hilfe">
        </b-tab>
      </b-tabs>
  </div>
</template>

<script>
import KV from './KV.vue'
import Accounts from './Accounts.vue'
import Users from './Users.vue'
import Competitor from './Competitor.vue'
import Products from './Products.vue'
import Roles from './Roles.vue'
import Pakets from './Pakets.vue'
export default {
  name: 'Admin',
  components: {
    KV, Accounts, Users, Competitor, Products, Roles, Pakets
  },
  created () {
  },
  data: function () {
    return {
      tabIndex: 0
    }
  },
  methods: {
    tabChange: function () {
    },
    openHelp () {
      window.open("/static/INCOAID_DMS_Handbuch.pdf", "_blank");
    }
  }
}
</script>
