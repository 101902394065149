Verwaltet die Liste an der Benutzern. Das "clickedUser"-Objekt speichert die Daten beim Editieren oder Neuanlegen.

Die Anzeige wird über die eigenen Rechte und die API gesteuert. HARTMANN-Admins dürfen alle Benutzer sehen. 
Institutions-Admins nur die Benutzer der eigenen Institution. 
Neue Benutzer werden mit dem Passwort "Incoaid2019!" angelegt und in den Zustand "wartend" gesetzt.
<template>
  <div class="accounts">
      <div class="headerLine">
        <b-button style="float:left; margin-right: 10px; " v-if="hartmann || (isInstitution && userArray.length < institution.PaketObjekt.Nutzerlimit)" @click="create()">Benutzer hinzufügen</b-button>
        <input class="searchField" style="float: left; width: 250px;" type="text" v-model="search" placeholder="Benutzer oder Rolle suchen">
        <div v-if="userArray.length >= institution.PaketObjekt.Nutzerlimit">Sie haben ihr Limit an Benutzern erreicht, bitte wenden Sie sich an den HARTMANN Support.</div>
      </div>
      <h5>alle Benutzer ({{matches.length}})</h5>
      <table>
        <tr>
          <th class="special">
            <input type="text" v-model="searchVorname" placeholder="Vorname">
            <span @click="sortBy='vornameUp'" :class = "'sort ' +isActive('vornameUp')"></span>
            <span @click="sortBy='vornameDown'" :class = "'sort ' +isActive('vornameDown')"></span>
          </th>
          <th class="special">
            <input type="text" v-model="searchNachname" placeholder="Nachname">
            <span @click="sortBy='nachnameUp'" :class = "'sort ' +isActive('nachnameUp')"></span>
            <span @click="sortBy='nachnameDown'" :class = "'sort ' +isActive('nachnameDown')"></span>
          </th>
          <th class="special">
            <input type="text" v-model="searchBenutzer" placeholder="Benutzername">
            <span @click="sortBy='benutzerUp'" :class = "'sort ' +isActive('benutzerUp')"></span>
            <span @click="sortBy='benutzerDown'" :class = "'sort ' +isActive('benutzerDown')"></span>
          </th>
          <th class="special" v-if="hartmann || isInstitution">
            <input type="text" v-model="searchMail" placeholder="Email Adresse">
            <span @click="sortBy='mailUp'" :class = "'sort ' +isActive('mailUp')"></span>
            <span @click="sortBy='mailDown'" :class = "'sort ' +isActive('mailDown')"></span>
          </th>
          <th v-if="hartmann">
            erstellt am
          </th>
          <th v-if="!hartmann && !isInstitution">
            <input type="text" v-model="searchKundeninstitution" placeholder="Institution">
            <span @click="sortBy='instituionUp'" :class = "'sort ' +isActive('instituionUp')"></span>
            <span @click="sortBy='instituionDown'" :class = "'sort ' +isActive('instituionDown')"></span>
          </th>
          <th class="special" v-if="!hartmann && !isInstitution">
            <input type="text" v-model="searchId" placeholder="Kunden Nr.">
            <span @click="sortBy='numberUp'" :class = "'sort ' +isActive('numberUp')"></span>
            <span @click="sortBy='numberDown'" :class = "'sort ' +isActive('numberDown')"></span>
          </th>
          <th v-if="!hartmann && !isInstitution">
            <input type="text" v-model="searchOrt" placeholder="Ort">
            <span @click="sortBy='ortUp'" :class = "'sort ' +isActive('ortUp')"></span>
            <span @click="sortBy='ortDown'" :class = "'sort ' +isActive('ortDown')"></span>
          </th>
          <th v-if="!hartmann && !isInstitution">
            <input type="text" v-model="searchPLZ" placeholder="Plz">
            <span @click="sortBy='plzUp'" :class = "'sort ' +isActive('plzUp')"></span>
            <span @click="sortBy='plzDown'" :class = "'sort ' +isActive('plzDown')"></span>
          </th>
          <th class="special">
            Status
            <span @click="sortBy='statusUp'" :class = "'sort ' +isActive('statusUp')"></span>
            <span @click="sortBy='statusDown'" :class = "'sort ' +isActive('statusDown')"></span>
          </th>
          <th v-if="isInstitution">
            Rolle
          </th>
        </tr>
        <template v-for="(userObject, key) in matches">
          <tr class="dataRow" :key="key" @click="edit(key)">
            <td>{{userObject.Vorname}}</td>
            <td>{{userObject.Nachname}}</td>
            <td>{{userObject.Loginname}}</td>
            <td v-if="hartmann || isInstitution">{{userObject.Mail}}</td>
            <td v-if="hartmann">{{userObject.created}}</td>
            <td v-if="!hartmann && !isInstitution">{{userObject.Institution.Name}}</td>
            <td v-if="!hartmann && !isInstitution">{{userObject.Institution.Kundennummer}}</td>
            <td v-if="!hartmann && !isInstitution">{{userObject.Institution.Ort}}</td>
            <td v-if="!hartmann && !isInstitution">{{userObject.Institution.PLZ}}</td>
            <td>{{userObject.Status}}</td>
            <td v-if="isInstitution">{{setRoleName(userObject.RollenId)}}</td>
          </tr>
        </template>
      </table>
      <b-modal id="editUserModal" modal-class="editModal" ref="editUserModal" size="lg" hide-footer hide-header title="">
        <div v-if="editMode==false">
          <div>
            <h4>{{clickedUser.Vorname}} {{clickedUser.Nachname}} <span @click="editMode=true" >bearbeiten</span></h4>
            <div class="dataContainer">
              <div id="userData">
                <div class="customRow">
                  <div class="oneFour">
                    <span>Kontaktperson:</span>
                     {{transformAnrede(clickedUser.Anrede)}} {{clickedUser.Vorname}}  {{clickedUser.Nachname}}
                  </div>
                  <div class="oneFour">
                    <span>Emailadresse:</span>
                    {{clickedUser.Email}}
                  </div>
                  
                  <div v-if="clickedUser.RollenId != 1" class="oneFour">
                    <span>Rolle:</span>
                    {{setRoleName(clickedUser.RollenId)}}
                  </div>
                </div>
                <div class="customRow">
                  <div class="oneFour">
                    <span>Benutzername:</span>
                    {{clickedUser.Loginname}}
                  </div>
                  <div class="oneFour">
                    <span>Passwort:</span>
                    <div>*****</div>
                  </div>
                  <div>
                    <span>Status:</span>
                    {{clickedUser.Status}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
              <h4>{{clickedUser.Vorname}} {{clickedUser.Nachname}}</h4>
              <div class="dataContainer">
                <div id="userData">
                  <div class="customRow">
                     <div v-if="createMode==true" class="oneFour">
                      <span>Anrede*</span>
                       <select v-model='clickedUser.Anrede'>
                        <option value="0">Frau</option>
                        <option value="1">Herr</option>
                      </select>
                    </div>
                    <div v-if="createMode==true" class="oneFour">
                      <span>Vorname*</span>
                      <input type="text" v-model='clickedUser.Vorname'>
                    </div>
                    <div v-if="createMode==true" class="oneFour">
                      <span>Nachname*</span>
                      <input type="text" v-model='clickedUser.Nachname'>
                    </div>
                    <div v-if="createMode==false" class="oneFour">
                      <span>Kontaktperson:</span>
                         {{transformAnrede(clickedUser.Anrede)}} {{clickedUser.Vorname}} {{clickedUser.Nachname}}
                    </div>
                    <div class="oneFour">
                      <span>Email*</span>
                      <input type="email" v-model='clickedUser.Email'>
                    </div>
                 
                    <!-- dont' show roles for superadmins -->
                    <div v-if="clickedUser.RollenId != 1" class="oneFour">
                      <span>Rolle*</span>
                       <!-- dont' show select roles for admins -->
                      <select  v-if="clickedUser.RollenId != 2" v-model = 'clickedUser.RollenId'>
                        <option v-for="(role, key) in filteredRoles" v-bind:value="role.id" :key="key">
                          {{ role.Name }}
                        </option>
                      </select>
                      <div v-else>
                        {{setRoleName(clickedUser.RollenId)}}
                      </div>
                    </div>
                  </div>
                  <div class="customRow">
                    <div class="oneFour">
                      <span>Benutzername</span>
                      <div>{{createLoginName()}}</div>
                    </div>
                    <div class="oneFour">
                      <span>Passwort</span>
                      <div @click="resetMode = true" style="text-decoration: underline; cursor: pointer" v-if="createMode === false && resetMode === false">zurücksetzen</div>
                      <div v-if="createMode === false && resetMode === true">
                        <h5 style="font-size: 16px; display: inline-block">Jetzt zurücksetzen?</h5>
                        <span style="display: inline-block" @click="resetPassword()"><img :src="require('../../assets/img/cms_check.png')"></span>
                        <span style="display: inline-block" @click="resetMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
                      </div>
                      <div v-if="createMode === true">wird gesetzt</div>
                    </div>
                    <div class="oneFour" v-if="clickedUser.RollenId != 2 || !isInstitution">
                      <span>Status*</span>
                      <div v-if="createMode === false && clickedUser.Status !== 'inaktiv'" @click="deactivateUser()" style="text-decoration: underline; cursor: pointer">Benutzer deaktivieren</div>
                      <div v-if="clickedUser.Status === 'inaktiv' && createMode === false" @click="resetPassword()" style="text-decoration: underline; cursor: pointer">Benutzer freischalten</div>
                      <div style="font-weight: normal; font-size: 13px;" v-if="clickedUser.Status === 'inaktiv' && createMode === false">Das vergebene Passwort des Benutzers wird dadurch zurückgesetzt.</div>
                      <div v-if="createMode === true">{{clickedUser.Status}}</div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div style="overflow: auto">
            <b-button v-if="createMode==false && clickedUser.RollenId != 2 && !isInstitution" class="deleteUser" @click="deleteMode = true; deletePermanentMode = false">Löschen</b-button>
            <b-button v-if="createMode==false && clickedUser.RollenId != 2 && isInstitution" class="deleteUser" @click="deletePermanentMode = true; deleteMode = false">Löschen</b-button>
            <b-button style="margin-left: 12px;" v-if="createMode==false && clickedUser.RollenId != 2 && !isInstitution" class="deleteUser" @click="deletePermanentMode = true; deleteMode = false">Löschen (DSGVO konform)</b-button>
            <b-button :class="buttonIsClickable().text" v-bind:disabled="buttonIsClickable().boolean" @click="save()">Speichern</b-button>
          </div>
          <div v-if="createMode==false && isInstitution && clickedUser.RollenId == 2 ">
            Dies ist ein Benutzer mit der Rolle "Administrator". Falls Sie ihn löschen möchten, <router-link to="/feedback">kontaktieren Sie bitte unser Kundencenter</router-link>.
          </div>
          <div style="clear: both; border-top: 1px solid #999; margin-top: 10px;" v-if="createMode === false && deleteMode === true">
            <h5 style="font-size: 16px; display: inline-block">Jetzt löschen?</h5>
            <span style="display: inline-block" @click="deleteUser(false)"><img :src="require('../../assets/img/cms_check.png')"></span>
            <span style="display: inline-block" @click="deleteMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
          </div>
          <div style="clear: both; border-top: 1px solid #999; margin-top: 10px;" v-if="createMode === false && deletePermanentMode === true">
            <h5 style="font-size: 16px; display: inline-block">Jetzt <span v-if="!isInstitution">DSGVO konform </span>löschen?</h5>
            <span style="display: inline-block" @click="deleteUser(true)"><img :src="require('../../assets/img/cms_check.png')"></span>
            <span style="display: inline-block" @click="deletePermanentMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
          </div>
        </div>
        <div class="closeButton" @click="back()" ></div>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="pwResetModal" hide-footer hide-header title="">
        <h5 v-if="resetMode === true">Das Passwort wurde erfolgreich zurückgesetzt.</h5>
        <h5 v-if="resetMode === false">Benutzer wurde freigeschaltet und sein Passwort zurückgesetzt.</h5>
        <label>Das Initialpasswort lautet:</label>
        <div style="position: relative">
          <input :type="passwordVisibleType" :value="standardPassword" autocomplete="new-password">
          
          <img v-if="passwordVisibleType=='password'" @click="passwordVisibleType='text'" :src="require('../../assets/img/visibility.svg')" class="visibilityStatus"/>
          <img v-if="passwordVisibleType=='text'" @click="passwordVisibleType='password'" :src="require('../../assets/img/visibility_off.svg')" class="visibilityStatus"/>
        </div>
        
        <b-button @click="$refs.pwResetModal.hide()" class="modalButton">Ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="deactivateUserModal" hide-footer hide-header title="">
        <div class="d-block text-center">
          <h5>Der Benutzer wurde erfolgreich deaktiviert.</h5>
          <b-button @click="$refs.deactivateUserModal.hide()" class="modalButton">Ok</b-button>
        </div>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="deleteUserModal" hide-footer hide-header title="">
        <div class="d-block text-center">
          <h5>Der Benutzer wurde erfolgreich gelöscht.</h5>
          <b-button @click="$refs.deleteUserModal.hide()" class="modalButton">Ok</b-button>
        </div>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="newUserModal" hide-footer hide-header title="">
        <h5>Ein neuer Benutzer wurde erfolgreich hinzugefügt.</h5>
        <label>Das Initialpasswort lautet:</label>
        <div style="position: relative">
          <input :type="passwordVisibleType" :value="standardPassword" autocomplete="new-password">
          <img v-if="passwordVisibleType=='password'" @click="passwordVisibleType='text'" :src="require('../../assets/img/visibility.svg')" class="visibilityStatus"/>
          <img v-if="passwordVisibleType=='text'" @click="passwordVisibleType='password'" :src="require('../../assets/img/visibility_off.svg')" class="visibilityStatus"/>
        </div>
        <b-button @click="$refs.newUserModal.hide()" class="modalButton">Ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="editedUserModal" hide-footer hide-header title="">
        <div class="d-block text-center">
          <h5>Der Benutzer wurde erfolgreich bearbeitet.</h5>
          <b-button @click="$refs.editedUserModal.hide()" class="modalButton">Ok</b-button>
        </div>
      </b-modal>
  </div>
</template>

<script>
import DataService from '@/services/DataService'
import {mapGetters} from 'vuex'
import {EventBus} from '@/services/EventBus.js'
import {birthdateInRightFormatUtil} from '../../utils/util.js'

export default {
  name: 'Accounts',
  props: {
    hartmann: Boolean,
    isInstitution: Boolean // false if hartmann admin
  },
  data: function () {
    return {
      roles: [],
      institution: {PaketObjekt: {}},
      institutionen: [],
      userArray: [],
      editMode: false,
      createMode: false,
      resetMode: false,
      deleteMode: false,
      deletePermanentMode: false,
      clickedUser: {},
      search: '',
      searchId: '',
      searchKundeninstitution: '',
      searchVorname: '',
      searchNachname: '',
      searchOrt: '',
      searchBenutzer: '',
      searchMail: '',
      sortBy: '',
      searchPLZ: '',
      eventBusObject: EventBus,
      standardPassword: 'Incoaid2019!',
      passwordVisibleType: 'password'
    }
  },
  created () {
    this.loadData()
    this.eventBusObject.$on('updateUserCMSTab', ($event) => {
      this.loadData()
    })
  },
  methods: {
    loadData: function () {
      
      DataService.fetchRoles().then((response) => {
        this.roles = response.data
        if (!this.isInstitution) {
          DataService.getAllInstitutionsData().then((response) => {
            this.institutionen = response.data
            this.userArray = []
            for (var i in this.institutionen) {
              var institution = this.institutionen[i]
              if ((!this.hartmann && institution.Name !== 'PAUL HARTMANN AG') || (this.hartmann && institution.Name === 'PAUL HARTMANN AG')) {
                for (var j in institution.Benutzer) {
                  var user = institution.Benutzer[j]
                  var userObject = {}
                  var IEreadyString = user.created.replace(/-/g, '/')
                  var newDateString = birthdateInRightFormatUtil(IEreadyString) 
                  userObject['Anrede'] = user.Anrede
                  userObject['Vorname'] = user.Vorname
                  userObject['Nachname'] = user.Nachname
                  userObject['Loginname'] = user.Loginname
                  userObject['id'] = user.id
                  userObject['Mail'] = user.Mail
                  userObject['Status'] = user.Status
                  userObject['RollenId'] = user.RollenId
                  userObject['Institution'] = institution
                  userObject['Gelesen'] = user.Gelesen
                  userObject['created'] = newDateString
                  userObject['RollenName'] = ''
                  for (var roleIndex in this.roles) {
                    if (this.roles[roleIndex].id == user.RollenId) {
                      userObject['RollenName'] = this.roles[roleIndex].Name
                    }
                  }      
                  this.userArray.push(userObject)
                }
              }
            }
          }).catch((err) => {
            // handle error
            console.log(err)
          })
        } else {
          DataService.getInstitutionData(this.getInstitution).then((response) => {
          this.institution = response.data
          this.userArray = []
  
            // console.log(JSON.stringify(institution))
            for (var j in this.institution.Benutzer) {
              var user = this.institution.Benutzer[j]
              var userObject = {}
              var IEreadyString = user.created.replace(/-/g, '/')
              var newDateString = birthdateInRightFormatUtil(IEreadyString) 
              userObject['Anrede'] = user.Anrede
              userObject['Vorname'] = user.Vorname
              userObject['Nachname'] = user.Nachname
              userObject['Loginname'] = user.Loginname
              userObject['id'] = user.id
              userObject['Mail'] = user.Mail
              userObject['Status'] = user.Status
              userObject['RollenId'] = user.RollenId
              userObject['Gelesen'] = user.Gelesen
              userObject['Institution'] = this.institution
              userObject['created'] = newDateString
              userObject['RollenName'] = ''
              for (var roleIndex in this.roles) {
                if (this.roles[roleIndex].id == user.RollenId) {
                  userObject['RollenName'] = this.roles[roleIndex].Name
                }
              }      
                
              this.userArray.push(userObject)
            }
            
          }).catch((err) => {
            // handle error
            console.log(err)
          })
        }
      }).catch((err) => {
            // handle error
            if (err.response.status === 401) {
              this.eventBusObject.$emit('tokenInvalid')
            } 
            console.log(err)
          })
    },
    edit: function (key) {
      this.createMode = false
      this.resetMode = false
      this.deleteMode = false
      this.deletePermanentMode = false
      this.editMode = false
      var user = this.matches[key]
      var newUser = {}
      newUser['Anrede'] = user.Anrede
      newUser['Vorname'] = user.Vorname
      newUser['Nachname'] = user.Nachname
      newUser['BenutzerID'] = user.id
      newUser['Email'] = user.Mail
      newUser['Status'] = user.Status
      newUser['Loginname'] = user.Loginname
      newUser['RollenId'] = user.RollenId
      newUser['Gelesen'] = user.Gelesen
      newUser['Institution'] = user.Institution.id
      this.clickedUser = newUser
      this.$refs.editUserModal.show()
    },
    save: function () {
      if (this.requiredAnswers() === true) {
        // divide model into institution and user and update database
        var user = {}
        user['id'] = this.clickedUser.BenutzerID
        user['Anrede'] = this.clickedUser.Anrede
        user['Vorname'] = this.clickedUser.Vorname
        user['Nachname'] = this.clickedUser.Nachname
        user['Mail'] = this.clickedUser.Email
        user['Status'] = this.clickedUser.Status
        user['RollenId'] = this.clickedUser.RollenId
        user['Loginname'] = this.clickedUser.Loginname
        user['Gelesen'] = this.clickedUser.Gelesen
        user['Institution'] = this.clickedUser.Institution
        DataService.setUserData(user).then((response) => {
          this.loadData()
          if (this.createMode === true) {
            this.$refs.newUserModal.show()
          } else {
            this.$refs.editedUserModal.show()
          }
        }).catch((err) => {
          // handle error
          console.log(err)
        })
        this.$refs.editUserModal.hide()
        this.editMode = false
      } else {

      }
    },
    deleteUser: function (dsvgo) {
      DataService.deleteUser(this.clickedUser.BenutzerID, dsvgo).then((response) => {
          this.loadData()
          this.$refs.editUserModal.hide()
          this.$refs.deleteUserModal.show()
        }).catch((err) => {
          // handle error
          console.log(err)
        })
    },
    create: function () {
      var institutionId = 0
      if (this.hartmann == true) 
      {
        institutionId = 1
      }
      else if (this.isInstitution == true) {
        institutionId = this.getInstitution
      }

      var roleId = 0
      if (this.hartmann == true) 
      {
        roleId = 1
      }
      else if (this.isInstitution == true) {
        for (var key in this.roles) {
          if (this.roles[key].istStandard == 1) {
            roleId = this.roles[key].id
          }
        }
      }
   
      this.clickedUser = {
        Status: 'wartend',
        RollenId: roleId,
        Gelesen: 1,
        Institution: institutionId
      }
      this.editMode = true
      this.createMode = true
      this.$refs.editUserModal.show()
    },
    back: function () {
      // reload user
      this.editMode = false
      this.$refs.editUserModal.hide()
    },
    buttonIsClickable: function () {
      if (this.requiredAnswers() === true) {
        return {
          text: 'enabled',
          boolean: false
        }
      } else {
        return {
          text: 'disabled',
          boolean: true
        }
      }
    },
    createLoginName: function () {
      if (this.clickedUser.Loginname !== '' && this.clickedUser.Loginname !== undefined) {
        return this.clickedUser.Loginname
      } else {
        return 'wird generiert'
      }
    },
    resetPassword: function () {
      const self = this
      DataService.resetUserPW(this.clickedUser.BenutzerID).then((response) => {
        self.loadData()
        self.$refs.editUserModal.hide()
        self.editMode = false
        self.$refs.pwResetModal.show()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    deactivateUser: function () {
      const self = this
      DataService.deactivateUser(this.clickedUser.BenutzerID).then((response) => {
        self.loadData()
        self.$refs.editUserModal.hide()
        self.editMode = false
        self.$refs.deactivateUserModal.show()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    requiredAnswers: function () {
      if (
        this.clickedUser.Vorname !== '' && this.clickedUser.Vorname !== undefined &&
        this.clickedUser.Nachname !== '' && this.clickedUser.Nachname !== undefined &&
        this.clickedUser.Email !== '' && this.clickedUser.Email !== undefined
      ) {
        return true
      } else {
        return false
      }
    },
    sortDown: function (a, b) {
      if (a === b) {
        return 0
      }
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1
      }
    },
    sortUp: function (a, b) {
      if (a === b) {
        return 0
      }
      if (a.toLowerCase() < b.toLowerCase()) {
        return 1
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return -1
      }
    },
    isActive: function (sortString) {
      if (sortString === this.sortBy) {
        return 'active'
      } else {
        return ''
      }
    },
    setRoleName: function (roleId) {
      for (var key in this.roles) {
        if (this.roles[key].id == roleId) {
          return this.roles[key].Name
        }
      }
      return 'keine Rolle'
    },
    transformAnrede: function (anrede) {
      if (anrede == 0) {
        return "Frau"
      } else {
        return "Herr"
      }
    }
  },
  computed: {
    ...mapGetters(['getInstitution', 'isAdmin']),
    filteredRoles () {
      return this.roles.filter(function(role) {
        return role.id !== 2
      })
    },
    tableFields () { return
      [
          {
            key: 'Vorname',
            sortable: true
          },
          {
            key: 'Nachname',
            sortable: true
          }
        ]
    },
    matches () {
      const self = this
      var newArrayForSorting = this.userArray
      newArrayForSorting.sort(function (a, b) {
        switch (self.sortBy) {
          case 'vornameUp':
            return self.sortUp(a.Vorname, b.Vorname)
          case 'vornameDown':
            return self.sortDown(a.Vorname, b.Vorname)
          case 'nachnameUp':
            return self.sortUp(a.Nachname, b.Nachname)
          case 'nachnameDown':
            return self.sortDown(a.Nachname, b.Nachname)

          case 'benutzerUp':
            return self.sortUp(a.Loginname, b.Loginname)
          case 'benutzerDown':
            return self.sortDown(a.Loginname, b.Loginname)

          case 'mailUp':
            return self.sortUp(a.Mail, b.Mail)
          case 'mailDown':
            return self.sortDown(a.Mail, b.Mail)

          case 'numberUp':
            return self.sortUp(a.Institution.Kundennummer, b.Institution.Kundennummer)
          case 'numberDown':
            return self.sortDown(a.Institution.Kundennummer, b.Institution.Kundennummer)

          case 'institutionUp':
            return self.sortUp(a.Institution.Name, b.Institution.Name)
          case 'institutionDown':
            return self.sortDown(a.Institution.Name, b.Institution.Name)

          case 'ortUp':
            return self.sortUp(a.Institution.Ort, b.Institution.Ort)
          case 'ortDown':
            return self.sortDown(a.Institution.Ort, b.Institution.Ort)

          case 'plzUp':
            return self.sortUp(a.Institution.PLZ+'', b.Institution.PLZ+'')
          case 'plzDown':
            return self.sortDown(a.Institution.PLZ+'', b.Institution.PLZ+'')
          

          case 'statusUp':
            return self.sortUp(a.Status, b.Status)
          case 'statusDown':
            return self.sortDown(a.Status, b.Status)

          default:
            return self.sortDown(a.Kundennummer, b.Kundennummer)
        }
      })
      return newArrayForSorting.filter((element) => {
        var elementNr = element.Institution.Kundennummer
        var elementKundeninstituion = element.Institution.Name
        var elementVorname = element.Vorname
        var elementNachname = element.Nachname
        var elementBenutzer = element.Loginname
        var elementMail = element.Mail
        var elementOrt = element.Institution.Ort
        var elementPLZ = element.Institution.PLZ + ''
        var elementStatus = element.Status
        var elementRoleName = element.RollenName
        var returnBool = ((
         elementKundeninstituion.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementVorname.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementNachname.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || 
         elementBenutzer.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementMail.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || 
         elementOrt.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementNr.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementPLZ.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementStatus.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementRoleName.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         this.search.length === 0) &&
         (elementNr.toLowerCase().indexOf(this.searchId.toLowerCase()) >= 0 || this.searchId.length === 0) &&
         (elementKundeninstituion.toLowerCase().indexOf(this.searchKundeninstitution.toLowerCase()) >= 0 || this.searchKundeninstitution.length === 0) &&
         (elementVorname.toLowerCase().indexOf(this.searchVorname.toLowerCase()) >= 0 || this.searchVorname.length === 0) &&
         (elementNachname.toLowerCase().indexOf(this.searchNachname.toLowerCase()) >= 0 || this.searchNachname.length === 0) &&
         (elementBenutzer.toLowerCase().indexOf(this.searchBenutzer.toLowerCase()) >= 0 || this.searchBenutzer.length === 0) &&
         (elementMail.toLowerCase().indexOf(this.searchMail.toLowerCase()) >= 0 || this.searchMail.length === 0) &&
         (elementOrt.toLowerCase().indexOf(this.searchOrt.toLowerCase()) >= 0 || this.searchOrt.length === 0) &&
         (elementPLZ.toLowerCase().indexOf(this.searchPLZ.toLowerCase()) >= 0 || this.searchPLZ.length === 0))
        return returnBool
      })
    }
  },
  beforeDestroy: function () {
    this.eventBusObject.$off('updateUserCMSTab')
  }
}
</script>
