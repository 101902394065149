Zeigt je nach URL und Rollen-Recht unterschiedliche Informationen an.
Zb. wird der Verorgungsrechner bei manchen Rollen übersprungen, deshalb werden hier die Links angepasst.
Kommuniziert auch über den Eventbus mit den Templates.
<template>
  <div v-if="route!=='welcome' && route!=='register' && route!=='registeruser' && route!=='registerpass' && route!=='success'" id="footer">
    <div id="segmentNavi">
    <template v-if="route==='products' || route==='impressum' || route==='cookiepolicy' || route==='feedback' || route ==='settings' || route ==='passwd' || route ==='myAccount' || route ==='createPassword' ">
      <b-button @click="$router.go(-1)">zurück</b-button>
    </template>

    <template v-if="route=='cart'">
      <template v-if="showAnamnese">
        <router-link to="/wizard/4"><b-button class="left">zurück zur Anamnese</b-button></router-link>
      </template>
      <template v-else>
        <router-link to="wizard/0"><b-button class="left">zurück</b-button></router-link>
      </template>
      <router-link to="/offer"><b-button class="right">zur Zusammenfassung</b-button></router-link>
    </template>

    <template v-if="route=='recommendations'">
      <router-link to="/wizard/4"><b-button class="left">zurück</b-button></router-link>
      <b-button v-if="showVersorgung" @click="eventBusObject.$emit('saveRecommdenationAndGoToCart')" class="right">Versorgung berechnen</b-button>
      <b-button v-else @click="eventBusObject.$emit('saveRecommdenationAndGoToCart')" class="right">zur Zusammenfassung</b-button>
    </template>

    <template v-if="route=='offer'">
      <template v-if="showVersorgung">
        <router-link to="cart"><b-button class="left">zurück</b-button></router-link>
      </template>
      <template v-else>
        <template v-if="showAnamnese">
            <router-link to="recommendations"><b-button class="left">zurück</b-button></router-link>
        </template>
        <template v-else>
            <router-link to="wizard/0"><b-button class="left">zurück</b-button></router-link>
        </template>
      </template>
      <router-link to="/selling"><b-button class="right">weiter</b-button></router-link>
    </template>

    <template v-if="route=='selling'">
      <router-link to="/offer"><b-button class="left">zurück</b-button></router-link>
      <router-link to="/final"><b-button class="right action">Beratung abschließen</b-button></router-link>
      <b-button class="right" @click="eventBusObject.$emit('generatePHA')">Pflegehilfsmittelantrag generieren</b-button>
    </template>

    <template v-if="route=='final'">
      <router-link to="/selling"><b-button class="left">zurück</b-button></router-link>
    </template>

    <template v-if="route=='settings'">
      <b-button v-if="routePath!=='/settings/1'" @click="eventBusObject.$emit('saveSettings')" class="right">speichern</b-button>
      <b-button v-if="routePath!=='/settings/4' && routePath!=='/settings/1'  && routePath!=='/settings/0'" @click="eventBusObject.$emit('deleteThisSettingsEventually')" class="right">alle Einträge löschen</b-button>
    </template>
  </div>
  </div>
</template>

<script>

import {EventBus} from '@/services/EventBus.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',
  props: {
    count: Number
  },
  data: function () {
    return {
      eventBusObject: EventBus
    }
  },

  computed: {
    ...mapGetters(['getRights']),
    route: function () {
      return this.$route.name
    },
    routePath: function () {
      return this.$route.path
    },
    showVersorgung: function () {
      return this.getRights.indexOf('versorg_aufr') !== -1
    },
    showAnamnese: function () {
      return this.getRights.indexOf('bera_durch') !== -1
    }
  }
}
</script>
