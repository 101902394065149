import Vue from 'vue'

//filter for the product page
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'

//actions used during registration
export const ADD_REG_MAIL = 'ADD_REG_MAIL'
export const ADD_REG_MODEL = 'ADD_REG_MODEL'
export const REMOVE_REG_MAIL = 'REMOVE_REG_MAIL'
export const ADD_REG_USERNAME = 'ADD_REG_USERNAME'
export const REMOVE_REG_USERNAME = 'REMOVE_REG_USERNAME'


const state = { status: '', 
                filter: localStorage.getItem('filter') || null, 
                mail :  localStorage.getItem('mail') || null,
                username :  localStorage.getItem('username') || null,
                registerModel :  localStorage.getItem('registerModel') ||  '{"Kundennummer":"","KnrVerifiziert":false,"selectedVersion":0}'
              }

const getters = {
  getFilter: state => state.filter,
  getMail: state => state.mail,
  getUsername: state => state.username,
  getRegisterModel: state => JSON.parse(state.registerModel)
}

const actions = {
  [CHANGE_FILTER]: ({commit, dispatch}, filter) => {
    commit(CHANGE_FILTER, filter)
  },
  [REMOVE_FILTER]: ({commit, dispatch}) => {
    commit(REMOVE_FILTER)
  },
  [ADD_REG_MAIL]: ({commit, dispatch}, mail) => {
    commit(ADD_REG_MAIL, mail)
  },
  [ADD_REG_MODEL]: ({commit, dispatch}, model) => {
    commit(ADD_REG_MODEL, model)
  },
  [REMOVE_REG_MAIL]: ({commit, dispatch}) => {
    commit(REMOVE_REG_MAIL)
  },
  [ADD_REG_USERNAME]: ({commit, dispatch}, username) => {
    commit(ADD_REG_USERNAME, username)
  },
  [REMOVE_REG_USERNAME]: ({commit, dispatch}) => {
    commit(REMOVE_REG_USERNAME)
  }

}

const mutations = {
  [CHANGE_FILTER]: (state, filter) => {
    Vue.set(state, 'filter', filter)
    localStorage.setItem('filter', filter)
  },
  [REMOVE_FILTER]: (state) => {
    Vue.set(state, 'filter', null)
    localStorage.setItem('filter', null)
  },
  [ADD_REG_MODEL]: (state, knr) => {
    Vue.set(state, 'registerModel', JSON.stringify(knr))
    localStorage.setItem('registerModel', JSON.stringify(knr))
  },
  [ADD_REG_MAIL]: (state, mail) => {
    Vue.set(state, 'mail', mail)
    localStorage.setItem('mail', mail)
  },
  [REMOVE_REG_MAIL]: (state) => {
    Vue.set(state, 'mail', null)
    localStorage.setItem('mail', null)
  },
  [ADD_REG_USERNAME]: (state, username) => {
    Vue.set(state, 'username', username)
    localStorage.setItem('username', username)
  },
  [REMOVE_REG_USERNAME]: (state) => {
    Vue.set(state, 'username', null)
    localStorage.setItem('username', null)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
