Startbildschirm nach dem Login
<template>
  <div id="homeScreen">
    <div id="headerPic">
    </div>
    <div class="claim">
      <!-- ><h3><img :src="require('../assets/img/inco_logo.svg')"></h3> -->
      <p>
        INCOAID - Der neue Maßstab in der Inkontinenzberatung. Effizient. Einfach. Wirtschaftlich.
      </p>
      <b-button @click="showModal()" >Beratung beginnen</b-button>
    </div>
    <b-modal id="deleteDataModal" class="deleteDataModal" ref="deleteDataModal" hide-footer hide-header title="">
      <div class="d-block text-center">
        <h5>Sollen die bisherigen Daten des Betroffenen gelöscht werden?</h5>
      </div>
      <b-button @click="hideModal(true)" class="modalButton">Ja</b-button>
      <b-button @click="hideModal(false)" class="rightModalButton">Nein</b-button>
    </b-modal>
  </div>
</template>

<script>

import {cartArrayStorage, answerStorage, cartProductStorage} from '@/utils/localstorage.js'
import {OneLevelObjectIsEquivalent} from '@/utils/util.js'

export default {
  name: 'Home',
  props: {
    msg: String
  },
  methods: {
    hideModal: function (remove) {
      if (remove) {
        cartArrayStorage.remove()
        answerStorage.save({})
        cartProductStorage.save([])
      }
      this.$refs.deleteDataModal.hide()
      this.$nextTick().then(() => {
        this.$router.push('/wizard/0')
      })
    },
    showModal: function () {
      // go directly if already deleted
      if (OneLevelObjectIsEquivalent(answerStorage.fetch(), {}) && cartArrayStorage.fetch().length === 3 && cartProductStorage.fetch().length === 0) {
        this.$router.push('/wizard/0')
      } else {
        this.$refs.deleteDataModal.show()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

</style>
