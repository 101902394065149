Hier werden die Informationen des eingeloggten Benutzers angezeigt. Die Daten werden direkt vom Dataservice aus der Datenbank geladen.
Je nach Recht werden ander Links angezeigt.
<template>
  <div id="myAccount">
    <div class='breadcrumb'>
      <h2>Mein Account</h2>
      <div class='breadcrumbArrow'>
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
        <div class="four"></div>
      </div>
    </div>
    <div class="content">
      <div id="institutionData">
        <div class="customRow">
          <div class="oneFour">
            <span>Kundennummer:</span>
            {{accountData.Kundennummer}}
          </div>
          <div class="oneFour">
            <span>Institutionsname:</span>
            {{accountData.Name}}
          </div>
        </div>
        <div class="customRow">
          <div>
            <span>Adresse:</span>
            {{accountData.Strasse}} {{accountData.Hausnummer}}, {{accountData.PLZ}}   {{accountData.Ort}}
          </div>
        </div>
        <div class="customRow">
          <div class="oneFour">
            <span>Telefon:</span>
            {{accountData.Telefon}}
          </div>
        </div>
        <div v-if="showContactSettings">
         Die Institutionsdaten können in den <router-link to="/settings/0">Kontaktdaten</router-link> verändert werden.
        </div>
        <div v-else>
          Sollten sich Ihre Daten ändern, kontaktieren Sie bitte Ihren Administrator.
        </div>
      </div>
      <div id="userData">
        <div class="customRow">
          <div class="oneFour">
            <span>Kontaktperson:</span>
            {{accountData.Benutzer[0].Vorname}}  {{accountData.Benutzer[0].Nachname}}
          </div>
          <div>
            <span>Emailadresse:</span>
            {{accountData.Benutzer[0].Mail}}
          </div>
        </div>
        <div class="customRow">
          <div class="oneFour">
            <span>Benutzername:</span>
            {{accountData.Benutzer[0].Loginname}}
          </div>
          <div class="oneFour">
            <span>Passwort:</span>
            <div>*****</div>
          </div>
        </div>
        <div v-if="showUsermanagement">
          Die Benutzerdaten können in den <router-link to="/settings/1">Benutzer Einstellungen</router-link> verändert werden.
        </div>
        <div v-else>
          Sollten sich Ihre Daten ändern, kontaktieren Sie bitte Ihren Administrator.
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataService from '@/services/DataService'
export default {
  name: 'myAccount',
  data: function () {
    return {
      accountData: {Benutzer: [{}]}
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData: function () {
    
        DataService.getUserData(this.getUserId).then((response) => {
          this.accountData = response.data
        }).catch((err) => {
          // handle error
          console.log(err)
        })
     
    }
  },
  computed: {
    ...mapGetters(['getRights', 'getUserId']),
    showUsermanagement: function () {
      return this.getRights.indexOf('insti_nutzer') !== -1
    },
    showContactSettings: function () {
      return this.getRights.indexOf('kont_einst') !== -1
    },
  }
}
</script>
