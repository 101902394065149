Zeigt die Institutionen im DMS an.
Edititieren oder Neuanlegen öffnet ein Modal mit dem "clickedUserInstitution" Object. 
Beim Speichern wird dieses Object an den DataService übergeben.
Bei der Anzeige kann nach verschiedenen Kriterien gefiltert oder sortiert werden. Dies geschieht in der matches() Methode in Computed()
<template>
  <div id="accounts">
      <div class="headerLine">
        <b-button @click="create()" style="float:left">Institution hinzufügen</b-button>
       
        <input class="searchField" style="margin-left: 10px; float: left; width: 250px;" type="text" v-model="search" placeholder="Kundensuche">
        <div style="float: right; display: flex;">
          <div class="uploadMatchingTable" @click="uploadCSV('match')">
            <h4>Upload Matching Tabelle</h4>
            (Zuletzt geändert am {{LastCSVUpload}})
          </div>
          <div class="uploadMatchingTable" @click="uploadCSV('adm')">
            <h4>Upload ADM Tabelle</h4>
            (Zuletzt geändert am {{LastADMCSVUpload}})
          </div>
        </div>
       
      </div>
      <div style="clear:both; margin-top: 10px">
        <h5>alle Kunden ({{matches.length}})</h5>
      </div>
      <table>
        <tr>
          <th class="special">
            Demo
            <span @click="sortBy='testUp'" :class = "'sort ' +isActive('testUp')"></span>
            <span @click="sortBy='testDown'" :class = "'sort ' +isActive('testDown')"></span>
          </th>
          <th class="special" style="padding-right: 6px;">
            Vollv.
          </th>
          <th class="special">
            <input type="text" v-model="searchId" placeholder="Kunden-Nr.">
            <span @click="sortBy='numberUp'" :class = "'sort ' +isActive('numberUp')"></span>
            <span @click="sortBy='numberDown'" :class = "'sort ' +isActive('numberDown')"></span>
          </th>
          <th class="special">
            <input type="text" v-model="searchName" placeholder="Name">
            <span @click="sortBy='nameUp'" :class = "'sort ' +isActive('nameUp')"></span>
            <span @click="sortBy='nameDown'" :class = "'sort ' +isActive('nameDown')"></span>
          </th>
          <th><input type="text" v-model="searchOrt" placeholder="Ort"></th>
          <th><input type="text" v-model="searchPLZ" placeholder="PLZ"></th>
          <th class="special">
            Vertr.
            <span @click="sortBy='vertriebUp'" :class = "'sort ' +isActive('vertriebUp')"></span>
            <span @click="sortBy='vertriebDown'" :class = "'sort ' +isActive('vertriebDown')"></span>
          </th>
          <th class="special">BM
            <span @click="sortBy='bmUp'" :class = "'sort ' +isActive('bmUp')"></span>
            <span @click="sortBy='bmDown'" :class = "'sort ' +isActive('bmDown')"></span>
          </th>
          <th><input type="text" v-model="searchBenutzer" placeholder="Benutzer"></th>
          <th><input type="text" v-model="searchEmail" placeholder="Emailadresse"></th>
          <th class="special">
            Status
            <span @click="sortBy='statusUp'" :class = "'sort ' +isActive('statusUp')"></span>
            <span @click="sortBy='statusDown'" :class = "'sort ' +isActive('statusDown')"></span>
          </th>
        </tr>
        <template v-for="(accountsObject, key) in matches">
          <tr class="dataRow" :key="key" @click="edit(accountsObject.BenutzerID)">
            <td v-if="accountsObject.Testphase">
              <span v-if="accountsObject.Testende <= 0" class="redAlarm">Beendet</span>
              <span v-if="accountsObject.Testende > 0 && accountsObject.Testende < 4" class="orangeAlarm">{{accountsObject.Testende}} Tage</span>
              <span v-if="accountsObject.Testende >= 4" class="greenAlarm">{{accountsObject.Testende}} Tage</span>
            </td>
            <td v-else></td>
            <td><span v-if="accountsObject.VollversionVorgemerkt == 1">ja</span></td>
            <td>{{accountsObject.Kundennummer}}</td>
            <td>{{accountsObject.Name}}</td>
            <td>{{accountsObject.Ort}}</td>
            <td>{{accountsObject.PLZ}}</td>
            <td>{{accountsObject.Vertriebsweg}}</td>
            <td>{{accountsObject.Berechnungsmethode}}</td>
            <td>{{accountsObject.Benutzer}}</td>
            <td>{{accountsObject.Email}}</td>
            <td>{{accountsObject.Status}}</td>
          </tr>
        </template>
      </table>
      <b-modal id="editUserModal" modal-class="editModal" ref="editUserModal" size="lg" hide-footer hide-header title="">
        <div v-if="editMode==false">
          <div>
            <h4>{{clickedUserInstitution.Name}} <span @click="editMode=true" >bearbeiten</span></h4>
            <div class="dataContainer">
              <div id="institutionData">
                <div class="customRow">
                  <div class="oneFour">
                    <span>Kundennummer:</span>
                    {{clickedUserInstitution.Kundennummer}}
                  </div>
                  <div class="oneFour">
                    <span>Vertriebsweg:</span>
                    {{clickedUserInstitution.Vertriebsweg}}
                  </div>
                  <div style="margin-left: auto;">
                    <span>erstellt am:</span>
                    {{clickedUserInstitution.created}}
                  </div>
                </div>
                <div class="customRow">
                  <div>
                    <span>Adresse:</span>
                    {{clickedUserInstitution.Strasse}} {{clickedUserInstitution.Hausnummer}}, {{clickedUserInstitution.PLZ}}   {{clickedUserInstitution.Ort}}
                  </div>
                </div>
                <div class="customRow">
                  <div class="oneFour">
                    <span>Telefon:</span>
                    {{clickedUserInstitution.Telefon}}
                  </div>
                  <div class="oneFour">
                    <span>Berechnungsmethode:</span>
                    Methode {{clickedUserInstitution.Berechnungsmethode}}
                  </div>
                  <div v-if="clickedUserInstitution.PaketObjekt != null" class="oneFour">
                    <span>Paket:</span>
                    {{clickedUserInstitution.PaketObjekt.Name}}
                  </div>
                  <div v-if="clickedUserInstitution.Testphase==0" class="oneFour">
                      <span>Testphase:</span>      
                        Nein
                  </div>
                  <div v-else class="oneFour">
                      <span>Testbeginn:</span>
                      {{new Date(clickedUserInstitution.Testbeginn).toLocaleDateString()}}
                  </div>
                  <div v-if="clickedUserInstitution.Testphase!=0" class="oneFour">
                      <span>Testende:</span>
                      <template v-if="clickedUserInstitution.Testende>0">
                        in {{clickedUserInstitution.Testende}} Tagen
                      </template>
                      <template v-else>
                        Testphase abgelaufen
                      </template>
                  </div>
                </div>
              </div>
              <div id="userData">
                <div class="customRow">
                  <div class="oneFour">
                    <span>Kontaktperson:</span>
                    {{clickedUserInstitution.Vorname}} {{clickedUserInstitution.Nachname}}
                  </div>
                  <div>
                    <span>Emailadresse:</span>
                    {{clickedUserInstitution.Email}}
                  </div>
                </div>
                <div class="customRow">
                  <div class="oneFour">
                    <span>Benutzername:</span>
                    {{clickedUserInstitution.Loginname}}
                  </div>
                  <div class="oneFour">
                    <span>Passwort:</span>
                    <div>*****</div>
                  </div>
                  <div>
                    <span>Status:</span>
                    {{clickedUserInstitution.Status}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
              <h4>{{clickedUserInstitution.Name}}</h4>
              <div class="dataContainer">
                <div id="institutionData">
                  <div class="customRow">
                    <div class="oneFour">
                      <span>Institutionname*</span>
                      <input type="text" v-model='clickedUserInstitution.Name'>
                    </div>
                    <div class="oneFour">
                      <span>Kundennummer*</span>
                      <input type="text" v-model='clickedUserInstitution.Kundennummer' maxlength="8">
                    </div>
                    <div class="oneEight">
                      <span>Vertriebsweg*</span>
                      <select v-model='clickedUserInstitution.Vertriebsweg'>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="28">28</option>
                        <option value="30">30</option>
                        <option value="35">35</option>
                        <option value="40">40</option>
                      </select>
                    </div>
                  </div>
                  <div class="customRow">
                    <div class="oneFour">
                      <span>Strasse*</span>
                      <input type="text" v-model='clickedUserInstitution.Strasse'>
                    </div>
                    <div class="oneEight">
                      <span>Hausnummer*</span>
                      <input type="text" v-model='clickedUserInstitution.Hausnummer'>
                    </div>
                    <div class="oneEight">
                      <span>PLZ*</span>
                      <input type="text" v-model='clickedUserInstitution.PLZ'>
                    </div>
                    <div class="oneFour">
                      <span>Ort*</span>
                      <input type="text" v-model='clickedUserInstitution.Ort'>
                    </div>
                  </div>
                  <div class="customRow">
                    <div class="oneFour">
                      <span>Telefon</span>
                      <input type="text" v-model='clickedUserInstitution.Telefon'>
                    </div>
                    <div>
                      <span>Berechnungsmethode</span>
                      <select v-model='clickedUserInstitution.Berechnungsmethode'>
                        <option value="A">Methode A</option>
                        <option value="B">Methode B</option>
                        <option value="C">Methode C</option>
                      </select>
                    </div>
                    <div>
                      <span>Pakete</span>
                      <select v-model='clickedUserInstitution.PaketId' @change="onChangePaket()">
                        <option v-for="(paket, key) in pakets" :value="paket.id" :key="key">{{paket.Name}}</option>
                      </select>
                    </div>
                    <div v-if="clickedUserInstitution.Testphase==0" class="oneFour">
                        <span>Testphase:</span>
                
                          Nein
                    </div>
                    <div v-else class="oneFour">
                      <span>Testbeginn:</span>
                        {{new Date(clickedUserInstitution.Testbeginn).toLocaleDateString()}}
                    </div>
                    <div v-if="clickedUserInstitution.Testphase==1" class="oneFour">
                      <span>Testende:</span>
                      <template v-if="clickedUserInstitution.Testende>0">
                        in {{clickedUserInstitution.Testende}} Tagen
                      </template>
                      <template v-else>
                        Testphase abgelaufen
                      </template>
                    </div>
              
                  </div>
                </div>
                <div id="userData">
                  <div class="customRow">
                    <div class="oneEight">
                      <span>Anrede*</span>
                      <select v-model='clickedUserInstitution.Anrede'>
                        <option value="0">Frau</option>
                        <option value="1">Herr</option>
                      </select>
                    </div>
                    <div class="oneFour">
                      <span>Kontaktperson Vorname*</span>
                      <input type="text" v-model='clickedUserInstitution.Vorname'>
                    </div>
                    <div class="oneFour">
                      <span>Kontaktperson Nachname*</span>
                      <input type="text" v-model='clickedUserInstitution.Nachname'>
                    </div>
                    <div class="oneFour">
                      <span>Email*</span>
                      <input type="email" v-model='clickedUserInstitution.Email'>
                    </div>
                  </div>
                  <div class="customRow">
                    <div class="oneFour">
                      <span>Benutzername</span>
                      <div>{{createLoginName()}}</div>
                    </div>
                    <div class="oneFour">
                      <span>Passwort</span>
                      <div @click="resetMode = true" style="text-decoration: underline; cursor: pointer" v-if="createMode === false && resetMode === false">zurücksetzen</div>
                      <div v-if="createMode === false && resetMode === true">
                        <h5 style="font-size: 16px; display: inline-block">Jetzt zurücksetzen?</h5>
                        <span style="display: inline-block" @click="resetPassword()"><img :src="require('../../assets/img/cms_check.png')"></span>
                        <span style="display: inline-block" @click="resetMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
                      </div>
                      <div v-if="createMode === true">wird gesetzt</div>
                    </div>
                    <div class="oneFour">
                      <span>Status*</span>
                      <div v-if="createMode === false && clickedUserInstitution.Status!== 'inaktiv'" @click="deactivateUser()" style="text-decoration: underline; cursor: pointer">Benutzer deaktivieren</div>
                      <div v-if="clickedUserInstitution.Status === 'inaktiv' && createMode === false" @click="resetPassword()" style="text-decoration: underline; cursor: pointer">Benutzer freischalten</div>
                      <div style="font-weight: normal; font-size: 13px;" v-if="clickedUserInstitution.Status === 'inaktiv' && createMode === false">Das vergebene Passwort des Benutzers wird dadurch zurückgesetzt.</div>
                      <div v-if="createMode === true">{{clickedUserInstitution.Status}}</div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div style="overflow: auto">
            <b-button v-if="createMode==false" class="deleteUser" @click="deleteMode = true; deletePermanentMode = false">Löschen</b-button>
            <b-button v-if="createMode==false" style="margin-left: 12px" class="deleteUser" @click="deletePermanentMode = true; deleteMode = false">Löschen (DSGVO konform)</b-button>
            <b-button :class="buttonIsClickable().text" v-bind:disabled="buttonIsClickable().boolean" @click="save()">Speichern</b-button>
            <b-button :class="'noHighlight ' + buttonIsClickable().text" v-bind:disabled="buttonIsClickable().boolean" v-if="createMode === true" style="margin-right: 20px" @click="save('next')" >speichern und weiteres hinzufügen</b-button>
          </div>

          <div style="clear: both; border-top: 1px solid #999; margin-top: 10px;" v-if="createMode === false && deleteMode === true">
            <h5 style="font-size: 16px; display: inline-block">Jetzt löschen?</h5>
            <span style="display: inline-block" @click="deleteInstitution(false)"><img :src="require('../../assets/img/cms_check.png')"></span>
            <span style="display: inline-block" @click="deleteMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
          </div>
          <div style="clear: both; border-top: 1px solid #999; margin-top: 10px;" v-if="createMode === false && deletePermanentMode === true">
            <h5 style="font-size: 16px; display: inline-block">Jetzt DSGVO konform löschen?</h5>
            <span style="display: inline-block" @click="deleteInstitution(true)"><img :src="require('../../assets/img/cms_check.png')"></span>
            <span style="display: inline-block" @click="deletePermanentMode = false"><img :src="require('../../assets/img/cms_cancel.png')"></span>
          </div>
        
        </div>
        <div class="closeButton" @click="back()" ></div>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="pwResetModal" hide-footer hide-header title="">
        <h5 v-if="resetMode === true">Das Passwort wurde erfolgreich zurückgesetzt.</h5>
        <h5 v-if="resetMode === false">Benutzer wurde freigeschaltet und sein Passwort zurückgesetzt.</h5>
        <label>Das Initialpasswort lautet:</label>
        <div style="position: relative">
          <input :type="passwordVisibleType" :value="standardPassword" autocomplete="new-password">
          
          <img v-if="passwordVisibleType=='password'" @click="passwordVisibleType='text'" :src="require('../../assets/img/visibility.svg')" class="visibilityStatus"/>
          <img v-if="passwordVisibleType=='text'" @click="passwordVisibleType='password'" :src="require('../../assets/img/visibility_off.svg')" class="visibilityStatus"/>
        </div>
        <b-button @click="$refs.pwResetModal.hide()" class="modalButton">ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="deactivateUserModal" hide-footer hide-header title="">
        <h5>Der Benutzer wurde erfolgreich deaktiviert.</h5>
        <b-button @click="$refs.deactivateUserModal.hide()" class="modalButton">ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="newInstitutionModal" hide-footer hide-header title="">
        <h5>Eine neue Institution wurde erfolgreich hinzugefügt.</h5>
        <label>Das Initialpasswort lautet:</label>
        <div style="position: relative">
          <input :type="passwordVisibleType" :value="standardPassword" autocomplete="new-password">
          
          <img v-if="passwordVisibleType=='password'" @click="passwordVisibleType='text'" :src="require('../../assets/img/visibility.svg')" class="visibilityStatus"/>
          <img v-if="passwordVisibleType=='text'" @click="passwordVisibleType='password'" :src="require('../../assets/img/visibility_off.svg')" class="visibilityStatus"/>
        </div>
        <b-button @click="$refs.newInstitutionModal.hide()" class="modalButton">ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="editInstitutionModal" hide-footer hide-header title="">
        <h5>Die Institution wurde erfolgreich bearbeitet.</h5>
        <b-button @click="$refs.editInstitutionModal.hide()" class="modalButton">ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="instiNrModal" hide-footer hide-header title="">
        <h5>Diese Kundennummer ist schon vergeben.</h5>
        <b-button @click="$refs.instiNrModal.hide()" class="modalButton">ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="saveErrorModal" hide-footer hide-header title="">
        <h5>Es gab einen Fehler beim speichern.</h5>
        <b-button @click="$refs.saveErrorModal.hide()" class="modalButton">ok</b-button>
      </b-modal>
      <b-modal modal-class="cms_notification_modal" ref="saveSuccessModal" hide-footer hide-header title="">
        <h5>Das Speichern war erfolgreich.</h5>
        <b-button @click="$refs.saveSuccessModal.hide()" class="modalButton">ok</b-button>
      </b-modal>
      <b-modal modal-class="uploadCSVModal cms_notification_modal" ref="uploadCSVModal" size="m" hide-footer hide-header title="">
        <vue-dropzone style="float: left;" ref="myVueDropzone" id="dropzone" :options="whichCSV=='match'? dropzoneOptionsMatch : dropzoneOptionsADM" v-on:vdropzone-success="dropSuccess" v-on:vdropzone-error="dropError"  v-on:vdropzone-canceled="dropCanceled"></vue-dropzone>
        <div v-if="CSVUploadCallback != null && CSVUploadCallback.edited != undefined"  style="float:left; margin-left:10px">
            {{CSVUploadCallback.edited}} Zeile(n) bearbeitet <br />
            {{CSVUploadCallback['not edited']}} Zeile(n) identisch <br />
            {{CSVUploadCallback.inserted}} Zeile(n) neu eingefügt <br />
            {{CSVUploadCallback.errors}} Fehler <br />
            {{CSVUploadCallback.errorMessages}} <br />
            <b-btn @click="writeCSVTempToDB">speichern</b-btn>
        </div>
        <div v-if="CSVUploadError != null && CSVUploadError != ''" style="float:left; margin-left:10px">
          Es gab einen Fehler beim Upload: {{CSVUploadError}}
        </div>
        <div class="closeButton" @click="hideCSVModal()" ></div>
      </b-modal>
  </div>
</template>

<script>
import {birthdateInRightFormatUtil} from '../../utils/util.js'
import DataService from '@/services/DataService'
import axios from 'axios'
import {EventBus} from '@/services/EventBus.js'
import vue2Dropzone from '@/libs/vue-dropzone/index.js'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'


export default {
  name: 'Accounts',
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function () {
    return {
      institutionen: [],
      accountsArray: [],
      editMode: false,
      createMode: false,
      resetMode: false,
      deleteMode: false,
      deletePermanentMode: false,
      clickedUserInstitution: {},
      search: '',
      searchId: '',
      searchName: '',
      searchOrt: '',
      searchBenutzer: '',
      searchEmail: '',
      sortBy: '',
      searchPLZ: '',
      pakets: [],
      eventBusObject: EventBus,
      standardPassword: 'Incoaid2019!',
      passwordVisibleType: 'password',
      upload: false,
      dropzoneOptionsMatch: {
        createImageThumbnails: false,
        dictDefaultMessage: 'Matching CSV hochladen',
        url: '/static/api/register/uploadmatchingcsv.php',
        thumbnailWidth: 0,
        maxFilesize: 10,
        acceptedFiles: '.csv',
        timeout: 60000,
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user-token') }
      },
      dropzoneOptionsADM: {
        createImageThumbnails: false,
        dictDefaultMessage: 'ADM CSV hochladen',
        url: '/static/api/register/uploadadmcsv.php',
        thumbnailWidth: 0,
        maxFilesize: 10,
        acceptedFiles: '.csv',
        timeout: 60000,
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user-token') }
      },
      whichCSV: 'match',
      CSVUploadCallback: {},
      CSVUploadError: '',
      LastCSVUpload: '',
      LastADMCSVUpload: ''
    }
  },
  created () {
    this.loadData()
    this.eventBusObject.$on('updateInstiCMSTab', ($event) => {
      this.loadData()
    })
    DataService.getLastUploadDate('match').then((response) => {
        var date = new Date(response.data.lastchange)
        this.LastCSVUpload = date.getUTCDate() + '.' + (date.getUTCMonth() + 1) + '.' + date.getUTCFullYear()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    DataService.getLastUploadDate('adm').then((response) => {
      var date = new Date(response.data.lastchange)
      this.LastADMCSVUpload = date.getUTCDate() + '.' + (date.getUTCMonth() + 1) + '.' + date.getUTCFullYear()
    }).catch((err) => {
      // handle error
      console.log(err)
    })
  },
  methods: {
    loadPakets: function () {
      DataService.fetchPakets(false).then((response) => {
        this.pakets = response.data
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    loadData: function () {
      this.loadPakets()
      DataService.getAllInstitutionsData().then((response) => {
        this.institutionen = response.data
        this.accountsArray = []
        // console.log(JSON.stringify(this.institutionen))
        for (var i in this.institutionen) {
          var institution = this.institutionen[i]
          if (institution.Name !== 'PAUL HARTMANN AG') {
            for (var j in institution.Benutzer) {
              var user = institution.Benutzer[j]
              var accountsObject = {}
              accountsObject['Kundennummer'] = institution.Kundennummer
              accountsObject['Name'] = institution.Name
              accountsObject['Ort'] = institution.Ort
              accountsObject['PLZ'] = institution.PLZ
              accountsObject['Strasse'] = institution.Strasse
              accountsObject['Hausnummer'] = institution.Hausnummer
              accountsObject['Telefon'] = institution.Telefon
              accountsObject['Fax'] = institution.Fax
              accountsObject['PdfPasswort'] = institution.PdfPasswort
              accountsObject['Webseite'] = institution.Webseite
              accountsObject['id'] = institution.id
              accountsObject['Vertriebsweg'] = institution.Vertriebsweg
              accountsObject['Berechnungsmethode'] = institution.Berechnungsmethode
              accountsObject['Benutzer'] = user.Vorname + ' ' + user.Nachname
              accountsObject['BenutzerID'] = user.id
              accountsObject['Email'] = user.Mail
              accountsObject['Status'] = user.Status
              accountsObject['RollenId'] = user.RollenId
              accountsObject['Testphase'] = institution.Testphase
              accountsObject['Testbeginn'] = institution.Testbeginn
              accountsObject['PaketObjekt'] = institution.PaketObjekt
              accountsObject['DatenTransferiert'] = institution.DatenTransferiert
              accountsObject['KnrVerifiziert'] = institution.KnrVerifiziert
              accountsObject['VollversionVorgemerkt'] = institution.VollversionVorgemerkt
              accountsObject['Gelesen'] = user.Gelesen
              var days = 0
              if (institution.Testphase) {
                days = this.calcDaysTillEnd(institution.Testbeginn, institution.PaketObjekt.Testphasendauer)
              }
             
              accountsObject['Testende'] = days
              // only show admin account of institution in list
              if (accountsObject['RollenId'] === '2') {
                this.accountsArray.push(accountsObject)
                break
              }
            }
          }
        }
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    edit: function (userID) {
      this.resetMode = false
      this.createMode = false
      this.deleteMode = false
      this.deletePermanentMode = false
    
            DataService.getUserData(userID).then((response) => {
              var institution = response.data
              var user = institution.Benutzer[0]
              if (user != null) {
                var IEreadyString = institution.created.replace(/-/g, '/')
                var newDateString = birthdateInRightFormatUtil(IEreadyString) 
                var newUserInstitution = {}
                newUserInstitution['Kundennummer'] = institution.Kundennummer
                newUserInstitution['Name'] = institution.Name
                newUserInstitution['id'] = institution.id
                newUserInstitution['Vertriebsweg'] = institution.Vertriebsweg
                newUserInstitution['Berechnungsmethode'] = institution.Berechnungsmethode
                newUserInstitution['Strasse'] = institution.Strasse
                newUserInstitution['Hausnummer'] = institution.Hausnummer
                newUserInstitution['PLZ'] = institution.PLZ
                newUserInstitution['Ort'] = institution.Ort
                newUserInstitution['PaketId'] = institution.PaketId
                newUserInstitution['Testphase'] = institution.Testphase
                newUserInstitution['Testbeginn'] = institution.Testbeginn
                newUserInstitution['PaketObjekt'] = institution.PaketObjekt
                newUserInstitution['Fax'] = institution.Fax
                newUserInstitution['Webseite'] = institution.Webseite
                newUserInstitution['PdfPasswort'] = institution.PdfPasswort
                newUserInstitution['DatenTransferiert'] = institution.DatenTransferiert
                
                var days = 0
                if (institution.Testphase) {
                days = this.calcDaysTillEnd(institution.Testbeginn, institution.PaketObjekt.Testphasendauer)
                }
                  
                  
                newUserInstitution['Testende'] = days
                newUserInstitution['Telefon'] = institution.Telefon
                newUserInstitution['created'] = newDateString
                // user:
                newUserInstitution['Anrede'] = user.Anrede
                newUserInstitution['Vorname'] = user.Vorname
                newUserInstitution['Nachname'] = user.Nachname
                newUserInstitution['BenutzerID'] = user.id
                newUserInstitution['Email'] = user.Mail
                newUserInstitution['Status'] = user.Status
                newUserInstitution['Loginname'] = user.Loginname
                newUserInstitution['RollenId'] = user.RollenId
                newUserInstitution['Institution'] = user.Institution
                newUserInstitution['Gelesen'] = user.Gelesen
                this.clickedUserInstitution = newUserInstitution
                // console.log(JSON.stringify(newUserInstitution))
                this.$refs.editUserModal.show()
              }
            }).catch((err) => {
              // handle error
              console.log(err)
            })

       
     
    },
    back: function () {
      // reload user
      this.editMode = false
      this.$refs.editUserModal.hide()
    },
    save: function (special) {
      if (this.requiredAnswers() === true) {
        // divide model into institution and user and update database
        var institution = {}
        institution['Kundennummer'] = this.clickedUserInstitution.Kundennummer
        institution['Name'] = this.clickedUserInstitution.Name
        institution['id'] = this.clickedUserInstitution.id
        institution['Vertriebsweg'] = this.clickedUserInstitution.Vertriebsweg
        institution['Berechnungsmethode'] = this.clickedUserInstitution.Berechnungsmethode
        institution['Strasse'] = this.clickedUserInstitution.Strasse
        institution['Hausnummer'] = this.clickedUserInstitution.Hausnummer
        institution['PLZ'] = this.clickedUserInstitution.PLZ
        institution['Ort'] = this.clickedUserInstitution.Ort
        institution['PaketId'] = this.clickedUserInstitution.PaketId
        institution['Telefon'] = this.clickedUserInstitution.Telefon
        institution['Fax'] = this.clickedUserInstitution.Fax
        institution['Webseite'] = this.clickedUserInstitution.Webseite
        institution['PdfPasswort'] = this.clickedUserInstitution.PdfPasswort
        institution['DatenTransferiert'] = this.clickedUserInstitution.DatenTransferiert
        
        DataService.countInstitutionNumber(this.clickedUserInstitution.Kundennummer, this.clickedUserInstitution.id).then((response) => {
          var isFree = response.data.free
          if (isFree === true) {
            DataService.setInstitutionData(institution).then((response) => {
              var institutionId = response.data.id
              if (institutionId > 0) {
                var user = {}
                user['id'] = this.clickedUserInstitution.BenutzerID
                user['Anrede'] = this.clickedUserInstitution.Anrede
                user['Vorname'] = this.clickedUserInstitution.Vorname
                user['Nachname'] = this.clickedUserInstitution.Nachname
                user['Mail'] = this.clickedUserInstitution.Email
                user['Status'] = this.clickedUserInstitution.Status
                /*
                if (response.data.paketchange == true && this.clickedUserInstitution.Status == 'inaktiv') {
                  user['Status'] = 'aktiv';
                }
                */ 
               
                user['RollenId'] = this.clickedUserInstitution.RollenId
                user['Loginname'] = this.clickedUserInstitution.Loginname
                user['Gelesen'] = this.clickedUserInstitution.Gelesen
                user['Institution'] = institutionId
          
                DataService.setUserData(user).then((response) => {
                  this.loadData()
                  this.eventBusObject.$emit('updateUserCMSTab')
                  if (special !== 'next') {
                    if (this.createMode === true) {
                      this.$refs.newInstitutionModal.show()
                    } else {
                      this.$refs.editInstitutionModal.show()
                    }
                    this.$refs.editUserModal.hide()
                    this.editMode = false
                  } else {
                    this.clickedUserInstitution = {}
                  }
                }).catch((err) => { // set user
                  // handle error
                  console.log(err)
                })
              } else {
                this.$refs.saveErrorModal.show()
              }
            }).catch((err) => { // set insti
              // handle error
              this.$refs.saveErrorModal.show()
              console.log(err)
            })
          } else {
            this.$refs.instiNrModal.show()
          }        
        }).catch((err) => { //count insti number
          // handle error
          console.log(err)
        })

      } else {
      }
    },
    create: function () {
      this.clickedUserInstitution = {
        Vertriebsweg: '20',
        Berechnungsmethode: 'A',
        Status: 'wartend',
        RollenId: 2,
        PaketId: 1,
        Testphase: 0,
        Testbeginn: "0000-00-00",
        Fax: "",
        Webseite: "",
        PdfPasswort: "",
        DatenTransferiert: 1,
        Gelesen: 1
      }
      this.editMode = true
      this.createMode = true
      this.$refs.editUserModal.show()
    },
    deleteInstitution: function (dsvgo) {
      DataService.deleteInstitution(this.clickedUserInstitution.id, dsvgo).then((response) => {
          this.loadData()
          this.eventBusObject.$emit('updateUserCMSTab')
          this.$refs.editUserModal.hide()
        }).catch((err) => {
          // handle error
          console.log(err)
        })
    },
    buttonIsClickable: function () {
      if (this.requiredAnswers() === true) {
        return {
          text: 'enabled',
          boolean: false
        }
      } else {
        return {
          text: 'disabled',
          boolean: true
        }
      }
    },
    createLoginName: function () {
      if (this.clickedUserInstitution.Loginname !== '' && this.clickedUserInstitution.Loginname !== undefined) {
        return this.clickedUserInstitution.Loginname
      } else {
        return 'wird generiert'
      }
    },
    resetPassword: function () {     
      const self = this
      DataService.resetUserPW(this.clickedUserInstitution.BenutzerID).then((response) => {
        self.loadData()
        self.$refs.editUserModal.hide()
        self.editMode = false
        self.$refs.pwResetModal.show()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    deactivateUser: function () {
      const self = this
      DataService.deactivateUser(this.clickedUserInstitution.BenutzerID).then((response) => {
        self.loadData()
        self.$refs.editUserModal.hide()
        self.editMode = false
        self.$refs.deactivateUserModal.show()
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    requiredAnswers: function () {
      if (
        this.clickedUserInstitution.Name !== '' && this.clickedUserInstitution.Name !== undefined &&
        this.clickedUserInstitution.Kundennummer !== '' && this.clickedUserInstitution.Kundennummer !== undefined &&
        this.clickedUserInstitution.Vertriebsweg !== '' && this.clickedUserInstitution.Vertriebsweg !== undefined &&
        this.clickedUserInstitution.Strasse !== '' && this.clickedUserInstitution.Strasse !== undefined &&
        this.clickedUserInstitution.Ort !== '' && this.clickedUserInstitution.Ort !== undefined &&
        this.clickedUserInstitution.PLZ !== '' && this.clickedUserInstitution.PLZ !== undefined &&
        this.clickedUserInstitution.Hausnummer !== '' && this.clickedUserInstitution.Hausnummer !== undefined &&
        this.clickedUserInstitution.Vorname !== '' && this.clickedUserInstitution.Vorname !== undefined &&
        this.clickedUserInstitution.Nachname !== '' && this.clickedUserInstitution.Nachname !== undefined &&
        this.clickedUserInstitution.Email !== '' && this.clickedUserInstitution.Email !== undefined
      ) {
        return true
      } else {
        return false
      }
    },
    sortDown: function (a, b) {
      if (a==null || b==null || a === b) {
        return 0
      }
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1
      }
    },
    sortUp: function (a, b) {
      if (a==null || b==null || a === b) {
        return 0
      }
      if (a.toLowerCase() < b.toLowerCase()) {
        return 1
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return -1
      }
    },
    isActive: function (sortString) {
      if (sortString === this.sortBy) {
        return 'active'
      } else {
        return ''
      }
    },
    onChangePaket: function() {
      var newPaketId = this.clickedUserInstitution.PaketId

      for (var i in this.pakets) {
        var paket = this.pakets[i]
       if (paket.id == newPaketId) {
         
        if (paket.Testphasendauer > 0) {
          this.clickedUserInstitution.Testphase = 1
          this.clickedUserInstitution.Testbeginn = new Date().toDateString()
          this.clickedUserInstitution.Testende = this.calcDaysTillEnd(new Date(), paket.Testphasendauer)
        } else {
          this.clickedUserInstitution.Testphase = 0
          this.clickedUserInstitution.Testbeginn = '0000-00-00'
          this.clickedUserInstitution.Testende = 0
        }
       }
      }
    },
    calcDaysTillEnd: function (date, testdays) {
      var testDate = new Date(date)
      testDate.setDate(testDate.getDate() + testdays)
      var diff = new Date(testDate - new Date())     
      diff = parseInt((testDate-new Date())/(24*3600*1000))
      return diff
    },
    dropSuccess: function (file, response) {
      this.CSVUploadCallback = response
      if (file != null && file.status !== 'error') {
        console.log('success')
      } else {
        console.log('error')
      }
    },
    dropError: function (error, errorMessage) {
      console.log(error)
      this.CSVUploadError = errorMessage
    },
    dropCanceled: function (file) {
      console.log('canceled')
    },
    writeCSVTempToDB: function () {
      DataService.writeKundenChangesToDB(this.whichCSV).then((response) => {
        var returnObject = response.data
        if (returnObject.done === true) {
          this.CSVUploadCallback = {}
          this.$refs.uploadCSVModal.hide()
          this.$refs.saveSuccessModal.show()
        } else {
          this.CSVUploadError = 'Fehler beim Übertragen der Tabelle'
        }
      }).catch((err) => {
        this.CSVUploadError = 'Server-Fehler beim Speichern'
        console.log(err)
      })
    },
    uploadCSV: function (which) {
      this.whichCSV = which
      console.log(this.whichCSV)
      this.CSVUploadError = ''
      this.CSVUploadCallback = {}
      this.$refs.uploadCSVModal.show()
      this.$nextTick().then(() => {
        if (this.$refs.myVueDropzone != null) {
          this.$refs.myVueDropzone.removeAllFiles();
        }
      })
    },
    hideCSVModal: function () {
      this.$refs.uploadCSVModal.hide()
    }
  },
  computed: {
    matches () {
      const self = this
      var newArrayForSorting = this.accountsArray
      newArrayForSorting.sort(function (a, b) {
        switch (self.sortBy) {
          case 'testUp':
            return a.Testende - b.Testende
          case 'testDown':
            return b.Testende - a.Testende
          case 'numberUp':
            return self.sortUp(a.Kundennummer, b.Kundennummer)
          case 'numberDown':
            return self.sortDown(a.Kundennummer, b.Kundennummer)
          case 'nameUp':
            return self.sortUp(a.Name, b.Name)
          case 'nameDown':
            return self.sortDown(a.Name, b.Name)
          case 'vertriebUp':
            return self.sortUp(a.Vertriebsweg, b.Vertriebsweg)
          case 'vertriebDown':
            return self.sortDown(a.Vertriebsweg, b.Vertriebsweg)
          case 'statusUp':
            return self.sortUp(a.Status, b.Status)
          case 'statusDown':
            return self.sortDown(a.Status, b.Status)
          case 'bmUp':
            return self.sortUp(a.Berechnungsmethode, b.Berechnungsmethode)
          case 'bmDown':
            return self.sortDown(a.Berechnungsmethode, b.Berechnungsmethode)
          default:
            return self.sortDown(a.Kundennummer, b.Kundennummer)
        }
      })
      return newArrayForSorting.filter((element) => {
        var elementNr = element.Kundennummer
        var elementText = element.Name
        var elementOrt = element.Ort
        var elementBenutzer = element.Benutzer
        var elementEmail = element.Email
        var elementStrasse = element.Strasse
        var elementHausnummer = element.Hausnummer
        var elementVertrieb = element.Vertriebsweg
        var elementPLZ = element.PLZ + ''
        var elementStatus = element.Status
        var returnBool = ((elementText.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementOrt.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementBenutzer.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementNr.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementEmail.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementStrasse.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementHausnummer.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementVertrieb.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || elementPLZ.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         elementStatus.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
         this.search.length === 0) &&
         (elementNr.toLowerCase().indexOf(this.searchId.toLowerCase()) >= 0 || this.searchId.length === 0) &&
         (elementText.toLowerCase().indexOf(this.searchName.toLowerCase()) >= 0 || this.searchName.length === 0) &&
         (elementOrt.toLowerCase().indexOf(this.searchOrt.toLowerCase()) >= 0 || this.searchOrt.length === 0) &&
         (elementBenutzer.toLowerCase().indexOf(this.searchBenutzer.toLowerCase()) >= 0 || this.searchBenutzer.length === 0) &&
         (elementPLZ.toLowerCase().indexOf(this.searchPLZ.toLowerCase()) >= 0 || this.searchPLZ.length === 0) &&
         (elementEmail.toLowerCase().indexOf(this.searchEmail.toLowerCase()) >= 0 || this.searchEmail.length === 0))
        return returnBool
      })
    }
  },
  mounted() {
     this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
       if (modalId === 'editUserModal') {
        this.editMode = false
       }
     })
   }
}
</script>
