import FileService from '@/services/FileService'
import DataBaseService from '@/services/DatabaseService'

export default {
  // products are combined by artikelnumber. sizes as array in every product object
  fetchCombinedProducts () {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getCombinedProducts()
    } else {
      return DataBaseService.getCombinedProducts()
    }
  },
  // every size as own object
  fetchAllFlatProducts () {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getFlatProducts()
    } else {
      return DataBaseService.getFlatProducts()
    }
  },
  setProductData (product) {
    return DataBaseService.setProductData(product)
  },
  deleteProduct (id) {
    return DataBaseService.deleteProduct(id)
  },
  getInstitutionData (id) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockInstitutionData()
    } else {
      return DataBaseService.getInstitutionData(id)
    }
  },
  getUserData (id) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockInstitutionData()
    } else {
      return DataBaseService.getUserData(id)
    }
  },
  setUserData (user) {
    return DataBaseService.setUserData(user)
  },
  deleteUser (id, dsvgo) {
    return DataBaseService.deleteUser(id, dsvgo)
  },
  deactivateUser (id) {
    return DataBaseService.deactivateUser(id)
  },
  resetUserPW (id) {
    return DataBaseService.resetUserPW(id)
  },

  getAllInstitutionsData () {
    if (window.location.href.indexOf('8080') >= 0) {
      return FileService.getMockInstitutionsData()
    } else {
      return DataBaseService.getAllInstitutionsData()
    }
  },
  // before deleting check if there is no other knr registered
  countInstitutionNumber (kundennummer, id) {
    return DataBaseService.countInstitutionNumber(kundennummer, id)
  },
  setInstitutionData (institution) {
    return DataBaseService.setInstitutionData(institution)
  },
  deleteInstitution (id, dsvgo) {
    return DataBaseService.deleteInstitution(id, dsvgo)
  },
  fetchWizard () {
    return FileService.getWizard()
  },
  fetchRecommendations () {
    return FileService.getRecommendations()
  },
  fetchTextRecommendations () {
    return FileService.getTextRecommendations()
  },
  fetchKrankenkassen () {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getKrankenkassen()
    } else {
      return DataBaseService.getKrankenkassen()
    }
  },
  setKVData (kv) {
    return DataBaseService.setKVData(kv)
  },
  deleteKVData (id) {
    return DataBaseService.deleteKVData(id)
  },
  fetchWettbewerber () {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getWettbewerber()
    } else {
      return DataBaseService.getWettbewerber()
    }
  },
  setWettbewerberData (wettbewerber) {
    return DataBaseService.setWettbewerberData(wettbewerber)
  },
  deleteWettbewerberData (id) {
    return DataBaseService.deleteWettbewerberData(id)
  },
  writeProductChangesToDB () {
    return DataBaseService.getWriteTempToProd()
  },
  fetchRoles () {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockRolesData()
    } else {
      return DataBaseService.getAllRoles()
    }
  },
  getRolesCount (roleId) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockRolesCountData()
    } else {
      return DataBaseService.getRolesCount(roleId)
    }
  },
  getPaketsCount (paketId) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockRolesCountData()
    } else {
      return DataBaseService.getPaketsCount(paketId)
    }
  },
  setRole (role) {
    return DataBaseService.setRole(role)
  },
  deleteRole (id) {
    return DataBaseService.deleteRole(id)
  },
  fetchRights () {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockRightsData()
    } else {
      return DataBaseService.getAllRights()
    }
  },
  fetchPakets (demo) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockPaketsData()
    } else {
      return DataBaseService.getPakets(demo)
    }
  },
  setPaket (paket) {
    return DataBaseService.setPaket(paket)
  },
  deletePaket (id) {
    return DataBaseService.deletePaket(id)
  },
  fetchPrices (instiId) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockPriceData()
    } else {
      return DataBaseService.getOwnPrices(instiId)
    }
  },
  setPrices (prices, instiId) {
    return DataBaseService.setOwnPrices(prices, instiId)
  },
  deletePrices (id, instiId) {
    return DataBaseService.deleteOwnPrice(id, instiId)
  },

  fetchOwnKVs (instiId) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockOwnKVData()
    } else {
      return DataBaseService.getOwnKVs(instiId)
    }
  },
  setOwnKVs (kvs, instiId) {
    return DataBaseService.setOwnKVs(kvs, instiId)
  },
  deleteOwnKVs (id, instiId) {
    return DataBaseService.deleteOwnKV(id, instiId)
  },
  checkKnr (knr)  {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockOwnKNRCheck()
    } else {
      return DataBaseService.checkKnr (knr) 
    }
  },
  checkRecaptcha (token)  {
    return DataBaseService.checkRecaptcha (token) 
  },
  register (registerModel) {
    return DataBaseService.register (registerModel)
  },
  writeKundenChangesToDB (from) {
    return DataBaseService.getWriteTempToProdKunden(from)
  },
  verifyEmail (id, token, del) {
    return DataBaseService.verifyEmail(id, token, del)
  },
  resetPassword (id, token, pwd) {
    return DataBaseService.resetPassword(id, token, pwd)
  },
  resendEmail (mail, username, forPassword) {
    return DataBaseService.resendEmail(mail, username, forPassword)
  },
  getLastUploadDate (from) {
    if (window.location.href.indexOf(':8080') >= 0) {
      return FileService.getMockLastUploadDate()
    } else {
      return DataBaseService.getLastUploadDate(from)
    }
   
  },
  sendMail (contactModel) {
    return DataBaseService.sendMail(contactModel)
  },
  applyForFullversionDS (loginname, token){
    return DataBaseService.applyForFullversionDB (loginname, token)
  }
}
