<template>
  <div id="registerScreen">
    <div id="headerPic">
    </div>
    <div class="innerContainer">
      <h1>INCOAID Registrierung<br />
      <span v-if="registerModel.selectedVersion == 0">Demoversion</span>
      <span v-else>Vertragsversion</span>
      </h1>
      <div class="formContainer">
        <h2>2. Benutzerdaten</h2>
        <form @submit.prevent="registerInstitution">
          <div class="data">
            <div class="column">
              <div id="registerInstituionsContainer">
                <div class="full">
                  <label for="anrede">Anrede*</label><br/>
                  <b-form-select style="width: 50%;" v-model="registerModel.Anrede" :options="options"></b-form-select>
                </div>
                <div class="full">
                  <label for="prename">Vorname*</label><br/>
                  <b-form-input id="prename" required v-model="registerModel.Vorname" />
                </div>
                <div class="full">
                  <label for="lastname">Nachname*</label><br/>
                  <b-form-input id="lastname" required v-model="registerModel.Nachname" />
                </div>
                <div class="full">
                  <label for="mail">E-Mail*</label><br/>
                  <b-form-input id="mail" required v-model="registerModel.Mail" />
                </div>
                <div class="full">
                  <label for="mail">E-Mail wiederholen*</label><br/>
                  <b-form-input id="mail" required v-model="email2" />
                </div>
              </div>
              <span style="font-size: 14px; display: block; margin-top: 8px;">* Pflichtfeld</span>
            </div>  
            <div class="column">
              <div class="registerFortschritt">
                Schritt 2/3
                <span class="twoThird"></span>
              </div>
              <img :src="require('@/assets/img/computer_ipad.png')">
              <p style="padding: 8px 0px">Die hier hinterlegte Person wird zum Administrator für INCOAID. Als Administrator können Sie Einstellungen vornehmen und weitere Benutzer anlegen.</p>
            </div>
            <div style="width: 100%;">
              <p style="width: 100%; padding: 10px 0px; color: #F6A800">{{createError}}</p>
            </div>
          </div>
          <div class="navigation">
            <b-button @click="$router.push({name: 'register'})" class="backButton btn-secondary">Zurück</b-button>
            <b-button class="registerButton" type="submit">Weiter</b-button>
          </div>  
        </form>
      </div>
      <div class="registerFooter">
        <div>
          <span v-if="registerModel.selectedVersion == 0" @click="registerModel.selectedVersion = 1">Für Vertragsversion registrieren</span>
          <span v-else @click="registerModel.selectedVersion = 0">Für Demoversion registrieren</span>
        </div>
        <div>
          <a href="https://hartmann.info/de-DE/Impressum" target="_blank">Impressum</a> | <a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutzerklärung</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from '@/services/EventBus.js'
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
import { ADD_REG_MAIL, ADD_REG_USERNAME, ADD_REG_MODEL } from '@/store/modules/data'

export default {
  name: 'Register',
  data () {
    return {
      registerModel: {},
      createError: '',
      emailError: '',
      correctKnr: '',
      email2: '',
      options: [{value: null, text: 'Bitte auswählen', disabled: true}, {value: 0, text: 'Frau'}, {value: 1, text: 'Herr'}  ]
    }
  },
  created: function () {
    this.registerModel = this.getRegisterModel
    this.registerModel.Anrede = null
  },
  methods: {
    buttonIsClickable: function() {
      if ( 
        this.registerModel.Anrede != null &&
        this.registerModel.Vorname !== "" && this.registerModel.Vorname !== undefined &&
        this.registerModel.Nachname !== "" && this.registerModel.Nachname !== undefined &&
        this.registerModel.Mail !== "" && this.registerModel.Mail !== undefined && 
        this.email2 !== "" && this.email2!== undefined 
      ) {
        return false
      } else {
        return true
      }
    },
    registerInstitution: function () {
      const self = this
      console.log(this.email2)
      console.log(this.registerModel.Mail)
      if (this.registerModel.Anrede != null) {
        if (this.email2 == this.registerModel.Mail) {
          if(this.validateEmail(this.registerModel.Mail)) {
          this.$store.dispatch(ADD_REG_MODEL,this.registerModel)
          this.$router.push({name: 'registerpass'})
            
          } else {
            this.createError = 'Keine korrekte E-Mail Adresse. Bitte überprüfen Sie die Schreibweise.'
          }
        } else {
          this.createError = 'Die Email-Adressen stimmen nicht überein.'
        }
      } else {
        this.createError = 'Bitte wählen Sie eine Anrede aus.'
      }
      
    },
    validateEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
  computed: {
    ...mapGetters(['getRegisterModel'])
  }
}
</script>
