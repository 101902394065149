Letzte Seite in der Anamnese.
<template>
  <div id="final">
    <div class='breadcrumb'>
      <!-- ><h2 v-if="currentWizardStep === 0">{{questionsComplete[currentWizardStep].segmentName}}</h2> -->
      <h2 style="margin-top: 140px;">Vielen Dank,</h2>
      <p style="width: 100%">dass Sie INCOAID zur Inkontinenzberatung benutzt haben.</p>
    </div>
    <div style="padding: 30px">
      <div style="padding: 40px 0px 40px 0px; border-bottom: 1px solid #989EA3">
        <b-button @click="removeStuff">Neue Beratung beginnen</b-button>
        <p>(löscht Daten des Betroffenen)</p>
      </div>
      <div style="padding: 40px 0px 0px 0px">
        <p>
          Sie können uns unter unserer Support-Hotline telefonisch erreichen:<br />
          <a style="color: #00BD71; font-size: 24px">0800 7235585</a>
        </p>
        <p>
          <strong>Unsere Servicezeiten:</strong><br />
          Montag bis Freitag: 8.00 Uhr - 16:30 Uhr
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import {EventBus} from '@/services/EventBus.js'
import {cartArrayStorage, answerStorage, cartProductStorage} from '@/utils/localstorage.js'
export default {
  name: 'Selling',
  data: function () {
    return {
      eventBusObject: EventBus
    }
  },
  methods: {
    removeStuff: function () {
      cartArrayStorage.remove()
      answerStorage.save({})
      cartProductStorage.save([])
      this.$router.push('/')
      this.eventBusObject.$emit('updateSummary')
    }
  }
}
</script>
