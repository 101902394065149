<template>
  <div id="successScreen">
    <div id="headerPic">
    </div>
    <div class="innerContainer">
      <h1>Ihre Registrierung war erfolgreich!</h1>
      <div class="formContainer">
        <div class="column">
          <h2>Bitte überprüfen Sie Ihre E-Mails</h2>
          <p style="margin-top: 40px;" >Wir haben eine E-Mail an <strong>{{mail}}</strong> geschickt. Klicken Sie auf den Link in der E-Mail, um Ihre E-Mail-Adresse zu bestätigen und Ihren Zugang zu aktivieren.</p>  
          <div style="margin-top: 120px;">
            <span  v-if="showResendEmailButton">Sie können die E-Mail nicht finden? Bitte sehen Sie in Ihrem Spam-Ordner nach.<br /><span style="color: #fff; text-decoration: underline; cursor: pointer;" @click="resendMail">E-Mail erneut senden</span>
            </span> 
            <span  v-if="resendSuccess" style="color: #fff!important;">E-mail wurde erneut an {{mail}} gesendet</span> 
          </div>
        </div>
        <div class="column">
          <img style="margin-top: 100px" :src="require('@/assets/img/emailIcon.png')">
        </div>
      </div>
      <div class="registerFooter">
        <div>
          <span><a href="/"> Zurück zur Startseite</a></span>
        </div>
        <div>
          <a href="https://hartmann.info/de-DE/Impressum" target="_blank">Impressum</a> | <a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutzerklärung</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
import { ADD_REG_MAIL } from '@/store/modules/data'
export default {
  name: 'RegisterSuccess',
  data () {
    return {
      showResendEmailButton: true,
      resendSuccess: false,
      mail: 'Ihre E-Mail-Adresse'
    }
  },
  created: function () {
    this.mail = this.getMail
  },
  methods: {
    resendMail: function () {
      const self = this
      self.mail = this.getMail
      var username = this.getUsername
      self.showResendEmailButton = false
      DataService.resendEmail(self.mail, username, false).then((response) => {
        self.resendSuccess = true
      }).catch((err) => {
        console.log(err)
        self.loginError = 'Der Benutzername ist nicht bekannt oder schon verifiziert.'
      })
    },
  },
  computed: {
    ...mapGetters(['getMail', 'getUsername'])
  }
}
</script>
