<template>
  <div id="welcomeScreen">
    <div :class="overlayClass" id="videoOverlay">
      <div @click="closeVideo()" class="closeButton"></div>
      <div class="videoContainer">
        <iframe id="yt_player_iframe" width="889" height="500" src="https://www.youtube.com/embed/WyYWQKupfNA?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div :class="nbClass" id="nbOverlay">
      <div @click="nbClass = ''" class="closeButton"></div>
      <div class="nbContent">
        Hier kann der Text für die Nutzungsbedingungen rein.
      </div>
    </div>
    <b-modal size="m" ref="emailsendModal" hide-footer title="Nachricht erfolgreich verschickt" style="text-align: center">
      <div class="d-block text-center">
        <br/>
        <b-button @click="$refs.emailsendModal.hide()" class="modalButton">Ok</b-button>
      </div>       
    </b-modal>
    <b-modal size="m" ref="emailsendErrorModal" hide-footer title="Es gab einen Fehler beim Versenden der Nachricht" style="text-align: center">
      <div class="d-block text-center">
        <br/>
        <b-button @click="$refs.emailsendErrorModal.hide()" class="modalButton">Ok</b-button>
      </div>       
    </b-modal>
    <b-modal size="m" ref="recaptchaErrorModal" hide-footer title="Roboter erkannt" style="text-align: center">
      <div class="d-block text-center">
        Wenn Sie wirklich kein Roboter sind, versuchen Sie es bitte erneut.
        <br/>
        <b-button @click="$refs.recaptchaErrorModal.hide()" class="modalButton">Ok</b-button>
      </div>       
    </b-modal>
    <div id="headerPicWelcome">
    </div>
    <div class="innerContainer">
      <div class="row">
        <div class="column">
          <h1>INCOAID - einfach, effizient und wirtschaftlich bei Inkontinenz beraten</h1>
          <div id="vorteile">
            <div>
              <img :src="require('@/assets/img/welcome_vorteil1.png')"> 
              <p>            
                <strong>INCOAID spart Zeit:</strong><br />Einfache digitale Anamnese und Dokumentation des Beratungsgespräches.
              </p>
            </div>  
            <div>
              <img :src="require('@/assets/img/welcome_vorteil2.png')">
              <p>
                <strong>INCOAID macht Sorgfalt einfach: </strong><br />Hilfestellung für eine professionelle Beratung – ohne aufwendige Schulung.
              </p>
             </div>
             <div>
              <img :src="require('@/assets/img/welcome_vorteil3.png')">
              <p>
                <strong>INCOAID hat die Kosten im Blick:</strong><br />Wirtschaftlichkeit und gute Versorgung gehen mit INCOAID Hand in Hand.
              </p>
             </div>
          </div>
        </div>
        <div class="column">
          <div id="registerContainer">
            <h3>Sofort loslegen! Richten Sie Ihren INCOAID Zugang ein!</h3>
            <div> 
              <label for="knr">Meine HARTMANN-Kundennummer</label><br/>
              <b-form-input maxlength="8" id="knr" required v-model="knr" />
              <div v-if="correctKnr === 'invalid'">
                <p style="padding: 10px 0px 0px 0px; color: #F6A800">Diese Kundennummer ist nicht in unserem System hinterlegt! Bitte korrigieren Sie oder geben Sie an, dass Ihnen die Kundenummer nicht bekannt ist.</p>
              </div>
              <div v-if="correctKnr === 'correct'">
                <p style="padding: 10px 0px 0px 0px">Super, diese Kundennummer ist unserem System hinterlegt! Wir haben einige deiner Daten voreingetragen.</p>
              </div>
            </div>
            <div>
              <b-form-checkbox v-model="knrMissing" @change="correctKnr = ''">
                <span>Ich habe noch keine Kundennummer oder diese gerade nicht zur Hand.</span>
              </b-form-checkbox>
            </div>
            <div>
              <b-button class="registerButton" @click='select("0")'>Kostenlos {{paket.Testphasendauer}} Tage testen*</b-button>
              <b-button class="registerButton" @click='select("1")'>Für Vertragsversion** registrieren</b-button>
            </div>
            <div class="legalHint">Hinweis: Wenn Sie fortfahren, stimmen Sie unseren <a href="/static/Nutzungsbedingungen INCOAID_2021.pdf" target="_blank">Nutzungsbedingungen</a> und <a target="_blank" href="/static/Datenschutz_INCOAID_2024.pdf">Datenschutzrichtlinien</a> zu.</div>
          </div>
          <div class="costHint mb-2">
            * Unverbindlich und ohne vertragliche Verpflichtung. Zugang endet automatisch ohne dass es einer Kündigung bedarf.
          </div>
          <div class="costHint">
            ** 19,95€ monatliche Nutzungsgebühr bei bis zu {{paket.Nutzerlimit-1}} weiteren Mitarbeiteraccounts
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <img :src="require('@/assets/img/computer_ipad.png')">
        </div>
        <div class="column">
          <h2>INCOAID - der neue Maßstab in der Inkontinenzberatung</h2>
          <p>INCOAID ist ein digitales Anamnesetool, das Beratende und Betroffene durch das Beratungsgespräch führt und automatisch die passenden Produkte für die Versorgung identifiziert.</p>
          <div>
            <a href="https://www.hartmann.info/de-de/loesungen/l/de/fuer-apotheken/incoaid" target="_blank"><b-button>Mehr erfahren</b-button></a>
            <b-button @click="startVideo()" style="margin-left: 12px;" variant="primary" >Video anschauen</b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="!sendMailSuccess" id="kontaktformular">

          <h2>Gerne überzeugen wir Sie von INCOAID persönlich. Schreiben Sie uns! </h2>
          <div>
            <span style="display: block; float: right; font-size: 11px; margin-top: 12px;">*Pflichtfelder</span>
          </div>
          <form @submit.prevent="sendMail">
          <div class="flexBlock">
            <div class="flexRow">
              <label for="instiname">Name der Institution*</label>           
              <b-form-input id="instiname" required v-model="emailKontakt.Institution" autocomplete="off" />
            </div>
            <div class="flexRow">
              <label for="instinr">Kundennummer</label>            
              <b-form-input placeholder="Ihre HARTMANN Kundenummer" maxlength="8" id="instinr" v-model="emailKontakt.Kundennummer" autocomplete="off"/>
            </div>
            <div class="flexRow">
              <label for="anrede">Anrede*</label>
              <b-form-select v-model="emailKontakt.Anrede" :options="options"></b-form-select>
            </div>
            <div class="flexRow">
              <label for="given-name">Vorname*</label>
              <b-form-input id="given-name" required v-model="emailKontakt.Vorname" />
            </div>
            <div class="flexRow">
              <label for="family-name">Nachname*</label>
              <b-form-input id="family-name" required v-model="emailKontakt.Nachname" />
            </div>
            <div class="flexRow">
              <label for="Plz">PLZ*</label>
              <b-form-input id="Plz" required v-model="emailKontakt.Plz" />
            </div>
            <div class="flexRow">
              <label for="ort">Ort*</label>
              <b-form-input id="ort" required v-model="emailKontakt.Ort" />
            </div>
            <div class="flexRow">
              <label for="strasse">Straße*</label>
              <b-form-input id="strasse" required v-model="emailKontakt.Strasse" />
            </div>
            <div class="flexRow">
              <label for="hausnummer"> Hausnummer*</label>
              <b-form-input id="hausnummer" required v-model="emailKontakt.Hausnummer" />
            </div>
            <div class="flexRow">
              <label for="mail">E-Mail*</label>
              <b-form-input id="mail" required v-model="emailKontakt.Mail" />
            </div>
            <div class="flexRow">
              <label for="telefon">Telefon*</label>
              <b-form-input id="telefon" required v-model="emailKontakt.Telefon" />
            </div>
            <div class="datenschutzCheck">
              <b-form-checkbox required v-model="datachecked">
                Ich bin mit der Speicherung und Verarbeitung meiner Daten zur Bearbeitung meiner Anfrage einverstanden und akzeptiere die <a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutzbestimmungen und Datenschutzregeln</a>*
              </b-form-checkbox>
              <span v-if="showNotAcceptedError && !datachecked" style="display: block;color:rgb(246, 168, 0); margin-top: 12px;">Bitte akzeptieren Sie unsere Datenschutzbestimmungen.</span>
            </div>
            <div style="overflow: auto;">
              <!--
              <div class="g-recaptcha" data-sitekey="6LeUfOIZAAAAAFeGm7jPnG-SQMBsg3tbaJGcHF3j"></div>
              -->
              <b-button type="submit" :class="datachecked? '':'owndisabledState'" >Absenden</b-button>
            </div>
          </div>
          </form>
        </div>
        <div v-else style="text-align: center;width:100%;margin-top: 50px; margin-bottom: 50px;">
          <span style="margin: 0 auto 32px auto;" class="checkCircle"></span>
          <h2>Danke für Ihre Anfrage! <br/>
          Wir setzen uns zeitnah mit Ihnen in Verbindung!</h2>
        </div>
      </div>
    </div>
    <div id="testimonial">
      <img :src="require('@/assets/img/testimonial.png')">
      <p>Axel Fels, Inhaber, Stadt-Apotheke Achern</p>
      <h3>„Neben der Struktur, die das Tool für alle Berater gleich vorgibt, liegt für uns der Vorteil auf jeden Fall in der Rechtssicherheit, die wir haben, wenn wir die Kunden beraten.“</h3>
    </div>
    <div id="moreInfos">
      <a target="_blank" href="https://www.hartmann.info/de-de/loesungen/l/de/fuer-apotheken/incoaid/vorteile">
        <h3>Darum INCOAID</h3>
        <img :src="require('@/assets/img/teaser_02.png')">
      </a>
      <a target="_blank" href="https://www.hartmann.info/de-de/wissen-und-news/d/1/incoaid-interview">
        <h3>Erfahrungen mit INCOAID</h3>
        <img :src="require('@/assets/img/teaser_01.png')">
      </a>
      <a target="_blank" href="https://www.hartmann.info/de-de/produkte/inkontinenzversorgung">
        <h3>Unser Inkontinenz Produktsortiment</h3>
        <img :src="require('@/assets/img/teaser_03.png')">
      </a>
    </div>
    <div id="welcomeFooter">
      <div>© 2020 PAUL HARTMANN AG</div>
      <div><a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutz</a> | <a href="https://www.hartmann.info/de-DE/Impressum" target="_blank">Impressum</a></div>
    </div>
  </div>
</template>

<script>
import {EventBus} from '@/services/EventBus.js'
import { ADD_REG_MODEL } from '@/store/modules/data'
import DataService from '@/services/DataService'

export default {
  name: 'Welcome',
  data () {
    return {
      eventBusObject: EventBus,
      overlayClass: "",
      nbClass: "",
      selectedVersion: '0',
      knr: '',
      knrMissing: false,
      correctKnr :'',
      datachecked: false,
      emailKontakt: {
        Anrede: null
      },
      showNotAcceptedError: false,
      sendMailSuccess: false,
      paket:{ 'Testphasendauer': 30},
      options: [{value: null, text: 'Bitte auswählen', disabled: true}, {value: 'Frau', text: 'Frau'}, {value: 'Herr', text: 'Herr'}  ]
    }
  },
  created () {
    DataService.fetchPakets(true).then((response) => {
        if (response.data.length > 0) {
          this.paket = response.data[0]
        }
      }).catch((err) => {
        // handle error
        console.log(err)
      })
     
  },
  mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render='+this.$recaptcha_key)
      document.head.appendChild(recaptchaScript)
    },
  methods: {
    
    select (x) {
      //console.log(this.$recaptcha_key)
      const self = this
      grecaptcha.ready(function() {
        grecaptcha.execute(self.$recaptcha_key, {action: 'submit'}).then(function(token) {
        // Send Recaptcha Token to backend for validation

          DataService.checkRecaptcha(token).then(function (response) {      
            if (response != null && response.data.success == true) {

              if (self.knrMissing == false) {
                if (self.knr != null && self.knr.length > 1) {
                  DataService.checkKnr(self.knr).then(function (response) {
                  
                    self.correctKnr = response.data.correct == true ? "correct" : "invalid"
                    
                    if (response.data.correct == true && response.data.data != null ) {
     
                      var regModel = {}

                      if (response.data.correct == true && response.data.data != null ) {
                        var institutionData = response.data.data 
                        var name = institutionData.name_1
                        if (institutionData.name_2 != null && institutionData.name_2.length > 0) {
                          name = name + " " + institutionData.name_2
                        }
                        regModel.Name = name
                        regModel.PLZ = institutionData.zip
                        regModel.Ort = institutionData.city
                        regModel.Strasse = institutionData.street
                        regModel.Hausnummer = institutionData.number
                    
                        regModel.Kundennummer = self.knr
                        regModel.KnrVerifiziert = true
                        regModel.selectedVersion = x
                      
                        self.$store.dispatch(ADD_REG_MODEL, regModel)
                        self.$router.push({name: 'register'}) //, params: {version: x }})
                      }

                    
                    }
                  }).catch((err) => {
                    console.log(err)
                  })
              
                } else {
                  self.correctKnr = 'invalid'
                }
              } else {
                var regModel = {
                        'Kundennummer' :'',
                        'KnrVerifiziert' : false,
                        'selectedVersion' : x
                      }
                 
                self.$store.dispatch(ADD_REG_MODEL, regModel)
                self.$router.push({name: 'register'}) // , params: {version: x }})
              }
              
            } else {
              self.$refs.recaptchaErrorModal.show()
            }
            
          }).catch((err) => {
              console.log(err)
              self.$refs.recaptchaErrorModal.show()
          })   
        }).catch((err) => {
          console.log(err)
        });
      });

      
     
      
    },
    pushToReg() {
      this.$router.push({name: 'register'})// , params: {version: this.selectedVersion }})
    },
    startVideo() {
      var video = document.getElementById("yt_player_iframe")
      video.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
      this.overlayClass = 'visible'
    },
    closeVideo() {
      var video = document.getElementById("yt_player_iframe")
      video.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
      this.overlayClass = ''
    },
    sendMail() {
      
      const self = this
      grecaptcha.ready(function() {
        grecaptcha.execute(self.$recaptcha_key, {action: 'submit'}).then(function(token) {
            // Send Recaptcha Token to backend for validation
            DataService.checkRecaptcha(token).then(function (response) {      
              if (response != null && response.data.success == true) {
                if (self.datachecked) {
                  self.showNotAcceptedError = false
                  DataService.sendMail(self.emailKontakt).then(function (response) {
                    if (response != null && response.data.success == true) {
                      //self.$refs.emailsendModal.show()
                      self.sendMailSuccess = true
                      self.emailKontakt = { Anrede: null}
                    } else {
                      self.$refs.emailsendErrorModal.show()
                    }
                  
                  }).catch((err) => {
                    console.log(err)
                    self.$refs.emailsendErrorModal.show()
                  })
                } else {
                  self.showNotAcceptedError = true
                }
              } else {
                self.$refs.recaptchaErrorModal.show()
              }
            
            }).catch((err) => {
              console.log(err)
              self.$refs.recaptchaErrorModal.show()
            })   
          }).catch((err) => {
              console.log(err)
          });
      });
    },
    buttonIsActive: function (key) {
      if (this.selectedVersion === key) {
        return 'active'
      }
    }
  }
}
</script>
