
Verwaltet die Liste aller Krankenkassen.
<template>
  <div id="cms-kvs">
    <div class="headerLine">
      <div style='overflow: auto; margin-bottom: 4px;'>
      <table>
        <tr>
          <td>
            Name
          </td>
          <td>
            Kinderpauschale in € (Netto)
          </td>
          <td>
            Urinpauschale in € (Netto)
          </td>
          <td>
            Stuhlpauschale in € (Netto)
          </td>
          <td>
            Kinderalter
          </td>
        </tr>
        <tr>
          <td>
            <input type="text" v-model="newKV.Kasse">
          </td>
          <td>
            <input type="number" v-model="newKV.Kinderpauschale">
          </td>
          <td>
            <input type="number" v-model="newKV.Urinpauschale">
          </td>
          <td>
            <input type="number" v-model="newKV.Stuhlpauschale">
          </td>
          <td>
            <input type="number" v-model="newKV.Kindalter">
          </td>
        </tr>
      </table>
      <b-btn @click="addNew()">hinzufügen</b-btn>
      </div>
      <div style='font-size: 12px; float: none'>
        <span>Schreibweise der Preise mit "." (Punkt)</span>
      </div>
    </div>
    <div>
      <table id="kvTable">
        <tr>
          <th><input class="searchField" type="text" v-model="search" placeholder="Name"></th>
          <th>Kinderpauschale in € (Netto)
          </th>
          <th>Urinpauschale in € (Netto)</th>
          <th>Stuhlpauschale in € (Netto)</th>
          <th>Kindalter</th>
        </tr>
        <template v-for="(kv, key) in matches">
          <tr :key="key" >
            <td v-if="editId == kv.id"><input type="text" v-model="editKV.Kasse"></td>
            <td v-else>{{kv.Kasse}}</td>
            <td v-if="editId == kv.id"><input type="text" v-model="editKV.Kinderpauschale"></td>
            <td v-else>{{kv.Kinderpauschale}}</td>
            <td v-if="editId == kv.id"><input type="text" v-model="editKV.Urinpauschale"></td>
            <td v-else>{{kv.Urinpauschale}}</td>
            <td v-if="editId == kv.id"><input type="text" v-model="editKV.Stuhlpauschale"></td>
            <td v-else>{{kv.Stuhlpauschale}}</td>
            <td v-if="editId == kv.id"><input type="text" v-model="editKV.Kindalter"></td>
            <td v-else>{{kv.Kindalter}}</td>
            <td v-if="editId === kv.id" @click="save()"><img :src="require('../../assets/img/cms_check.png')"></td>
            <td v-if="editId === kv.id" @click="cancel()"><img :src="require('../../assets/img/cms_cancel.png')"></td>
            <td v-if="editId !== kv.id" @click="edit(kv)"><img :src="require('../../assets/img/cms_edit.png')"></td>
            <td v-if="editId !== kv.id" @click="del(kv.id, false)"><img :src="require('../../assets/img/cms_delete.png')"></td>
          </tr>
        </template>
      </table>
    </div>

    <b-modal class="cms_notification_modal" ref="newKvModal" hide-footer hide-header title="">
      <div class="d-block text-center">
        <h5>Eine neue Krankenkasse wurde hinzugefügt.</h5>
        <b-button @click="$refs.newKvModal.hide()" class="modalButton">ok</b-button>
      </div>
    </b-modal>

    <b-modal class="cms_notification_modal" ref="editKvModal" hide-footer hide-header title="">
      <div class="d-block text-center">
        <h5>Die Krankenkasse wurde erfolgreich bearbeitet.</h5>
        <b-button @click="$refs.editKvModal.hide()" class="modalButton">Ok</b-button>
      </div>
      
    </b-modal>

    <b-modal class="cms_notification_modal" ref="deleteKvModal" hide-footer hide-header title="">
      <h5>Wollen Sie diese Krankenkasse wirklich entfernen?</h5>
      <b-button @click="del(0, true)" class="modalButton"  style="margin-right: 10px">Ja</b-button>
      <b-button @click="$refs.deleteKvModal.hide()" class="rightModalButton">Nein</b-button>
    </b-modal>
    <b-modal ref="errorAddModal" hide-footer title="Es gab einen Fehler beim Hinzufügen. Bitte überprüfen Sie die Eingaben.">
      <div class="d-block text-center">
        <div>
          <b-button class="modalButton" @click="$refs.errorAddModal.hide()">Ok</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
export default {
  name: 'KV',
  data: function () {
    return {
      kvArray: [],
      newKV: {Kasse: '', Kinderpauschale: 0, Urinpauschale: 0, Stuhlpauschale: 0, Kindalter: 18},
      editKV: {Kasse: '', Kinderpauschale: 0, Urinpauschale: 0, Stuhlpauschale: 0, Kindalter: 18},
      search: '',
      editId: 0,
      deleteID: 0
    }
  },
  created () {
  
    this.loadData()
  },
  methods: {
    loadData: function () {
      DataService.fetchKrankenkassen().then((response) => {
        this.kvArray = response.data
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    },
    addNew: function () {
      
      if (this.newKV.Kasse != null && this.newKV.Kasse !== '' && this.newKV.Kinderpauschale != null && this.newKV.Kinderpauschale !== '' && this.newKV.Urinpauschale != null && this.newKV.Urinpauschale !== '' && this.newKV.Stuhlpauschale != null && this.newKV.Stuhlpauschale !== ''&& this.newKV.Kindalter != null && this.newKV.Kindalter !== '') {
        
        var kindPausch = 0
        if (parseFloat(this.newKV.Kinderpauschale.toString().replace(",", ".")) != null) {
          kindPausch = parseFloat(this.newKV.Kinderpauschale.toString().replace(",", "."))
        }
        var urinPausch = 0 
        if (parseFloat(this.newKV.Urinpauschale.toString().replace(",", ".")) != null) {
          urinPausch = parseFloat(this.newKV.Urinpauschale.toString().replace(",", "."))
        }
        var stuhlPausch = 0
        if (parseFloat(this.newKV.Stuhlpauschale.toString().replace(",", ".")) != null) {
          stuhlPausch = parseFloat(this.newKV.Stuhlpauschale.toString().replace(",", "."))
        }
        this.newKV['Kinderpauschale'] = kindPausch
        this.newKV['Urinpauschale'] = urinPausch
        this.newKV['Stuhlpauschale'] = stuhlPausch

        
        DataService.setKVData(this.newKV).then((response) => {
          this.newKV = {Kasse: '', Kinderpauschale: 0, Urinpauschale: 0, Stuhlpauschale: 0, Kindalter: 18}
          this.loadData()
          this.$refs.newKvModal.show()
        }).catch((err) => {
          // handle error
          console.log(err)
        })
      } else {
        this.$refs.errorAddModal.show()
      }
    },
    del: function (id, confirmed) {
      if (confirmed === false) {
        this.deleteID = id
        this.$refs.deleteKvModal.show()
      } else {
        DataService.deleteKVData(this.deleteID).then((response) => {
          this.loadData()
          this.deleteID = 0
          this.$refs.deleteKvModal.hide()
        }).catch((err) => {
          // handle error
          console.log(err)
        })
      }
    },
    edit: function (kv) {
      this.editId = kv.id
      this.editKV = kv
    },
    save: function () {
      if (this.editKV.Kasse.length > 0) {
        DataService.setKVData(this.editKV).then((response) => {
          this.loadData()
          this.editId = 0
          this.editKV = {Kasse: '', Kinderpauschale: 0, Urinpauschale: 0, Stuhlpauschale: 0, Kindalter: 18}
          this.$refs.editKvModal.show()
        }).catch((err) => {
          // handle error
          console.log(err)
          this.editId = 0
        })
      }
    },
    cancel: function () {
      this.editId = 0
      this.editKV = {Kasse: '', Kinderpauschale: 0, Urinpauschale: 0, Stuhlpauschale: 0, Kindalter: 0}
      this.loadData()
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded', 'getInstitution']),
    matches () {
      return this.kvArray.filter((element) => {
        var elementText = element.Kasse
        return (elementText.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || this.search.length === 0)
      })
    }
  }
}
</script>
