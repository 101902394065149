<template>
  <div id="registerScreen">
    <div id="headerPic">
    </div>
    <div class="innerContainer">
      <h1>INCOAID Registrierung<br />
      <span v-if="registerModel.selectedVersion == 0">Demoversion</span>
      <span v-else>Vertragsversion</span>
      </h1>
      <div class="formContainer">
        <form @submit.prevent="registerInstitution">
          <h2>1. Adressdaten</h2>
          <div class="data">
            <div class="column">
              <div id="registerInstituionsContainer">
                <div style="width: 100%;">
                  <label for="name">Name der Institution*</label><br/>
                  <b-form-input id="name" required v-model="registerModel.Name"/>
                </div>
                <!--
                <div style="width: 76%;">
                  <label for="telefon">Telefonnummer</label><br/>
                  <b-form-input id="telefon" v-model="registerModel.Telefon" />
                </div>
                -->
                <div class="twoThird">
                  <label for="street">Straße*</label><br/>
                  <b-form-input id="street" required v-model="registerModel.Strasse" />
                </div>
                <div class="oneThird">
                  <label for="hausnummer">Hausnr.*</label><br/>
                  <b-form-input id="hausnummer" required v-model="registerModel.Hausnummer" />
                </div>
                <div class="oneThird">
                  <label for="plz">Postleitzahl*</label><br/>
                  <b-form-input id="plz" required v-model="registerModel.PLZ" />
                </div>
                <div class="twoThird">
                  <label for="ort">Ort*</label><br/>
                  <b-form-input id="ort" required v-model="registerModel.Ort" />
                </div>
              </div>
              <span style="font-size: 14px; display: block; margin-top: 8px;">* Pflichtfeld</span>
            </div> 
            <div class="column">
              <div class="registerFortschritt">
                Schritt 1/3
                <span class="oneThird"></span>
              </div>
              <img :src="require('@/assets/img/computer_ipad.png')">
            </div>
          </div>
          <div class="navigation">
           <b-button class="registerButton" type="submit">Weiter</b-button>
          </div>
        </form>
      </div>
      <div> 
      </div>
      <div class="registerFooter">
        <div>
          <span v-if="registerModel.selectedVersion == 0" @click="registerModel.selectedVersion = 1">Für Vertragsversion registrieren</span>
          <span v-else @click="registerModel.selectedVersion = 0">Für Demoversion registrieren</span>
        </div>
        <div>
          <a href="https://hartmann.info/de-DE/Impressum" target="_blank">Impressum</a> | <a href="/static/Datenschutz_INCOAID_2024.pdf" target="_blank">Datenschutzerklärung</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from '@/services/EventBus.js'
import DataService from '@/services/DataService'
import { mapGetters } from 'vuex'
import { ADD_REG_MAIL, ADD_REG_USERNAME, ADD_REG_MODEL} from '@/store/modules/data'

export default {
  name: 'Register',
  data () {
    return {
      registerModel: {},
      correctKnr: ''
    }
  },
  created: function () {
    this.registerModel = this.getRegisterModel
  },
  methods: {
    buttonIsClickable: function() {
      if ( 
        this.registerModel.Name !== "" && this.registerModel.Name !== undefined &&
        this.registerModel.PLZ !== "" && this.registerModel.PLZ !== undefined &&
        this.registerModel.Strasse !== "" && this.registerModel.Strasse !== undefined &&
        this.registerModel.Ort !== "" && this.registerModel.Ort !== undefined &&
        this.registerModel.Hausnummer !== "" && this.registerModel.Hausnummer !== undefined) {
        return false
      } else {
        return true
      }
    },
    registerInstitution: function () {
      this.$store.dispatch(ADD_REG_MODEL,this.registerModel)
      this.$router.push({name: 'registeruser'})    
    },
    prefillData(institutionData) {
      var name = institutionData.name_1
      if (institutionData.name_2 != null && institutionData.name_2.length > 0) {
        name = name + " " + institutionData.name_2
      }
      this.registerModel.Name = name
      this.registerModel.PLZ = institutionData.zip
      this.registerModel.Ort = institutionData.city
      this.registerModel.Strasse = institutionData.street
      this.registerModel.Hausnummer = institutionData.number
    }
  },
  computed: {
    ...mapGetters(['getRegisterModel'])
  }
}
</script>
